import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core';
import {EzFormControl} from 'wobi-web-common';

const useStyles = makeStyles(() => ({
  smallSelect: {
    padding: 10,
  },
}));

const SmallSelect = props => {
  const classes = useStyles();
  const {name, label, width, ...rest} = props;
  return (
    <EzFormControl
      formControlProps={{size: 'small'}}
      name={name}
      label={label}
      isInputLabel
    >
      <Select
        color='primary'
        style={{width}}
        classes={{select: classes.smallSelect}}
        {...rest}
      />
    </EzFormControl>
  );
};

SmallSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  width: PropTypes.string,
};

SmallSelect.defaultProps = {
  width: '100%',
};

export default SmallSelect;
