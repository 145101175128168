import React from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress} from '@material-ui/core';
import {fireGtmEvent} from '../../utils/marketing.utils';
import {getImageByAgent} from '../../config/agent';

const NoOffersImage = getImageByAgent('no-offers.svg');
const Coffee = getImageByAgent('coffee.svg');
const NoOffers = ({classes, submitContactUs, showContactUsThanks, isContactUsLoading}) => {
  React.useEffect(() => {
    fireGtmEvent('NoOffersPage');
  }, []);

  return (
    <div className={classes.noOffers}>
      <div className='text'>
        אופס..
        <br/>
        נראה שלא הצלחנו לאתר עבורך הצעות באתר.
        <br/>
        נשמח לדבר ולנסות למצוא לך את הצעת המחיר המשתלמת ביותר מחברות הביטוח המובילות במשק.
      </div>
      <div>
        {
          !showContactUsThanks ?
            !isContactUsLoading ?
              <Button variant='outlined' onClick={submitContactUs}>
                חזרו אלי
              </Button> :
              <CircularProgress className='contact_us_loader' size={40}/> :
            <div className={classes.contactUsThanks}>
              <img src={Coffee} className='coffee' alt='coffee'/>
              <div>
                <div className='title1'>פנייתך התקבלה</div>
                <div className='title2'>נציג מכירות יחזור אליך בהקדם</div>
              </div>
            </div>
        }
      </div>
      <div className='img-container'>
        <img src={NoOffersImage} alt='no-offers'/>
      </div>
    </div>
  );
};

NoOffers.propTypes = {
  classes: PropTypes.object,
  isContactUsLoading: PropTypes.bool,
  showContactUsThanks: PropTypes.bool,
  submitContactUs: PropTypes.func,
};

export default NoOffers;
