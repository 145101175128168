/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint  indent: ["warn",4,{ "ignoredNodes": ["TemplateLiteral"] }] */
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import Moment from 'moment';
import {Button, Grid, Icon, MenuItem} from '@material-ui/core';
import * as yup from 'yup';
import WobiLogo from 'wobi-web-common/dist/assets/img/wobi-logo.svg';
import {Loader} from 'wobi-web-common';
import {validIsraeliId} from 'wobi-web-common/dist/components/utils/validation';
import {setSimulatorOffersFields} from '../../utils/persistOfferDetails';
import Offer from '../../components/OfferSimulator';
import SmallSelect from '../../components/SimulatorComponents/SmallSelect';
import ModalContainer from '../../components/SimulatorComponents/ModalContainer';
import {ProductType} from '../../enums';
import {checkFullCoverIsProtected} from '../../utils/common';
import {getFollowUpCallRandomDate} from '../../utils/apiHandlers';
import {conditions} from '../../utils/formConditions';
import TwoFaceButton from '../../components/common/TwoFaceButton';
import GeneralDetails from './GeneralAutoDetails/GeneralDetails';
import AutoInfoDetails from './GeneralAutoDetails/AutoInfoDetails';
import useStyles from './useStyles';
import DriversDetails from './DriversDetails/DriversDetails';
import PassedInsurance from './DriversDetails/PassedInsurance';
import Claims from './DriversDetails/Claims';
import MoreDetails from './MoreDetails';
import FollowUpCall from './FollowUpCall';

const policyTypes = [
    {
        text: 'מקיף',
        value: 'FULLCOVER',
    },
    {
        text: 'צד ג׳',
        value: '3RDPARTY',
    },
];

const ALLOW_FIELDS_CHANGE_AFTER_GET_OFFER = {
    firstName: true,
    followupCallDate: true,
    followupPhoneNumber: true,
    israeliId: true,
    isSaleInProgress: true,
    lastName: true,
};

const DISABLE_SIMULATOR_FOLLOW_UP_CALLS = process.env.REACT_APP_DISABLE_SIMULATOR_FOLLOW_UP_CALLS;
const ALLOWED_FOLLOW_UP_RECORD_TYPES = 'Car_Insurance';

const SimulatorLayout = React.memo(({
    formikHandleChange,
    formikSetField,
    formikValues,
    formikValidateFrom,
    formikSetTouched,
    submitCallback,
    offers,
    onSelectOffer,
    processWithoutOffer,
    openModal,
    modalHeader,
    updating,
    isUpdated,
    closeModalCallback,
    setCityHasStreets,
    setLicenseNumberValidationCallback,
    currentStreetAndBuildingValidation,
    currentLicenseNumberValidation,
    coversTitles,
    loaderState,
    setIsOffersValuesChanged,
    agent,
}) => {
    const intl = useIntl();
    const classes = useStyles();
    const messages = (id) => intl.formatMessage({id});
    const [openOfferIndex, setOpenOfferIndex] = useState(0);
    const [isSpecialOffersOnly, setIsSpecialOffersOnly] = useState(false);
    const [isClicked] = useState(false);
    const [autoInfo, setAutoInfo] = useState(formikValues.autoInfo || null);
    const preferredOffersList = {};
    const isOpportunity = useRef(new URLSearchParams(window.location.search).get('id')?.slice(0, 3) === '006');
    const isAgentAllowedToFollowupCall = agent ? agent.isFollowUpEnabled : true;
    const isEnableFollowUpCalls =
        formikValues.recordTypeDeveloperName === ALLOWED_FOLLOW_UP_RECORD_TYPES &&
        !isOpportunity.current &&
        DISABLE_SIMULATOR_FOLLOW_UP_CALLS !== 'true' &&
        Boolean(isAgentAllowedToFollowupCall);
    const currentDay = Moment();
    const [isFollowUpLoading, setIsFollowUpLoading] = useState(false);
    const [maxDate, setMaxDate] = useState(null);

    const handleDateChange = (date) => {
        formikSetField('followupCallDate', date);
    };

    const updateFollowUpCallRandomDate = async (isNextMonthButton) => {
        const shouldSkipGettingFollowUp =
            !isAgentAllowedToFollowupCall ||
            !isEnableFollowUpCalls ||
            !formikValues.startDate && !isNextMonthButton;

        if (shouldSkipGettingFollowUp) {
            return;
        }
        setIsFollowUpLoading(true);
        getMaxDate();

        const {startDate} = formikValues;
        const result = await getFollowUpCallRandomDate({
            policyStartDate: startDate,
        });
        handleDateChange(result.data);
        setIsFollowUpLoading(false);
    };

    const getMaxDate = () => {
        const {startDate} = formikValues;
        const startDateMoment = Moment(startDate);

        const isSameDayPolicy = startDateMoment.isSame(currentDay, 'day');
        if (isSameDayPolicy) {
            const endOfTodayWorkingDay = currentDay.clone().endOf('day').hour(16).minute(59);
            const nowPlus2Hours = currentDay.clone().add(2, 'hours');

            if (nowPlus2Hours.isAfter(endOfTodayWorkingDay)) {
                setMaxDate(startDateMoment.clone().add(1, 'days').hour(15));
                return;
            }

            setMaxDate(endOfTodayWorkingDay);
            return;
        }

        const isFirstOfTheMonth = startDateMoment.date() === 1;
        if (isFirstOfTheMonth) {
            setMaxDate(startDateMoment);
        }

        const differentDays = startDateMoment.diff(currentDay.startOf('day'), 'days');
        if (differentDays > 7) {
            setMaxDate(currentDay.add(7, 'days'));
        } else {
            setMaxDate(startDateMoment);
        }
    };

    useEffect(() => {
        formikSetField('isOpportunity', isOpportunity.current);
        formikSetField('isAgentAllowedToFollowupCall', isAgentAllowedToFollowupCall);
        formikSetField('isEnableFollowUpCalls', isEnableFollowUpCalls);
        if (isEnableFollowUpCalls) {
            updateFollowUpCallRandomDate();
        }
    }, []);

    const [logoSrc, setLogoSrc] = useState('');
    useEffect(() => {
        getLogo().then(src => setLogoSrc(src));
    }, []);

    async function getLogo() {
        try {
            const src = agent.getAgentLogoPath;
            const image = await import(`../../${src}`);
            return image.default;
        } catch (error) {
            console.log('Error loading the image:', error);
        }
    }
    const handleSimulatorChange = (isChanged, fieldName) => {
        if (fieldName && ALLOW_FIELDS_CHANGE_AFTER_GET_OFFER[fieldName]) {
            return;
        }

        setIsOffersValuesChanged(isChanged);
    };

    const handleSetFromikFieldValue = (fieldName, value) => {
        handleSimulatorChange(true, fieldName);
        formikSetField(fieldName, value);
    };

    const handleFormikFieldChange = (event) => {
        handleSimulatorChange(true, event.target.name);
        formikHandleChange(event);
    };

    const handleSubmitStep = async () => {
        handleSimulatorChange(false);
        formikSetField('canPurchaseStep', false);
        const errors = await formikValidateFrom();

        const {buildingNumber, street, ...restErrors} = errors;
        if (Object.keys(restErrors).length !== 0) {
            const firstError = Object.keys(errors)[0];
            const firstErrorElement = document.getElementsByName(firstError)[0];
            if (firstErrorElement) {
                firstErrorElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
            }
            Object.keys({
                ...simulatorValidations,
                ...currentLicenseNumberValidation,
            }).forEach(field => formikSetTouched(field, true));
            return;
        }

        formikSetField('canPurchaseStep', true);

        setSimulatorOffersFields({...formikValues, autoInfo}, false);

        // setOffersValues(formikValues);
        submitCallback();
    };

    const handlePurchaseStep = async (offer, index) => {
        formikSetField('canPurchaseStep', true);
        const errors = await formikValidateFrom();

        // Check errors for streets and building number before purchase
        if (Object.keys(errors).length !== 0) {
            Object.keys({
                ...simulatorValidations,
                ...currentStreetAndBuildingValidation,
            }).forEach(field => formikSetTouched(field, true));

            const firstError = Object.keys(errors)[0];
            const firstErrorElement = document.getElementsByName(firstError)[0];
            if (firstErrorElement) {
                firstErrorElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
            }
            return;
        }

        if (formikValues.followupCallDate) {
            formikValues.followupCallDate = new Date(formikValues.followupCallDate).toISOString();
        }

        setSimulatorOffersFields({...formikValues, autoInfo}, false);

        if (!offer) {
            processWithoutOffer();
            return;
        }
        onSelectOffer(offer, index);
    };

    const handleSpecialButton = (state) => {
        setIsSpecialOffersOnly(state);
    };

    const onChangePreferredOffer = (offer, isPreferredOffer) => {
        if (preferredOffersList[offer.productLineId] !== undefined) {
            if (!isPreferredOffer) {
                delete preferredOffersList[offer.productLineId];
            }
        } else if (isPreferredOffer) {
            preferredOffersList[offer.productLineId] = offer;
        }
    };

    const filterOfferIsSpecial = (offer) => isSpecialOffersOnly ? offer.groups?.some(group => group.group === 'Special') :
        !offer.groups?.some(group => group.group === 'Special');

    return (
        <Grid container spacing={0} >
            <Grid item xs={12} className={classes.localHeader}>
                <Grid container spacing={0}>
                    <Grid item xs={2}>
                        <Button
                            size='small'
                            disabled={
                                formikValues.licenseRevocation.toString() === '-1' ||
                                formikValues.licenseRevocation.toString() === '2' || loaderState
                            }
                            onClick={handleSubmitStep}
                        >
                            {messages('simulator.getOffers')}
                            <Icon>arrow_back</Icon>
                        </Button>
                    </Grid>

                    <Grid item xs={3}>
                        <SmallSelect
                            width='220px'
                            value={formikValues.policyType}
                            onChange={(event) => {
                                handleSetFromikFieldValue('policyType', event.target.value);
                            }}
                            name='policyType'
                            label={intl.formatMessage({id: 'simulator.policyType'})}
                        >
                            {policyTypes.map(item => (<MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>))}
                        </SmallSelect>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            color='grey'
                            size='small'
                            disabled={
                                formikValues.licenseRevocation.toString() === '-1' ||
                                formikValues.licenseRevocation.toString() === '2' || loaderState
                            }
                            onClick={() => handlePurchaseStep()}
                        >
                            {messages('simulator.saveWithoutOffer')}
                            <Icon>arrow_back</Icon>
                        </Button>
                    </Grid>
                    <Grid item xs={4} container justify='flex-end'>
                        {agent ? <img src={logoSrc} alt='דף הבית wobi  משווים וחוסכים' /> : <img src={WobiLogo} alt='דף הבית wobi  משווים וחוסכים' />}
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
            </Grid>
            <Grid item xs={7} style={{padding: '70px 0 70px 7px'}}>
                <GeneralDetails
                    isOpportunity={isOpportunity.current}
                    formikHandleChange={handleFormikFieldChange}
                    formikSetField={handleSetFromikFieldValue}
                    formikValues={formikValues}
                    updateFollowUpCallRandomDate={updateFollowUpCallRandomDate}
                />
                {isEnableFollowUpCalls ? (
                    <FollowUpCall
                        formikHandleChange={handleFormikFieldChange}
                        formikSetField={handleSetFromikFieldValue}
                        formikValues={formikValues}
                        handleDateChange={handleDateChange}
                        isAgentAllowedToFollowupCall={isAgentAllowedToFollowupCall}
                        isEnableFollowUpCalls={isEnableFollowUpCalls}
                        isFollowUpLoading={isFollowUpLoading}
                        maxDate={maxDate}
                        minDate={currentDay}
                    />
                ) : null}
                <AutoInfoDetails
                    autoInfo={autoInfo}
                    setAutoInfo={setAutoInfo}
                    formikHandleChange={handleFormikFieldChange}
                    formikSetField={handleSetFromikFieldValue}
                    formikValues={formikValues}
                    setLicenseNumberValidationCallback={setLicenseNumberValidationCallback}
                    agentDeveloperName={agent?.developerName}
                />
                <DriversDetails
                    formikHandleChange={handleFormikFieldChange}
                    formikSetField={handleSetFromikFieldValue}
                    formikValues={formikValues} />
                <PassedInsurance formikSetField={handleSetFromikFieldValue} formikValues={formikValues} />
                <Claims formikSetField={handleSetFromikFieldValue} formikValues={formikValues} />
                <MoreDetails
                    formikHandleChange={handleFormikFieldChange}
                    formikSetField={handleSetFromikFieldValue}
                    formikValues={formikValues}
                    handleSubmitStep={handleSubmitStep}
                    setCityHasStreets={setCityHasStreets}
                />
            </Grid>
            <Grid item xs={5} style={{padding: '110px 7px 70px 0'}}>
                <Grid container spacing={0}>
                    <Grid
                        item
                        xs={12}
                        container
                        justify='center'
                        className={`${classes.loaderContainer}
            ${loaderState ? '' : 'hide'}`}
                    >
                        <Loader>
                            מגוון הצעות בדרך...
                        </Loader>
                    </Grid>
                    {offers.length > 0 ?
                        <Grid item xs={12} style={{margin: '10px 0px'}}
                            className={`${updating || isUpdated || isClicked ? classes.wrapperDisabled : ''}`}
                        >
                            <TwoFaceButton
                                onClick={(state) => handleSpecialButton(state)}
                                onText={messages('simulator.specialButton2')}
                                offText={messages('simulator.specialButton1')} />
                        </Grid> : ''
                    }
                    {
                        offers.filter((offer) => checkFullCoverIsProtected(offer) && filterOfferIsSpecial(offer)).map((offer, index) => (
                            <Grid item xs={12} key={`offer-${index}`} className={`${updating ? classes.wrapperDisabled : ''}`}>
                                <Offer
                                    titles={coversTitles}
                                    setIsOpen={() => setOpenOfferIndex(index)}
                                    onSelectOffer={() => handlePurchaseStep(offer, index)}
                                    onChangePreferredOffer={(isPreferredOffer) => onChangePreferredOffer(offer, isPreferredOffer)}
                                    data={{
                                        ...offer,
                                        pdfLink: `/policies/${offer.productLineId}/policy.pdf`,
                                    }}
                                    isOpen={true || openOfferIndex === index}
                                    noLeft
                                    updating={updating}
                                    agent={agent}
                                />
                            </Grid>
                        ))
                    }
                    {offers.length > 0 ?
                        <Grid item xs={12} style={{margin: '20px 0 0 0'}}
                            className={`${updating || isUpdated || isClicked ? classes.wrapperDisabled : ''}`}
                        >
                            <Button
                                className='submit'
                                onClick={() => {
                                    closeModalCallback();
                                }}
                            >
                                {messages('simulator.offer.back_potential')}
                            </Button>
                        </Grid> : ''
                    }
                    <ModalContainer showCloseButton={isOpportunity.current}
                        openModal={openModal} closeModalCallback={closeModalCallback} modalHeader={modalHeader}
                        showDefaultHeader={false}/>
                </Grid>
            </Grid>
        </Grid>
    );
});

SimulatorLayout.propTypes = {
    agent: PropTypes.object,
    closeModalCallback: PropTypes.func,
    coversTitles: PropTypes.array,
    currentLicenseNumberValidation: PropTypes.object,
    currentStreetAndBuildingValidation: PropTypes.object,
    formikHandleChange: PropTypes.func,
    formikSetField: PropTypes.func,
    formikSetTouched: PropTypes.func,
    formikValidateFrom: PropTypes.func,
    formikValues: PropTypes.object,
    isNewPotential: PropTypes.bool,
    isUpdated: PropTypes.bool,
    loaderState: PropTypes.bool,
    lowestPrice: PropTypes.number,
    modalHeader: PropTypes.string,
    offers: PropTypes.array,
    onSelectOffer: PropTypes.func,
    openModal: PropTypes.bool,
    potentialId: PropTypes.string,
    processWithoutOffer: PropTypes.func,
    recordTypeDeveloperName: PropTypes.string,
    setCityHasStreets: PropTypes.func,
    setIsOffersValuesChanged: PropTypes.func,
    setLicenseNumberValidationCallback: PropTypes.func,
    setStreetAndBuildingNumberValidationCallback: PropTypes.func,
    submitCallback: PropTypes.func,
    updatePreferredCallback: PropTypes.func,
    updating: PropTypes.bool,
};

const claimWithNoPastInsuranceMsg = 'לא ניתן להזין פרטי תביעה ללא עבר ביטוחי';

const now = Moment();

export const simulatorValidations = {
    canPurchaseStep: yup.boolean(),
    city: yup.string().required('שדה חובה'),
    driversCount: yup.string().required('שדה חובה'),
    fcw: yup.string().required('שדה חובה'),
    firstName: yup.string().when('canPurchaseStep', {
        is: (value) => value,
        then: yup.string().required('שדה חובה'),
    }),

    followupPhoneNumber: yup.string().nullable().when([
        'canPurchaseStep',
        'isEnableFollowUpCalls',
    ], {
        is: (
            canPurchaseStep,
            isEnableFollowUpCalls,
        ) => {
            if (!isEnableFollowUpCalls) {
                return false;
            }
            return canPurchaseStep || false;
        },
        then: yup.string()
            .length(10, 'יש להקליד עשר ספרות בלבד')
            .matches(/^0\d{9}$/, 'מספר טלפון - ספרות בלבד')
            .required('שדה חובה'),
    }),
    haveAnotherCar: yup.string().when('policyType',
        {
            is: (value) => value === ProductType.FullCover,
            then: yup.string().required('שדה חובה'),
        }),
    injuryClaims_1: yup.number().typeError('שדה חובה').required('שדה חובה'),

    injuryClaims_2: yup.number().typeError('שדה חובה').required('שדה חובה'),

    injuryClaims_3: yup.number().typeError('שדה חובה').required('שדה חובה'),

    insurance_1: yup.string().required('שדה חובה'),

    insurance_2: yup.string().required('שדה חובה'),

    insurance_3: yup.string().required('שדה חובה'),

    israeliId: yup.string().nullable().when(['canPurchaseStep', 'isOpportunity'], {
        is: (canPurchaseStep, isOpportunity) => {
            if (isOpportunity) {
                return false;
            } else {
                return canPurchaseStep;
            }
        },
        then: validIsraeliId,
    }),

    isSaleInProgress: yup.boolean().nullable().when([
        'canPurchaseStep',
        'isOpportunity',
        'isAgentAllowedToFollowupCall',
        'isEnableFollowUpCalls',
    ], {
        is: (
            canPurchaseStep,
            isOpportunity,
            isAgentAllowedToFollowupCall,
            isEnableFollowUpCalls,
        ) => {
            const IS_SIMULATOR_FOLLOWUP_FIELD_OPTIONAL = process.env.REACT_APP_IS_SIMULATOR_FOLLOWUP_FIELD_OPTIONAL;
            const isSimulatorFollowupFieldOptional = IS_SIMULATOR_FOLLOWUP_FIELD_OPTIONAL === 'true';
            if (isOpportunity || isSimulatorFollowupFieldOptional || !isAgentAllowedToFollowupCall || !isEnableFollowUpCalls) {
                return false;
            }
            return canPurchaseStep || false;
        },
        then: yup.boolean().required('שדה חובה'),
    }),
    lastName: yup.string().when('canPurchaseStep', {
        is: (value) => value,
        then: yup.string().required('שדה חובה'),
    }),
    ldw: yup.string().required('שדה חובה'),
    licenseRevocation: yup.number().required('שדה חובה'),
    mainDriverBirthDate: yup.string().nullable(true).min(1).when(['driversCount', 'isYoungDriverIsMain'], {
        is: (driversCount, isYoungDriverIsMain) => driversCount === '-1' && isYoungDriverIsMain === 'no',
        then: yup.string().min(1).nullable(true).required('שדה חובה'),
    }),

    mainDriverSeniority: yup.string().when(['driversCount', 'isYoungDriverIsMain'], {
        is: (driversCount, isYoungDriverIsMain) => driversCount === '-1' && isYoungDriverIsMain === 'no',
        then: yup.string().required('שדה חובה'),
    }),

    // youngDriverBirthDate: yup.date().typeError('שדה חובה').required(),
    model: yup.string().typeError('שדה חובה').required('שדה חובה'),

    pledged: yup.string().required('שדה חובה'),

    policyType: yup.string().required('שדה חובה'),

    propClaims_1: yup.number().required('שדה חובה').test(
        'propClaims_1',
        claimWithNoPastInsuranceMsg,
        function () {
            return this.parent.insurance_1 === '0' ? this.parent.propClaims_1 === 0 : true;
        },
    ).test(
        'propClaims_1',
        'לא ניתן להמשיך עם יותר משתי תביעות',
        function () {
            return this.parent.propClaims_1 +
                this.parent.propClaims_2 +
                this.parent.propClaims_3 < 3;
        },
    ),
    propClaims_2: yup.number().required('שדה חובה').test(
        'propClaims_2',
        claimWithNoPastInsuranceMsg,
        function () {
            return this.parent.insurance_2 === '0' ? this.parent.propClaims_2 === 0 : true;
        },
    ).test(
        'propClaims_2',
        'לא ניתן להמשיך עם יותר משתי תביעות',
        function () {
            return this.parent.propClaims_1 +
                this.parent.propClaims_2 +
                this.parent.propClaims_3 < 3;
        },
    ),
    propClaims_3: yup.number().required('שדה חובה').test(
        'propClaims_3',
        claimWithNoPastInsuranceMsg,
        function () {
            return this.parent.insurance_3 === '0' ? this.parent.propClaims_3 === 0 : true;
        },
    ).test(
        'propClaims_3',
        'לא ניתן להמשיך עם יותר משתי תביעות',
        function () {
            return this.parent.propClaims_1 +
                this.parent.propClaims_2 +
                this.parent.propClaims_3 < 3;
        },
    ),
    saturdayDriving: yup.string().required('שדה חובה'),
    startDate: yup.mixed().required('שדה חובה')
        .test('validDate', 'תאריך שגוי', (date) => conditions.isValidDate(date))
        .test('aboveMin', 'לא ניתן לבחור בתאריך שכבר חלף',
            (date) => conditions.isAboveMin(date, now)),

    /*
  street: yup.string().when('canPurchaseStep', {
    is: (value) => value,
    then: yup.string().required('שדה חובה'),
  }),
  */

    subModel: yup.string().typeError('שדה חובה').required('שדה חובה'),
    year: yup.string().typeError('שדה חובה').required('שדה חובה'),
    youngDriverGender: yup.string().required('שדה חובה'),
    youngDriverSeniority: yup.string().test(
        'youngDriverSeniority',
        'ותק לא תקין',
        function () {
            const firstYearSeniorityAgeYears = 16;
            const firstYearSeniorityAgeMonths = 9;
            return Moment(this.parent.youngDriverBirthDate)
                .add(firstYearSeniorityAgeYears + Number(this.parent.youngDriverSeniority), 'years')
                .add(firstYearSeniorityAgeMonths, 'months')
                .isBefore();
        },
    ).required('שדה חובה'),
};

export default SimulatorLayout;
