import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Grid, Box, makeStyles, Button, CircularProgress} from '@material-ui/core';
import {Black} from 'wobi-web-common/dist/Colors';
import {Breakpoints as breakpoints} from 'wobi-web-common';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import {getOffersFields} from '../utils/persistOfferDetails';
import {handleContactUsSubmit} from '../utils/apiHandlers';
import {
  updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields,
  updateForceTriggeredByInSessionStorageOffersFields,
} from '../utils/common';
import {TRIGGERED_BY, abandonmentStep} from '../constants/enums';
import {getImageByAgent} from '../config/agent';

const Coffee = getImageByAgent('coffee.svg');
const LaptopImage = getImageByAgent('hero-home.png');

const OverUse = () => {
  const classes = useStyles();
  const [isContactingUs, setContactingUs] = React.useState(false);
  const [isThankYou, setThankYou] = React.useState(false);
  const policyDetails = {
    firstname: getOffersFields('firstname'),
    lastname: getOffersFields('lastname'),
    phone: getOffersFields('phone'),
  };

  const submitAction = () => {
    setContactingUs(true);
    updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_1.key);
    updateForceTriggeredByInSessionStorageOffersFields(TRIGGERED_BY.WEBSITE_BLOCKED_OVERUSE_USER);
    handleContactUsSubmit({
      fullname: `${policyDetails.firstname} ${policyDetails.lastname}`,
      phone: policyDetails.phone,
    }, getOffersFields(), TRIGGERED_BY.WEBSITE_BLOCKED_OVERUSE_USER).then(() => {
      setContactingUs(false);
      setThankYou(true);
      setTimeout(() => {
        setThankYou(false);
      }, 5000);
    });
  };

  return (
    <div>
      <Box pr={{lg: 0,
        xs: 3}}>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <div className={`${classes.messageTop}`}>
              <FormattedMessage id='overuse.main_message' />
            </div>
          </Grid>
          {isThankYou ? (
            <Grid item xs={12} lg={8}>
              <div className={classes.contactUsThanks}>
                <img src={Coffee} className='coffee' alt='coffee'/>
                <div>
                  <div className='title1'>פנייתך התקבלה</div>
                  <div className='title2'>נציג מכירות יחזור אליך בהקדם</div>
                </div>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} lg={8}>
              {isContactingUs ? (
                <CircularProgress className='contact_us_loader' size={40}/>
              ) : (
                <Button
                  className={`${classes.callMeBackButton}`}
                  variant='outlined'
                  onClick={submitAction}
                >
                  <FormattedMessage id='overuse.send_button' />
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
      <div className={`${classes.imgContainer}`}>
        <img src={LaptopImage} alt='contact us for better offers'/>
      </div>
    </div>
  );
};

export default OverUse;

const useStyles = makeStyles(() => ({
  callMeBackButton: {
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'space-between',
    marginTop: 30,
    width: '200px',
  },
  contactUsThanks: {
    '& > div': {
      color: agentDeveloperUtils.getConvertedColor(Black[170]),
      fontSize: 18,
      marginLeft: 20,
    },
    '& img': {
      width: 80,
    },
    alignItems: 'center',
    border: `1px solid ${agentDeveloperUtils.getConvertedColor(Black[1])}`,
    borderRadius: 10,
    display: 'inline-flex',

    margin: '20px 0',

    padding: '15px 40px',
  },
  imgContainer: {
    '& > img': {
      maxWidth: 500,
      width: '100%',
    },
    alignSelf: 'flex-end',
    padding: '20px 45px 20px 0',
    textAlign: 'center',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {padding: '10px'},
    width: '100%',
  },
  messageTop: {
    color: agentDeveloperUtils.getConvertedColor(Black[170]),
    fontSize: '24px',
    marginBottom: '20px',
  },
}));
