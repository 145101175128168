import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {Form, Formik} from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Button, TextField, MenuItem, FormHelperText, CircularProgress, Grid} from '@material-ui/core';
import {Breakpoints as breakpoints, EzFormControl, Tooltip, agentDeveloperUtils} from 'wobi-web-common';
import {validIsraeliId} from 'wobi-web-common/dist/components/utils/validation';
import HelpIcon from '@material-ui/icons/Help';
import {getOffersFields, setOffersFields, setSelectedOffer} from '../../utils/persistOfferDetails';
import {utils} from '../../utils/offerFieldsActions';
import {verifyPayment, updateCustomerSelectedOffer, handleContactUsSubmit} from '../../utils/apiHandlers';
import {fireGtmEvent} from '../../utils/marketing.utils';
import {
  updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields,
  updateForceTriggeredByInSessionStorageOffersFields,
} from '../../utils/common';
import {TRIGGERED_BY, abandonmentStep} from '../../constants/enums';
import {getImageByAgent} from '../../config/agent';
import {isShowContactUsPostIlEmploeeDiscount} from '../../utils/showContactUsHelper';
import useStyles from './useStyles';

const TelephoneWhiteIcon = getImageByAgent('telephone-white.svg');
const daniela = getImageByAgent('daniela.png');

const PurchaseContainer = injectIntl(withRouter(({intl, data, history, openContactUs, setCreditCardCommission,
  setCreditCardCommissionDiscount}) => {
  const messages = (id) => intl.formatMessage({id});
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);
  const yearsOptions = utils.getYearsOptions();
  const monthsOptions = utils.getMonthsOptions();
  const classes = useStyles({isMobile});

  // State
  const [showAllForm, setShowAllForm] = React.useState(false);
  const [payments, setPayments] = React.useState([]);
  const [freeOfInterest, setFreeOfInterest] = React.useState(null);
  const [creditInterest, setCreditInterest] = React.useState([]);
  const [isContactLoading, setIsContactLoading] = React.useState(false);
  const [isVerifyingPayment, setIsVerifyingPayment] = React.useState(false);
  const [isShowContactUs, setIsShowContactUs] = React.useState(true);

  const creditCardNumber_class = (formShowAll, errors_creditCardNumber, touched_creditCardNumber) => {
    if (!formShowAll) {
      return 'init';
    }
    if (!errors_creditCardNumber) {
      return 'helperText';
    }
    if (showAllForm && errors_creditCardNumber && touched_creditCardNumber) {
      return 'helperText err';
    }
    return '';
  };

  const handleSelectedOffer = async () => {
    updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_3.key);
    setSelectedOffer(data);
    const token = getOffersFields('offersCallId');
    if (token) {
      const resp = await updateCustomerSelectedOffer({
        selectedOffer: data,
        token,
      });
      if (resp.success && resp.data) {
        setFreeOfInterest(resp.data.freeOfInterest);
        setCreditInterest(resp.data.creditInterest);
        generatePayments(resp.data);
      }
    }
  };

  const extendForm = () => {
    handleSelectedOffer();
    setShowAllForm(true);
  };

  const submitContactUs = async () => {
    updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_3.key);
    updateForceTriggeredByInSessionStorageOffersFields(TRIGGERED_BY.WEBSITE_INSIDE_OFFER);
    setIsContactLoading(true);
    try {
      fireGtmEvent('ContactusOffersButton', {provider: data.provider});
      await handleSelectedOffer();
      await handleContactUsSubmit({
        fullname: `${getOffersFields('firstname')} ${getOffersFields('lastname')}`,
        offersCallId: getOffersFields('offersCallId'),
        phone: getOffersFields('phone'),
      }, getOffersFields(), TRIGGERED_BY.WEBSITE_INSIDE_OFFER);
      openContactUs();
      setIsContactLoading(false);
    } catch (error) {
      setIsContactLoading(false);
      window.rollbar.error(error);
    }
  };

  const generatePayments = (selectedOfferData) => {
    const items = [];
    for (let i = 0; i < selectedOfferData.creditInterest.length; i++) {
      items.push(i + 1);
    }
    setPayments(items);
  };

  const handlePaymentChange = (value, setFieldValue) => {
    setFieldValue('payment', value);
    if (Number(freeOfInterest) < Number(value)) {
      // eslint-disable-next-line max-len
      const creditInterestCalc = Math.round(data.cascoPrice * creditInterest[Number(value) - 1] / 100);
      setCreditCardCommission(creditInterestCalc);
      // eslint-disable-next-line max-len
      const creditInterestCalcDiscount = Math.round(data.cascoAfterDiscountPrice * creditInterest[Number(value) - 1] / 100);
      setCreditCardCommissionDiscount(creditInterestCalcDiscount);
    } else {
      setCreditCardCommission(0);
      setCreditCardCommissionDiscount(0);
    }
  };

  const handleIsShowContactUs = () => {
    const offersFields = sessionStorage.getItem('offers_fields');
    let utmSource = '';
    if (offersFields) {
      const parsedFields = JSON.parse(offersFields);
      utmSource = parsedFields?.utm_source;
    }
    const agentDeveloperName = agentDeveloperUtils.getAgentDeveloperName;
    if (!isShowContactUsPostIlEmploeeDiscount(utmSource, agentDeveloperName)) {
      setIsShowContactUs(false);
    }
  };

  React.useEffect(() => {
    handleIsShowContactUs();
  }, []);

  return (
    <div className={classes.purchaseContainer}>
      <Formik
        onSubmit={(values, {setErrors}) => {
          const phoneNumber = getOffersFields('phone');
          const requestData = {
            ...values,
            creditInterest: creditInterest[Number(values.payment) - 1],
            month: String(values.month).padStart(2, '0'),
            offersCallId: getOffersFields('offersCallId'),
            orderId: sessionStorage.getItem('orderId'),
            payment: String(values.payment),
            phoneNumber,
            year: String(values.year),
          };
          if (!getOffersFields('licenseNumber')) {
            setOffersFields({licenseNumber: values.licenseNumber});
          }
          setIsVerifyingPayment(true);
          verifyPayment(requestData).then(resp => {
            setIsVerifyingPayment(false);
            if (!resp || !resp.success) {
              updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_4.key);
              setErrors({creditCardNumber: messages('validation.payment_submit_error')});
            } else {
              // add action
              setOffersFields({potentialId: resp.data.id});
              fireGtmEvent('ConfirmOrder', {provider: data.providerName});
              history.push('/thank-you/');
            }
          }).catch(() => {
            setIsVerifyingPayment(false);
            updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_4.key);
            setErrors({creditCardNumber: messages('validation.payment_submit_error')});
          });
        }}
        validationSchema={yup.object().shape({
          // make sure in the card holder contain only hebrew letters or english letters and one space between them
          cardHolder: yup.string().test('len', messages('validation.required'), (val) => {
            if (val) {
              return val.split(' ').filter(item => item !== '').length === 2;
            }
            return true;
          }).required(messages('validation.required')),
          creditCardNumber: yup.number()
            .typeError(messages('validation.number_not_valid'))
            .required(messages('validation.required')),
          cvv: yup.number()
            .typeError(messages('validation.number_not_valid'))
            .required(messages('validation.required')),
          identification: validIsraeliId,
          licenseNumber: yup.number()
            .typeError(messages('validation.number_not_valid'))
            .required(messages('validation.required'))
            .test('len', messages('validation.number_not_valid'), utils.licenseNumberValidation),
          month: yup.string().required(messages('validation.required')),
          payment: yup.string().required(messages('validation.required')),
          year: yup.string().required(messages('validation.required')),
        })}
        initialValues={{
          cardHolder: `${getOffersFields('firstname')} ${getOffersFields('lastname')}`,
          creditCardNumber: '',
          creditCardType: '',
          cvv: '',
          identification: '',
          licenseNumber: getOffersFields('licenseNumber') || '',
          month: '',
          payment: '',
          year: '',
        }}>
        {({values, errors, touched, handleChange, handleSubmit, setFieldValue}) => (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              className={`${classes.form} ${showAllForm ? '' : 'init'}`}
            >
              {showAllForm && (
                <Grid
                  item
                  xs={12}
                  md={showAllForm ? 12 : 6}
                  lg={6}
                  className={creditCardNumber_class(showAllForm, errors.creditCardNumber, touched.creditCardNumber)}
                >
                  <EzFormControl
                    name='creditCardNumber'
                    label={messages('fields.credit_card_number')}
                    labelFor={`creditCardNumber-${data.productLineId}`}
                    isLabelInChild
                  >
                    <TextField
                      id={`creditCardNumber-${data.productLineId}`}
                      onFocus={() => {
                        fireGtmEvent('Purchase', {provider: data.provider});
                        extendForm();
                      }}
                      className={classes.creditCardInput}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                        handleChange(e);
                      }
                      }
                      size='small'
                      fullWidth
                      InputLabelProps={{className: classes.inputLabel}}
                    />
                  </EzFormControl>
                  {showAllForm ? (
                    <FormHelperText className={classes.creditCardHelp}>
                      {messages('fields.credit_card_number.helpText')}
                    </FormHelperText>
                  ) : false}
                </Grid>
              )}
              { !isMobile && !showAllForm && isShowContactUs && (
                <Grid item xs={12} md={6} className={classes.callToRepresentativeBtn}>
                  <Button
                    className={`${classes.contactUsBtn} init`}
                    onClick={submitContactUs}
                    disabled={isContactLoading}
                  >
                    <img src={TelephoneWhiteIcon} alt='call-us' className={classes.whiteTelephone} />
                    {messages('offers.contact_us')}
                  </Button>
                </Grid>)}
              {!showAllForm && <Grid item xs='auto' md={6} lg='auto' style={{padding: 0}} />}

              {!showAllForm && (
                <Grid item xs={12} md={6} className={classes.paymentBtnInitWrapper}>
                  <Button
                    className={`${classes.paymentBtn} init`}
                    onClick={extendForm}
                    fullWidth
                  >
                    {messages('offers.payment_btn')}
                  </Button>
                  {isMobile && data.protectionTitle ? (
                    <div className={`${classes.protectionWrapper} open`}>
                      <span className={classes.protection}>{data.protectionTitle}</span>
                    </div>
                  ) : false}
                  {isMobile && (
                    <div className={classes.danielaMobile}>
                      <img src={daniela} alt='daniela' />
                    </div>
                  )}
                </Grid>
              )}
              {showAllForm && (
                <React.Fragment>
                  <Grid item xs={4} sm={6} lg={3}>
                    <EzFormControl
                      name='year'
                      label={messages('fields.card_year')}
                      labelFor='creditcard-year'
                      isLabelInChild
                    >
                      <TextField
                        select
                        id='creditcard-year'
                        labelId='year-label'
                        value={values.year}
                        onChange={(e) => setFieldValue('year', e.target.value)}
                        size='small'
                        fullWidth
                        InputLabelProps={{className: classes.inputLabel}}
                      >
                        {yearsOptions.map(year => <MenuItem key={`year-${year}`} value={year}>{year}</MenuItem>)}
                      </TextField>
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={4} sm={6} lg={3}>
                    <EzFormControl
                      name='month'
                      label={messages('fields.card_month')}
                      labelFor='creditcard-month'
                      isLabelInChild
                    >
                      <TextField
                        select
                        id='creditcard-month'
                        labelId='month-label'
                        value={values.month}
                        onChange={(e) => setFieldValue('month', e.target.value)}
                        fullWidth
                        size='small'
                        InputLabelProps={{className: classes.inputLabel}}
                      >
                        {monthsOptions.map(month => <MenuItem key={`month-${month}`} value={month}>{month}</MenuItem>)}
                      </TextField>
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={4} sm={6} lg={3}>
                    <EzFormControl
                      name='cvv'
                      label={messages('fields.cvv')}
                      labelFor={`cvv-${data.productLineId}`}
                      isLabelInChild
                    >
                      <TextField
                        id={`cvv-${data.productLineId}`}
                        onChange={e => {
                          e.target.value = e.target.value.replace(/\D/g, '');
                          handleChange(e);
                        }}
                        type='cvv'
                        inputProps={{maxLength: '4'}}
                        size='small'
                        fullWidth
                        onBlur={() => fireGtmEvent('FillCreditDetails', {provider: data.provider})}
                        InputLabelProps={{className: classes.inputLabel}}
                      />
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={4}>
                    <EzFormControl
                      name='licenseNumber'
                      label={messages('fields.licenseNumber')}
                      labelFor={`licenseNumber-${data.productLineId}`}
                      isLabelInChild
                    >
                      <TextField
                        id={`licenseNumber-${data.productLineId}`}
                        value={values.licenseNumber}
                        onChange={handleChange}
                        disabled={Boolean(getOffersFields('licenseNumber'))}
                        size='small'
                        fullWidth
                        InputLabelProps={{className: classes.inputLabel}}
                      />
                    </EzFormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={5}>
                    <EzFormControl
                      name='cardHolder'
                      label={messages('fields.cardHolder')}
                      labelFor={`cardHolder-${data.productLineId}`}
                      isLabelInChild
                    >
                      <TextField
                        id={`cardHolder-${data.productLineId}`}
                        onChange={(e) => {
                          // replace all non hebrew letters or english letters
                          e.target.value = e.target.value.replace(/[^ A-Za-zא-ת]/g, '');
                          handleChange(e);
                        }}
                        value={values.cardHolder}
                        size='small'
                        fullWidth
                        InputLabelProps={{className: classes.inputLabel}}
                      />
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <EzFormControl
                      name='identification'
                      label={messages('fields.identification')}
                      labelFor={`identification-${data.productLineId}`}
                      isLabelInChild
                    >
                      <TextField
                        id={`identification-${data.productLineId}`}
                        onChange={(e) => {
                          e.target.value = e.target.value.replace(/\D/g, '');
                          handleChange(e);
                        }}
                        size='small'
                        fullWidth
                        InputLabelProps={{className: classes.inputLabel}}
                      />
                    </EzFormControl>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <EzFormControl
                      name='payment'
                      isLabelInChild
                      label={`${messages('fields.payments')} ${messages(data.productType.toLowerCase())}`}
                      labelFor='creditcard-payment'
                    >
                      <TextField
                        select
                        id='creditcard-payment'
                        value={values.payment}
                        onChange={(e) => handlePaymentChange(e.target.value, setFieldValue)}
                        style={{
                          flex: '1 0 auto',
                          minWidth: 135,
                        }}
                        size='small'
                        InputLabelProps={{className: classes.inputLabel}}
                      >
                        {payments.map(item => <MenuItem key={`payment-${item}`} value={item}>{item}</MenuItem>)}
                      </TextField>
                      <Tooltip
                        placement='bottom'
                        title={`עד ${
                          freeOfInterest
                        } תשלומים ללא ריבית, מעל ${
                          freeOfInterest
                        } תשלומים יתווספו דמי אשראי ע״פ תנאי חברת ${
                          data.providerName
                        }`}
                      >
                        <span className={classes.tooltipWrap}>
                          <HelpIcon style={{height: 20,
                            width: 20}} className={classes.tooltip} />
                        </span>
                      </Tooltip>
                    </EzFormControl>
                    {isMobile ? false : (
                      <div className={classes.securedPayment}>
                        <div className={classes.securedPaymentInner}>{messages('offers.secured')}</div>
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} className={classes.purchaseBtnWrapper}>
                    <Button
                      className={classes.paymentBtn}
                      type='submit'
                      disabled={isVerifyingPayment}
                      data-testid='offer-purchase-btn'
                    >
                      {messages('offers.payment_end_btn')}
                    </Button>
                    {
                      isVerifyingPayment ? <CircularProgress className={classes.verifyLoader} size={30} /> : false
                    }
                    {isMobile && (
                      <div className={`${classes.danielaMobile} ${classes.danielaMobileOpen}`}>
                        <img src={daniela} alt='daniela' />
                      </div>
                    )}
                  </Grid>
                  {
                    isMobile ?
                      <div className={`${classes.includedWrapper} open`}>
                        <div className={classes.includedText}>{messages('offers.included1')}</div>
                        <div className={classes.includedText}>{messages('offers.included2')}</div>
                      </div> : false
                  }
                </React.Fragment>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      {!isMobile && (
        <div className={classes.daniela}>
          <img src={daniela} alt='daniela' />
        </div>
      )}
    </div>
  );
}));

export default PurchaseContainer;
