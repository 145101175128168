import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {FormControl, FormLabel, Fab} from '@material-ui/core';
import carBrandsData from 'wobi-web-common/dist/assets/js/carBrandsData';
import {ChipsSelect, agentDeveloperUtils} from 'wobi-web-common';
import {AutocompleteSingleSelect} from 'wobi-web-common/dist/components/Autocomplete';
import {Black} from 'wobi-web-common/dist/Colors';
import {findSelectedOption} from 'wobi-web-common/dist/components/utils/helpers';
import Grow from '@material-ui/core/Grow';
import {Plus} from '../assets/js/icons';
import {thirdPartyPopularBrands} from '../assets/js/popularCarBrands';

const CarBrandSelect = (props) => {
  const useStyles = makeStyles((theme) => ({
    addBtn: {
      '&:hover': {
        backgroundColor: theme.palette.type === 'dark' ? agentDeveloperUtils.getSecondaryColor(550) :
          agentDeveloperUtils.getSecondaryColor(50),
      },
      backgroundColor: theme.palette.type === 'dark' ? agentDeveloperUtils.getConvertedColor(Black[900]) :
        agentDeveloperUtils.getSecondaryColor(10),
      border: theme.palette.type === 'dark' ? `1px solid ${agentDeveloperUtils.getConvertedColor(Black[10])}` :
        `1px solid ${agentDeveloperUtils.getSecondaryColor(100)}`,
      borderRadius: '50%',
      boxShadow: 'none',
      color: theme.palette.secondary.main,
      height: 72,
      minWidth: 'auto',
      padding: 0,
      position: 'relative',
      width: 72,
    },
    addBtnLabel: {
      fontFamily: 'Open-sans-hebrew',
      fontSize: theme.typography.pxToRem(10.5),
      fontWeight: 'bold',
      lineHeight: '17.8px',
      position: 'absolute',
    },
    autocompleteWrap: {
      margin: '8px 8px 0',
    },
    formControl: {
      margin: '0 6px',
      maxWidth: 508,
      position: 'relative',
    },
    legend: {
      color: theme.palette.secondary.main,
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: '14px',
      paddingLeft: '12px',
    },
  }));
  const classes = useStyles();

  /* 'popularBrands' are different for FULLCOVER/3RDPARTY insurances */
  const popularBrands = props.defaultChipsItems ? props.defaultChipsItems : thirdPartyPopularBrands;

  /* brands that are shown in the chips, init with most popular brands */
  const popular = carBrandsData.filter(brand => popularBrands.includes(brand.value));

  const [chipData, setChipData] = React.useState(popular);

  /* open autocomplete animation */
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const handleClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /* CarBrandSelect value
   * if brand is not in current list (init with 'popularBrands'), add it.
   * the 11th item can be overwritten by another choice which isn't in 'popularBrands'.
   * unselecting with 'x' doesn't effect presented chips (just the orange border).
   */
  const [value, setValue] = React.useState(props.value || null);
  const shouldUpdateChipsItems = (value_) => {
    const newChips = chipData;
    if (value_ !== null && !findSelectedOption(value_, chipData)) {
      const selected = findSelectedOption(value_, carBrandsData);
      newChips.splice(10, 1, selected);
    }
    setChipData(newChips);
  };

  React.useEffect(() => {
    const numberValue = props.value && props.value.value ? props.value.value : props.value;
    if (numberValue) {
      shouldUpdateChipsItems(numberValue);
    }
  }, [props.value, shouldUpdateChipsItems]);

  const handleChipSelect = (value_) => {
    setValue(value_);
    setDropdownOpen(false);
    props.onSelect(value_);
  };
  const handleAutocompleteSelect = (value_) => {
    if (value_ !== null) {
      setTimeout(() => {
        setDropdownOpen(false);
      }, 400);
    }
    const numberValue = value_ && value_.value ? value_.value : value_;
    setValue(numberValue);

    // will trigger useEffect and update chips items
    props.onSelect(value_);
  };

  return (
    <FormControl className={classes.formControl}>
      <FormLabel component='legend' className={classes.legend}>יצרן</FormLabel>
      <ChipsSelect
        items={chipData}
        parentValue={value && value.value ? value.value : value}
        onSelect={(value_) => handleChipSelect(value_)}>
        <Fab variant='extended' aria-label='עוד' onClick={handleClick} className={classes.addBtn}>
          <Plus />
          <span className={classes.addBtnLabel}>עוד</span>
        </Fab>
      </ChipsSelect>
      <Grow in={dropdownOpen} mountOnEnter unmountOnExit timeout={{
        appear: 300,
        enter: 200,
        exit: 300,
      }}>
        <div className={classes.autocompleteWrap}>
          <AutocompleteSingleSelect
            items={carBrandsData}
            parentValue={value && value.value ? value.value : value}
            defaultMenuIsOpen={false}
            onSelect={(value_) => handleAutocompleteSelect(value_)}/>
        </div>
      </Grow>
    </FormControl>
  );
};

CarBrandSelect.propTypes = {
  defaultChipsItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  items: PropTypes.array,
  onSelect: PropTypes.func,
  value: PropTypes.any,
};

export default CarBrandSelect;
