import Moment from 'moment';

const yesNoToBool = value => {
  if (value === 'yes') {
    return true;
  } else if (value === 'no') {
    return false;
  }
  return value;
};

export const mapRequestData = (oldData) => Object.entries(oldData).reduce((curry, [key, value]) => {
  curry[key] = yesNoToBool(value);
  if (key === 'startDate' || key === 'endDate') {
    curry[key] = Moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY');
  }

  // checking if driver birthday is between now and policy start. if so then driver age has to be increased by 1
  if (key === 'youngDriverAge' &&
    Moment(oldData.youngDriverBirthDate, 'DD-MM')
      .isBetween(Moment(), Moment(oldData.startDate, 'DD-MM'))) {
    curry[key] = value + 1;
  }
  if (key === 'brand') {
    curry[key] = value.toString();
  }
  if (oldData.policyType === '3RDPARTY') {
    if (key === 'insCovers' && oldData.hasFullInsPast === 'no') {
      curry[key] = {
        1: value[1],
        2: value[2],
        3: value[3],
      };
    }
    if (key === 'propClaimInLast3Years' && value === 1 && oldData.propClaimInYear === 4) {
      curry[key] = -1;
    }
    if (key === 'propClaimInYear' && value === 4) {
      curry[key] = '';
    }
  }
  if (key === 'additionalDetails') {
    curry[key] = value;
  }

  if (key === 'experimentID' || key === 'experimentVariant') {
    curry[key] = value.toString();
  }
  return curry;
}, {});

export const mapSimulatorRequestData = (oldData, isPotentialUpdate = false) => Object.entries(oldData)
  .reduce((curry, [key, value]) => {
    curry[key] = yesNoToBool(value);

    // For update_potential action crm-integration-service map dates in YYYY-MM-DD format
    if (key === 'startDate' ||
      key === 'endDate' ||
      key === 'youngDriverBirthDate' ||
      key === 'mainDriverBirthDate' ||
      key === 'secondDriverBirthDate' ||
      key === 'thirdDriverBirthDate'
    ) {
      curry[key] = value === null ? curry[key] : Moment(value).format(isPotentialUpdate ? 'YYYY-MM-DD' : 'DD/MM/YYYY');
    }
    if (key === 'brand') {
      curry[key] = value.toString();
    }
    if (key === 'insuranceHistory') {
      curry[key].insurance
        .filter(element => element.type === '0')
        // eslint-disable-next-line no-return-assign
        .map(element => curry[key].insurance[curry[key].insurance.indexOf(element)].type = '');
    }
    return curry;
  }, {});

export const utils = {
  getMonthsOptions: () => Array.from({length: 12}, (v, k) => k + 1),

  getPaymentOptions: (numberOfPaymentsAvailable) => Array.from({length: numberOfPaymentsAvailable}, (v, k) => k + 1),

  getYearsOptions: () => {
    const year = Moment().year();
    return Array.from({length: 10}, (v, k) => year + k);
  },

  licenseNumberValidation: value => {
    if (!value) {
      return true;
    }
    const reg = new RegExp(/^\d+$/);
    return (value.toString().length === 7 || value.toString().length === 8) && reg.test(value);
  },
};
