import {getOffersFields} from '../utils/persistOfferDetails';

const validate = (fieldsKeyToValidate, values) => fieldsKeyToValidate
  .every(field => values[field] || values[field] === 0);

export const protectedPaths = ['/details', '/offers'];

export default [
  {
    check: () => {
      const values = getOffersFields();
      const fieldsKeyToValidate = ['policyType', 'startDate', 'endDate'];
      return validate(fieldsKeyToValidate, values);
    },
    redirectTo: '/getting-started',
    uri: '/details#auto-info',
  },
  {
    check: () => {
      const values = getOffersFields();
      const fieldsKeyToValidate = ['brand', 'year', 'model', 'subModel'];
      return validate(fieldsKeyToValidate, values);
    },
    redirectTo: '/details#auto-info',
    uri: '/details#drivers',
  },
  {
    check: () => {
      const values = getOffersFields();
      const fieldsKeyToValidate = ['driversCount', 'youngDriverGender', 'youngDriverBirthDate', 'youngDriverSeniority'];
      return validate(fieldsKeyToValidate, values);
    },
    redirectTo: '/details#drivers',
    uri: '/details#insurance-history',
  },
  {
    check: () => {
      const values = getOffersFields();
      const fieldsKeyToValidate = ['propClaimInLast3Years', 'bodyClaimInLast3Years', 'licenseRevocation'];
      if (process.env.REACT_APP_NODE_ENV === 'local') {
        return true;
      }
      return validate(fieldsKeyToValidate, values);
    },
    redirectTo: '/details#insurance-history',
    uri: '/offers',
  },
];
