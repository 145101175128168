export default [
  {index: 0,
    key: 'details',
    label: 'פרטים'},
  {index: 1,
    key: 'offers',
    label: 'הצעות'},
  {index: 2,
    key: 'payment',
    label: 'תשלום וסיום'},
];
