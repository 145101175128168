const routeBuilder = (pathname, showSidebar, showHeader, showOnlyBaseHeader = false, narrowPadding = false) => ({
  narrowPadding,
  pathname,
  showHeader,
  showOnlyBaseHeader,
  showSidebar,
});

export const SimulatorRoute = routeBuilder('/simulator', false, false, true);
export const PreferredOffersRoute = routeBuilder('/simulator/preferred-offers', false, false, true);
export const CallSummaryRoute = routeBuilder('/simulator/call-summary', false, false, true);
export const CreditGuardRoute = routeBuilder('/simulator/credit-guard', false, false, true);
export const GettingStartedRoute = routeBuilder('/getting-started', true, true);
export const DetailsRoute = routeBuilder('/details', true, true);
export const OffersRoute = routeBuilder('/offers', false, true, false, true);
export const ThankYouRoute = routeBuilder('/thank-you', false, true);
export const OverUseRoute = routeBuilder('/overuse', false, true, true);
export const AskToContactRoute = routeBuilder('/ask-to-contact/:leadId', false, true, true);

export const routes = [
  SimulatorRoute,
  PreferredOffersRoute,
  CallSummaryRoute,
  GettingStartedRoute,
  DetailsRoute,
  OffersRoute,
  ThankYouRoute,
  CreditGuardRoute,
  OverUseRoute,
  AskToContactRoute,
];
