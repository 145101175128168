const ProductType = {
  FullCover: 'FULLCOVER',
  ThirdParty: '3RDPARTY',
};

export const isFullCover = type => type ? type === ProductType.FullCover : false;

export const isThirdParty = type => type ? type === ProductType.ThirdParty : false;

export default ProductType;
