import {localMocks} from './localMocks';

const {REACT_APP_NODE_ENV, REACT_APP_AUTO_SERVICE_URL} = process.env;
const protocolPrefix = ['staging', 'local', 'development'].includes(REACT_APP_NODE_ENV) ? 'http://' : 'https://';

const timeoutSignal = (msTimeout = 30000) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), msTimeout);
  return controller.signal;
};

export const backendServicePrefix = `${protocolPrefix}${REACT_APP_AUTO_SERVICE_URL}`;

const fetchPlus = async (url, options = {}, retries) => {
  for (let index = retries; index > 0; index--) {
    try {
      const res = await fetch(url, options);
      if (res.ok) {
        return res.json();
      }
      if (index === 1) {
        const errorResponseData = await res.json();
        throw new Error(errorResponseData.message || errorResponseData.error || res.status);
      }
    } catch (error) {
      if (index === 1) {
        throw new Error(error);
      }
    }
  }
};

/**
 * Common function for API call request
 * If application running locally, then API request will be mocked
 *
 * @param {object} params - Object of params for API call
 * @param {string} params.endpoint
 * @param {object} [params.data] - Data of call (only for POST)
 * @param {object} [params.headers]
 * @param {string} params.method
 * @returns {Promise} - Promise with real or mocked call
 */
export const apiRequest = ({endpoint, data, headers, method, timeout, retries = 2}) => {
  if (REACT_APP_NODE_ENV === 'local') {
    const mock = localMocks(endpoint.split('?')[0]);
    if (mock) {
      return Promise.resolve(mock);
    }
  }

  const baseHeaders = {
    'Content-Type': 'application/json',
  };

  if (data && typeof data.brand === 'object') {
    // eslint-disable-next-line no-param-reassign
    data = {
      ...data,
      brand: Object.values(data)[0].value,
    };
  }

  const body = data ? JSON.stringify(data)
    .replace(new RegExp('firstname', 'g'), 'firstName')
    .replace(new RegExp('lastname', 'g'), 'lastName') : JSON.stringify(data);

  let url = `${backendServicePrefix}/${endpoint}`;
  if (!['staging', 'local'].includes(REACT_APP_NODE_ENV) && endpoint.includes('churn')) {
    url = url.replace('svc', 'svc2');
  }

  return fetchPlus(url, {
    body,
    headers: Object.assign(baseHeaders, headers),
    method,
    signal: timeout ? timeoutSignal(timeout) : undefined,
  }, retries);
};

/**
 * API call request with method 'POST'
 *
 * @param {string} endpoint
 * @param {object} [data]
 * @param {object} [headers]
 */
export const apiPostRequest = (endpoint, data, headers = {}, timeout) => apiRequest({
  data,
  endpoint,
  headers,
  method: 'POST',
  timeout,
});

/**
 * API call request with method 'GET'
 *
 * @param {string} endpoint
 * @param {object} [headers]
 */
export const apiGetRequest = (endpoint, headers = {}) => apiRequest({
  endpoint,
  headers,
  method: 'GET',
});
