import Moment from 'moment';
// eslint-disable-next-line import/no-cycle
import {fireGtmEvent} from './marketing.utils';

// inner keys for offer object
export const LICENSE_NUMBER = 'licenseNumber';

// keys for session storage
export const OFFERS_FIELDS_KEY = 'offers_fields';
export const SIMULATOR_OFFERS_FIELDS_KEY = 'simulator_offers_fields';
export const SELECTED_OFFER_KEY = 'selected';
export const SESSION_TIMESTAMP = 'session_timestamp';

const getFromStorage = (storageKey, key = false, isSimulator = false) => {
  let isSimulatorPage = isSimulator;

  if (!isSimulatorPage) {
    const simulatorOfferFields = sessionStorage.getItem(SIMULATOR_OFFERS_FIELDS_KEY);
    const parsedSimulatorOfferFields = simulatorOfferFields ?
      JSON.parse(simulatorOfferFields) : null;

    isSimulatorPage = parsedSimulatorOfferFields ?
      parsedSimulatorOfferFields.isSimulator :
      false;
  }

  const timestamp = sessionStorage.getItem(SESSION_TIMESTAMP);
  if (!isSimulatorPage && timestamp &&
    Moment(timestamp).add(process.env.REACT_APP_SESSION_TIMEOUT, 'seconds') < Moment()) {
    clearOffersFields();

    if (['/getting-started', '/details'].includes(window.location.pathname)) {
      fireGtmEvent('RefreshFirstStep');
    }
    window.location.href = '/';

    return null;
  }
  const dataString = sessionStorage.getItem(storageKey);
  const dataObject = dataString ? JSON.parse(dataString) : {};

  return key ? dataObject[key] : dataObject;
};

const saveToStorage = (storageKey, newData = {}, isSimulator = false) => {
  const oldDataObject = getFromStorage(storageKey, false, isSimulator);
  sessionStorage.setItem(storageKey, JSON.stringify(
    Object.assign(oldDataObject instanceof Object ? oldDataObject : {}, newData),
  ));
  sessionStorage.setItem(SESSION_TIMESTAMP, Moment().format());
};

// Use special calls and SIMULATOR_KEY to skip SESSION_TIMEOUT check for Simulator
export const setSimulatorOffersFields = (newData = {}) => saveToStorage(SIMULATOR_OFFERS_FIELDS_KEY, newData, true);
export const getSimulatorOffersFields = (key = false) => getFromStorage(SIMULATOR_OFFERS_FIELDS_KEY, key, true);

export const setOffersFields = (newData = {}) => saveToStorage(OFFERS_FIELDS_KEY, newData);
export const getOffersFields = (key = false) => getFromStorage(OFFERS_FIELDS_KEY, key);

export const getSelectedOffer = (key = false) => getFromStorage(SELECTED_OFFER_KEY, key);
export const setSelectedOffer = (newData = {}) => saveToStorage(SELECTED_OFFER_KEY, newData);

export const removeOffersFieldsValue = (key) => {
  const offersFieldsObject = getOffersFields();
  delete offersFieldsObject[key];

  sessionStorage.setItem(OFFERS_FIELDS_KEY, JSON.stringify(offersFieldsObject));
};

export const clearOffersFields = () => {
  const policyType = getPolicyTypeFromStorage();
  sessionStorage.removeItem(OFFERS_FIELDS_KEY);
  sessionStorage.removeItem(SESSION_TIMESTAMP);
  sessionStorage.setItem(OFFERS_FIELDS_KEY, JSON.stringify({policyType}));
};

export const getPolicyTypeFromStorage = () => {
  const dataString = sessionStorage.getItem(OFFERS_FIELDS_KEY);
  const {policyType} = dataString ? JSON.parse(dataString) : {};
  return policyType;
};

export const setCookie = (cname, cvalue) => {
  const d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000); // one year
  const expires = 'expires=' + d.toUTCString();
  const domain = `domain=${process.env.REACT_APP_NODE_ENV === 'staging' ?
    process.env.REACT_APP_STAGING_DOMAIN :
    'wobi.co.il'
  }`;
  const cookie = cname + '=' + cvalue + ';' + expires + ';' + domain + ';path=/;samesite=strict';
  document.cookie = cookie;
};

export const getCookie = cname => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.slice(1);
    }
    if (c.indexOf(name) === 0) {
      return c.slice(name.length, c.length);
    }
  }
  return '';
};
