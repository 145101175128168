import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {IntlProvider} from 'react-intl';
import App from './App';

const defaultLanguage = 'he';
const messages = {
  he: {},
};
it('renders without crashing', () => {
  const div = document.createElement('div');
  ReactDOM.render(<IntlProvider locale={defaultLanguage} messages={messages[defaultLanguage]}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </IntlProvider>, div);
  ReactDOM.unmountComponentAtNode(div);
});
