import {getOffersFields} from '../../../utils/persistOfferDetails';

export const detailInitialState = () => Object.assign({
  bodyClaimInLast3Years: '',
  bodyClaimInYear: '',
  brand: '',
  city: '',
  cityCode: '',
  cityPostCode: '',
  driversCount: '',
  email: '',
  fcw: '',
  hasEspClientAnswer: '',
  hasFullInsPast: '',
  haveAnotherCar: false,
  insCovers: {
  },
  ldw: '',
  licenseRevocation: '',
  model: '',
  privateUCarAndOnlyPrivateUse: '',
  propClaimInLast3Years: '',
  propClaimInYear: '',
  subModel: '',
  year: '',
  youngDriverAge: '',
  youngDriverBirthDate: null,
  youngDriverGender: '',
  youngDriverSeniority: '',
}, getOffersFields());

export const MAX_YEAR_FOR_ESP_QUESTION = 2010;
