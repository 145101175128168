const ROAD_SERVICE_COMPANIES_HEBREW = {
  BLUE: 'BLUE',
  'Drachim * 2008': '*2008 דרכים',
  'Memsi * 5202': '*5202 ממסי',
  'PMI * 656': '*656 פמי',
  'Shagrir * 8888': '*8888 שגריר',
  'Shlomo Sixt * 6448': '*6448 שלמה סיקסט',
  'Solvents * 5202': 'Solvents * 5202',
  'Zebra * 2113': 'Zebra * 2113',
};

export default ROAD_SERVICE_COMPANIES_HEBREW;
