import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import {EzFormControl, DatePicker, agentDeveloperUtils} from 'wobi-web-common';
import {
  RadioGroup, Radio, FormControlLabel, Button,
  withStyles, Grow, Paper,
} from '@material-ui/core';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import Moment from 'moment';
import {FormattedMessage, injectIntl} from 'react-intl';
import 'moment/locale/he';
import {getOffersFields, getPolicyTypeFromStorage, setOffersFields} from '../../utils/persistOfferDetails';
import ProductTypes from '../../enums/product-type';
import {
  constractCampaignArgs,
  fireGtmEvent,
  initializeGoogleOptimizeExperiment,
} from '../../utils/marketing.utils';
import {conditions} from '../../utils/formConditions';
import {getStartingDate} from '../../utils/apiHandlers';
import AlwaysOnTooltip from '../../components/AlwaysOnTooltip';
import BannerStep1a from '../../components/BannersDesktop-experiment/Banner1a';
import BannerStep1b from '../../components/BannersDesktop-experiment/Banner1b';
import {getImageByAgent} from '../../config/agent';
import {isShowPostIlEmpoleeDiscount} from '../../utils/postIlEmploeeHelper';
import {isShowContactUsPostIlEmploeeDiscount} from '../../utils/showContactUsHelper';
import {useStyles} from './styles';

const getStartDateFromStorage = () => {
  const startDate = getOffersFields('startDate');
  return startDate ? Moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
};

const queryStringParametersNames = [
  'first-referrer',
  'gclid',
  'referrer',
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'utm_medium',
  'first-utm_medium',
  'first-utm_source',
  'first-gclid',
  'utm_keywords',
];

class GettingStartedStep extends Component {
  constructor(props) {
    super(props);
    this.setPolicyType();
  }

  state = {
    experiment: null,
    initialStartDate: '',
    isShowPostIlConstactus: true,
    isShowPostIlDiscount: false,
    show: false,
    startDate: getStartDateFromStorage(),
  };

  componentDidMount() {
    this.setPolicyType();
    const timestamp = sessionStorage.getItem('session_timestamp');
    const policyType = getPolicyTypeFromStorage();
    if (timestamp) {
      if (Moment(timestamp).add(process.env.REACT_APP_SESSION_TIMEOUT, 'seconds') < Moment()) {
        sessionStorage.clear();
        window.location.href = '/';
        setOffersFields({policyType});
      }
    }
    this.getStartDate();
    if (!policyType) {
      this.setPolicyType();
    }
    this.setCampaignArgs();
    this.setGoogleOptimizeExperiment(process.env.REACT_APP_EXPERIMENT_ID);
    this.isShowPostIlDiscount();
  }

  calcHowManyMonthsToShowAhead = () => {
    const NUMBER_OF_DAYS_FROM_END_OF_MONTH_TO_SHOW_2_MONTHS_AHEAD = 7;

    const totalDaysOfTheMonth = Moment().daysInMonth();
    const currentDay = Number(Moment().format('DD'));
    let numberOfMonthsToShowAhead = 1; // default

    const breakpointDay = totalDaysOfTheMonth - NUMBER_OF_DAYS_FROM_END_OF_MONTH_TO_SHOW_2_MONTHS_AHEAD;
    const isCurrentDayPassedBreakpoint = breakpointDay < currentDay;

    if (isCurrentDayPassedBreakpoint) {
      numberOfMonthsToShowAhead = 2;
    }

    return new Moment().add(numberOfMonthsToShowAhead, 'month').endOf('month');
  };

  getStartDate() {
    getStartingDate().then(resp => {
      if (resp.success) {
        this.setState({
          initialStartDate: resp.data.startDate,
          startDate:
            resp.data.startDate === Moment().startOf('month').format('YYYY-MM-DD') ?
              resp.data.startDate : Moment().add('1', 'month').startOf('month').format('YYYY-MM-DD'),
        });
      }
    });
  }

  selectStartDate() {
    return this.state.startDate;
  }

  isShowPostIlDiscount() {
    const offersFields = sessionStorage.getItem('offers_fields');
    let utmSource = '';
    let policyType = '';
    if (offersFields) {
      const parsedFields = JSON.parse(offersFields);
      utmSource = parsedFields?.utm_source;
      policyType = parsedFields?.policyType;
    }

    const agentDeveloperName = agentDeveloperUtils.getAgentDeveloperName;
    if (isShowPostIlEmpoleeDiscount(utmSource, agentDeveloperName, policyType)) {
      this.setState({isShowPostIlDiscount: true});
    }
    if (!isShowContactUsPostIlEmploeeDiscount(utmSource, agentDeveloperName)) {
      this.setState({isShowPostIlConstactus: false});
    }
  }

  fixQueryStringDuplicateParam(querystringParameters) {
    const fixedQueryStringParams = {
      ...querystringParameters,
    };
    const stringParameters = Object.keys(fixedQueryStringParams);
    stringParameters.forEach(param => {
      if (queryStringParametersNames.includes(param)) {
        if (Array.isArray(fixedQueryStringParams[param])) {
          fixedQueryStringParams[param] = fixedQueryStringParams[param][0];
        }
      }
    });
    return fixedQueryStringParams;
  }

  static handleRadioChange(value, setFieldValue) {
    setFieldValue('policyStartDate', value);
  }

  static handleDateChanged(value, setFieldValue) {
    fireGtmEvent('OtherDate');
    setFieldValue('policyStartDate', value);
  }

  resolvePolicyType() {
    let policyType = this.props.history.location.hash.replace('#', '').toUpperCase();
    if (policyType.includes('?')) {
      policyType = policyType.slice(0, policyType.indexOf('?'));
    }
    if (!Object.values(ProductTypes).includes(policyType)) {
      policyType = ProductTypes.FullCover;
    }

    return policyType;
  }

  setPolicyType() {
    const policyType = this.resolvePolicyType();

    fireGtmEvent(policyType === ProductTypes.FullCover ? 'SelectFullCover' : 'SelectThirdParty');
    setOffersFields({policyType});
  }

  setCampaignArgs() {
    const {location} = this.props;
    const values = queryString.parse(location.search || location.hash.split('?')[1]);
    const fixedValues = this.fixQueryStringDuplicateParam(values);
    const offersFields = constractCampaignArgs(fixedValues);
    if (fixedValues['first-referrer']) {
      offersFields.first_referrer = fixedValues['first-referrer'];
    }
    if (fixedValues['first-utm_medium']) {
      offersFields.first_utm_medium = fixedValues['first-utm_medium'];
    }
    if (fixedValues['first-utm_source']) {
      offersFields.first_utm_source = fixedValues['first-utm_source'];
    }
    if (fixedValues['first-gclid']) {
      offersFields.first_gclid = fixedValues['first-gclid'];
    }

    setOffersFields(offersFields);
  }

  setGoogleOptimizeExperiment(experimentID) {
    initializeGoogleOptimizeExperiment(experimentID).then(experimentVariant => {
      if (experimentVariant) {
        setOffersFields({
          experimentID,
          experimentVariant,
        });
        this.setState({experiment: experimentVariant});
      }
    });
  }

  isPolicyStartDayIsLast2DaysOfMonth(policyStartDate) {
    return Moment(policyStartDate).endOf('month').format('DD') - Moment(policyStartDate).format('DD') < 2;
  }

  getTooltipMessage(date) {
    const diff = Moment(date).diff(Moment(date).startOf('month'), 'days');

    return diff ?
      `לתקופה של 11 חודשים ו-${Moment(date).endOf('month').diff(Moment(date), 'days') + 1} ימים` :
      'תקופה של 12 חודשים';
  }

  render() {
    const {classes} = this.props;
    const now = Moment();
    const nextMonth = Moment().add('1', 'month').startOf('month');
    const nextMonthTitle = `מ - 1 ${nextMonth.format('MMMM')}`;
    const nextMonthsEnd = this.calcHowManyMonthsToShowAhead();
    const dateFormatIsValid = (this.state.startDate || this.state.initialStartDate) === now.format('YYYY-MM-DD');
    const isWithBanners = Number.parseInt(this.state.experiment, 10);
    const carDateImgStyle = this.state.isShowPostIlConstactus ? {} : {marginTop: '300px'};
    const PostIlDiscountStyle = {
      backgroundColor: '#FFF9F8',
      border: '1px solid #e8e8e8',
      borderRadius: '5px',
      boxShadow: '0px 2px 3px #00000007',
      maxWidth: '80%',
      padding: '5px',
      width: 'fit-content',
    };
    return (
      <div className={classes.root}>
        <div className='form-container'>
          <div className={classes.title}>
            <FormattedMessage id='getting_started.here_we_begin' tagName='h2' />
            <FormattedMessage id='getting_started.what_date' tagName='span' />
          </div>
          {
            this.state.initialStartDate ?
              <Grow in={Boolean(this.state.initialStartDate)}>
                <Paper className={classes.paper}>
                  <Formik
                    onSubmit={(values) => {
                      const endDate = Moment(values.policyStartDate)
                        .add(1, 'year')
                        .subtract(1, 'month')
                        .endOf('month')
                        .format('DD/MM/YYYY');
                      const data = {
                        endDate,
                        startDate: Moment(values.policyStartDate).format('DD/MM/YYYY'),
                      };
                      setOffersFields(data);
                      fireGtmEvent('AutoStart');
                      this.props.history.push('details#auto-info');
                    }}
                    validationSchema={yup.object().shape({
                      policyStartDate: yup.mixed().required('שדה חובה')
                        .test('validDate', 'תאריך שגוי', (date) => conditions.isValidDate(date))
                        .test('underMax', 'לא ניתן לרכוש פוליסה שתתחיל לאחר סוף החודש הבא',
                          (date) => conditions.isUnderMax(date, nextMonthsEnd))
                        .test('aboveMin', 'לא ניתן לרכוש פוליסה שמתחילה בתאריך זה',
                          (date) => conditions.isAboveMin(date, now))
                        .test('aboveMin', 'לא ניתן לרכוש פוליסה שמתחילה בתאריך זה',
                          (date) => conditions.isAboveMin(date, this.state.initialStartDate)),
                    })}
                    initialValues={{policyStartDate:
             this.selectStartDate() < this.state.initialStartDate ?
               this.state.initialStartDate : this.selectStartDate()}
                    }>
                    {({values, errors, setFieldValue}) => (
                      <Form>
                        <EzFormControl name='predefinedDates'>
                          <RadioGroup
                            className={classes.preDefineDates}
                            onChange={(event, value) => GettingStartedStep.handleRadioChange(value, setFieldValue)}
                            row
                          >
                            <FormControlLabel

                              // checked='false'
                              disabled={this.state.initialStartDate > nextMonth.format('YYYY-MM-DD')}
                              value={nextMonth.format('YYYY-MM-DD')} control={<Radio />}
                              onChange={() => {
                                fireGtmEvent('FirstDay');
                                this.setState({startDate: nextMonth.format('YYYY-MM-DD')});
                              }}
                              label={nextMonthTitle}
                            />
                            <FormControlLabel
                              checked={dateFormatIsValid}
                              disabled={this.state.initialStartDate !== now.format('YYYY-MM-DD')}
                              value={this.state.initialStartDate}
                              onChange={() => {
                                fireGtmEvent('Today');
                                this.setState(previousState => ({startDate: previousState.initialStartDate}));
                              }}
                              control={<Radio />}
                              label={<FormattedMessage id='getting_started.today' />}
                            />
                          </RadioGroup>
                        </EzFormControl>
                        <div className={classes.wrapper}>
                          <EzFormControl
                            label={this.props.intl.formatMessage({id: 'getting_started.different_date'})}
                            name='policyStartDate'
                            isLabelInChild
                          >
                            <DatePicker
                              style={{width: '100%'}}
                              value={values.policyStartDate}
                              onChange={(date) => GettingStartedStep.handleDateChanged(date, setFieldValue)}
                              onAccept={() => { }}
                              autoOk
                              invalidDateMessage=''
                              minDateMessage=''
                              maxDateMessage=''
                              minDate={this.state.initialStartDate}
                              maxDate={nextMonthsEnd}
                              openTo='date'
                              id='differentPolicyStartDate'
                              variant='inline'
                            />
                          </EzFormControl>
                          <AlwaysOnTooltip
                            show={Boolean(values.policyStartDate)}
                            kind='Bulb'
                            showText
                            className={classes.tooltipOverride}
                          >
                            {this.getTooltipMessage(values.policyStartDate)}
                          </AlwaysOnTooltip>
                          {
                            this.isPolicyStartDayIsLast2DaysOfMonth(values.policyStartDate) && (
                              <div className={classes.text}>
                                <FormattedMessage id='getting_started.last_day_of_month_warning' />
                              </div>
                            )
                          }
                          {this.state.isShowPostIlDiscount && <div style={{width: '80vw'}}>
                            <h5 style={PostIlDiscountStyle}>
                                ביטוח רכב משתלם במיוחד לעובדי הדואר
                               300 ש״ח הנחה
                                לתשומת לבך ההנחה תשוקף בשיחת האימות , המחיר המוצג הינו טרם הנחה
                            </h5>
                          </div>}
                          <div>
                            <Button
                              className={classes.submitBtn}
                              size='large'
                              type='submit'
                              aria-label={errors && Object.keys(errors).length ?
                                `${this.props.intl.formatMessage({
                                  id: 'getting_started.continue_to_auto_details',
                                })}. ` +
                  `${this.props.intl.formatMessage({
                    id: 'getting_started.validation',
                  })}` : null}>
                              <FormattedMessage id='getting_started.continue_to_auto_details' />
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Paper>
              </Grow> : false
          }
        </div>
        <aside className={`container ${isWithBanners ? 'with-banner' : ''}`}>
          {this.state.experiment === '1' ? <BannerStep1a /> :
            this.state.experiment === '2' ? <BannerStep1b /> :
              this.state.experiment === null ? '' : false}
          <div className='img-container' style={{position: 'relative'}}>
            <img src={getImageByAgent('car-date.svg')} style={carDateImgStyle} alt='insurance date' />
            <div style={{left: '50%', position: 'absolute', transform: 'translateX(-50%)', width: 'inherit'}} >
              {agentDeveloperUtils.getFooterDisclamerText ?
                <span style={{fontSize: 7}} className='footer-disclaimer IGNORE_NAME_REPLACE' >
                  {agentDeveloperUtils.getFooterDisclamerText}
                  <br />
                </span> : ''}
              {agentDeveloperUtils.getIsShowPoweredByWobi ?
                <span style={{fontSize: 8}} className='powered-by-wobi IGNORE_NAME_REPLACE' >
                  Powered by Wobi
                </span> : ''}
            </div>
          </div>
        </aside>
      </div>
    );
  }
}

GettingStartedStep.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
  location: PropTypes.object,
};

export default withStyles(useStyles)(withRouter(injectIntl(GettingStartedStep)));
