export const coversAndProductInfoTransform = (covers, products, potential) => {
  // covers
  const cancellationTitle = 'ביטול השתתפות עצמית';
  const flashLightsTitle = 'כיסוי שבר פנסים ומראות';
  const zeroPriceText = ' ללא עלות';
  const noteForFlashLightOnlyForShomra1 = `לרכב ששוויו עד 150,000 ש"ח הרחבת כיסוי פנסים ומראות
   השירות יינתן ע"י ספק אילן קאר גלאס ללא הגבלת סכום נזק`;
  const noteForFlashLightOnlyForShomra2 = `לרכב ששוויו מעל 150,000 ש"ח הרחבת כיסוי פנסים מראות השירות יינתן 
  ע"י ספק שגריר וסכום הכיסוי יוגבל ל 2000 ש"ח`;

  // fields
  const {flashlightsAndMirrorsPrice, cancellationRiderPrice} = potential;
  const coversData = potential.offersFields.manualOffer ? [] : covers?.insuranceCovers || [];

  // logic
  const priceText = (price) => price === 0 ? zeroPriceText : `${price} ש"ח`;

  let indexFlashLight = -1;

  const modifiedCovers = coversData.map(({title, text}, index) => {
    if (title === cancellationTitle && Number.isInteger(cancellationRiderPrice)) {
      return {text: priceText(cancellationRiderPrice), title};
    }

    if (title === flashLightsTitle && covers?.providerName === 'SHOMERA') {
      indexFlashLight = index;
    }

    if (title === flashLightsTitle && Number.isInteger(flashlightsAndMirrorsPrice)) {
      return {text: priceText(flashlightsAndMirrorsPrice), title};
    }

    return {text, title};
  });

  if (indexFlashLight !== -1) {
    modifiedCovers.splice(indexFlashLight + 1, 0,
      {isNote: true, text: noteForFlashLightOnlyForShomra1, title: ''});
    modifiedCovers.splice(indexFlashLight + 2, 0,
      {isNote: true, text: noteForFlashLightOnlyForShomra2, title: ''});
  }

  const isCascoAndMandatory = potential.isCasco && potential.isMandatory;
  return {
    isCascoAndMandatory,
    tCovers: modifiedCovers,
    tProducts: products,
  };
};
