export const CardsLabels = {
  Credit: 'אשראי (לא דיינרס)',
  Debit: 'דביט',
  Diners: 'דיינרס',
};

export const CardTypesLabels = {
  AmericanExpress: 'אמריקן אקספרס',
  Diners: 'דיינרס',
  Isracard: 'ישראכארט',
  MasterCard: 'מסטרכארד',
  Visa: 'ויזה',
};
