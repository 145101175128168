/* eslint-disable max-len */
/* eslint  indent: ["warn",4,{ "ignoredNodes": ["TemplateLiteral"] }] */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useIntl, FormattedMessage} from 'react-intl';
import {Backdrop, Button, DialogTitle, Fade, Grid, makeStyles, Modal, Paper, Typography} from '@material-ui/core';
import Moment from 'moment';
import {getGender, constructedInsuranceType, constructedInsuranceClaims} from '../../utils/common';
import {DRIVERS_COUNT} from '../../constants/enums';
import ROAD_SERVICE_COMPANIES_HEBREW from '../../constants/road.service.companies.hebrew';
import Agent, {getImageByAgent} from '../../config/agent';
import RidersDisclaimer from './RidersDisclaimer';
import CallSummerySign from './CallSummerySign';

const WobiLogo = getImageByAgent('companyLogo.svg');
const useStyles = makeStyles((theme) => ({
  behaveMargin: {
    paddingLeft: '10px',
  },
  boldTitle: {
    color: theme.palette.secondary.main,
  },
  documentsWarning: {
    fontSize: 'inherit',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  mainTitle: {
    color: theme.palette.secondary.main,
    fontSize: 36,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1,
    margin: 0,
    marginBottom: 20,
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  modalBtn: {
    fontSize: 18,
    height: 52,
    margin: '0 20px 40px',
    minWidth: '150px',
  },
  modalDialog: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px',
    maxHeight: 'calc(100% - 64px)',
    maxWidth: '600px',
    overflowY: 'auto',
    position: 'relative',
  },
  textHighlight: {
    display: 'contents',
    fontSize: '20px',
    fontWeight: 500,
  },
}));

const SHOMERA_PL = 'שומרה C רכב שני';
const ModalContainer = ({showModal, backToCrm, message, additionalInfo
  , showAlert, disableError, errorCodes}) => {
  const classes = useStyles();
  const intl = useIntl();
  const messages = (id, values) => intl.formatMessage({id}, values);
  return (
    <Modal
      open={showModal}
      className={`MuiModal ${classes.modal}`}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <Paper className={classes.paper}>
          <DialogTitle className={classes.modalDialog}>
            {message && messages(message)}
            {errorCodes && errorCodes.map((code, index) => (
              <React.Fragment key={index}>
                <span>{messages(`simulator.callSummary.payments.error_${code}`)}</span>
                <br/>
              </React.Fragment>))}
            {additionalInfo || ''}
            {showAlert ? (
              <React.Fragment>
                <span>{messages('simulator.callSummary.payments.alert')}</span>
              </React.Fragment>
            ) : null}
          </DialogTitle>
          <div className={classes.modal}>
            <Button className={classes.modalBtn} onClick={() => backToCrm()}>
              {messages('simulator.callSummary.backToCrm')}
            </Button>
            {showAlert && (
              <Button className={classes.modalBtn} onClick={() => disableError()}>
                {messages('simulator.callSummary.close')}
              </Button>)}
          </div>
        </Paper>
      </Fade>
    </Modal>
  );

  // eslint-disable-next-line no-unreachable
  ModalContainer.propTypes = {
    additionalInfo: PropTypes.string,
    backToCrm: PropTypes.func,
    disableError: PropTypes.func,
    errorCodes: PropTypes.array,
    message: PropTypes.string,
    showAlert: PropTypes.bool,
    showModal: PropTypes.bool,
  };
};

const CallSummary = ({
  callSummaryText,
  covers,
  disableError,
  documentsWarning,
  drivers,
  errorCodes,
  errorMessage,
  errorResponse,
  fullDisclosure,
  isCascoAndMandatory,
  kaskoInsuranceCompany,
  onBackToCrm,
  onConfirm,
  openModal,
  productLineName,
  products,
  productTotalPrice,
  protectionText,
  potential,
  showAlert,
  isSentPdf,
  handleSendPDF,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const messages = (id, values) => intl.formatMessage({id}, values);
  let agent;
  if (potential.agent) {
    agent = new Agent(potential.agent.developerName, potential.agent.developerName);
  } else {
    agent = new Agent('Wobi', 'Wobi');
  }
const signatureRequired = potential.agent?.handlingFeePrice > 0;

// && potential.opportunity.Name.includes('דמיטיפול');

  const [logoSrc, setLogoSrc] = useState('');

  useEffect(() => {
    getLogo().then(src => {
      setLogoSrc(src);
    });
}, []);

async function getLogo() {
  try {
    const src = agent.getAgentLogoPath;
    const image = await import(`../../${src}`);
    return image.default;
  } catch (error) {
      console.log('Error loading the image:', error);
  }
}

  const constructedDriverDetails = (age, driverSeniority, gender, driverSSN) => ({age,
    driverSeniority,
    driverSSN,
    gender: getGender(gender)});

  const constructHistoryInformationString = (insuranceHistoryObject, constructFunction, claimType) => {
    let historyString = '';
    Object.keys(insuranceHistoryObject).forEach(key => {
      historyString = historyString.concat(`, ${constructFunction(key, insuranceHistoryObject[key], claimType)}`);
    });
    historyString = historyString.slice(1);

    return historyString;
  };

  const constructInsuranceHistory = ({insCovers, injuryClaims_1, injuryClaims_2, injuryClaims_3,
    propClaims_1, propClaims_2, propClaims_3}) => {
    const result = [];
    const injuryClaimObject = {1: injuryClaims_1, 2: injuryClaims_2, 3: injuryClaims_3};
    const propClaimObject = {1: propClaims_1, 2: propClaims_2, 3: propClaims_3};

    if (insCovers) {
      result.push(constructHistoryInformationString(insCovers, constructedInsuranceType));
    }

    if (propClaimObject) {
      result.push(constructHistoryInformationString(propClaimObject, constructedInsuranceClaims, 'propClaim'));
    }

    if (injuryClaimObject) {
      result.push(constructHistoryInformationString(injuryClaimObject, constructedInsuranceClaims, 'injuryClaim'));
    }

    return result;
  };

  const provideDocumentsFormattedText = () => protectionText ? 'עבר ביטוחי ומיגון' : 'עבר ביטוחי';

  const insuranceHistory = constructInsuranceHistory(potential?.offersFields);

  const productTypeText = potential?.offersFields?.policyType === 'FULLCOVER' ? 'מקיף' : 'צד ג׳';

  const isSameCompanyForAllProducts = potential.mainProduct ? potential.mainProduct.casco?.providerName === potential.mainProduct.mandatory?.providerName : false;

  let productsOrderedListIndex = 0;

  const formattedSuspensionNumber = (number) => {
    switch (number) {
    case 1:
      return 'כרגע בשלילה';
    case -1:
      return 'שלילה אחת';
    case -2:
      return 'שתי שלילות ומעלה';
    default:
    }
  };

  const PaymentText = (payment, type, shouldShowCasco) => (
    <h4>
      <span>
        {isCascoAndMandatory && !shouldShowCasco ?
          messages('mandatory_and_casco') : messages(type)
        }
        {': '}
      </span>
      {payment.paymentType &&
        payment.paymentType.includes('CREDIT') ?
        isCascoAndMandatory && !shouldShowCasco ?
          <FormattedMessage
            id='simulator.callSummary.paymentInformation.CascoAndMandatory'
            values={{
              paymentCascos: payment.paymentCasco,
              paymentMandatorys: payment.paymentMandatory,
              productType: productTypeText,
            }} /> :
          type !== 'fullcover' && type !== 'thirdparty' ?
            <FormattedMessage
              id='simulator.callSummary.paymentInformation.Mandatory'
              values={{
                paymentMandatorys: payment.paymentMandatory,
              }} /> :
            <FormattedMessage
              id='simulator.callSummary.paymentInformation.Casco'
              values={{
                paymentCascos: payment.paymentCasco,
                productType: productTypeText,
              }} /> :
        payment.paymentType &&
          payment.paymentType.includes('BANK_TRANSFER') ?
          <React.Fragment>
            <FormattedMessage
              id='simulator.callSummary.paymentInformation.bank'
            />
            <h4>
              <FormattedMessage
                id='simulator.callSummary.payments.note'
              />
            </h4>
          </React.Fragment> :
          payment.paymentType &&
            payment.paymentType.includes('DEBIT') ?
            <React.Fragment>
              <FormattedMessage
                id='simulator.callSummary.paymentInformation.debit'
              />
              <h4>
                <FormattedMessage
                  id='simulator.callSummary.payments.note'
                />
              </h4>
            </React.Fragment> :
            false
      }
    </h4>);

  if (!openModal && !isSentPdf) {
    return (
      <RidersDisclaimer onClick={handleSendPDF} />
    );
  }
  return openModal ?
    <ModalContainer
      showModal={openModal}
      message={errorMessage}
      errorCodes={errorCodes}
      disableError={disableError}
      showAlert={showAlert}
      additionalInfo={errorResponse}
      backToCrm={onBackToCrm}/> :
    <Grid container spacing={0}>
      <Grid item xs={12} container justify='flex-end' >
        {agent && logoSrc ? <img src={logoSrc} alt='דף הבית  משווים וחוסכים'/> :
        <img src={WobiLogo} alt='דף הבית wobi  משווים וחוסכים'/> }
      </Grid>
      <Grid item xs={12}>
        <div className={classes.mainTitle}>
          {`${messages('simulator.callSummary.title')}`}
        </div>
        {!signatureRequired && <h3>
          {`${messages('simulator.callSummary.subtitle')}`}
        </h3>}
      </Grid>
      {!signatureRequired && <Grid item xs={12}>
        <h3>
          {messages('simulator.callSummary.policyHolder.title')}
        </h3>
        <p>
          <FormattedMessage
            id='simulator.callSummary.policyHolder.info'
            values={{
              carManufacturer: potential?.offersFields?.carManufacturer || '-',
              carTypeDescription: potential?.autoInfo?.model_desc_1 || '-',
              carYear: potential?.offersFields?.carYear || '-',
              firstName: potential?.offersFields?.firstName,
              lastName: potential?.offersFields?.lastName,
              licenseNumber: potential?.offersFields?.licenseNumber,
              pOwnerSSN: potential?.offersFields?.pOwnerSSN || '-',
              span: value => <span dir='ltr'>{value}</span>,
            }}
          />
        </p>
      </Grid>}
      <Grid item xs={12}>
        {!signatureRequired && <Grid container spacing={0}>
          {
            <Grid item xs={12}>
              <h3>
                {`${messages('simulator.callSummary.products.title')}`}
              </h3>
              <div>
                {
                  Object.keys(potential.mainProduct).map((key) => {
                    const isCasco = key === 'casco';
                    if (!isCasco && key !== 'mandatory') {
                      return null;
                    }

                    const product = potential.mainProduct[key];
                    if (!product) {
                      return null;
                    }

                    productsOrderedListIndex += 1;
                    return (
                      <div key={product.name}>
                        <FormattedMessage
                        id={isSameCompanyForAllProducts ? 'simulator.callSummary.products.information.sameCompany' : 'simulator.callSummary.products.information'}
                        values={{
                          company: !isSameCompanyForAllProducts ? <strong>{product.providerName}</strong> : null,
                          index: productsOrderedListIndex,
                          price: isCasco ? potential.mainProduct.totalPriceCasco : product.price,
                          product: product.name,
                        }} />
                      </div>);
                  })
                }
                {
                  products.map((product) => {
                    if (!product.isExternalRider) {
                      return null;
                    }

                    productsOrderedListIndex += 1;
                    return (
                      <div key={product.name}>
                        <FormattedMessage
                        id='simulator.callSummary.products.information.externalRider'
                        key={product.name}
                        values={{
                          index: productsOrderedListIndex,
                          price: product.price,
                          productName: product.name,
                        }} />
                        </div>);
                  })
                }
              </div>
              <FormattedMessage
                id='simulator.callSummary.products.information.company'
                values={{
                  company: <strong>{kaskoInsuranceCompany}</strong>,
                  productLineName: <strong>{productLineName}</strong>,
                }} />
              <div>
                <FormattedMessage
                  id='simulator.callSummary.products.timePeriod'
                  values={{
                    endDate: Moment(potential.offersFields.insuranceEndDate).format('DD/MM/YYYY'),
                    startDate: Moment(potential.offersFields.insuranceStartDate).format('DD/MM/YYYY'),
                  }}/>
              </div>
              <div>
                <h3>
                  {`${messages('simulator.callSummary.products.driversAllowed')}`}
                </h3>
                {potential.offersFields.driversCount === DRIVERS_COUNT.EVERY ? (
                  <FormattedMessage
                    id='simulator.callSummary.products.everyDriver'
                    values={{
                      age: potential.offersFields.youngDriverAge,
                      gender: getGender(potential.offersFields.youngDriverGender),
                      seniority: potential.offersFields.youngDriverSeniority,
                    }}/>
                ) : null}
                <div>
                  {potential.offersFields.driversCount !== DRIVERS_COUNT.EVERY ?
                    Object.keys(drivers).map(key => (<div key={`driver-${key}`} style={{display: 'flex'}}>
                      <div>{`${drivers[key].firstName} ${drivers[key].lastName}`}</div>
                      <div>
                        <FormattedMessage
                          id='simulator.callSummary.products.driverAllowed'
                          values={
                            constructedDriverDetails(drivers[key].age,
                              drivers[key].seniority,
                              drivers[key].gender,
                              drivers[key].driverSSN)
                          }/>
                      </div>
                    </div>)) :
                    null}
                </div>
              </div>
              <h3>
                {`${messages('simulator.callSummary.products.insuranceHistory.title')}`}
              </h3>
              <div>
                {
                  insuranceHistory.map(row => (<div key={`insurance-history-${row}`}>
                    <div style={{
                      columnGap: '4px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}>
                      <span>o</span>
                      <span>{row}</span>
                    </div>
                  </div>))
                }
                {
                  Boolean(potential.offersFields.licenseSuspension) &&
                  <>
                    o
                    {' '}
                    <FormattedMessage
                      id= 'simulator.callSummary.products.insuranceHistory.licenseSuspension'
                      values={{suspensionNumber: formattedSuspensionNumber(potential.offersFields.licenseSuspension)}}
                    />
                  </>
                }
              </div>
              <h3 className={classes.boldTitle}>
                {`${messages('simulator.callSummary.products.insuranceHistory.includeInformation')}`}
              </h3>
              <div>
                {
                  Boolean(covers.length) && potential && potential.offersFields &&
                    !potential.offersFields.manualOffer &&
                  <h3>
                    <FormattedMessage
                      id= 'simulator.callSummary.covers.title'
                      values={{productType: productTypeText}}
                    />
                  </h3>
                }
                <div>
                  {
                    covers && Boolean(covers.length) && covers.map((row, index) => {
                      const isHeadlightCover =
                        row.title.trim() === 'גבול כיסוי שבר פנסים ומראות' ||
                        row.title.trim() === 'השתתפות עצמית בפנסים ומראות';
                      const headlightsMirrorsProduct = isHeadlightCover ? products.find(p => p.productCategory.toUpperCase().includes('MIRRORS')) : null;
                      if (isHeadlightCover && !headlightsMirrorsProduct) {
                        return null;
                      }
                      return (<div
                        key={`covers-${row.title}-${index}`}>
                        {`o ${!row.isNote ? `${row.title} -` : ''} ${row.title === 'ספק שירותי דרך וגרירה נבחר' ?
                          ROAD_SERVICE_COMPANIES_HEBREW[row.text] || row.text :
                          row.text}
                      `}
                      </div>);
                    })
                  }
                  {
                    products && products.map((product) => {
                      if (!product.displayTextOnCallSummaryCoverTable && product.productCategory !== 'Increasing_Third_Party_Limit') {
                        return null;
                      }

                      return (<div
                        key={`covers-${product.name}`}>
                        {`o ${`${product.name} - `} 
                        ${product.productCategory === 'Increasing_Third_Party_Limit' ?
                          ` עבור הגדלת כיסוי צד ג׳ ל${potential?.offersFields.thirdPartyIncreasedLiabilityAmount}` :
                          product.displayTextOnCallSummaryCoverTable
                        }`}
                      </div>);
                    })
                  }
                  {
                    products && products.map((product) => {
                      if (product.customerExposedText) {
                        return (<div
                          key={`covers-${product.name}`}>
                          {`o ${product.customerExposedText}`}
                        </div>);
                      }
                      return null;
                    })
                  }
                </div>
              </div>
              {
                potential.offersFields.isCanceledSelfParticipation &&
                    <div>
                      {`o ${messages('simulator.callSummary.canceledSelfParticipation')}`}
                    </div>
              }
              {
                potential.offersFields?.clalBehaveTotalPrice &&
                    <div>
                      <div>
                        {`o ${messages('simulator.callSummary.clalBehave.title1')}`}
                      </div>
                      <div className={classes.behaveMargin}>
                        <div>
                          {`o ${messages('simulator.callSummary.clalBehave.title2')}`}
                        </div>
                        <div>
                          {`o ${messages('simulator.callSummary.clalBehave.title3')}`}
                        </div>
                      </div>
                      <div>
                        <FormattedMessage
                          id= 'simulator.callSummary.clalBehave.title4'
                          values={{
                            clalBehaveMaxPrice: potential.offersFields.clalBehaveTotalPrice,
                            clalBehaveTotalPrice: productTotalPrice,
                          }}/>
                      </div>
                      <div>
                        {`o ${messages('simulator.callSummary.clalBehave.title5')}`}
                      </div>
                    </div>
              }
              <br />
              {
                potential.offersFields.isMortgaged ?
                  <div>
                    {`o ${messages('simulator.callSummary.products.mortgaged')} `}
                    {potential.offersFields.mortgageCompany}
                  </div> : false
              }
              {
                <div>
                  {fullDisclosure && `o ${fullDisclosure}`}
                </div>
              }

              {callSummaryText ? (
                <div>
                  {`o ${callSummaryText?.replace(/<p>/g, '')?.replace(/<\/p>/g, '')}`}
                </div>
              ) : null}
              <div>
                <div>
                <FormattedMessage
                id= 'simulator.callSummary.extraInformation.title1'
                values={{
                    formsEmailAddress: agent.formsEmailAddress,
                    isAgentManualRenewal: potential.isAgentManualRenewal ? '' :
                        messages('simulator.callSummary.extraInformation.title1_extra', {formsEmailAddress: potential.agent.formsEmailAddress}),
                }}/>
                </div>
                {documentsWarning ? (
                  <Typography className={classes.documentsWarning}>
                    {`o ${documentsWarning}`}
                  </Typography>
                ) : null}
                <div>
                  { potential?.offersFields?.hasEspClientAnswer &&
                messages('simulator.callSummary.extraInformation.hasEspAnswerFromClient')}
                </div>
                {protectionText ? (
                  <div>
                    {`o\tטופס מיגון חתום ע"י מתקין מורשה של חברת הביטוח לצורך הכיסוי הביטוחי: ${protectionText}`}
                  </div>
                ) : null}
                <div>
                  <FormattedMessage
                    id= 'simulator.callSummary.extraInformation.title2'
                    values={{
                      provideDocumentsText: provideDocumentsFormattedText(),
                    }}/>
                </div>
              </div>
              <div>
                {potential.offersFields.productline_name === SHOMERA_PL &&
                    <div>
                      {messages('simulator.callSummary.extraInformation.plShomeraExtraDetails')}
                      <div className={classes.textHighlight}>
                        {messages('simulator.callSummary.extraInformation.plShomeraHighlight')}
                      </div>
                    </div>
                }
              </div>
              <div>
                {`${messages('simulator.callSummary.extraInformation.title4')}`}
              </div>
              {potential.offersFields.paymentMandatory &&
              PaymentText(potential.offersFields.paymentMandatory, 'mandatory'
                , potential.offersFields.shouldShowCasco || !potential.offersFields.paymentCasco)}
              { potential.offersFields.shouldShowCasco &&
              PaymentText(potential.offersFields.paymentCasco,
                potential.offersFields.policyType === 'FULLCOVER' ? 'fullcover' : 'thirdparty'
                , potential.offersFields.shouldShowCasco)}
              <h4 style={{textAlign: 'center'}}>
                {`${messages('simulator.callSummary.extraInformation.title3')}`}
              </h4>
              { potential.agent && potential.agent.callSummaryRegulationText &&
              <h4 style={{textAlign: 'right'}}
              dangerouslySetInnerHTML={{__html: `${potential.agent.callSummaryRegulationText}`}} /> }
            </Grid>
          }
        </Grid>}
        {signatureRequired && <CallSummerySign
        potential = {potential}
        products = {products}
        productTotalPrice = {productTotalPrice}
        fullDisclosure = {fullDisclosure}
        documentsWarning = {documentsWarning}
         />}
        <div style={{float: 'left'}}>
          <Button style={{left: '10px'}} onClick={() => onBackToCrm()} color='secondary' variant='outlined'>
            {messages('simulator.callSummary.backToEdit')}
          </Button>
          <Button onClick={() => onConfirm()}>{messages('simulator.callSummary.confirm')}</Button>
        </div>

      </Grid>

        </Grid>;
};

CallSummary.propTypes = {
  callSummaryText: PropTypes.string,
  companyLogo: PropTypes.string,
  covers: PropTypes.array,
  disableError: PropTypes.func,
  disclaimer: PropTypes.string,
  documentsWarning: PropTypes.string,
  drivers: PropTypes.object,
  errorCodes: PropTypes.array,
  errorMessage: PropTypes.string,
  errorResponse: PropTypes.string,
  fullDisclosure: PropTypes.string,
  handleSendPDF: PropTypes.func,
  isCascoAndMandatory: PropTypes.bool,
  isSentPdf: PropTypes.bool,
  kaskoInsuranceCompany: PropTypes.string,
  onBackToCrm: PropTypes.func,
  onConfirm: PropTypes.func,
  openModal: PropTypes.bool,
  potential: PropTypes.object,
  productLineName: PropTypes.string,
  products: PropTypes.object,
  productTotalPrice: PropTypes.number,
  protectionText: PropTypes.string,
  showAlert: PropTypes.bool,
};

export default CallSummary;
