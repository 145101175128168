import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {createBrowserHistory} from 'history';
import MainContainer from './components/MainContainer';
import withStepGuardMiddleware from './utils/StepGuardMiddleware';
import ScrollToTop from './components/ScrollToTop';
import Routes from './components/Routes';

function App({location}) {
  const history = createBrowserHistory();
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  const search = (/\?(.*)$/.exec(location.hash) || [])[1];

  if (path) {
    history.replace(path);
    if (search) {
      location.search = '?' + search;
      location.hash = '';
      location.pathname = path.replace('?' + search, '');
    } else {
      location.pathname = decodeURIComponent(path);
    }
  }

  return (
    <MainContainer>
      <ScrollToTop>
        <Routes location={location} />
      </ScrollToTop>
    </MainContainer>
  );
}

App.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }),
};

export default withRouter(withStepGuardMiddleware(App));

