import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useIntl, FormattedMessage} from 'react-intl';
import {
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import {EzFormControl, YesNoRadioGroup} from 'wobi-web-common';
import {AutocompleteSingleSelect} from 'wobi-web-common/dist/components/Autocomplete';
import cities from '../../config/cities';
import SimulatorExpansionPanel from '../../components/SimulatorComponents/ExpansionPanel';
import {getStreetsByCityCode} from '../../utils/apiHandlers';
import {ProductType} from '../../enums';
import useStyles from './useStyles';

const MoreDetails = React.memo(({formikHandleChange,
  formikSetField,
  formikValues,
  setCityHasStreets}) => {
  const intl = useIntl();
  const classes = useStyles();
  const messages = (id) => intl.formatMessage({id});
  const [streets, setStreets] = useState([]);
  const handleCitySelectChange = (value) => {
    if (value.length < 2) {
      return [];
    }
    return cities.filter(item => item.name.slice(0, value.length) === value).map(item => Object.assign(item, {
      label: item.name,
      value: item.code,
    }));
  };

  const handleStreetSelectChange = (value) => {
    if (value.length < 2) {
      return [];
    }
    return streets.filter(item => item.name.slice(0, value.length) === value).map(item => Object.assign(item, {
      label: item.name,
      value: item.code,
    }));
  };

  const fillAditionalData = (e) => {
    e.stopPropagation();
    formikSetField('licenseRevocation', 0);
    formikSetField('haveAnotherCar', 'no');
    formikSetField('saturdayDriving', 'yes');
    formikSetField('pledged', 'no');
  };

  const handleCityChange = async (cityObject) => {
    if (!cityObject || !cityObject.name) {
      setStreets([]);
      setCityFields('', '', '');
    } else {
      await handleSetStreets(cityObject.code);
      setCityFields(cityObject.name, cityObject.code, cityObject.post_code, cityObject.post_code_5);
    }
  };

  const setCityFields = (name, code, post_code, post_code_5) => {
    formikSetField('city', name);
    formikSetField('cityCode', code);
    formikSetField('cityPostCode', Number(post_code));
    formikSetField('cityPostCode5', Number(post_code_5));
  };

  const handleSetStreets = async (cityCode) => {
    const responseData = await getStreetsByCityCode(cityCode);
    const foundStreets = responseData.data || [];

    // if you don't have streets found then set off validation
    if (foundStreets.length === 1) {
      setStreetFields(foundStreets[0].name, foundStreets[0].code, foundStreets[0].city_id);
    }
    setCityHasStreets(Boolean(foundStreets.length));
    setStreets(foundStreets);
  };

  const handleStreetChange = (streetObject) => {
    if (!streetObject || !streetObject.name || !streetObject.city_id) {
      setStreetFields('', '', '');
    } else {
      setStreetFields(streetObject.name, streetObject.code, streetObject.city_id);
    }
  };

  const setStreetFields = (name, code, cityId) => {
    formikSetField('street', name);
    formikSetField('streetCode', code);
    formikSetField('streetCityId', cityId);
  };

  React.useEffect(async () => {
    if (formikValues.city) {
      const initialCityObject = cities.find(item => item.name === formikValues.city);
      if (initialCityObject && initialCityObject.code) {
        await handleSetStreets(initialCityObject.code);
        if (formikValues.street) {
          const initialStreetObject = streets.find(item => item.name === formikValues.street);
          if (initialStreetObject) {
            handleStreetChange(initialStreetObject);
          }
        }
      }
    }
  }, []);
  return (
    <SimulatorExpansionPanel title={(
      <>
        <Typography>{messages('simulator.title.more_details')}</Typography>
        <Button classes={{root: classes.defaultButton}} size='small' onClick={fillAditionalData}>
          {messages('simulator.title.default_button')}
        </Button>
      </>
    )}>
      <Grid container spacing={1}>
        <Grid item xs={5} className={classes.subtitle}>{messages('insurance_history.license_revocation')}</Grid>
        <Grid item xs={7}>
          <EzFormControl name='licenseRevocation'>
            <RadioGroup value={formikValues.licenseRevocation.toString()}
              onChange={formikHandleChange}
              className={classes.radioGroup} row>
              <FormControlLabel
                style={{marginLeft: 0}}
                value='0'
                control={<Radio/>}
                label={<FormattedMessage id='insurance_history.label_no_negatives'/>}
              />
              <FormControlLabel
                style={{marginLeft: 0}}
                value='-1'
                control={<Radio/>}
                label={<FormattedMessage id='insurance_history.label_currently_negative'/>}
              />
              <FormControlLabel
                style={{marginLeft: 0}}
                value='1'
                control={<Radio/>}
                label={<FormattedMessage id='insurance_history.label_one'/>}
              />
              <FormControlLabel
                style={{marginLeft: 0}}
                value='2'
                control={<Radio/>}
                label={<FormattedMessage id='insurance_history.label_two_plus'/>}
              />
            </RadioGroup>
          </EzFormControl>
        </Grid>
        <Grid item xs={12} container spacing={1}>
          {formikValues.policyType !== ProductType.ThirdParty &&
            <Grid item container xs={6} spacing={1}>
              <Grid item xs={8} className={classes.subtitle}>{messages('auto_info.second_car')}</Grid>
              <Grid item xs={4}>
                <EzFormControl name='haveAnotherCar' isLabelInChild>
                  <YesNoRadioGroup value={formikValues.haveAnotherCar} onChange={formikHandleChange}/>
                </EzFormControl>
              </Grid>
            </Grid>
          }
          <Grid item container xs={6} spacing={1}>
            <Grid item className={classes.subtitle}>{messages('simulator.pledged')}</Grid>
            <Grid item>
              <EzFormControl name='pledged' isLabelInChild>
                <YesNoRadioGroup value={formikValues.pledged} onChange={formikHandleChange}/>
              </EzFormControl>
            </Grid>
          </Grid>
          <Grid item container xs={6} spacing={1}>
            <Grid item className={classes.subtitle}>{messages('simulator.saturdayDriving')}</Grid>
            <Grid item>
              <EzFormControl name='saturdayDriving' isLabelInChild>
                <YesNoRadioGroup value={formikValues.saturdayDriving} onChange={formikHandleChange}/>
              </EzFormControl>
            </Grid>
          </Grid>
        </Grid>
        <div id='addressSection' className={classes.addressSection}>
          <Grid item xs={16} style={{marginBottom: 'auto', minWidth: '170px'}}>
            <EzFormControl name='city'>
              <AutocompleteSingleSelect
                parentValue={formikValues.city}
                placeholder={messages('fields.city')}
                onInput={handleCitySelectChange}
                onSelect={handleCityChange}
                size='small'
                menuPlacement='top'
              />
            </EzFormControl>
          </Grid>
          <Grid item xs={4} className={classes.hide} style={{marginBottom: 'auto'}}>
            <EzFormControl name='street'>
              <AutocompleteSingleSelect
                parentValue={streets.length ? formikValues.street : null}
                placeholder={messages('fields.street')}
                onInput={handleStreetSelectChange}
                onSelect={handleStreetChange}
                size='small'
                menuPlacement='top'
                cacheOptions={false}
              />
            </EzFormControl>
          </Grid>
          <Grid item xs={4} className={classes.hide} style={{marginBottom: 'auto'}}>
            <EzFormControl name='buildingNumber'>
              <TextField
                classes={{
                  root: classes.buildingNumber,
                }}
                value={formikValues.buildingNumber}
                onChange={formikHandleChange}
                parentValue={formikValues.buildingNumber}
                placeholder={messages('fields.buildingNumber')}
                size='small'
              />
            </EzFormControl>
          </Grid>
          <Grid item xs={4} className={classes.hide}>
            <EzFormControl
              name='agricultDiscount'
              label={intl.formatMessage({id: 'simulator.agricultDiscount'})}
              isLabelInChild
            >
              <TextField
                id='outlined-required'
                className={`${classes.fullWidth} no-margin`}
                onChange={formikHandleChange}
                value={formikValues.agricultDiscount}
                margin='normal'
                variant='outlined'
                size='small'
              />
            </EzFormControl>
          </Grid>
        </div>
      </Grid>
    </SimulatorExpansionPanel>
  );
});

MoreDetails.propTypes = {
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikValues: PropTypes.object,
  setCityHasStreets: PropTypes.func,
};

export default MoreDetails;
