/* eslint-disable max-len */
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const iconsColors = makeStyles(theme => ({
  cls1: {
    fill: theme.palette.primary.main,
  },
  cls2: {
    fill: theme.palette.secondary.mainDark,
  },
  cls3: {
    fill: 'none',
    stroke: theme.palette.primary.main,
  },
  cls4: {
    fill: 'none',
    stroke: theme.palette.secondary.mainLight,
  },
}));

const Plus = () => {
  const classes = iconsColors();
  return (
    <svg width='21.355' height='21.355' viewBox='0 0 21.355 21.355'>
      <g id='Group_144' data-name='Group 144' transform='translate(2 2)'>
        <line id='Line_47' data-name='Line 47' y2='17.355' transform='translate(8.678 0)' className={classes.cls4} strokeLinecap='round' strokeWidth='4'/>
        <line id='Line_48' data-name='Line 48' x2='17.355' transform='translate(0 8.678)' className={classes.cls4} strokeLinecap='round' strokeWidth='4'/>
      </g>
    </svg>
  );
};

export {Plus};
