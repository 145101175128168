import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {getImageByAgent} from '../../config/agent';

const BannerImage = getImageByAgent('banners/banner1b.svg');

const useStyles = makeStyles(theme => ({
  banner: {
    '& img': {
      [theme.breakpoints.down(1040)]: {width: 67},
      [theme.breakpoints.up(1040)]: {width: 85},
      bottom: 0,
      left: 6,
      position: 'absolute',
    },
    '& span, b': {
      display: 'block',
    },
    [theme.breakpoints.down(1040)]: {
      fontSize: '1em',
      marginTop: 30,
      padding: '14px 30px 14px 80px',
    },
    [theme.breakpoints.up(1040)]: {
      fontSize: '1.125em',
      marginTop: 0,
      padding: '25px 40px 25px 96px',
    },
    borderRadius: 47,
    boxShadow: '0 0 12px 0 #0000001F',
    color: theme.palette.secondary.main,
    margin: '0 20px',
    maxWidth: 538,
    order: 0,
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down(768)]: {display: 'none'},
    [theme.breakpoints.up(769)]: {display: 'block'},
  },
}));

const BannerStep1b = () => {
  const classes = useStyles();
  return (
    <div className={classes.banner}>
      <img src={BannerImage} alt='' role='presentation'/>
      <FormattedMessage id='getting_started.banner1_1' tagName='span'/>
      <FormattedMessage id='getting_started.banner1_2' tagName='b'/>
    </div>
  );
};

export default BannerStep1b;
