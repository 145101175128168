import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import {
  getCoversTitles, getPreferredOffers, updatePreferredPotential, deletePreferredOffer,
} from '../utils/apiHandlers';
import PrefferedOffers from './simulator/PrefferedOffers';
import {sortingOptions} from './offers/offersSort';

class PreferredOffersPage extends React.Component {
  constructor(props) {
    super(props);
    this.selectPreferred = this.selectPreferred.bind(this);
    this.deletePreferred = this.deletePreferred.bind(this);
    this.gotoCRM = this.gotoCRM.bind(this);
  }

  state = {
    coversSecondaryTitles: {
      1: 'מתאריך ',
      2: 'נהגים מורשים',
      3: 'מין הנהג הצעיר ביותר',
      4: 'תאריך לידה',
      5: 'וותק נהיגה',
      6: 'תאריך לידת נהג שני (רשות)',
      7: 'וותק נהיגה מינימלי של הנהג השני',
      8: 'מין הנהג העיקרי',
      9: 'תאריך לידת הנהג העיקרי',
      10: 'וותק נהיגה של הנהג העיקרי',
    },
    coversTitles3RDPARTY: {},
    coversTitlesFULLCOVER: {},
    isPreferredPotentialUpdated: false,
    offers: [],
  };

  componentDidMount() {
    const parameters = queryString.parse(this.props.location.search);
    if (parameters.id) {
      getPreferredOffers({potential: parameters.id}).then(resp => {
        if (resp.success && resp.data) {
          getCoversTitles('FULLCOVER').then((resp2) => {
            if (resp2 && resp2.data) {
              this.setState({coversTitlesFULLCOVER: resp2.data.coversTitles});
            }
          });
          getCoversTitles('3RDPARTY').then((resp2) => {
            if (resp2 && resp2.data) {
              this.setState({coversTitles3RDPARTY: resp2.data.coversTitles});
            }
          });
          const comparer = sortingOptions.find(sortingOption => sortingOption.id === 1).comparer;
          this.setState({offers: [...resp.data.sort(comparer)]});
        }
      });
    }
  }

  selectPreferred(offer) {
    const parameters = queryString.parse(this.props.location.search);
    if (parameters.id) {
      updatePreferredPotential({
        selectedPreferredOffer: offer,
      }).then(response => {
        if (response.success) {
          this.setState({isPreferredPotentialUpdated: true});
        }
      }).catch((error) => {
        alert(JSON.stringify(error));
      });
    }
  }

  deletePreferred(offer) {
    const parameters = queryString.parse(this.props.location.search);
    if (parameters.id) {
      deletePreferredOffer({
        preferredOfferId: offer.preferredOfferId,
      }).then(response => {
        // eslint-disable-next-line no-prototype-builtins
        if (response.hasOwnProperty('size')) {
          const offers = this.state.offers;
          this.setState({offers: offers.filter(item => item.Id !== offer.Id)});
        }
      }).catch((error) => {
        alert(JSON.stringify(error));
      });
    }
  }

  gotoCRM(gotoCRM) {
    const parameters = queryString.parse(this.props.location.search);

    if (gotoCRM) {
      window.location.href = `${process.env.REACT_APP_CRM_URL}/${parameters.id}`;
    } else {
      this.setState({isPreferredPotentialUpdated: false});
    }
  }

  render() {
    return (
      <PrefferedOffers
        offers={this.state.offers}
        coversTitlesFULLCOVER={this.state.coversTitlesFULLCOVER}
        coversTitles3RDPARTY={this.state.coversTitles3RDPARTY}
        coversSecondaryTitles={this.state.coversSecondaryTitles}
        onSelectPreferred={(offerData) => this.selectPreferred(offerData)}
        openModal={this.state.isPreferredPotentialUpdated}
        closeModalCallback={this.gotoCRM}
        onDeletePreferred={(offerData) => this.deletePreferred(offerData)} />
    );
  }
}

PreferredOffersPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(PreferredOffersPage);
