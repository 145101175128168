import React from 'react';
import PropTypes from 'prop-types';
import {Header as BaseHeader, Breadcrumbs, Breakpoints as breakpoints} from 'wobi-web-common';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import {injectIntl} from 'react-intl';
import {getTabsPercentage, areBreadCrumbsNeeded, getStepName, stepNameToIndex} from '../utils/routingHelpers';
import fullDataSteps from '../config/steps';
import {handleContactUsSubmit, getPhoneNumber} from '../utils/apiHandlers';
import {GettingStartedRoute} from '../config/routes';
import {fireGtmEvent, fireGtmEventWithUserId} from '../utils/marketing.utils';
import {getOffersFields, getPolicyTypeFromStorage} from '../utils/persistOfferDetails';
import {
  updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields,
  updateForceTriggeredByInSessionStorageOffersFields,
} from '../utils/common';
import {TRIGGERED_BY, abandonmentStep} from '../constants/enums';
import MainTitle from './MainTitle';

const steps = fullDataSteps.reduce((curry, step) => {
  curry.push(step.label);
  return curry;
}, []);

const isPolicyPeriodPresented = (historyProperty) => historyProperty &&
  historyProperty.location &&
  historyProperty.location.pathname !== GettingStartedRoute.pathname;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.handleBack = this.handleBack.bind(this);
    this.setStepAndPercentage = this.setStepAndPercentage.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    isHeaderFixed: this.props.location.pathname !== '/offers',
    percentage: 0,
    phoneNumber: '',
    showSteps: true,
    stepIndex: 0,
    tabs: [],
    type: getOffersFields('policyType'),
  };

  handleBack() {
    fireGtmEvent('AutoTimePick');
    this.props.history.goBack();
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({...this.state, type: getPolicyTypeFromStorage()});
    this.setStepAndPercentage(this.props.location);
    this.unlisten = this.props.history.listen((location) => {
      this.setStepAndPercentage(location);
    });
    document.addEventListener('mouseup', this.handleClickOutside, false);
    setTimeout(() => {
      const sessionData = JSON.parse(sessionStorage.getItem('offers_fields'));
      const keywords = sessionData && sessionData.utm_keywords;
      if (keywords && typeof keywords === 'string') {
        try {
          getPhoneNumber(keywords).then(({data}) => {
            this.setState({
              phoneNumber: data.phoneNumbers,
            });
          });
        } catch (error) {
          console.info(error);
        }
      }
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({isHeaderFixed: this.props.location.pathname !== '/offers'});
    }
  }

  componentWillUnmount() {
    this.unlisten();
    document.removeEventListener('mouseup', this.handleClickOutside, false);
  }

  handleClickOutside() {
    setTimeout(() => {
      this.setState({type: getOffersFields('policyType')});
    }, 1000);
  }

  getPolicyTypeTitle() {
    const policyType = this.state.type;

    return (
      <div >
          ביטוח
          רכב
        < span >
          {policyType && this.props.intl.formatMessage({id: policyType.toLowerCase()})}
          {' '}
+ חובה
          {' '}
        </span>
      </div>
    );
  }

  setStepAndPercentage(location) {
    const step = getStepName(location);

    // Hide breadcrumbs on specific pages
    const hideBreadCrumbs = !areBreadCrumbsNeeded(step);
    if (hideBreadCrumbs) {
      this.setState({showSteps: false});
      return;
    }

    const stepIndex = stepNameToIndex(step);
    const percentage = getTabsPercentage(location, 'details');
    this.setState({percentage,
      showSteps: true,
      stepIndex});
  }

  render() {
    const headerEvents = {
      callHandler: () => fireGtmEvent('AutoCall'),
      chatHandler: () => fireGtmEvent(this.props.isMobile ? 'Auto2Chat' : 'Auto2ChatDSK'),
      headerContactHandler: () => fireGtmEvent('AutoHeaderContact'),
      logoHandler: () => fireGtmEvent('Auto2HomePage'),
    };

    const hideHeader = () => this.props.isMobile && !this.state.isHeaderFixed;

    return (
      <Container>
        <BaseHeader phoneNumber={this.state.phoneNumber} backFunction={this.handleBack}
          handler={(values) => {
            const triggeredBy = this.props.isMobile ? TRIGGERED_BY.WEBSITE_UPPER_MOBILE_FUNNEL :
              TRIGGERED_BY.WEBSITE_OFFERS_UP_LEFT;
            updateForceTriggeredByInSessionStorageOffersFields(triggeredBy);
            updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_1.key);
            fireGtmEventWithUserId(values.phone, 'AutoContactUs');
            return handleContactUsSubmit({
              offersCallId: getOffersFields('offersCallId'),
              ...values,
            }, getOffersFields(), triggeredBy);
          }}
          isHeaderFixed={this.state.isHeaderFixed}
          hasContactus={this.props.hasContactus}
          hasChatButton={this.props.hasChatButton}
          onCallMeClick={() => {
            const offersFields = getOffersFields();
            const {firstname, lastname, phone} = offersFields || {};
            if (firstname && lastname && phone) {
              return {
                firstname,
                lastname,
                phone,
              };
            }
            return null;
          }}
          {...headerEvents}
        />
        {this.props.showOnlyBaseHeader || hideHeader() ? false : (
          <div className='wrapper'>
            <MainTitle
              mainText={<React.Fragment>
                {this.getPolicyTypeTitle()}
              </React.Fragment>}
              subText={isPolicyPeriodPresented(this.props.history) &&
              getOffersFields('startDate') &&
              getOffersFields('endDate') ? <React.Fragment>
                    תקופת ביטוח
                  <span>
                    {moment(getOffersFields('startDate'), 'DD/MM/YYYY').format('D.M.YYYY')}
  -
                    {moment(getOffersFields('endDate'), 'DD/MM/YYYY').format('D.M.YYYY')}
                  </span>
                </React.Fragment> : ''}
            />
            {
              this.state.showSteps ? <Breadcrumbs
                currentStep={this.state.stepIndex}
                subStepPercentage={this.state.percentage} steps={steps}/> :
                false
            }
          </div>
        )}
      </Container>
    );
  }
}

Header.propTypes = {
  hasChatButton: PropTypes.bool,
  hasContactus: PropTypes.bool,
  history: PropTypes.object,
  intl: PropTypes.object,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  showOnlyBaseHeader: PropTypes.bool,
};

const Container = styled.div`
  padding: 10px 0 20px 0;
  
  .MuiPaper-root {
    padding: 0
  }
  
  .breadcrumbs {
    max-width: 500px;
  }
  
  @media screen and (max-width: ${breakpoints.mobile}px) {
    max-width: unset;
    padding: 0;
    
    .wrapper {
      padding: 0 15px;
    }
  }
`;

export default withRouter(injectIntl(Header));
