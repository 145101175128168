import React, {Component} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {askToContact} from '../utils/apiHandlers';
import {getImageByAgent} from '../config/agent';

const MobileBackground = getImageByAgent('mobileBackground.png');
const companyLogo = getImageByAgent('companyLogo.svg');

class AskToContactThankYouPage extends Component {
  state = {
    isUpdatedLead: false,
    isUpdatedLeadError: false,
  };

  updateLead(leadId) {
    if (leadId) {
      askToContact({
        leadId,
      }).then(resp => {
        this.setState({isUpdatedLead: true});
        if (!resp.success) {
          this.setState({isUpdatedLeadError: true});
        }
      });
    } else {
      this.setState({
        isUpdatedLead: true,
        isUpdatedLeadError: true,
      });
    }
  }

  componentDidMount() {
    const parameters = this.props.match.params;
    const {leadId} = parameters;
    this.updateLead(leadId);
  }

  render() {
    return (
      this.state.isUpdatedLead ?
        <Container background={MobileBackground}>
          <div className='inner'>
            <div className='logo-wrapper'>
              <img width='200px' src={companyLogo} alt='wobi logo'/>
            </div>
            {this.state.isUpdatedLeadError ? (
              <FormattedMessage tagName='div' id='ask_to_ontact.error_message'/>) :
              (<div>
                <FormattedMessage tagName='div' id='ask_to_ontact.hello_message'/>
                <FormattedMessage tagName='div' id='ask_to_ontact.got_your_request'/>
                <FormattedMessage tagName='div' id='ask_to_ontact.we_will_contact_you'/>
              </div>
              )
            }
          </div>
        </Container> : false
    );
  }
}

AskToContactThankYouPage.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  sendChurnPotentialId: PropTypes.func,
};

const Container = styled.div`
  padding: 20px;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-image: url(${props => props.background});
  .inner {
    color: #046994;
    text-align: center;
  }
  .logo-wrapper {
    margin-bottom: 30px;
  }
  .check-icon {
    color: #f6921e;
    font-size: 40px;
    margin-bottom: 30px;
    border: 1px solid #f6921e;
    padding: 10px;
    border-radius: 50px;
    background-color: #fff;
  }
`;

export default withRouter(AskToContactThankYouPage);
