const isPostIlEmpolee = (utmSource, agentDeveloperName) => {
  if (utmSource === 'postins-employee' && agentDeveloperName === 'Post_IL') {
    return true;
  }
  return false;
};

const isShowPostIlEmpoleeDiscount = (
  utmSource,
  agentDeveloperName,
  policyType,
) => {
  if (
    isPostIlEmpolee(utmSource, agentDeveloperName) &&
    policyType === 'FULLCOVER'
  ) {
    return true;
  }
  return false;
};

export {
  isPostIlEmpolee,
  isShowPostIlEmpoleeDiscount,
};

