import Moment from 'moment';

export const conditions = {
  isAboveMin(d, min) {
    return Moment(d).isAfter(min, 'day') || Moment(d).isSame(min, 'day') ||
          Moment(d, 'DD/MM/YYYY').isAfter(min, 'day') || Moment(d, 'DD/MM/YYYY').isSame(min, 'day');
  },

  isUnderMax(d, max) {
    return Moment(d).isBefore(max, 'day') || Moment(d).isSame(max, 'day') ||
          Moment(d, 'DD/MM/YYYY').isBefore(max, 'day') || Moment(d, 'DD/MM/YYYY').isSame(max, 'day');
  },

  isValidDate(d) {
    /* when d is 'null' there's another test for it ('required') */
    if (d instanceof Date || d === null) {
      return !Number.isNaN(d);
    } else {
      return Moment(d, 'DD/MM/YYYY').isValid() || Moment(d, 'YYYY-MM-DD').isValid();
    }
  },
};
