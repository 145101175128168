import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core';
import {ContactUs, agentDeveloperUtils} from 'wobi-web-common';
import {handleContactUsSubmit} from '../utils/apiHandlers';
import {fireGtmEventWithUserId} from '../utils/marketing.utils';
import {getOffersFields} from '../utils/persistOfferDetails';
import {
  updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields,
  updateForceTriggeredByInSessionStorageOffersFields,
} from '../utils/common';
import {TRIGGERED_BY, abandonmentStep} from '../constants/enums';
import {isShowContactUsPostIlEmploeeDiscount} from '../utils/showContactUsHelper';

const SideBar = ({intl}) => {
  const [isShowContactUs, setIsShowContactUs] = React.useState(true);
  const classes = useStyles();
  const messages = id => intl.formatMessage({id});

  const handleIsShowContactUsPostIlEmploeeDiscount = () => {
    const utmSource = getOffersFields('utm_source');
    const agentDeveloperName = agentDeveloperUtils.getAgentDeveloperName;
    if (!isShowContactUsPostIlEmploeeDiscount(utmSource, agentDeveloperName)) {
      setIsShowContactUs(false);
    }
  };

  React.useEffect(() => {
    handleIsShowContactUsPostIlEmploeeDiscount();
  }, []);
  return (<div className={classes.sticky}>
    {isShowContactUs && <ContactUs
      postRequest={(values) => {
        updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_1.key);
        updateForceTriggeredByInSessionStorageOffersFields(TRIGGERED_BY.WEBSITE_LEFT_FUNNEL);
        fireGtmEventWithUserId(values.phone, 'AutoContactUs');
        return handleContactUsSubmit(values, getOffersFields(), TRIGGERED_BY.WEBSITE_LEFT_FUNNEL);
      }}
      isOpen={false}
      label={messages('contact_us.terms_conditions_label')}
      linkText={messages('contact_us.terms_conditions_link_text')}
    />}
  </div>);
};

const useStyles = makeStyles(() => ({
  sticky: {
    position: 'sticky',
    top: 30,
  },
}));

SideBar.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(SideBar);
