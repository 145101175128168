/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus*/
import React from 'react';
import PropTypes from 'prop-types';
import {Black} from 'wobi-web-common/dist/Colors';
import Grow from '@material-ui/core/Grow';
import {makeStyles} from '@material-ui/core/styles';
import {agentDeveloperUtils} from 'wobi-web-common';
import {getImageByAgent} from '../config/agent';

const Hat = getImageByAgent('party-hat.gif');
const Bulb = getImageByAgent('lightbulb.gif');
const Alert = getImageByAgent('alert.svg');

const useStyles = makeStyles(theme => ({
  bubble: {
    '&::after': {
      border: 'solid transparent',
      borderBottomColor: agentDeveloperUtils.getConvertedColor(Black[10]),
      borderColor: 'rgba(136, 183, 213, 0)',
      borderWidth: 6,
      bottom: '50%',
      content: '""',
      display: 'static',
      height: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: '100%',
      transform: 'rotate(-90deg) translateX(-50%)',
      transformOrigin: 'center',
      width: 0,
      [theme.breakpoints.down(1343)]: {
        display: 'none',
      },
    },
    '&::before': {
      border: 'solid transparent',
      borderBottomColor: agentDeveloperUtils.getConvertedColor('#e8e8e8'),
      borderColor: 'rgba(194, 225, 245, 0)',
      borderWidth: 7,
      bottom: '50%',
      content: '""',
      height: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: '100%',
      transform: 'rotate(-90deg) translateX(-50%)',
      transformOrigin: 'center',
      width: 0,
    },
    backgroundColor: agentDeveloperUtils.getConvertedColor(theme.palette.background.default),
    border: `1px solid ${agentDeveloperUtils.getConvertedColor('#e8e8e8')}`,
    borderRadius: 8,
    boxShadow: `0px 2px 3px ${agentDeveloperUtils.getConvertedColor('#00000007')}`,
    color: theme.palette.text.secondary,
    flex: '1 0 auto',
    fontFamily: 'Ploni,Arial,sans-serif',
    fontSize: theme.typography.pxToRem(10.5),
    fontWeight: '500',
    lineHeight: 1,
    marginLeft: 17,
    maxWidth: 300,
    minWidth: 120,
    padding: '8px 10px',
    position: 'relative',
    wordWrap: 'break-word',
    zIndex: 1,
  },
  container: {
    alignItems: 'flex-end',
    display: 'flex',
    flexFlow: 'row nowrap',
    height: props => props.show ? '100%' : 0,
    opacity: props => props.show ? 1 : 0,
    pointerEvents: 'none',
  },
  icon: {
    '&.Alert': {
      backgroundColor: 'transparent',
      backgroundImage: props => `url(${props.img})`,
      width: 22.859,
    },
    '&.Bulb': {
      backgroundColor: 'transparent',
      backgroundImage: props => `url(${props.img})`,
    },
    '&.Hat': {
      backgroundColor: 'transparent',
      backgroundImage: props => `url(${props.img})`,
    },
    backgroundColor: agentDeveloperUtils.getPrimaryColor(450),
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    color: theme.palette.text.secondary,
    flex: '0 0 auto',
    height: 35,
    width: 33,

  },
  shouldBeClick: {
    animation: '$clickMe 1s infinite',
    cursor: 'pointer',
    pointerEvents: 'initial',
  },
}));

const AlwaysOnTooltip = (props) => {
  const {kind, show, className, img, children, bubbleClassName} = props;
  const icons = {
    Alert,
    Bulb,
    Hat,
  };
  const classes = useStyles({
    img: img || icons[kind],
    show,
  });
  return (
    <div className={`${show ? className : ''} ${classes.container}`} >
      <div role='img' className={`${classes.icon} ${kind}`} aria-label='הערה' aria-hidden={!show}/>
      {<Grow in={show} mountOnEnter unmountOnExit timeout={{
        appear: 300,
        enter: 300,
        exit: 300,
      }} style={{transitionDelay: '100ms'}}>
        <div className={`${classes.bubble} ${bubbleClassName}`}>
          <span>{children}</span>
        </div>
      </Grow>}
    </div>
  );
};

AlwaysOnTooltip.propTypes = {
  bubbleClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  img: PropTypes.any,
  kind: PropTypes.oneOf(['Hat', 'Bulb', 'Alert']),
  needClickOnIcon: PropTypes.bool,
  show: PropTypes.bool,
  showText: PropTypes.bool,
};

AlwaysOnTooltip.defaultProps = {
  bubbleClassName: '',
};

export default AlwaysOnTooltip;
