import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs, makeStyles} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Breakpoints as breakpoints} from 'wobi-web-common';

const contentsStyle = {
  padding: '0 50px',
  width: '100%',
};
contentsStyle[`@media screen and (max-width: ${breakpoints.mobile}px)`] = {
  padding: '0 0 20px',
};

const useStyles = makeStyles({
  contents: contentsStyle,
  hide: {
    display: 'none !important',
  },
  root: {
    display: 'flex',
  },
  tabs: {
    flex: 'none',
    width: 213,
  },
});

const a11yProps = (index, itemLabel, currentTabIndex) => ({
  'aria-controls': `vertical-tabpanel-${index}`,
  'aria-label': `${itemLabel} ${index < currentTabIndex ? 'ניתן לחזור ל-tab זה במקש enter' : ''}`,
  id: `vertical-tab-${index}`,
  key: `tab-${index}`,
  role: 'tab',
});

const TabsLayout = (
  {
    currentTabIndex,
    handleChange,
    tabs,
    formikSetTouched,
    children,
    formikTouched,
    formikHandleChange,
    formikSetField,
    formikHandleBlur,
    formikErrors,
    formikResetForm,
    formikValues,
    formikIsSubmitting,
    formikHandleSubmit,
    formikValidateFrom,
    maxIndex,
  },
) => {
  const classes = useStyles();
  const isDesktop = !useMediaQuery(`(max-width:${breakpoints.mobile}px)`);

  const isComplete = (index) => index < currentTabIndex ? 'complete' : undefined;

  const TabsContainer = (
    <Tabs
      className={classes.tabs}
      orientation='vertical'
      variant='scrollable'
      value={currentTabIndex}
      onChange={handleChange}
      aria-label='טאבים בשלב זה, שלב פרטים'>
      {tabs.map((item, index) => (
        <Tab key={index} disabled={maxIndex < index}
          label={index === currentTabIndex ? (<h1>{item.label}</h1>) : item.label}
          className={isComplete(index)} {...a11yProps(index, item.label, currentTabIndex)} />
      ))}
    </Tabs>
  );

  return (
    <div className={classes.root}>
      {
        isDesktop ? TabsContainer : ''
      }
      <div className={classes.contents}>
        { children && children.length ?
          children.map((item, index) => React.cloneElement(item, {
            className: index === currentTabIndex ? '' : classes.hide,
            formikErrors,
            formikHandleBlur,
            formikHandleChange,
            formikHandleSubmit,
            formikIsSubmitting,
            formikResetForm,
            formikSetField,
            formikSetTouched,
            formikTouched,
            formikValidateFrom,
            formikValues,
            key: `tab-content-${index}`,
          })) : false}
      </div>
    </div>
  );
};

TabsLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
  currentTabIndex: PropTypes.number,
  formikErrors: PropTypes.object,
  formikHandleBlur: PropTypes.func,
  formikHandleChange: PropTypes.func,
  formikHandleSubmit: PropTypes.func,
  formikIsSubmitting: PropTypes.bool,
  formikResetForm: PropTypes.func,
  formikSetField: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikTouched: PropTypes.any,
  formikValidateFrom: PropTypes.func,
  formikValues: PropTypes.object,
  handleChange: PropTypes.func,
  maxIndex: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    key: PropTypes.string,
    label: PropTypes.string,
  })),

};

export default TabsLayout;
