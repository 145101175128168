import React from 'react';
import {injectIntl} from 'react-intl';
import propTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Grid} from '@material-ui/core';
import {Offer as GenericOffer} from 'wobi-web-common';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import companiesLogos from '../../assets/js/companiesLogos';
import PurchaseContainer from '../Offer-inner-comps/PurchaseContainer';
import useStyles from './useStyles';

// import { agentDeveloperUtils } from 'wobi-web-common/agentDeveloperUtils';

const Offer = (props) => {
  const isXSmall = useMediaQuery('(max-width:420px)');
  const {data, creditCardCommission, setCreditCardCommission,
    creditCardCommissionDiscount, setCreditCardCommissionDiscount} = props;
  const classes = useStyles({isMobile: true,
    isOpen: true});
  const {
    productType,
    providerName,
    productLineName,
    price,
    mandatoryPrice,
    cascoPrice,
    cascoAfterDiscountPrice,
    priceAfterDiscount,
  } = data;

  const messages = (id) => props.intl.formatMessage({id});
  const logo = companiesLogos.find(elm => elm.key === props.data.logo);
  const getLogoSource = () => !logo ? '' : isXSmall ? logo.imgSrc_small : logo.imgSrc_big;

  const isUsingCarHandlingFee = agentDeveloperUtils.isUsingCarHandlingFee();
  const carHendelingFee = agentDeveloperUtils.getCarHandlingFee();

  return (
    <GenericOffer {...props}>
      <div
        role='button'
        tabIndex={0}
        className={classes.offerContent}
      >
        <div className={classes.left}>
          <Grid
            container
            style={{position: 'relative'}}
            spacing={0}
            justify='flex-start'
          >
            <div>
              <div className={classes.logo}>
                <img
                  src={getLogoSource()}
                  alt={`חברת ${providerName}`}
                  data-testid='open-offer-logo-img'
                />
                <b data-testid='open-offer-product-line-name'>{productLineName}</b>
              </div>
            </div>
            <span className={classes.partition} />
            <Grid item xs={6} >
              {priceAfterDiscount ? (
                <Grid item xs={6}
                  container
                  style={{position: 'relative'}}
                  spacing={0}
                  justify='space-between'>
                  <Grid items xs={3} className={classes.pricesWrapDiscount} style={{maxWidth: 'unset'}}>
                    <div className={classes.prices}>
                      <div className={[classes.totalPrice].join(' ')}>
                        <span>₪</span>
                        { Math.round(creditCardCommissionDiscount + priceAfterDiscount).toLocaleString() }
                      </div>
                      <div className={classes.partialPrices}>
                        <span className={classes.partialPrice}>
                          {`${messages(productType.toLowerCase())}
                          ${cascoAfterDiscountPrice.toLocaleString() }`}
                        </span>
                        <span className={classes.partialPrice}>
                          {`${messages('mandatory')}
                          ${mandatoryPrice.toLocaleString()}`}
                        </span>
                        {isUsingCarHandlingFee && <br/>}
                        {isUsingCarHandlingFee && <span className={classes.partialPrice}>
                          {`${messages('דמי טיפול')}
                          ${carHendelingFee.toLocaleString()}`}
                        </span>}
                        {
                          creditCardCommissionDiscount ? (
                            <span className={[classes.partialPrice, classes.creditCardCommission].join(' ')}>
                              {`${messages('offers.credit_card_commission')}
                            ${creditCardCommissionDiscount.toLocaleString()} ₪`}
                            </span>
                          ) : false
                        }
                      </div>
                    </div>
                  </Grid>
                  <Grid items xs={3}
                    className={[classes.pricesWrapDiscount, classes.totalPriceCrossedOpen].join(' ')}
                    style={{maxWidth: 'unset'}}>
                    <div className={[classes.prices].join(' ')}>
                      <div className={[classes.totalPrice, classes.totalPriceDiscount].join(' ')}>
                        <span>₪</span>
                        { Math.round(creditCardCommission + price).toLocaleString() }
                      </div>
                      <div className={classes.partialPrice}>
                        <span className={classes.partialPriceDiscount}>
                          {`${messages(productType.toLowerCase())}
                          ${cascoPrice.toLocaleString() }`}
                        </span>
                        <span className={classes.partialPriceDiscount}>
                          {`${messages('mandatory')}
                          ${mandatoryPrice.toLocaleString()}`}
                        </span>
                        {isUsingCarHandlingFee && <br/>}
                        {isUsingCarHandlingFee && <span className={classes.partialPriceDiscount}>
                          {`${messages('דמי טיפול')}
                        ${carHendelingFee.toLocaleString()}`}
                        </span>
                        }
                        {
                          creditCardCommission ? (
                            <span className={[classes.partialPrice, classes.creditCardCommission].join(' ')}>
                              {`${messages('offers.credit_card_commission')}
                            ${creditCardCommission.toLocaleString()} ₪`}
                            </span>
                          ) : false
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <div className={classes.prices}>
                  <div className={classes.totalPrice}>
                    <span>₪</span>
                    { Math.round(creditCardCommission + price).toLocaleString() }
                  </div>
                  <div className={classes.partialPrices}>
                    <span className={classes.partialPrice}>
                      {`${messages(productType.toLowerCase())}
                      ${cascoPrice.toLocaleString() }`}
                    </span>
                    <span className={classes.partialPrice}>
                      {`${messages('mandatory')}
                      ${mandatoryPrice.toLocaleString()}`}
                    </span>
                    {isUsingCarHandlingFee && <br/>}
                    {isUsingCarHandlingFee && <span className={classes.partialPrice}>
                      {`${messages('דמי טיפול')}
                          ${carHendelingFee.toLocaleString()}`}
                    </span>}
                    {
                      creditCardCommission ? (
                        <span className={[classes.partialPrice, classes.creditCardCommission].join(' ')}>
                          {`${messages('offers.credit_card_commission')}
                        ${creditCardCommission.toLocaleString()} ₪`}
                        </span>
                      ) : false
                    }
                  </div>
                </div>)}
            </Grid>
          </Grid>
          <PurchaseContainer
            classes={classes}
            creditCardCommission={creditCardCommission}
            setCreditCardCommission={setCreditCardCommission}
            creditCardCommissionDiscount={creditCardCommissionDiscount}
            setCreditCardCommissionDiscount={setCreditCardCommissionDiscount}
            openContactUs={props.openContactUs} data={data}
          />
        </div>
      </div>
    </GenericOffer>
  );
};

Offer.propTypes = {
  ...GenericOffer.propTypes,
  closeContactUsHandler: propTypes.func.isRequired,
  openContactUs: propTypes.func.isRequired,
};

export default injectIntl(Offer);
