import {isPostIlEmpolee} from './postIlEmploeeHelper';

const isShowContactUsPostIlEmploeeDiscount = (utmSource, agentDeveloperName) => {
  if (isPostIlEmpolee(utmSource, agentDeveloperName)) {
    return false;
  }
  return true;
};

export {
  isShowContactUsPostIlEmploeeDiscount,
};
