/* eslint-disable max-len */
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';

const ExpandIcon = () => (
  <SvgIcon>
    <path d='M12,15.7l-7.6-5.4C4,10,3.9,9.4,4.2,8.9c0.3-0.5,0.9-0.6,1.4-0.2l6.4,4.6l6.4-4.6c0.4-0.3,1.1-0.2,1.4,0.2c0.3,0.4,0.2,1.1-0.2,1.4L12,15.7z' />
  </SvgIcon>);

const CollapseIcon = () => (
  <SvgIcon>
    <path d='M19.6,13.9c0.4,0.3,0.5,1,0.2,1.4s-1,0.5-1.4,0.2L12,10.9l-6.4,4.6c-0.5,0.4-1.1,0.3-1.4-0.2c-0.3-0.5-0.2-1.1,0.2-1.4L12,8.5L19.6,13.9z' />
  </SvgIcon>);

const SortIcon = () => (
  <svg viewBox='0 0 18 19' className='sortIcon'>
    <polygon id='up' points='5.2,0 0,6.9 3.5,5.9 3.5,19 6.9,19 6.9,5.9 10.4,6.9 '/>
    <polygon id='down' points='12.8,19 18,12.1 14.6,13.1 14.6,0 11.1,0 11.1,13.1 7.6,12.1 '/>
  </svg>);

export {ExpandIcon, CollapseIcon, SortIcon};
