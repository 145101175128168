import {DRIVERS_COUNT} from './enums';

export const EMPTY_STRING_VALUE = '';

export const LICENSE_KEY = '82384001';
export const DRIVERS_KEYS = {
  city: 'תל אביב - יפו',
  cityCode: '5000',
  driversCount: DRIVERS_COUNT.ONE,
  email: 'test123@wobi.co.il',
  youngDriverBirthDate: '01/01/1980',
  youngDriverGender: '1',
  youngDriverSeniority: '5',
};
export const HISTORY_KEYS = {
  bodyClaimInLast3Years: '-1',
  hasFullInsPast: 'yes',
  licenseRevocation: '0',
  propClaimInLast3Years: '-1',
};
export const CREDITCARD_OWNER_RELATION_SELECT_OPTIONS = [
  {
    name: 'בן/בת זוג',
    value: 'Spouse',
  },
  {
    name: 'בעל',
    value: 'Husband',
  },
  {
    name: 'אישה',
    value: 'wife',
  },
  {
    name: 'אמא',
    value: 'Mother',
  },
  {
    name: 'אבא',
    value: 'Father',
  },
  {
    name: 'בן',
    value: 'Son',
  },
  {
    name: 'בת',
    value: 'Daughter',
  },
  {
    name: 'אח',
    value: 'Brother',
  },
  {
    name: 'אחות',
    value: 'Sister',
  },
  {
    name: 'חבר',
    value: 'Friend',
  },
  {
    name: 'מנהל',
    value: 'Boss',
  },
];
