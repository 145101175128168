import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {Button, Grid} from '@material-ui/core';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Black} from 'wobi-web-common/dist/Colors';
import {Breakpoints as breakpoints, Offer as GenericOffer} from 'wobi-web-common';
import WobiLogo from 'wobi-web-common/dist/assets/img/wobi-logo.svg';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import companiesLogos from '../../assets/js/companiesLogos';
import ModalContainer from '../../components/SimulatorComponents/ModalContainer';
import {getCompanyLogo, checkFullCoverIsProtected} from '../../utils/common';

const PrefferedOffers = ({
  offers,
  coversTitlesFULLCOVER,
  coversTitles3RDPARTY,
  coversSecondaryTitles,
  openModal,
  closeModalCallback,
  onSelectPreferred,
  onDeletePreferred,
}) => {
  const intl = useIntl();
  const messages = (id) => intl.formatMessage({id});
  const isXSmall = useMediaQuery('(max-width:420px)');
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} container justify='flex-end'>
        <img src={WobiLogo} alt='דף הבית wobi  משווים וחוסכים'/>
      </Grid>
      <Grid item xs={12}>
        <div style={{
          color: agentDeveloperUtils.getSecondaryColor(300),
          fontSize: 36,
          fontWeight: 300,
          lineHeight: 1,
          margin: 0,
          marginBottom: 20,
        }}>
          {`${messages('simulator.preferred.title')}`}
        </div>
      </Grid>

      { offers.length > 0 ? <ModalContainer openModal={openModal} closeModalCallback={closeModalCallback}/> : false

        // <Grid item xs={12} style={{margin: '0 0 20px 0'}}>
        // <Button className={'submit'} onClick={() => window.close()}>{messages('simulator.preferred.back')}</Button>
        // </Grid> : ''
      }

      <Grid item xs={12}>
        <Grid container spacing={0}>
          {
            offers.filter(checkFullCoverIsProtected).map((offer, index) => {
              if (offer.coversSecondary) {
                offer.coversSecondary[2] = messages(`fields.drivers.${offer.driversCount}`);
              }
              const companyLogo = companiesLogos.find(elm => elm.key === offer.logo);
              return (
                <Grid item xs={12} key={`preferred-offer-${index}`}>

                  <GenericOffer titles={offer.policyType === 'FULLCOVER' ? coversTitlesFULLCOVER : coversTitles3RDPARTY}
                    titlesSecondary={coversSecondaryTitles} data={offer} isOpen >
                    <OfferContent isMobile={isMobile} isOpen className='open'>
                      {isMobile ? false : (
                        <div className='logo'>
                          <img
                            src={getCompanyLogo(companyLogo, isXSmall)}
                            alt={`חברת ${offer.providerName}`}
                          />
                          <b>{offer.productLineName}</b>
                        </div>
                      )}
                      <div className='left open'>
                        <Grid container spacing={0} justify={isMobile ? 'flex-start' : 'space-between'}>
                          {
                            isMobile ?
                              <div>
                                <div className='logo'>
                                  <img
                                    src={getCompanyLogo(companyLogo, isXSmall)}
                                    alt={`חברת ${offer.providerName}`}
                                  />
                                  <b>{offer.productLineName}</b>
                                </div>
                              </div> : false
                          }
                          { isMobile ? <span className='partition' /> : false }
                          <div className='prices-wrap'>
                            <Grid item xs={6} className='prices'>
                              <div className='total-price'>
                                <span>₪</span>
                                { Math.round(offer.price).toLocaleString() }
                              </div>
                              {
                                <div className='partial-prices'>
                                  <span>
                                    {`${messages(offer.policyType.toLowerCase())}
                           ${offer.cascoPrice.toLocaleString() }`}
                                  </span>
                                  <span>
                                    {`${messages('mandatory')}
                           ${offer.mandatoryPrice.toLocaleString()}`}
                                  </span>
                                </div>
                              }
                            </Grid>
                          </div>
                          {isMobile ? false : (
                            <Grid className='included' item xs={12} lg={6}>
                              <div>
                                {`${messages('simulator.preferred.allowed_drivers')}: 
                              ${messages('fields.drivers.' + offer.driversCount)} `}
                              </div>
                              <div>{`${messages('simulator.preferred.start_date')}: ${offer.startDate}`}</div>
                              <div>{`${messages('fields.youngDriverBirthDate')}: ${offer.youngDriverBirthDate}`}</div>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                      <div style={{marginTop: 40}}>
                        <Button onClick={() => onSelectPreferred(offer)}>{messages('offers.payment_btn')}</Button>
                        <Button onClick={() => onDeletePreferred(offer)} color='secondary' variant='outlined'>
                          {messages('simulator.preferred.cancel_offer')}
                        </Button>
                      </div>
                    </OfferContent>
                  </GenericOffer>

                </Grid>
              );
            })
          }
        </Grid>
      </Grid>
    </Grid>
  );
};

const OfferContent = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 20px 35px;
  @media screen and (max-width: ${breakpoints.mobile}px) {padding: 10px 10px 14px 14px; justify-content: space-around;}
  @media screen and (max-width: 420px) {padding: 0 10px 10px;}
  &.open {
    @media screen and (max-width: ${breakpoints.mobile}px) {padding: 20px 0 10px;}
  }
  &:not(.open) > .logo {margin-top: 8px;}
  &:not(.open) > .logo > b {@media screen and (max-width: 420px) {position: relative; width: 158px;}}
  .logo {
    width: 150px;
    height: 100px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    > img {display: inline-block; width: inherit;}
    @media screen and (max-width: 802px) and (min-width: ${breakpoints.mobile + 1}px) {width: 120px;}
    > b {
      font-size: 14px;
      line-height: 14px;
      padding: 0 3px;
      font-weight: 700;
      color: ${agentDeveloperUtils.getSecondaryColor(500)};
    }
    @media screen and (max-width: 1023px) {margin-top: 17px;}
    @media screen and (max-width: ${breakpoints.mobile}px) {
      margin-top: 0;
      height: unset;
      justify-content: flex-end;
    }
    @media screen and (max-width: 420px) {
      min-width: 88px;
      width: 40%;
      height: 67px;
      justify-content: flex-end;
      > img {width: 88px;}
    }
  }

  .left {
    margin-right: 10px;
    width: 100%;
    max-width: 560px;
    min-width: 0;
    &:not(.open) .prices-wrap {@media screen and (max-width: 1280px) {width: 50%;}}
    &:not(.open) .prices-wrap {@media screen and (max-width: 420px) {justify-content:center;}}
    &:not(.open) .prices {@media screen and (max-width: 830px) {margin-right: 0; min-width: 100px;}}
    &:not(.open) .total-price {margin-top: 8px; margin-top: 0;}
    &.open > div {@media screen and (max-width: ${breakpoints.mobile}px) {position: relative;}}
    @media screen and (max-width: 1023px) {margin-top: 10px;}
    &:not(.open) {@media screen and (max-width: 1023px) {margin-top: 30px;}}
    &:not(.open) {@media screen and (max-width: 420px) {margin-top: 0;}}
    @media screen and (max-width: ${breakpoints.mobile}px) {
      margin: 0;
      max-width: 100%;
      .partition {
        display: block;
        height: 65px;
        width: 2px;
        background-color: ${agentDeveloperUtils.getConvertedColor('#D8EAF1')};
        position: absolute;
        left: 50%;
        transform: translateX(-1px);
        bottom: 0;
      }
      > div { height: inherit; }
      > div > div {
        width: 50%;
        display: flex;
        justify-content: center;
      }
      &:not(.open) {
        height: 67px;
        > div > div {justify-content: flex-start;}
        .prices {justify-content: center; position: static;}
        .total-price {
          font-size: 28px;
          margin-top: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &:not(.open) .total-price {@media screen and (max-width: 500px) {position: static; transform: none;}}
    &:not(.open) .total-price {@media screen and (max-width: 1023px) {font-size: 32px;}}
    &:not(.open) .prices {@media screen and (max-width: 500px) {justify-content:flex-start;}}
    @media screen and (max-width: 420px) {
      .partition { display: none; }
      > div > div {
        &:first-child {min-width: 88px; width: 40%;}
        &:last-child {width: 60%; width: calc(50% - 8px);}
      }
      &:not(.open) {
        > div > div {
          justify-content: flex-start;
          min-width: 70px;
          min-width: unset;
        }
        .prices {
          min-width: 70px;
        }
        .total-price {font-size: 16px; margin-top: 13px; margin-top: 28px;}
        .total-price > span {font-size: 16px;}
      }
    }

    .prices {
      display: flex;
      align-items: flex-start;
      flex-flow: column nowrap;
      position: relative;
      min-width: 191px;
      margin-right: 33px;
      @media screen and (max-width: 1097px) {margin-right: 20px;}
      @media screen and (max-width: 1023px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      @media screen and (max-width: ${breakpoints.mobile}px) {
        width: 150px;
        min-width: 150px;
        flex: 0;
        justify-content: flex-end;
        height: unset;
        align-items: center;
      }
      @media screen and (max-width: 420px) {
        width: inherit;
        min-width: 140px;
        align-items: center;
        .partial-prices { font-size: 11px; }
      }
    }

    .total-price {
      font-size: 44px;
      color: ${agentDeveloperUtils.getPrimaryColor(450)};
      font-weight: 700;
      line-height: 1;
      margin-bottom: 5px;
      transition: all 0.3s;
      margin-top: 21px;
      @media screen and (max-width: ${breakpoints.mobile}px) {margin-bottom: 0;}
      @media screen and (max-width: 420px) {
        font-size: 36px;

      }
      > span {
        font-size: 24px;
        margin-right: 5px;
      }
    }

    .partial-prices {
      .credit_card_commission {
        display: block;      
      }
    
        @media screen and (max-width: ${breakpoints.mobile}px) {height: 14px; line-height: 14px;}
      > span {
      color: ${agentDeveloperUtils.getConvertedColor(Black[300])};
      font-size: 14px;
      font-weight: 500;

      :first-child {
       margin-left: 10px;
        @media screen and (max-width: 420px) {margin-left: 4px;}
        :after {
          margin-right: 10px;
          @media screen and (max-width: 420px) {margin-right: 4px;}
          font-size: 16px;
          font-weight: 200;
          display: inline-block;
          content: '|';
          color: ${agentDeveloperUtils.getConvertedColor(Black[220])};
          opacity: 0.9;
        }
      }
      }
       
    }
    .included {
      height: 114px;
      position: relative;
      max-width: 225px;
      @media screen and (max-width: 1097px) {
        flex-basis: 100%;
        margin-right: 8px;
        position: static;
        height: auto;
      }
      &.open {
        margin: 0 auto;
      }
    }
    .included > div {
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
      color: ${agentDeveloperUtils.getConvertedColor(Black[300])};
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      @media screen and (max-width: 1097px) {position: static; margin: 7px 0;}
      &:first-child {
        margin-top: 40px;
        letter-spacing: -0.4px;
      }
      :before {
        content: '';
        display: block;
        background-color: #B8D1DE;
        min-width: 6px;
        width: 6px;
        height: 6px;
        margin-left: 6px;
        border-radius: 6px;
      }
    }

    .protection {
        margin-top: 10px;
        font-size: 13px;
        line-height: 1;
        font-weight: 600;
        color: ${agentDeveloperUtils.getConvertedColor(Black[300])};
        display: flex;
        align-items: center;
        width: 100%;
        span {
          position: relative;
          padding-right: 10px;
        }
        span::before {
          content: '*';
          color: ${agentDeveloperUtils.getPrimaryColor(450)};
          font-size: 19px;
          position: absolute;
          top: -1px;
          right: -1px;
        }
        @media screen and (max-width: 1280px) {width: 100%;}
        &.open {
          width: 100%;
          justify-content: center;
        }
      }

    .purchase-btn-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      flex: 0 1 auto;
      width: 191px;
      @media screen and (max-width: 1023px) {
        flex: 0 1 auto;
        width: calc(50% - 16px);
        margin-right: 16px;
      }
      @media screen and (max-width: ${breakpoints.mobile}px) {justify-content:flex-end;}
      @media screen and (max-width: 420px) {margin-right: 8px;}
      &.initial {
        max-width: 167px;
      }

      button {
        font-weight: 700;
        font-size: 16px;
        min-width: ${props => props.isMobile ? '70px' : '191px'};
        @media screen and (max-width: 1023px) {width: 100%; min-width: unset;}
        @media screen and (max-width: ${breakpoints.mobile}px) {width: calc(100% - 10px); max-width: 191px;}
        @media screen and (max-width: 420px) {margin-top:16px; width: 100%; font-size: 14px; padding: 7px 10px;}
      }
    }
  }
`;

PrefferedOffers.propTypes = {
  closeModalCallback: PropTypes.func,
  coversSecondaryTitles: PropTypes.array,
  coversTitles3RDPARTY: PropTypes.array,
  coversTitlesFULLCOVER: PropTypes.array,
  offers: PropTypes.array,
  onDeletePreferred: PropTypes.func,
  onSelectPreferred: PropTypes.func,
  openModal: PropTypes.func,
};

export default PrefferedOffers;
