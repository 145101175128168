import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion as ExpansionPanel,
  AccordionDetails as ExpansionPanelDetails,
  AccordionSummary as ExpansionPanelSummary} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    padding: theme.spacing(1),
  },
  panelDetails: {
    padding: theme.spacing(1),
  },
  panelRoot: {
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0),
    },
  },
  summaryContent: {
    '&.Mui-expanded': {
      margin: theme.spacing(1, 0),
    },
    margin: 0,
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
  },
}));

const SimulatorExpansionPanel = props => {
  const classes = useStyles();
  return (
    <ExpansionPanel defaultExpanded classes={{root: classes.panelRoot}}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          content: classes.summaryContent,
          expandIcon: classes.icon,
          root: classes.summaryRoot,
        }}
      >
        {props.title}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

SimulatorExpansionPanel.propTypes = {
  children: PropTypes.any,
  title: PropTypes.node,
};

export default SimulatorExpansionPanel;
