import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {Button, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import {YesNoRadioGroup} from 'wobi-web-common';
import SmallSelect from '../../../components/SimulatorComponents/SmallSelect';
import SimulatorExpansionPanel from '../../../components/SimulatorComponents/ExpansionPanel';
import useStyles from '../useStyles';

const PassedInsurance = React.memo(({formikSetField, formikValues}) => {
  const intl = useIntl();
  const messages = (id) => intl.formatMessage({id});
  const classes = useStyles();

  const policyTypes_insurance = [
    {text: 'מקיף',
      value: 'FULLCOVER'},
    {text: 'צד ג׳',
      value: '3RDPARTY'},
    {text: 'ללא',
      value: '0'},
  ];

  const setInsuranceType = (index, event) => {
    const insuranceHistory = formikValues.insuranceHistory;
    insuranceHistory.insurance[index].type = event.target.value;

    formikSetField('insuranceHistory', insuranceHistory);
    formikSetField('insurance_' + (index + 1), event.target.value);
  };

  const autoFill = () => {
    setIsCarOwnerForLast3Years('yes');
    setOwnershipDuration('');
    setInsuranceContinuity('');

    const insuranceHistory = formikValues.insuranceHistory;
    insuranceHistory.insurance[0].type = 'FULLCOVER';
    insuranceHistory.insurance[1].type = 'FULLCOVER';
    insuranceHistory.insurance[2].type = 'FULLCOVER';
    formikSetField('insuranceHistory', insuranceHistory);

    formikSetField('insurance_1', 'FULLCOVER');
    formikSetField('insurance_2', 'FULLCOVER');
    formikSetField('insurance_3', 'FULLCOVER');
  };

  const OWNERSHIP_COUNT = {
    UNDER1YEAR: '0',
    UNDER2YEARS: '1',
    UNDER3YEARS: '2',
  };

  const [isCarOwnerForLast3Years, setIsCarOwnerForLast3Years] = useState('yes'); // 'yes' or 'no'
  const [ownershipDuration, setOwnershipDuration] = useState(''); // OWNERSHIP_COUNT
  const [insuranceContinuity, setInsuranceContinuity] = useState(''); // 'yes' or 'no'

  const handleChangeIsCarOwnerForLast3Years = (value) => {
    setIsCarOwnerForLast3Years(value);
    if (value === 'no') {
      setOwnershipDuration(OWNERSHIP_COUNT.UNDER3YEARS);
      setInsuranceContinuity('yes');
    }
  };
  const autoFillNoInsurance = () => {
    const insuranceHistory = formikValues.insuranceHistory;
    switch (ownershipDuration) {
    case OWNERSHIP_COUNT.UNDER1YEAR:
      formikSetField('insurance_1', '0');
      formikSetField('insurance_2', '0');
      formikSetField('insurance_3', '0');
      insuranceHistory.insurance[0].type = '0';
      insuranceHistory.insurance[1].type = '0';
      insuranceHistory.insurance[2].type = '0';
      break;
    case OWNERSHIP_COUNT.UNDER2YEARS:
      formikSetField('insurance_2', '0');
      formikSetField('insurance_3', '0');
      insuranceHistory.insurance[1].type = '0';
      insuranceHistory.insurance[2].type = '0';
      break;
    case OWNERSHIP_COUNT.UNDER3YEARS:
      formikSetField('insurance_3', '0');
      insuranceHistory.insurance[2].type = '0';
      break;
    default:
      break;
    }
    formikSetField('insuranceHistory', insuranceHistory);
  };

  const handleChangeInsuranceContinuity = (value) => {
    setInsuranceContinuity(value);
  };

  useEffect(() => {
    if (insuranceContinuity === 'no') {
      autoFillNoInsurance();
    }
  }, [ownershipDuration, insuranceContinuity]);

  const InsuranceTypeEachYear = (yearsToAsk, isDisabled = false, isSmallContainer = false) => (
    <>
      {yearsToAsk?.insurance_1 && (
        <Grid item xs={isSmallContainer ? 6 : 3}>
          <SmallSelect
            style={{width: 250}}
            value={formikValues.insurance_1}
            onChange={(event) => setInsuranceType(0, event)}
            name='insurance_1'
            label={intl.formatMessage({id: 'simulator.insurance_1'})}
            disabled={isDisabled}
          >
            {policyTypes_insurance.map(item => (
              <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
            ))}
          </SmallSelect>
        </Grid>
      )}
      {yearsToAsk?.insurance_2 && (
        <Grid item xs={isSmallContainer ? 6 : 3}>
          <SmallSelect
            style={{width: 250}}
            value={formikValues.insurance_2}
            onChange={(event) => setInsuranceType(1, event)}
            name='insurance_2'
            label={intl.formatMessage({id: 'simulator.insurance_2'})}
            disabled={isDisabled}
          >
            {policyTypes_insurance.map(item => (
              <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
            ))}
          </SmallSelect>
        </Grid>
      )}
      {yearsToAsk?.insurance_3 && (
        <Grid item xs={isSmallContainer ? 6 : 3}>
          <SmallSelect
            style={{width: 250}}
            value={formikValues.insurance_3}
            onChange={(event) => setInsuranceType(2, event)}
            name='insurance_3'
            label={intl.formatMessage({id: 'simulator.insurance_3'})}
            disabled={isDisabled}
          >
            {policyTypes_insurance.map(item => (
              <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
            ))}
          </SmallSelect>
        </Grid>
      )}
    </>
  );

  const QuestionsPassedInsurance = () => (
    <>
      { ownershipDuration === OWNERSHIP_COUNT.UNDER2YEARS || ownershipDuration === OWNERSHIP_COUNT.UNDER3YEARS ? (
        <>
          <Grid container spacing={1}>
            <Grid item xs={3} className={classes.subtitle}>
              {messages('simulator.passedInsurance.insuranceTypeForCurrentPeriod')}
            </Grid>
            {
              InsuranceTypeEachYear({
                insurance_1: true,
                insurance_2: ownershipDuration === OWNERSHIP_COUNT.UNDER3YEARS,
              })
            }
          </Grid>
        </>
      ) : false }
      <Grid container spacing={0} xs={ownershipDuration === OWNERSHIP_COUNT.UNDER1YEAR ? 12 : 6}
        className={classes.iContinuity}>
        <Grid item xs={ownershipDuration === OWNERSHIP_COUNT.UNDER1YEAR ? 6 : 6}
          style={{maxWidth: 370}} className={classes.subtitle}>
          {messages('simulator.passedInsurance.insuranceContinuity')}
          {ownershipDuration === OWNERSHIP_COUNT.UNDER1YEAR ?
            ' ' + messages('simulator.passedInsurance.wasCarInsured') : false}
        </Grid>
        <Grid item xs={5}>
          <YesNoRadioGroup
            value={insuranceContinuity}
            onChange={(e, value) => handleChangeInsuranceContinuity(value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} xs={ownershipDuration === OWNERSHIP_COUNT.UNDER1YEAR ? 12 : 6}
        className={`${classes.iTypeSelect} ${ownershipDuration === OWNERSHIP_COUNT.UNDER1YEAR ? 'mrgr0' : ''}`}>
        { ownershipDuration && insuranceContinuity && InsuranceTypeEachYear({
          insurance_1: ownershipDuration === OWNERSHIP_COUNT.UNDER1YEAR,
          insurance_2: ownershipDuration === OWNERSHIP_COUNT.UNDER1YEAR ||
                      ownershipDuration === OWNERSHIP_COUNT.UNDER2YEARS,
          insurance_3: true,
        },
        insuranceContinuity === 'no',
        ownershipDuration !== OWNERSHIP_COUNT.UNDER1YEAR)
        }
      </Grid>
    </>
  );

  return (
    <>
      <SimulatorExpansionPanel title={<Typography>{messages('simulator.title.passed_insurance')}</Typography>}>
        <Grid container spacing={0}>
          <Button
            className={classes.autoFillButton}
            onClick={autoFill}
            color='secondary'
            variant='outlined'
            size='small'
          >
            {messages('simulator.preferred.autofill_carOwnership')}
          </Button>
          <Grid container spacing={0} style={{alignItems: 'center',
            marginBottom: isCarOwnerForLast3Years === 'yes' ? 20 : 10}}>
            <Grid item xs={3} className={classes.subtitle}>
              {messages('simulator.passedInsurance.isOwnerForAtlLeast3Years')}
            </Grid>
            <Grid item xs={9}>
              <YesNoRadioGroup
                value={isCarOwnerForLast3Years}
                onChange={(e, value) => handleChangeIsCarOwnerForLast3Years(value)}
              />
            </Grid>
          </Grid>
          { isCarOwnerForLast3Years === 'yes' ?
            (<Grid container spacing={1}>
              {InsuranceTypeEachYear({insurance_1: true, insurance_2: true, insurance_3: true})}
            </Grid>) :
            isCarOwnerForLast3Years === 'no' ? (
              <>
                <Grid container spacing={0}
                  style={{alignItems: 'center',
                    marginBottom: ownershipDuration === OWNERSHIP_COUNT.UNDER1YEAR ? 10 : 20}}>
                  <Grid item xs={3} className={classes.subtitle}>
                    {messages('simulator.passedInsurance.carOwnershipDuration')}
                  </Grid>
                  <Grid item xs={9}>
                    <RadioGroup
                      value={ownershipDuration}
                      onChange={(e, value) => setOwnershipDuration(value)}
                      row>
                      <FormControlLabel
                        value={OWNERSHIP_COUNT.UNDER1YEAR}
                        control={<Radio/>}
                        label={messages('seniorityOptions.years-0')}/>
                      <FormControlLabel
                        value={OWNERSHIP_COUNT.UNDER2YEARS}
                        control={<Radio/>}
                        label={messages('seniorityOptions.under-2-years')}/>
                      <FormControlLabel
                        value={OWNERSHIP_COUNT.UNDER3YEARS}
                        control={<Radio/>}
                        label={messages('seniorityOptions.under-3-years')}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                {ownershipDuration ? QuestionsPassedInsurance() : false}
              </>
            ) : false
          }
          <Grid item style={{marginBottom: 10}} className={classes.subtitle}>
            {messages('simulator.passedInsurance.information')}
          </Grid>
        </Grid>
      </SimulatorExpansionPanel>
    </>
  );
});

PassedInsurance.propTypes = {
  formikSetField: PropTypes.func,
  formikValues: PropTypes.object,
};

export default PassedInsurance;
