import React from 'react';
import {useIntl} from 'react-intl';
import {Button, Grid, MenuItem, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import SmallSelect from '../../../components/SimulatorComponents/SmallSelect';
import SimulatorExpansionPanel from '../../../components/SimulatorComponents/ExpansionPanel';
import useStyles from '../useStyles';

const Claims = React.memo(({formikSetField, formikValues}) => {
  const intl = useIntl();
  const messages = (id) => intl.formatMessage({id});
  const classes = useStyles();

  const numberOfPropClaims = [
    {text: 0,
      value: 0},
    {text: 1,
      value: 1},
    {text: 2,
      value: 2},
    {text: 3,
      value: 3},
  ];
  const numberOfInjuryClaims = [
    {text: 0,
      value: 0},
    {text: 1,
      value: 1},
    {text: 2,
      value: 2},
  ];

  const setInsuranceClaims = (event) => {
    const Year = Number(event.target.name?.split('Claims_')[1]);
    let type = event.target.name?.split('Claims_')[0];
    if (type === 'prop') {
      type = 'property';
    }

    const insuranceHistory = formikValues.insuranceHistory;
    const index = insuranceHistory.claims[type].findIndex(x => x.yearsPast === Year);
    if (index === -1) {
      if (event.target.value !== 0) {
        insuranceHistory.claims[type].push({amount: event.target.value,
          yearsPast: Year});
      }
    } else if (event.target.value === 0) {
      insuranceHistory.claims[type].splice(index, 1);
    } else {
      insuranceHistory.claims[type][index].amount = event.target.value;
    }

    formikSetField('insuranceHistory', insuranceHistory);
    formikSetField((type === 'property' ? 'propClaims_' : 'injuryClaims_') + Year, event.target.value);
  };

  const autoFill = () => {
    const insuranceHistory = formikValues.insuranceHistory;
    insuranceHistory.claims = {injury: [],
      property: []};
    formikSetField('insuranceHistory', insuranceHistory);

    formikSetField('propClaims_1', 0);
    formikSetField('propClaims_2', 0);
    formikSetField('propClaims_3', 0);
    formikSetField('injuryClaims_1', 0);
    formikSetField('injuryClaims_2', 0);
    formikSetField('injuryClaims_3', 0);
  };

  return (
    <>
      <SimulatorExpansionPanel title={<Typography>{messages('simulator.title.property_claims')}</Typography>}>
        <Button
          className={classes.autoFillButton}
          onClick={autoFill}
          color='secondary'
          variant='outlined'
          size='small'
        >
          {messages('simulator.preferred.autofill_noclaims')}
        </Button>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SmallSelect
              style={{width: 250}}
              value={formikValues.propClaims_1}
              onChange={(event) => setInsuranceClaims(event)}
              name='propClaims_1'
              label={intl.formatMessage({id: 'simulator.propClaims_1'})}
            >
              {numberOfPropClaims.map(item => (
                <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
              ))}
            </SmallSelect>
          </Grid>
          <Grid item xs={3}>
            <SmallSelect
              style={{width: 250}}
              value={formikValues.propClaims_2}
              onChange={(event) => setInsuranceClaims(event)}
              name='propClaims_2'
              label={intl.formatMessage({id: 'simulator.propClaims_2'})}
            >
              {numberOfPropClaims.map(item => (
                <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
              ))}
            </SmallSelect>
          </Grid>
          <Grid item xs={3}>
            <SmallSelect
              style={{width: 250}}
              value={formikValues.propClaims_3}
              onChange={(event) => setInsuranceClaims(event)}
              name='propClaims_3'
              label={intl.formatMessage({id: 'simulator.propClaims_3'})}
            >
              {numberOfPropClaims.map(item => (
                <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
              ))}
            </SmallSelect>
          </Grid>
        </Grid>
      </SimulatorExpansionPanel>
      <SimulatorExpansionPanel title={<Typography>{messages('simulator.title.body_claims')}</Typography>}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <SmallSelect
              style={{width: 250}}
              value={formikValues.injuryClaims_1}
              onChange={(event) => setInsuranceClaims(event)}
              name='injuryClaims_1'
              label={intl.formatMessage({id: 'simulator.injuryClaims_1'})}
            >
              {numberOfInjuryClaims.map(item => (
                <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
              ))}
            </SmallSelect>
          </Grid>
          <Grid item xs={3}>
            <SmallSelect
              style={{width: 250}}
              value={formikValues.injuryClaims_2}
              onChange={(event) => setInsuranceClaims(event)}
              name='injuryClaims_2'
              label={intl.formatMessage({id: 'simulator.injuryClaims_2'})}
            >
              {numberOfInjuryClaims.map(item => (
                <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
              ))}
            </SmallSelect>
          </Grid>
          <Grid item xs={3}>
            <SmallSelect
              style={{width: 250}}
              value={formikValues.injuryClaims_3}
              onChange={(event) => setInsuranceClaims(event)}
              name='injuryClaims_3'
              label={intl.formatMessage({id: 'simulator.injuryClaims_3'})}
            >
              {numberOfInjuryClaims.map(item => (
                <MenuItem key={item.value} value={item.value}>{item.text}</MenuItem>
              ))}
            </SmallSelect>
          </Grid>
        </Grid>
      </SimulatorExpansionPanel>
    </>
  );
});

Claims.propTypes = {
  formikSetField: PropTypes.func,
  formikValues: PropTypes.object,
};

export default Claims;
