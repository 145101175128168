/* eslint-disable max-len */
/* eslint  indent: ["warn",4,{ "ignoredNodes": ["TemplateLiteral"] }] */
import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import {Loader} from 'wobi-web-common';
import {
    sendCallSummaryMail,
    getCallSummaryData,
} from '../utils/apiHandlers';
import {setSimulatorOffersFields} from '../utils/persistOfferDetails';
import {fireGtmEvent} from '../utils/marketing.utils';
import {coversAndProductInfoTransform} from '../utils/callSummaryDataTransform';
import Agent from '../config/agent';
import CallSummary from './simulator/CallSummary';

class CallSummaryPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            agent: {},
            companyLogo: '',
            covers: {data: [], disclaimer: '', fullDisclosure: ''},
            drivers: {},
            errorMessage: '',
            errorResponse: '',
            isCascoAndMandatory: false,
            isSentPdf: !queryString.parse(this.props.location.search).isSendToClient,
            loading: true,
            openErrorModal: false,
            potential: {offersCompany: '', offersFields: {}},
            products: {},
            productTotalPrice: 0,
            providerLogo: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.ERRORS_CODES = {
            0: 'MANDATORY_MISSING',
            1: 'CASCO_MISSING',
            2: 'MANDATORY_CASCO_MISSING',
            3: 'MORE_THEN_ONE_MANDATORY',
            4: 'MORE_THEN_ONE_CASCO',
            5: 'MORE_THEN_ONE_CASCO_MANDATORY',
        };
    }

    async getLogo(agent) {
        try {
            const src = agent.getAgentLogoPath;
            const image = await import(`../${src}`);
            return image.default;
        } catch (error) {
            console.log('Error loading the image:', error);
        }
    }

    async componentDidMount() {
        setSimulatorOffersFields({
            isSimulator: true,
        });
        const parameters = queryString.parse(this.props.location.search);
        if (parameters.id) {
            try {
                const callSummaryData = await getCallSummaryData({
                    potential: parameters.id,
                    token: parameters.token,
                });
                const {warnings, errors} = callSummaryData;

            if (callSummaryData.success && callSummaryData.data) {
                const {
                    potential,
                    products,
                    covers,
                    drivers,
                } = callSummaryData.data;
                let agent = callSummaryData.data.agent;
                if (agent.developerName) {
                    agent = new Agent(agent.developerName, agent.developerName);
                } else {
                    agent = new Agent('Wobi', 'Wobi');
                }

                const mainProduct = potential.mainProduct;
                if ((!products || !products.length) && (!mainProduct || !Object.keys(mainProduct).length)) {
                    // eslint-disable-next-line react/no-did-mount-set-state
                    this.setState({
                        errorMessage: 'simulator.callSummary.products.no.products.error',
                        loading: false,
                        openErrorModal: true,
                    });
                    return;
                }
                this.sendMetaErrorCodeAlertsGTM(warnings);
                const isShowWarning = warnings?.length > 0;
                const {tCovers, tProducts, isCascoAndMandatory} = coversAndProductInfoTransform(covers, products, potential);
                const companyLogo = await this.getLogo(agent);
                // eslint-disable-next-line react/no-did-mount-set-state
                this.setState({
                    agent,
                    companyLogo,
                    covers: {
                        callSummaryText: covers?.callSummaryText,
                        data: tCovers,
                        disclaimer: covers?.disclaimer,
                        documentsWarning: covers?.documentsWarning,
                        fullDisclosure: covers?.fullDisclosure,
                        kaskoInsuranceCompany: potential.offersFields.insuranceCompanyName,
                        productLineName: potential.offersFields.productLineName,
                        protectionText: covers?.protectionText,
                    },
                    drivers,
                    errorCodes: warnings,
                    isCascoAndMandatory,
                    loading: false,
                    openErrorModal: isShowWarning,
                    potential,
                    products: tProducts,
                    productTotalPrice: potential.mainProduct.totalPrice,
                    providerLogo: covers?.providerLogo,
                    showAlert: isShowWarning,
                });
            } else {
                const stateToChange = {
                    errorResponse: callSummaryData.message,
                    loading: false,
                    openErrorModal: true,
                };

                    if (errors?.length > 0) {
                        stateToChange.errorCodes = errors;
                        this.sendMetaErrorCodeAlertsGTM(errors);
                    } else {
                        stateToChange.errorMessage = 'simulator.callSummary.error';
                    }
                    // eslint-disable-next-line react/no-did-mount-set-state
                    this.setState(stateToChange);
                }
            } catch (error) {
                console.log('callsumaary error', error);
                if (error.message.includes('Salesforce authorization token is missing or expired')) {
                    const errorMessage = 'simulator.callSummary.error.token_missed';
                    // eslint-disable-next-line react/no-did-mount-set-state
                    this.setState({errorMessage, loading: false, openErrorModal: true});
                }
            }
        }
    }

    async handleSubmit() {
        const parameters = queryString.parse(this.props.location.search);
        window.location.href = `${process.env.REACT_APP_CRM_URL}/${parameters.id}`;
    }

    async handleSendPDF() {
        const parameters = queryString.parse(this.props.location.search);
        this.setState({
            isSentPdf: true,
            loading: true,
        });

        const response = await sendCallSummaryMail(
            {agent: this.state.agent,
                covers: this.state.covers,
                drivers: this.state.drivers,
                potential: {
                    productTotalPrice: this.state.potential.mainProduct.totalPrice,
                    ...this.state.potential,
                },
                potentialId: parameters.id,
                products: this.state.products,
                providerLogo: this.state.providerLogo,
                token: parameters.token,
            },
        );
        if (!response.success) {
            this.setState({errorMessage: 'simulator.callSummary.mailError', loading: false, openErrorModal: true});
        } else {
            this.setState({isSentPdf: true, loading: false});
        }
    }

    sendMetaErrorCodeAlertsGTM(errorCodes) {
        if (Array.isArray(errorCodes) && errorCodes.length) {
            // eslint-disable-next-line no-unused-vars
            for (const code of errorCodes) {
                fireGtmEvent('CALL_SUMMARY_ALERTS', {value: this.ERRORS_CODES[code]});
            }
        } else {
            fireGtmEvent('CALL_SUMMARY_ALERTS', {value: 'SUCCESS'});
        }
    }

    disableError() {
        this.setState({
            openErrorModal: false,
            showAlert: false,
        });
    }

    renderCallSummaryOrSendPDF() {
        return (
            <CallSummary productTotalPrice={this.state.productTotalPrice}
                productLineName={this.state.covers.productLineName}
                kaskoInsuranceCompany={this.state.covers.kaskoInsuranceCompany}
                covers={this.state.covers.data}
                disclaimer={this.state.covers.disclaimer}
                protectionText={this.state.covers.protectionText}
                fullDisclosure={this.state.covers.fullDisclosure}
                products={this.state.products}
                potential={this.state.potential}
                drivers={this.state.drivers}
                isCascoAndMandatory={this.state.isCascoAndMandatory}
                showAlert={this.state.showAlert}
                onConfirm={() => this.handleSubmit(true)}
                onBackToCrm={() => this.handleSubmit(false)}
                openModal={this.state.openErrorModal}
                errorMessage={this.state.errorMessage}
                errorResponse={this.state.errorResponse}
                disableError={() => this.disableError()}
                errorCodes={this.state.errorCodes}
                documentsWarning={this.state.covers.documentsWarning}
                callSummaryText={this.state.covers.callSummaryText}
                companyLogo={this.state.companyLogo}
                isSentPdf={this.state.isSentPdf}
                handleSendPDF = {() => this.handleSendPDF()}
            />
        );
    }

    render() {
        return (
            !this.state.loading ? this.renderCallSummaryOrSendPDF() : <Loader show={this.state.loading}/>
        );
    }
}

CallSummaryPage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default withRouter(CallSummaryPage);
