export const sortingOptions = [
  {
    comparer: (item1, item2) => {
      const price1 = item1.maximumPrice !== undefined ? item1.maximumPrice : item1.price;
      const price2 = item2.maximumPrice !== undefined ? item2.maximumPrice : item2.price;

      return price1 - price2;
    },
    id: 1,
    title: 'מחיר נמוך לגבוה',
  },
  {
    comparer: (item1, item2) => {
      const price1 = item1.additionalPrices[0].value;
      const price2 = item2.additionalPrices[0].value;

      return price2 - price1;
    },
    id: 2,
    title: 'כיסוי גבוה לנמוך',
  },
  {
    comparer: (item1, item2) => {
      const price1 = item1.additionalPrices[1].value;
      const price2 = item2.additionalPrices[1].value;

      return price1 - price2;
    },
    id: 3,
    title: 'השתתפות עצמית נמוכה לגבוהה',
  },
];
