/* eslint-disable max-len */
import {constractCampaignArgs} from './marketing.utils';

describe('constractCampaignArgs', () => {
  it('empty querystring', () => {
    const returnObject = constractCampaignArgs({});
    expect(returnObject).toEqual({source: 'direct'});
  });

  it('LandingPage Campaign', () => {
    const returnObject = constractCampaignArgs({referrer: 'https://www.wobi.co.il/LP-CAR-YOUTUBE-VIDEO-ARMY'});
    expect(returnObject.utm_campaign).toEqual('VIDEO');
    expect(returnObject.utm_medium).toEqual('YOUTUBE');
    expect(returnObject.utm_content).toEqual('ARMY');
    expect(returnObject.utm_source).toEqual('CAR');
  });

  it('UTM param to sponsored link', () => {
    const returnObject = constractCampaignArgs({
      gclid: 'Cj0KCQiAgKzwBRCjARIsABBbFui_79WOq6BNLf9xp5S_EZxMgQW6zlyzK0jS4Nr1CoBaJJWasS0J1LYaAumYEALw_wcB',
      utm_campaign: '1478248762',
      utm_content: '65898152588_377848508767_g_c_1t1',
      utm_medium: 'cpc',
      utm_source: 'google',
      utm_term: 'WOBI',
    });
    expect(returnObject.utm_source).toEqual('google');
    expect(returnObject.utm_keywords).toEqual('WOBI');
    expect(returnObject.utm_medium).toEqual('cpc');
    expect(returnObject.utm_content).toEqual('65898152588_377848508767_g_c_1t1');
    expect(returnObject.utm_campaign).toEqual('1478248762');
    expect(returnObject.gclid).toEqual('Cj0KCQiAgKzwBRCjARIsABBbFui_79WOq6BNLf9xp5S_EZxMgQW6zlyzK0jS4Nr1CoBaJJWasS0J1LYaAumYEALw_wcB');
  });

  it('Organic', () => {
    const returnObject = constractCampaignArgs({referrer: 'https://www.google.com/'});
    expect(returnObject.source).toEqual('organic');
    expect(returnObject.referrer).toEqual('https://www.google.com/');
  });

  it('googleAds', () => {
    const returnObject = constractCampaignArgs({
      gclid: 'EAIaIQobChMI-o-a4cKl5AIVxud3Ch2JHAKzEAAYASAAEgLAL_D_BwE',
      referrer: 'https://www.google.com/',
    });
    expect(returnObject.source).toEqual('googleAds');
    expect(returnObject.gclid).toEqual('EAIaIQobChMI-o-a4cKl5AIVxud3Ch2JHAKzEAAYASAAEgLAL_D_BwE');
    expect(returnObject.marketingId).toEqual('EAIaIQobChMI-o-a4cKl5AIVxud3Ch2JHAKzEAAYASAAEgLAL_D_BwE');
    expect(returnObject.referrer).toEqual('https://www.google.com/');
  });

  it('Direct', () => {
    const returnObject = constractCampaignArgs({referrer: 'https://www.wobi.co.il/'});
    expect(returnObject.source).toEqual('direct');
  });

  it('UTM', () => {
    const returnObject = constractCampaignArgs({
      referrer: 'https://www.galgalim.co.il/',
      utm_campaign: 'co_op',
      utm_content: 'banner_main',
      utm_medium: 'link',
      utm_source: 'galgalim',
    });
    expect(returnObject.utm_source).toEqual('galgalim');
    expect(returnObject.utm_medium).toEqual('link');
    expect(returnObject.utm_content).toEqual('banner_main');
    expect(returnObject.utm_campaign).toEqual('co_op');
    expect(returnObject.referrer).toEqual('https://www.galgalim.co.il/');
  });

  it('Facebook desktop', () => {
    const returnObject = constractCampaignArgs({
      fbclid: 'IwAR2FFhvPSbqWxgjRtp8pzl4Opxd4rMCcjs5BOx7atMlcs1mwxi8J9K4EogE',
      referrer: 'https://www.facebook.com/',
    });
    expect(returnObject.source).toEqual('facebook');
    expect(returnObject.fbclid).toEqual('IwAR2FFhvPSbqWxgjRtp8pzl4Opxd4rMCcjs5BOx7atMlcs1mwxi8J9K4EogE');
    expect(returnObject.marketingId).toEqual('IwAR2FFhvPSbqWxgjRtp8pzl4Opxd4rMCcjs5BOx7atMlcs1mwxi8J9K4EogE');
    expect(returnObject.referrer).toEqual('https://www.facebook.com/');
  });

  it('Facebook mobile', () => {
    const returnObject = constractCampaignArgs({
      fbclid: 'IwAR2FFhvPSbqWxgjRtp8pzl4Opxd4rMCcjs5BOx7atMlcs1mwxi8J9K4EogE',
      referrer: 'https://m.facebook.com/',
    });
    expect(returnObject.source).toEqual('facebook');
    expect(returnObject.fbclid).toEqual('IwAR2FFhvPSbqWxgjRtp8pzl4Opxd4rMCcjs5BOx7atMlcs1mwxi8J9K4EogE');
    expect(returnObject.marketingId).toEqual('IwAR2FFhvPSbqWxgjRtp8pzl4Opxd4rMCcjs5BOx7atMlcs1mwxi8J9K4EogE');
    expect(returnObject.referrer).toEqual('https://m.facebook.com/');
  });

  it('Facebook app', () => {
    const returnObject = constractCampaignArgs({
      h:
        'AT3Wrcg4_AjLYJ9tUe3qKGM_zvSY7OVAeZ5-M4_BKQsB9t7NwAjhdmKl1bS08ZXtq63F2FPut1IqAasSoxU0-ZgnmxgnlFwthQNFObz5ePUlUXZJa6-waKMqTuRA',
      referrer:
        'https://lm.facebook.com/l.php?u=https%253A%252F%252Fwww.wobi.co.il%252F%25D7%2591%25D7%2599%25D7%2598%25D7%2595%25D7%2597-%25D7%25A8%25D7%259B%25D7%2591%252F',
    });
    expect(returnObject.source).toEqual('facebook');
    expect(returnObject.referrer).toEqual('https://lm.facebook.com/');
  });

  // /
});
