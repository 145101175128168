import React from 'react';
import {injectIntl} from 'react-intl';
import styled from 'styled-components';
import propTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Grid, Button} from '@material-ui/core';
import {Black} from 'wobi-web-common/dist/Colors';
import {Breakpoints as breakpoints, Offer as GenericOffer} from 'wobi-web-common';
import DOMPurify from 'dompurify';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import companiesLogos from '../assets/js/companiesLogos';

const Offer = (props) => {
  const carHendelingFee = props.agent.handlingFeePrice;
  const isUsingCarHandlingFee = agentDeveloperUtils.isUsingCarHandlingFee(true) && carHendelingFee > 0;
  // eslint-disable-next-line prefer-const
  let updatedPropsForHandlindFee = {...props};

  if (isUsingCarHandlingFee) {
    updatedPropsForHandlindFee.data.price += carHendelingFee;
  }
  const isXSmall = useMediaQuery('(max-width:420px)');
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);
  const {data, isOpen} = updatedPropsForHandlindFee;

  const {
    productType,
    providerName,
    price,
    maximumPrice,
    maximumCascoPrice,
    mandatoryPrice,
    cascoPrice,
    alternativeTitle,
    disclaimer,
    isChanged,
    simulatorText,
  } = data;

  const messages = (id) => props.intl.formatMessage({id});
  const logo = companiesLogos.find(elm => elm.key === props.data.logo);
  const getLogoSource = () => !logo ? '' : isXSmall ? logo.imgSrc_small : logo.imgSrc_big;
  const totalPriceClassName = maximumPrice ? 'total-price-last' : '';

  return (
    <GenericOffer simulator {...props}>
      <OfferContent isMobile={isMobile} isOpen={isOpen} className={isOpen ? 'open' : ''}>
        <Grid container alignItems='flex-start' justify='space-between' className='header-container'>
          {!isMobile || !isOpen ?
            <Grid item xs={4} className='logo'>
              <img src={logo ? logo.imgSrc_big : ''} alt={`חברת ${providerName}`}/>
              <b>{alternativeTitle}</b>
            </Grid> : false
          }
          <Grid item xs={5} className={`prices-wrapper left ${isOpen ? 'open' : ''}`}>
            <Grid container spacing={0} justify='center'>
              {isMobile && isOpen ?
                <div>
                  <div className='logo'>
                    <img src={getLogoSource()} alt={`חברת ${providerName}`}/>
                    <b>{alternativeTitle}</b>
                  </div>
                </div> : false}
              { isMobile && isOpen ? <span className='partition' /> : false }
              <div className='prices-wrap'>
                <div className='prices'>
                  <div className='total-price'>
                    {
                      maximumPrice ? (
                        <>
                          <div className={totalPriceClassName}>
                            <span>₪</span>
                            {Math.round(maximumPrice).toLocaleString()}
                          </div>
                          <span className='total-price-separator' />
                        </>
                      ) : null
                    }
                    <div className={totalPriceClassName}>
                      <span>₪</span>
                      { Math.round(price).toLocaleString() }
                    </div>
                  </div>
                  {isOpen ? (
                    <div className='partial-prices-container'>
                      {maximumCascoPrice ? (
                        <div className='partial-prices partial-prices-margin'>
                          <span>
                            {`${messages(productType.toLowerCase())}
                             ${maximumCascoPrice.toLocaleString()}`}
                          </span>
                          <span>
                            {`${messages('mandatory')}
                             ${mandatoryPrice.toLocaleString()}`}
                          </span>
                          {isUsingCarHandlingFee && <br/>}
                          {isUsingCarHandlingFee && <span>
                            {`${messages('offers.hendeling_fee')}
                           ${carHendelingFee.toLocaleString()}`}
                          </span>}
                        </div>
                      ) : null}
                      <div className='partial-prices'>
                        <span>
                          {`${messages(productType.toLowerCase())}
                           ${cascoPrice.toLocaleString() }`}
                        </span>
                        <span>
                          {`${messages('mandatory')}
                           ${mandatoryPrice.toLocaleString()}`}
                        </span>
                        {isUsingCarHandlingFee && <br/>}
                        {isUsingCarHandlingFee && <span>
                          {`${messages('offers.hendeling_fee')}
                           ${carHendelingFee.toLocaleString()}`}
                        </span>}
                      </div>
                    </div>
                  ) : false}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={3} className='purchase-btn-wrapper'>
            <Button
              onClick={() => {
              // setIsOpen(!isOpen);
                props.onSelectOffer();
              }}
              disabled={props.updating}
              color='secondary'
              variant='outlined'
            >
              {messages('simulator.offer.purchase')}
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems='flex-start'>

          <Grid item xs={6}>
            <div className='isPreferredOfferDisclaimer'>
              {/* <div>
                <Checkbox
                  name='isPreferredOffer'
                  id='isPreferredOffer'
                  onChange={(event) => {
                    setIsPreferredOffer(event.target.checked);
                    props.onChangePreferredOffer(event.target.checked);
                  }}
                  checked={isPreferredOffer}
                />
                <span>{messages('simulator.offer.is_preferred')}</span>
              </div> */}
              {disclaimer && <div className='disclaimer'>
                <span>
                  {disclaimer.length && disclaimer.includes('<' && '>') ?
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(disclaimer)}} /> :
                    disclaimer}
                </span>
              </div>}
            </div>
          </Grid>
          {simulatorText ?
            <Grid item xs={12}>
              <div className='isPreferredOfferDisclaimer'>
                {simulatorText && <div className='disclaimer'>
                  <span>
                    {simulatorText.length && simulatorText.length > 1 ?
                      <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(simulatorText)}} /> :
                      simulatorText}
                  </span>
                </div>}
              </div>
            </Grid> :
            null}
          <Grid item xs={6}>
            <span style={{color: 'red'}}>{isChanged ? messages('simulator.offer.data_was_changed') : ''}</span>
          </Grid>
        </Grid>
      </OfferContent>
    </GenericOffer>
  );
};

Offer.propTypes = {
  ...GenericOffer.propTypes,
  onChangePreferredOffer: propTypes.func.isRequired,
  onSelectOffer: propTypes.func.isRequired,
};

const OfferContent = styled.div`
  padding: 8px;
  &:not(.open) > .logo {margin-top: 8px;}
  &:not(.open) > .logo > b {@media screen and (max-width: 420px) {position: relative; width: 158px;}}
  .logo {
    width: 150px;
    height: 100px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    > img {display: inline-block; width: inherit;}
    @media screen and (max-width: 802px) and (min-width: ${breakpoints.mobile + 1}px) {width: 120px;}
    > b {
      font-size: 14px;
      line-height: 14px;
      padding: 0 3px;
      font-weight: 700;
      color: ${agentDeveloperUtils.getSecondaryColor(500)};
    }
    @media screen and (max-width: 1023px) {margin-top: 17px;}
    @media screen and (max-width: ${breakpoints.mobile}px) {
      margin-top: 0;
      height: unset;
      justify-content: flex-end;
    }
    @media screen and (max-width: 420px) {
      min-width: 88px;
      width: 40%;
      height: 67px;
      justify-content: flex-end;
      > img {width: 88px;}
    }
  }

  .left {
    width: 100%;
    max-width: 560px;
    min-width: 0;
    &:not(.open) .prices-wrap {@media screen and (max-width: 420px) {justify-content:center;}}
    &:not(.open) .prices {@media screen and (max-width: 830px) {margin-right: 0; min-width: 100px;}}
    &.open > div {@media screen and (max-width: ${breakpoints.mobile}px) {position: relative;}}
    @media screen and (max-width: 1023px) {margin-top: 10px;}
    &:not(.open) {@media screen and (max-width: 1023px) {margin-top: 30px;}}
    &:not(.open) {@media screen and (max-width: 420px) {margin-top: 0;}}
    @media screen and (max-width: ${breakpoints.mobile}px) {
      margin: 0;
      max-width: 100%;
      .partition {
        display: ${props => props.isOpen ? 'block' : 'none'};
        height: 65px;
        width: 2px;
        background-color: ${agentDeveloperUtils.getConvertedColor('#D8EAF1')};
        position: absolute;
        left: 50%;
        transform: translateX(-1px);
        bottom: 0;
      }
      > div { height: inherit; }
      > div > div {
        width: 50%;
        display: flex;
        justify-content: center;
      }
      &:not(.open) {
        height: 67px;
        > div > div {justify-content: flex-start;}
        .prices {justify-content: center; position: static;}
        .total-price {
          font-size: 28px;
          margin-top: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &:not(.open) .total-price {@media screen and (max-width: 500px) {position: static; transform: none;}}
    &:not(.open) .total-price {@media screen and (max-width: 1023px) {font-size: 32px;}}
    &:not(.open) .prices {@media screen and (max-width: 500px) {justify-content:flex-start;}}
    @media screen and (max-width: 420px) {
      .partition { display: none; }
      > div > div {
        &:first-child {min-width: 88px; width: 40%;}
        &:last-child {width: 60%; width: calc(50% - 8px);}
      }
      &:not(.open) {
        > div > div {
          justify-content: flex-start;
          min-width: 70px;
          min-width: unset;
        }
        .prices {
          min-width: 70px;
        }
        .total-price {font-size: 16px; margin-top: 13px; margin-top: 28px;}
        .total-price > span {font-size: 16px;}
      }
    }

    .prices {
      display: flex;
      align-items: center;
      flex-flow: column nowrap;
      position: relative;
      min-width: 191px;
      @media screen and (max-width: 1097px) {margin-right: 20px;}
      @media screen and (max-width: 1023px) {
        max-width: 100%;
        flex-basis: 100%;
      }
      @media screen and (max-width: ${breakpoints.mobile}px) {
        width: 150px;
        min-width: 150px;
        flex: 0;
        justify-content: flex-end;
        height: unset;
        align-items: center;
      }
      @media screen and (max-width: 420px) {
        width: inherit;
        min-width: 140px;
        align-items: center;
        .partial-prices { font-size: 11px; }
      }
    }

    .total-price {
      font-size: 44px;
      color: ${props => props.isOpen ? agentDeveloperUtils.getPrimaryColor(450) : '#595959'};
      font-weight: 700;
      line-height: 1;
      margin-bottom: 5px;
      transition: all 0.3s;
      margin-top: 21px;
      width: 100%;
      @media screen and (max-width: ${breakpoints.mobile}px) {margin-bottom: 0;}
      @media screen and (max-width: 420px) {
        font-size: 36px;

      }
      > span {
        font-size: 24px;
        margin-right: 5px;
      }
    }
    
    .partial-prices-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .partial-prices-margin {
      margin-right: 10px;
    }
    
    .partial-prices {
      .credit_card_commission {
        display: block;      
      }
    
        @media screen and (max-width: ${breakpoints.mobile}px) {height: 14px; line-height: 14px;}
      > span {
      color: ${agentDeveloperUtils.getConvertedColor(Black[300])};
      font-size: 14px;
      font-weight: 500;

      :first-child {
       margin-left: 10px;
        @media screen and (max-width: 420px) {margin-left: 4px;}
        :after {
          margin-right: 10px;
          @media screen and (max-width: 420px) {margin-right: 4px;}
          font-size: 16px;
          font-weight: 200;
          display: inline-block;
          content: '|';
          color: ${agentDeveloperUtils.getConvertedColor(Black[220])};
          opacity: 0.9;
        }
      }
      }
    }
    
    .protection {
        margin-top: 10px;
        font-size: 13px;
        line-height: 1;
        font-weight: 600;
        color: ${agentDeveloperUtils.getConvertedColor(Black[300])};
        display: flex;
        align-items: center;
        width: 100%;
        span {
          position: relative;
          padding-right: 10px;
        }
        span::before {
          content: '*';
          color: ${agentDeveloperUtils.getPrimaryColor(450)};
          font-size: 19px;
          position: absolute;
          top: -1px;
          right: -1px;
        }
        @media screen and (max-width: 1280px) {width: 100%;}
        &.open {
          width: 100%;
          justify-content: center;
        }
    }
  }
  
  .isPreferredOfferDisclaimer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .disclaimer {
    margin: 0 10px 0 0;
    color: #4C8298;
    font-size: 1rem;
    font-family: Ploni,Arial,sans-serif;
    font-weight: 500;
    line-height: 1.43;
    span {
      background: #00A9E926 0% 0% no-repeat padding-box;
    }
  }
  
  .total-price-separator {
    display: block;
    width: 14px;
    height: 4px;
    margin: 0 2px;
    background-color: ${agentDeveloperUtils.getPrimaryColor(450)};
  }
  
  .total-price {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
  }
  
  .total-price > div {
    flex: 3;
  }
  
  .total-price-last {
    text-align: center;
  }
  
  .header-container > div {
    flex: 1;
  }
  
  .header-container div.prices-wrapper {
    flex: 2;
  }
  
  .prices-wrap {
    width: 100%;
  }

  .purchase-btn-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    flex: 0 1 auto;
    margin-top: 21px;
    @media screen and (max-width: 1023px) {
      flex: 0 1 auto;
      width: calc(50% - 16px);
      margin-right: 16px;
    }
    @media screen and (max-width: ${breakpoints.mobile}px) {justify-content:flex-end;}
    @media screen and (max-width: 420px) {margin-right: 8px;}
    &.initial {
      max-width: 167px;
    }

    button {
      font-weight: 700;
      font-size: 16px;
      color: #fff;
      background: ${agentDeveloperUtils.getPrimaryColor(450)};
      border: none;
      min-width: ${props => props.isMobile ? '70px' : '151px'};
      @media screen and (max-width: 1023px) {width: 100%; min-width: unset;}
      @media screen and (max-width: ${breakpoints.mobile}px) {width: calc(100% - 10px); max-width: 151px;}
      @media screen and (max-width: 420px) {margin-top:16px; width: 100%; font-size: 14px; padding: 7px 10px;}
    }
  }
`;

export default injectIntl(Offer);
