import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {GettingStartedRoute} from '../config/routes';
import {getPolicyTypeFromStorage} from '../utils/persistOfferDetails';

const useStyles = makeStyles(theme => ({
  mainText: {
    '& span': {
      color: theme.palette.secondary.dark,
      fontSize: theme.typography.pxToRem(29.75),
      fontWeight: 300,
      marginLeft: 10,
    },
    fontSize: theme.typography.pxToRem(31.5),
    fontWeight: 'bold',
  },
  root: {
    color: theme.palette.secondary.main,
    margin: '25px 0',
  },
  subText: {
    '& a': {
      '&:hover': {
        '& span': {color: theme.palette.secondary.dark},
        textDecoration: 'underline',
      },
      color: 'inherit',
      textDecoration: 'none',
    },
    '& span': {
      '&:hover': {color: theme.palette.secondary.dark},
      color: theme.palette.secondary.mainLight,
      display: 'inline-block',
      fontWeight: 300,
      marginLeft: 12,
    },
    fontSize: theme.typography.fontSize,
  },
}));

const MainTitle = ({mainText, subText}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.mainText} role='heading' aria-level='1'>{mainText}</div>
      {subText ?
        <div className={classes.subText}>
          <a href={`${GettingStartedRoute.pathname}#${getPolicyTypeFromStorage()}`}>
            {subText}
          </a>
        </div> : null
      }
    </div>
  );
};

MainTitle.propTypes = {
  mainText: PropTypes.node,
  subText: PropTypes.node,
};

export default MainTitle;
