import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import Moment from 'moment';
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup, Typography,
} from '@material-ui/core';
import {EzFormControl, DatePicker, YesNoRadioGroup} from 'wobi-web-common';
import PropTypes from 'prop-types';
import useStyles from '../useStyles';
import SmallSelect from '../../../components/SimulatorComponents/SmallSelect';
import SimulatorExpansionPanel from '../../../components/SimulatorComponents/ExpansionPanel';
import {DRIVERS_COUNT} from '../../../constants/enums';
import {getYoungDriverMaxDate} from '../../../utils/common';

const datePickerStyle = {height: 40};

const DriversDetails = React.memo(({formikHandleChange, formikSetField, formikValues}) => {
  const intl = useIntl();
  const classes = useStyles();
  const messages = (id) => intl.formatMessage({id});

  const maleFemaleSelectOptions = [
    {messageId: 'male',
      value: '1'},
    {messageId: 'female',
      value: '2'},
  ];
  const youngDriverMaxDate = getYoungDriverMaxDate();
  const youngDriverMinDate = Moment().subtract(85, 'year');
  const initialFocusedDate = Moment().subtract(40, 'year');
  const [youngSeniorityOptions, setYoungSeniorityOptions] = useState([]);
  const [mainSeniorityOptions, setMainSeniorityOptions] = useState([]);
  const [secondSeniorityOptions, setSecondSeniorityOptions] = useState([]);
  const [thirdSeniorityOptions, setThirdSeniorityOptions] = useState([]);

  React.useEffect(() => {
    if (formikValues.youngDriverBirthDate) {
      setYoungSeniorityOptions(calculateSeniority(formikValues.youngDriverBirthDate, messages));
    }
    if (formikValues.mainDriverBirthDate) {
      setMainSeniorityOptions(calculateSeniority(formikValues.mainDriverBirthDate, messages));
    }
    if (formikValues.secondDriverBirthDate) {
      setSecondSeniorityOptions(calculateSeniority(formikValues.secondDriverBirthDate, messages));
    }
    if (formikValues.thirdDriverBirthDate) {
      setThirdSeniorityOptions(calculateSeniority(formikValues.thirdDriverBirthDate, messages));
    }
  }, []);

  const handleDateChange = (date, previousDate, fieldPrefix) => {
    if (date === null) {
      formikSetField(`${fieldPrefix}BirthDate`, null);
      formikSetField(`${fieldPrefix}Age`, '');
      formikSetField(`${fieldPrefix}Seniority`, '');
      return;
    }
    const isValidDate = date instanceof Date && !Number.isNaN(date);
    if (isValidDate) {
      formikSetField(`${fieldPrefix}BirthDate`, date);
      formikSetField(`${fieldPrefix}Age`, Moment().diff(Moment(date), 'years'));
      formikSetField(`${fieldPrefix}Seniority`, '');
      switch (fieldPrefix) {
      case 'youngDriver':
        setYoungSeniorityOptions(calculateSeniority(date, messages));
        break;
      case 'mainDriver':
        setMainSeniorityOptions(calculateSeniority(date, messages));
        break;
      case 'secondDriver':
        setSecondSeniorityOptions(calculateSeniority(date, messages));
        break;
      case 'thirdDriver':
        setThirdSeniorityOptions(calculateSeniority(date, messages));
        break;
      default: break;
      }
    } else {
      /* case typed impossible date- show 'wrong date' validation */
      formikSetField(`${fieldPrefix}BirthDate`, date);
      formikSetField(`${fieldPrefix}Age`, '');
      formikSetField(`${fieldPrefix}Seniority`, '');
      switch (fieldPrefix) {
      case 'youngDriver':
        setYoungSeniorityOptions(calculateSeniority(null, messages));
        break;
      case 'mainDriver':
        setMainSeniorityOptions(calculateSeniority(null, messages));
        break;
      case 'secondDriver':
        setSecondSeniorityOptions(calculateSeniority(null, messages));
        break;
      case 'thirdDriver':
        setThirdSeniorityOptions(calculateSeniority(null, messages));
        break;
      default:
        break;
      }
    }
  };

  const handleIsYoungMain = (value) => {
    formikSetField('isYoungDriverIsMain', value);
    if (value === 'yes') {
      formikSetField('mainDriverBirthDate', null);
      formikSetField('mainDriverSeniority', '');
      formikSetField('mainDriverGender', '');
    }
  };

  return (
    <SimulatorExpansionPanel title={<Typography>{messages('simulator.title.driver_details')}</Typography>}>
      <Grid container spacing={0}>
        <Grid item xs={1} className={classes.subtitle}>{messages('fields.drivers')}</Grid>
        <Grid item xs={11}>
          <EzFormControl name='driversCount' isInlineLabel={false}>
            <RadioGroup
              value={formikValues.driversCount}
              onChange={(event, value) => formikSetField('driversCount', value)}
              row>
              <FormControlLabel value={DRIVERS_COUNT.ONE} control={<Radio/>} label={messages('fields.one_driver')}/>
              <FormControlLabel value={DRIVERS_COUNT.TWO} control={<Radio/>} label={messages('fields.two_drivers')}/>
              <FormControlLabel
                value={DRIVERS_COUNT.THREE}
                control={<Radio/>}
                label={messages('fields.three_drivers')}
              />
              <FormControlLabel value={DRIVERS_COUNT.EVERY} control={<Radio/>} label={messages('fields.every_driver')}/>
            </RadioGroup>
          </EzFormControl>
        </Grid>

        <Grid item xs={2} className={classes.subtitle}>{messages('simulator.youngdriver_title')}</Grid>
        <Grid item xs={3}>
          <EzFormControl name='youngDriverGender' isInlineLabel={false}>
            <RadioGroup
              value={formikValues.youngDriverGender}
              onChange={(event, value) => formikSetField('youngDriverGender', value)}
              row>
              {maleFemaleSelectOptions.map(item => (
                <FormControlLabel
                  style={{marginLeft: 3}}
                  key={`gender-${item.value}`}
                  value={item.value}
                  control={<Radio/>}
                  label={messages(`fields.${item.messageId}`)}
                />
              ))}
            </RadioGroup>
          </EzFormControl>
        </Grid>
        <Grid item xs={4}>
          <EzFormControl
            name='youngDriverBirthDate'
            label={formikValues.driversCount === DRIVERS_COUNT.ONE ?
              messages('fields.youngDriverBirthDate') :
              messages('fields.youngDriverBirthDate.2')
            }
            isLabelInChild
          >
            <DatePicker
              value={formikValues.youngDriverBirthDate}
              onChange={(date) => handleDateChange(date, formikValues.youngDriverBirthDate, 'youngDriver')}
              onAccept={() => {}}
              autoOk
              id='youngDriverBirthDate'
              maxDate={youngDriverMaxDate}
              minDate={youngDriverMinDate}
              initialFocusedDate={initialFocusedDate}
              variant='inline'
              size='small'
            />
          </EzFormControl>
        </Grid>
        <Grid item xs={3}>
          <SmallSelect
            disabled={!youngSeniorityOptions.length || !formikValues.youngDriverBirthDate}
            value={formikValues.youngDriverSeniority}
            onChange={formikHandleChange}
            name='youngDriverSeniority'
            label={formikValues.driversCount === DRIVERS_COUNT.ONE ?
              messages('fields.youngDriverSeniority') :
              messages('fields.youngDriverSeniority.2')
            }
          >
            {youngSeniorityOptions.map(option => (
              <MenuItem key={`youngDriverSeniority-${option.value}`} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </SmallSelect>
        </Grid>
        {
          formikValues.driversCount === DRIVERS_COUNT.TWO || formikValues.driversCount === DRIVERS_COUNT.THREE ?
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={2} className={classes.subtitle}>{messages('simulator.seconddriver_birthday')}</Grid>
                <Grid item xs={4}>
                  <EzFormControl name='secondDriverBirthDate' label='' isLabelInChild>
                    <DatePicker
                      style={datePickerStyle}
                      value={formikValues.secondDriverBirthDate}
                      onChange={(date) => handleDateChange(date, formikValues.secondDriverBirthDate, 'secondDriver')}
                      onAccept={() => {}}
                      autoOk
                      id='secondDriverBirthDate'
                      maxDate={youngDriverMaxDate}
                      minDate={youngDriverMinDate}
                      initialFocusedDate={initialFocusedDate}
                      variant='inline'
                      size='small'
                    />
                  </EzFormControl>
                </Grid>
                <Grid item xs={2} className={classes.subtitle}>{messages('simulator.seconddriver_seniority')}</Grid>
                <Grid item xs={4}>
                  <SmallSelect
                    disabled={!secondSeniorityOptions.length || !formikValues.secondDriverBirthDate}
                    value={formikValues.secondDriverSeniority}
                    onChange={formikHandleChange}
                    name='secondDriverSeniority'
                    label=''
                  >
                    {secondSeniorityOptions.map(option => (
                      <MenuItem key={`secondDriverSeniority-${option.value}`} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </SmallSelect>
                </Grid>
              </Grid>
            </Grid> :
            false
        }
        {
          formikValues.driversCount === DRIVERS_COUNT.THREE ?
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={2} className={classes.subtitle}>{messages('simulator.third_driver_birthday')}</Grid>
                <Grid item xs={4}>
                  <EzFormControl name='thirdDriverBirthDate' label='' isLabelInChild>
                    <DatePicker
                      style={datePickerStyle}
                      value={formikValues.thirdDriverBirthDate}
                      onChange={(date) => handleDateChange(date, formikValues.thirdDriverBirthDate, 'thirdDriver')}
                      onAccept={() => {}}
                      autoOk
                      id='thirdDriverBirthDate'
                      maxDate={youngDriverMaxDate}
                      minDate={youngDriverMinDate}
                      initialFocusedDate={initialFocusedDate}
                      variant='inline'
                      size='small'
                    />
                  </EzFormControl>
                </Grid>
                <Grid item xs={2} className={classes.subtitle}>{messages('simulator.third_driver_seniority')}</Grid>
                <Grid item xs={4}>
                  <SmallSelect
                    disabled={!thirdSeniorityOptions.length || !formikValues.thirdDriverBirthDate}
                    value={formikValues.thirdDriverSeniority}
                    onChange={formikHandleChange}
                    name='thirdDriverSeniority'
                    label=''
                  >
                    {thirdSeniorityOptions.map(option => (
                      <MenuItem key={`thirdDriverSeniority-${option.value}`} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </SmallSelect>
                </Grid>
              </Grid>
            </Grid> :
            false
        }
        {
          formikValues.driversCount === DRIVERS_COUNT.EVERY ?
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={4} className={classes.subtitle}>{messages('simulator.is_youngdriver_main')}</Grid>
                <Grid item xs={8}>
                  <EzFormControl name='isYoungDriverIsMain' isLabelInChild>
                    <YesNoRadioGroup
                      value={formikValues.isYoungDriverIsMain}
                      onChange={(event, value) => handleIsYoungMain(value)}
                    />
                  </EzFormControl>
                </Grid>
              </Grid>
            </Grid> :
            false
        }
        {
          formikValues.driversCount === DRIVERS_COUNT.EVERY && formikValues.isYoungDriverIsMain === 'no' ?
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={1} className={`${classes.subtitle} ${classes.hide}`}>
                  {messages('simulator.maindriver_gender')}
                </Grid>
                <Grid item xs={3} className={classes.hide}>
                  <EzFormControl name='mainDriverGender' isInlineLabel={false}>
                    <RadioGroup
                      value={formikValues.mainDriverGender}
                      onChange={(event, value) => formikSetField('mainDriverGender', value)}
                      row>
                      {maleFemaleSelectOptions.map(item => (<FormControlLabel
                        key={`gender-${item.value}`} value={item.value} control={<Radio/>}
                        label={messages(`fields.${item.messageId}`)}/>))}
                    </RadioGroup>
                  </EzFormControl>
                </Grid>
                <Grid item xs={4}>
                  <EzFormControl
                    name='mainDriverBirthDate'
                    label={messages('simulator.maindriver_birthday')}
                    isLabelInChild
                  >
                    <DatePicker
                      style={datePickerStyle}
                      value={formikValues.mainDriverBirthDate}
                      onChange={(date) => handleDateChange(date, formikValues.mainDriverBirthDate, 'mainDriver')}
                      onAccept={() => {}}
                      autoOk
                      id='mainDriverBirthDate'
                      maxDate={youngDriverMaxDate}
                      minDate={youngDriverMinDate}
                      initialFocusedDate={initialFocusedDate}
                      variant='inline'
                      size='small'
                    />
                  </EzFormControl>
                </Grid>
                <Grid item xs={4}>
                  <SmallSelect
                    disabled={!mainSeniorityOptions.length || !formikValues.mainDriverBirthDate}
                    value={formikValues.mainDriverSeniority}
                    onChange={formikHandleChange}
                    name='mainDriverSeniority'
                    label={messages('simulator.maindriver_seniority')}
                  >
                    {mainSeniorityOptions.map(option => (
                      <MenuItem
                        key={`mainDriverSeniority-${option.value}`}
                        value={option.value}
                      >
                        {option.text}
                      </MenuItem>
                    ))}
                  </SmallSelect>
                </Grid>
              </Grid>
            </Grid> :
            false
        }
      </Grid>
    </SimulatorExpansionPanel>
  );
});

const calculateSeniority = (value, messages) => {
  const maxSeniorityPossible = 70;
  const now = Moment();
  const birthDate = Moment(value);
  const age = now.diff(birthDate, 'years');
  const months = now.diff(birthDate, 'month') - age * 12;
  const maxSeniority = age === 16 ? months >= 9 ? 1 : 0 : age - 16;
  if (Number.isNaN(maxSeniority) || maxSeniority < 0) {
    // maybe show a validation to the user instead of return
    return [];
  }

  const seniorityOptions = new Array(maxSeniority).fill('1')
    .map((v, index) => {
      let label = '';
      if (index < 3) {
        label = `${messages(`seniorityOptions.years-${index}`)}`;
      } else {
        label = `${index} ${messages('seniorityOptions.years')}`;
      }
      return {text: label,
        value: index.toString()};
    });

  if (seniorityOptions.length < maxSeniorityPossible) {
    return seniorityOptions;
  }
  return [];
};

DriversDetails.propTypes = {
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikValues: PropTypes.object,
};

export default DriversDetails;
