/* eslint-disable max-len */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Dialog, Slide, Box, Grid, makeStyles, Typography,
  TextField, Icon, Button, Select, MenuItem, CircularProgress} from '@material-ui/core';
import {Black} from 'wobi-web-common/dist/Colors';
import {Formik, Form, ErrorMessage} from 'formik';
import {injectIntl, FormattedMessage} from 'react-intl';
import * as yup from 'yup';
import {EzFormControl, DatePicker, Tooltip} from 'wobi-web-common';
import {AutocompleteSingleSelect} from 'wobi-web-common/dist/components/Autocomplete';
import Moment from 'moment';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import peopleImage from '../assets/images/people.png';
import cities from '../config/cities';
import {setOffersFields, getOffersFields} from '../utils/persistOfferDetails';
import {getStreetsByCityCode, handleAdditionalDetailsSubmit} from '../utils/apiHandlers';
import {DRIVERS_COUNT} from '../constants/enums';
import {calculateSeniority, handleDriverSeniorityDateChange, isValidEmail} from '../utils/common';
import {getImageByAgent} from '../config/agent';

const alertImage = getImageByAgent('alert.svg');

const Transition = React.forwardRef((props, ref) => <Slide direction='down' ref={ref} {...props} />);

const ShippingDetails = (props) => {
  const messages = (id) => props.intl.formatMessage({id}) || '';
  const classes = useStyles();
  const {onClose, open} = props;
  const [driversCount] = React.useState(getOffersFields('driversCount'));
  const [isLoading, setLoading] = React.useState(false);
  const [streets, setStreets] = React.useState([]);
  const defaultSeniorityOptions = calculateSeniority(Moment('01/01/1950'), messages);
  const [seniorityOptions, setSeniorityOptions] = React.useState(defaultSeniorityOptions);

  useEffect(() => {
    const {cityCode: code} = JSON.parse(sessionStorage.getItem('offers_fields'));
    getStreetsByCityCode(code).then((res) => {
      const foundStreets = res.data || [];
      setStreets(foundStreets);
    });
  }, []);

  const handleClose = () => {
    onClose();
  };

  const validationMessage = {
    email: (<FormattedMessage id='validation.email'/>),
    number: (<FormattedMessage id='validation.number_not_valid'/>),
    required: (<FormattedMessage id='validation.required'/>),
  };

  const validationSchema =
    yup.object().shape({
      deliveryCity: yup.string().required(validationMessage.required),
      deliveryHouseNum: yup.number().required(validationMessage.required).typeError(validationMessage.number),
      deliveryStreet: yup.string().required(validationMessage.required),
      email: yup.string().email('כתובת מייל לא תקינה').required(validationMessage.required).email(validationMessage.email).test('email', 'כתובת מייל לא תקינה', (_email) => isValidEmail(_email)),
      ownerFullName: yup.string().required(validationMessage.required),
      secondDriverFullName: driversCount === DRIVERS_COUNT.TWO ?
        yup.string().required(validationMessage.required) : yup.string(),
      secondDriverIdentificationNumber: driversCount === DRIVERS_COUNT.TWO ?
        yup.number().required(validationMessage.required).typeError(validationMessage.number) :
        yup.number().typeError(validationMessage.number),
      secondDriverSeniority: driversCount === DRIVERS_COUNT.TWO ?
        yup.number().required(validationMessage.required) : yup.number(),
    });

  const {
    firstname,
    lastname,
    city,
    cityCode,
    cityPostCode,
    email,
  } = JSON.parse(sessionStorage.getItem('offers_fields'));

  const initialValues = {
    deliveryCity: city,
    deliveryCityCode: cityCode,
    deliveryCityPostCode: cityPostCode,
    deliveryHouseNum: '',
    deliveryStreet: '',
    email: email || '',
    ownerFullName: firstname && lastname ? `${firstname || ''} ${lastname || ''}` : '',
    secondDriverFullName: '',
    secondDriverIdentificationNumber: '',
    secondDriverSeniority: '',
  };

  const handleCitySelectChange = (value) => cities.filter(item => item.name.slice(0, value.length) === value)
    .map(item => Object.assign(item, {
      label: item.name,
      value: item.code,
    }));

  const handleCityChange = async (cityObject, setFieldValue) => {
    if (!cityObject || !cityObject.name) {
      setFieldValue('deliveryCity', '');
      setFieldValue('deliveryCityCode', '');
      setFieldValue('deliveryCityPostCode', Number(''));
      setFieldValue('deliveryStreet', '');
      setStreets([]);
    } else {
      setFieldValue('deliveryCity', cityObject.name);
      setFieldValue('deliveryCityCode', cityObject.code);
      setFieldValue('deliveryCityPostCode', Number(cityObject.post_code));

      const responseData = await getStreetsByCityCode(cityObject.code);
      const foundStreets = responseData.data || [];
      setStreets(foundStreets);
    }
  };

  const handleSetStreets = (value) => streets.filter(item => item.name.slice(0, value.length) === value)
    .map(item => Object.assign(item, {
      label: item.name,
      value: item.name,
    }));

  const handleStreetChange = (streetObject, setFieldValue) => {
    if (!streetObject || !streetObject.name || !streetObject.city_id) {
      setFieldValue('deliveryStreet', '');
    } else {
      setFieldValue('deliveryStreet', streetObject.name);
    }
  };

  const assembleAdditionalData = (formValues) => {
    const mergedState = Object.assign({
      apartmentNum: '',
      city: formValues.deliveryCity,
      cityCode: formValues.deliveryCityCode,
      cityPostCode: formValues.deliveryCityPostCode,
      deliveryApartmentNum: '',
      deliveryMailbox: '',
      email: formValues.email,
      hasDifferentAddress: '',
      houseNum: '',
      licenseNumber: getOffersFields('licenseNumber'),
      ownerBirthDate: getOffersFields('youngDriverBirthDate'),
      ownerDriverAge: getOffersFields('youngDriverAge'),
      ownerFirstName: formValues.firstName, // incase there is more than one name
      ownerGender: getOffersFields('youngDriverGender'),
      ownerIdentificationNumber: getOffersFields('ownerIdentificationNumber'),
      ownerLastName: formValues.lastName,

      // streetData: {}, Is this needed ?
      ownerLicenseYear: getOffersFields('youngDriverSeniority'),
      ownerPhone: getOffersFields('phone'),
      potentialId: getOffersFields('potentialId'),
      secondDriverFirstName: formValues.secondDriverFirstName,
      secondDriverIdentificationNumber: getOffersFields('secondDriverIdentificationNumber'),
      secondDriverLastName: formValues.secondDriverLastName,
      street: '',

      // TODO: should probably be calculated
      token: getOffersFields('offersCallId'),
    }, formValues);

    // console.dir(mergedState);

    return mergedState;
  };

  const getDriverFullNameObject = (driverFullName) => {
    if (!driverFullName) {
      return null;
    }

    const splittedName = driverFullName.split(' ');
    const splittedNameLength = splittedName.length;

    return {
      firstName: splittedNameLength > 2 ?
        splittedName.slice(0, splittedNameLength - 1).join(' ') :
        splittedName[0],
      lastName: splittedName[splittedNameLength - 1],
    };
  };

  const handleSubmit = async (values) => {
    const ownerFullNameObject = getDriverFullNameObject(values.ownerFullName);
    const secondDriverFullNameObject = getDriverFullNameObject(values.secondDriverFullName);

    setLoading(true);
    const requestData = {
      ...values,
      firstName: ownerFullNameObject ? ownerFullNameObject.firstName : null,
      lastName: ownerFullNameObject ? ownerFullNameObject.lastName : null,
      licenseNumber: getOffersFields('licenseNumber'),
      secondDriverAge: Moment().diff(Moment(values.secondDriverBirthDate, 'DD/MM/YYYY'), 'years'),
      secondDriverFirstName: secondDriverFullNameObject ? secondDriverFullNameObject.firstName : null,
      secondDriverIdentificationNumber:
      values.secondDriverIdentificationNumber || null,
      secondDriverLastName: secondDriverFullNameObject ? secondDriverFullNameObject.lastName : null,
    };
    setOffersFields(requestData);
    try {
      await handleAdditionalDetailsSubmit(assembleAdditionalData(requestData));
      setLoading(false);
      handleClose();
    } catch {
      setLoading(false);
      handleClose();
    }
  };

  const handleDateChange = (date, setFieldValue) => handleDriverSeniorityDateChange(
    date,
    setFieldValue,
    setSeniorityOptions,
    messages,
    'second',
  );

  const secondDriverMaxDate = Moment().subtract(17, 'year');
  const secondDriverMinDate = Moment().subtract(85, 'year');
  const initialFocusedDate = Moment().subtract(40, 'year');

  return (
    <Dialog
      TransitionComponent={Transition}
      aria-labelledby='simple-dialog-title'
      className={classes.modal}
      maxWidth='lg'
      open={open}>
      <Box px={{lg: 7,
        xs: 3}} py={3}>
        <Formik onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues} enableReinitialize>
          {({values, errors, touched, handleChange, handleBlur, setFieldValue}) => (
            <Form>
              <Box mb={2} display='flex' alignItems='center'>
                <Box display={{lg: 'inline',
                  xs: 'none'}}>
                  <img className={classes.successImage} src={peopleImage} alt='success'/>
                </Box>
                <Typography id='simple-dialog-title' component='h1' className={classes.dialogTitle}>
                  <FormattedMessage id='shipping_details.success'/>
                </Typography>
              </Box>
              <Box mb={3}>
                <Grid container justify='space-between' alignItems='center'>
                  <Grid item>
                    <Typography component='h3' className={classes.dialogSubtitle}>
                      <FormattedMessage id='shipping_details.form_title'/>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip placement='right' title={<FormattedMessage id='shipping_details.tootip_title'/>}>
                      <Icon className={classes.helpIcon}>help</Icon>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={driversCount !== DRIVERS_COUNT.TWO ? 3 : 0}>
                <Grid container spacing={1}>
                  <Grid item container spacing={3} xs={12} lg={10}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='ownerFullName-textfield'
                        onChange={handleChange} onBlur={handleBlur}
                        error={errors.ownerFullName && touched.ownerFullName}
                        className={classes.fullWith} name='ownerFullName'
                        value={values.ownerFullName}
                        label={<FormattedMessage id='shipping_details.field_full_name'/>}
                        helperText={<ErrorMessage name='ownerFullName'/>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id='email-textfield'
                        onChange={handleChange} onBlur={handleBlur}
                        error={errors.email && touched.email}
                        className={classes.fullWith} name='email'
                        value={values.email} label={<FormattedMessage id='shipping_details.field_email'/>}
                        helperText={<ErrorMessage name='email'/>}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={3} xs={12} lg={12}>
                    <Grid item xs={12} sm={5}>
                      {/* provide select */}
                      <EzFormControl name='deliveryStreet'>
                        <AutocompleteSingleSelect
                          inputLabel={messages('fields.street')}
                          id='deliveryStreet-textfield'
                          onInput={handleSetStreets}
                          onSelect={(streetObject) => handleStreetChange(streetObject, setFieldValue)}
                          cacheOptions={false}
                        />
                      </EzFormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        id='deliveryHouseNum-textfield'
                        error={errors.deliveryHouseNum && touched.deliveryHouseNum}
                        onChange={handleChange} onBlur={handleBlur}
                        className={classes.fullWith} name='deliveryHouseNum'
                        value={values.deliveryHouseNum}
                        label={<FormattedMessage id='shipping_details.building_number'/>}
                        helperText={<ErrorMessage name='deliveryHouseNum'/>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <EzFormControl name='deliveryCity'>
                        <AutocompleteSingleSelect
                          inputLabel={messages('fields.city')}
                          onInput={handleCitySelectChange}
                          onSelect={(cityObject) => handleCityChange(cityObject, setFieldValue)}
                          defaultValue={{
                            cityCode: values.deliveryCityCode,
                            cityPostCode: values.deliveryCityPostCode,
                            label: values.deliveryCity,
                          }}
                        />
                      </EzFormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              {driversCount === DRIVERS_COUNT.TWO ?
                <Box mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component='h3' className={classes.dialogSubtitle}>
                        <FormattedMessage id='shipping_details.second_driver_title'/>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        onChange={handleChange} onBlur={handleBlur}
                        error={errors.secondDriverFullName && touched.secondDriverFullName}
                        className={classes.fullWith} name='secondDriverFullName'
                        value={values.secondDriverFullName}
                        label={<FormattedMessage id='shipping_details.field_full_name'/>}
                        helperText={<ErrorMessage name='ownerFullName'/>}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        onChange={handleChange} onBlur={handleBlur}
                        error={errors.secondDriverIdentificationNumber && touched.secondDriverIdentificationNumber}
                        className={classes.fullWith} name='secondDriverIdentificationNumber'
                        value={values.secondDriverIdentificationNumber}
                        label={<FormattedMessage id='shipping_details.field_id'/>}
                        helperText={<ErrorMessage name='secondDriverIdentificationNumber'/>}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <EzFormControl
                        name='secondDriverBirthDate'
                        label={messages('fields.driver_birthday')}
                        isLabelInChild
                      >
                        <DatePicker
                          id='secondDriverBirthDate-picker'
                          value={Moment(values.secondDriverBirthDate, 'DD/MM/YYYY').toISOString()}
                          onChange={(date) => handleDateChange(date, setFieldValue)}
                          onAccept={() => {}}
                          autoOk
                          invalidDateMessage='תאריך שגוי'
                          maxDateMessage='לא ניתן לרכוש עבור מבוטח מתחת לגיל 17'
                          minDateMessage='לא ניתן לרכוש עבור מבוטח מעבר לגיל 85'
                          maxDate={secondDriverMaxDate}
                          minDate={secondDriverMinDate}
                          initialFocusedDate={initialFocusedDate}
                          variant='inline'/>
                      </EzFormControl>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <EzFormControl className={classes.fullWidth} name='secondDriverSeniority'
                        label={messages('fields.licenseYear')} isInputLabel>
                        <Select
                          id='secondDriverSeniority'
                          className={classes.fullWidthSelect}
                          disabled={!seniorityOptions.length || !values.secondDriverBirthDate}
                          value={values.secondDriverSeniority}
                          onChange={handleChange}>
                          {seniorityOptions.map(option => (
                            <MenuItem key={`secondDriverSeniority-${option.value}`} value={option.value}>
                              {option.text}
                            </MenuItem>
                          ))
                          }
                        </Select>
                      </EzFormControl>
                    </Grid>
                  </Grid>
                </Box> :
                false}
              <Box mb={3}>
                <Grid container alignItems='center'>
                  <Grid item xs={2} md='auto'>
                    <Box pr={2}>
                      <img className={classes.alertImage} src={alertImage} alt='alert'/>
                    </Box>
                  </Grid>
                  <Grid item xs={10} md='auto'>
                    <span className={classes.alertText}><FormattedMessage id='shipping_details.alert_title'/></span>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={9}>
                    <Box pl={{lg: 6,
                      sm: 12}}>
                      <span className={classes.textMutted}><FormattedMessage id='shipping_details.alert_text'/></span>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mb={3}>
                <Button type='submit' className={classes.submitButton} disabled={isLoading}>
                  {isLoading ?
                    <CircularProgress/> :
                    <>
                      <FormattedMessage id='shipping_details.send_button'/>
                      <Icon className={classes.iconBack}>call_received</Icon>
                    </>
                  }
                </Button>
              </Box>
            </Form>)}
        </Formik>
      </Box>
    </Dialog>
  );
};

ShippingDetails.propTypes = {
  intl: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,

};

const useStyles = makeStyles(theme => ({
  '.MuiTooltip-touch': {
    fontSize: theme.typography.pxToRem(7.9),
  },
  alertImage: {
    height: 29,
    verticalAlign: 'middle',
    width: 'auto',
  },
  alertText: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 600,
  },
  buttonDismiss: {
    '&:after': {
      left: 1,
      top: -1,
      transform: 'rotate(-45deg)',
    },
    '&:before': {
      left: 1,
      top: 1,
      transform: 'rotate(45deg)',
    },
    '&:before, &:after': {
      border: `1px solid ${agentDeveloperUtils.getConvertedColor(Black[170])}`,
      content: '""',
      display: 'block',
      position: 'relative',
      width: 25,
      [theme.breakpoints.down('md')]: {
        width: 17,
      },
    },
    display: 'inline-block',
    [theme.breakpoints.down('md')]: {
      right: 22,
      top: 32,
    },
    position: 'absolute',
    right: 32,
    top: 32,
  },
  dialogSubtitle: {
    color: agentDeveloperUtils.getPrimaryColor(450),
    fontSize: theme.typography.pxToRem(15.75),
    fontWeight: 800,
  },
  dialogTitle: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.pxToRem(33.25),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24.5),
      lineHeight: '34px',
      maxWidth: '80%',
    },
  },
  fullWidthSelect: {
    '& .MuiOutlinedInput-root': '100%',
    width: '100%',
  },
  fullWith: {
    width: '100%',
  },
  helpIcon: {
    '&:hover': {
      opacity: 1,
    },
    color: theme.palette.secondary.main,
    fontSize: theme.typography.pxToRem(13.125),
    opacity: 0.7,
  },
  iconBack: {
    marginLeft: 15,
    transform: 'rotate(-45deg)',
  },
  modal: {
    '&>.MuiPaper-root': {
      borderRadius: 20,
      width: 809,
      [theme.breakpoints.down('md')]: {
        margin: 25,
      },
    },
  },
  submitButton: {
    fontWeight: 800,
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  successImage: {
    height: 'auto',
    marginRight: 20,
    width: 60,
  },
  textMutted: {
    color: theme.palette.type === 'light' ? '#9D9D9D' : theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12.25),
  },
}));

export default injectIntl(ShippingDetails);

