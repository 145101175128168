import {makeStyles} from '@material-ui/core/styles';
import {Black} from 'wobi-web-common/dist/Colors';
import {Breakpoints as breakpoints} from 'wobi-web-common';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import {getImageByAgent} from '../../config/agent';

const SecuredIcon = getImageByAgent('credit-card.svg');

const useStyles = makeStyles(theme => ({
  callToRepresentativeBtn: {
    '@media screen and (max-width: 960px)': {
      justifyContent: 'flex-start',
    },
    '@media screen and (min-width: 961px)': {
      flex: '0 0 auto',
      flexFlow: 'column nowrap',
      justifyContent: 'start',
    },
    alignItems: props => props.isMobile ? 'center' : 'flex-start',
    display: 'flex',
    width: 191,
  },
  callUs: {
    '& img': {
      position: 'relative',
      top: -2,
    },
    '&:hover': {
      '& span': {
        color: theme.palette.type === 'light' ? agentDeveloperUtils.getSecondaryColor(300) :
          agentDeveloperUtils.getSecondaryColor(90),
      },
      backgroundColor: 'transparent',
      boxShadow: 'unset',
    },
    '&>span': {
      marginLeft: 2,
    },
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    color: theme.palette.type === 'light' ? agentDeveloperUtils.getConvertedColor(Black[500]) :
      theme.palette.text.primary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(13.125),
    justifyContent: 'flex-start',
    margin: 0,
    marginTop: 20,
    minWidth: 167,
    padding: 3,
    width: '100%',
  },
  callUsStrong: {
    fontWeight: 700,
    margin: '0 6px',
  },
  closeBtn: {
    '& .MuiButton-label': {
      color: theme.palette.grey[700],
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
    backgroundColor: theme.palette.grey[300],
    boxShadow: 'unset',
  },
  contactUsBtn: {
    '&:hover': {
      backgroundColor: agentDeveloperUtils.getConvertedColor('#53C2EF'),
    },
    '@media screen and (min-width: 1024px)': {
      width: '10%',
    },
    backgroundColor: agentDeveloperUtils.getConvertedColor('#53C2EF'),
    boxShadow: 'unset',
    fontWeight: 700,
    minWidth: 167,
    padding: '6px 22px',
  },
  creditCardHelp: {
    fontSize: theme.typography.pxToRem(10.5),
  },
  creditCardInput: {
    minWidth: 167,
  },
  daniela: {
    '& img': {
      width: 180,
    },
    alignSelf: 'flex-end',
    bottom: -50,
    flex: '0 0 180px',
    position: 'absolute',
    right: 7,
    [theme.breakpoints.down('sm')]: {
      '& img': {
        width: 130,
      },
      bottom: -38,
      flex: '0 0 130px',
    },
  },

  danielaMobile: {
    '& img': {
      width: 100,
    },
    flex: '0 0 100px',

    // padding of the parent (offer-content)
    marginBottom: -40,
  },
  danielaMobileOpen: {
    bottom: 8,
    position: 'absolute',
    right: 0,
  },
  debitNote: {
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  errorText: {
    color: 'red',
  },
  form: {
    position: 'relative',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      '&.init': {
        flexFlow: 'column nowrap',
        marginTop: 20,
      },
      alignItems: 'center',
    },
  },
  includedText: {
    '&:before': {
      backgroundColor: '#B8D1DE',
      borderRadius: 6,
      content: '""',
      display: 'block',
      height: 6,
      marginRight: 6,
      minWidth: 6,
      width: 6,
    },
    '&:first-child': {
      bottom: 50,
      letterSpacing: '-0.4px',
      marginTop: 20,
    },
    '@media screen and (max-width: 1097px)': {
      margin: '7px 0',
      position: 'static',
    },
    alignItems: 'center',
    bottom: 25,
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 500,
    letterSpacing: '0.3px',
    lineHeight: '14px',
    position: 'absolute',
  },
  includedWrapper: {
    '@media screen and (max-width: 1097px)': {
      flexBasis: '100%',
      height: 'auto',
      marginRight: 8,
      position: 'static',
    },
    height: 114,
    maxWidth: 225,
    position: 'relative',
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12.25),
  },
  loaderContainer: {
    '& .contact_us_loader': {
      fill: agentDeveloperUtils.getPrimaryColor(450),
      margin: 8,
    },
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  payment: {
    flex: '1 0 auto',
    minWidth: 135,
  },
  paymentBtn: {
    boxShadow: 'unset',
    fontWeight: 700,
    minWidth: 167,
    padding: '6px 22px',
  },
  paymentBtnInitWrapper: {
    '@media screen and (min-width: 961px)': {
      height: '110px',
      justifyContent: 'start',
    },
    '@media screen and (min-width: 1024px)': {
      flexFlow: 'column nowrap',
    },
    alignItems: props => props.isMobile ? 'center' : 'flex-start',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column',
    width: 191,
  },
  protection: {
    '&:before': {
      color: agentDeveloperUtils.getPrimaryColor(450),
      content: '"*"',
      fontSize: theme.typography.pxToRem(16.6),
      left: -1,
      position: 'absolute',
      top: -1,
    },
    paddingLeft: 10,
    position: 'relative',
  },
  protectionWrapper: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(11.4),
    fontWeight: 600,
    lineHeight: 1,
    marginTop: 10,
  },
  protectionWrapperOpen: {
    justifyContent: 'center',
  },
  purchaseBtnWrapper: {
    position: 'relative',
    width: '100%',
  },
  purchaseContainer: {
    marginTop: 10,
    padding: '0 20px',
    [`@media screen and (min-width: ${breakpoints.mobile}px) and (max-width: 1093px)`]: {
      padding: 0,
    },
  },
  securedPayment: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 13,
    minWidth: 167,
    width: '50%',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      marginTop: 6,
    },
  },
  securedPaymentInner: {
    '&:before': {
      backgroundImage: `url(${SecuredIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 20px',
      content: '""',
      height: 20,
      margin: '3px 0 0 10px',
      width: 16,
    },
    color: theme.palette.text.secondary,
    display: 'flex',
    fontWeight: 500,
    lineHeight: '30px',
    width: 300,
  },
  tooltip: {
    '&:hover': {
      opacity: 1,
    },
    color: theme.palette.secondary.main,
    opacity: 0.7,
    transition: '0.3s all',
  },
  tooltipWrap: {
    marginLeft: 8,
  },
  verifyLoader: {
    position: 'absolute',
    right: 190,
    top: 5,
  },
  whiteTelephone: {
    marginRight: 5,
  },
}));

export default useStyles;
