const cities =
  [
    {
      code: '7',
      key: '1',
      name: 'שחר',
      post_code: '7933500',
      post_code_5: '79335',
    },
    {
      code: '10',
      key: '2',
      name: 'תירוש',
      post_code: '9981500',
      post_code_5: '99815',
    },
    {
      code: '11',
      key: '3',
      name: 'ניר ח"ן',
      post_code: '7933000',
      post_code_5: '79330',
    },
    {
      code: '13',
      key: '4',
      name: 'חצבה',
      post_code: '8681500',
      post_code_5: '86815',
    },
    {
      code: '15',
      key: '5',
      name: 'נועם',
      post_code: '7959000',
      post_code_5: '79590',
    },
    {
      code: '16',
      key: '6',
      name: 'בית ניר',
      post_code: '7956000',
      post_code_5: '79560',
    },
    {
      code: '18',
      key: '7',
      name: 'שדה משה',
      post_code: '7935500',
      post_code_5: '79355',
    },
    {
      code: '21',
      key: '8',
      name: 'באר אורה',
      post_code: '8881000',
      post_code_5: '88810',
    },
    {
      code: '22',
      key: '9',
      name: 'מקווה ישראל',
      post_code: '5891000',
      post_code_5: '58910',
    },
    {
      code: '23',
      key: '10',
      name: 'אמציה',
      post_code: '7936500',
      post_code_5: '79365',
    },
    {
      code: '24',
      key: '11',
      name: 'לכיש',
      post_code: '7936000',
      post_code_5: '79360',
    },
    {
      code: '26',
      key: '12',
      name: 'ראש פינה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '27',
      key: '13',
      name: 'שדות מיכה',
      post_code: '9981000',
      post_code_5: '99810',
    },
    {
      code: '28',
      key: '14',
      name: 'מזכרת בתיה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '29',
      key: '15',
      name: 'יסוד המעלה',
      post_code: '1210500',
      post_code_5: '12105',
    },
    {
      code: '31',
      key: '16',
      name: 'אופקים',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '32',
      key: '17',
      name: 'עוצם',
      post_code: '7935000',
      post_code_5: '79350',
    },
    {
      code: '33',
      key: '18',
      name: 'בת שלמה',
      post_code: '3099200',
      post_code_5: '30992',
    },
    {
      code: '35',
      key: '19',
      name: 'גדות',
      post_code: '1232500',
      post_code_5: '12325',
    },
    {
      code: '36',
      key: '20',
      name: 'שדה דוד',
      post_code: '7932000',
      post_code_5: '79320',
    },
    {
      code: '37',
      key: '21',
      name: 'איתן',
      post_code: '7958000',
      post_code_5: '79580',
    },
    {
      code: '38',
      key: '22',
      name: 'כרי דשא',
      post_code: '1292900',
      post_code_5: '12929',
    },
    {
      code: '39',
      key: '23',
      name: 'גפן',
      post_code: '9982000',
      post_code_5: '99820',
    },
    {
      code: '41',
      key: '24',
      name: 'אליכין',
      post_code: '3890800',
      post_code_5: '38908',
    },
    {
      code: '43',
      key: '25',
      name: 'מטולה',
      post_code: '1029200',
      post_code_5: '10292',
    },
    {
      code: '44',
      key: '26',
      name: 'זוהר',
      post_code: '7932500',
      post_code_5: '79325',
    },
    {
      code: '46',
      key: '27',
      name: 'יבנאל',
      post_code: '1522500',
      post_code_5: '15225',
    },
    {
      code: '47',
      key: '28',
      name: 'כפר תבור',
      post_code: '1524100',
      post_code_5: '15241',
    },
    {
      code: '48',
      key: '29',
      name: 'מנחמיה',
      post_code: '1494500',
      post_code_5: '14945',
    },
    {
      code: '49',
      key: '30',
      name: 'אילניה',
      post_code: '1525500',
      post_code_5: '15255',
    },
    {
      code: '52',
      key: '31',
      name: 'לוזית',
      post_code: '9984500',
      post_code_5: '99845',
    },
    {
      code: '53',
      key: '32',
      name: 'עתלית',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '55',
      key: '33',
      name: 'נוגה',
      post_code: '7934500',
      post_code_5: '79345',
    },
    {
      code: '57',
      key: '34',
      name: 'כנרת (קבוצה)',
      post_code: '1511800',
      post_code_5: '15118',
    },
    {
      code: '58',
      key: '35',
      name: 'מצפה',
      post_code: '1527600',
      post_code_5: '15276',
    },
    {
      code: '59',
      key: '36',
      name: 'נחושה',
      post_code: '9983300',
      post_code_5: '99833',
    },
    {
      code: '62',
      key: '37',
      name: 'דגניה א\'',
      post_code: '1512000',
      post_code_5: '15120',
    },
    {
      code: '63',
      key: '38',
      name: 'כנרת (מושבה)',
      post_code: '1510500',
      post_code_5: '15105',
    },
    {
      code: '64',
      key: '39',
      name: 'יד רמב"ם',
      post_code: '9979300',
      post_code_5: '99793',
    },
    {
      code: '65',
      key: '40',
      name: 'מגדל',
      post_code: '1495000',
      post_code_5: '14950',
    },
    {
      code: '66',
      key: '41',
      name: 'מרחביה (קיבוץ)',
      post_code: '1910000',
      post_code_5: '19100',
    },
    {
      code: '67',
      key: '42',
      name: 'אור הנר',
      post_code: '7919000',
      post_code_5: '79190',
    },
    {
      code: '69',
      key: '43',
      name: 'ניר עוז',
      post_code: '8512200',
      post_code_5: '85122',
    },
    {
      code: '70',
      key: '44',
      name: 'אשדוד',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '71',
      key: '45',
      name: 'אשבול',
      post_code: '8535500',
      post_code_5: '85355',
    },
    {
      code: '72',
      key: '46',
      name: 'גן שמואל',
      post_code: '3881000',
      post_code_5: '38810',
    },
    {
      code: '74',
      key: '47',
      name: 'עין הוד',
      post_code: '3089000',
      post_code_5: '30890',
    },
    {
      code: '76',
      key: '48',
      name: 'כפר גלעדי',
      post_code: '1221000',
      post_code_5: '12210',
    },
    {
      code: '77',
      key: '49',
      name: 'איילת השחר',
      post_code: '1220000',
      post_code_5: '12200',
    },
    {
      code: '78',
      key: '50',
      name: 'קריית ענבים',
      post_code: '9083300',
      post_code_5: '90833',
    },
    {
      code: '79',
      key: '51',
      name: 'דגניה ב\'',
      post_code: '1513000',
      post_code_5: '15130',
    },
    {
      code: '80',
      key: '52',
      name: 'נהלל',
      post_code: '1060000',
      post_code_5: '10600',
    },
    {
      code: '82',
      key: '53',
      name: 'עין חרוד (מאוחד)',
      post_code: '1896500',
      post_code_5: '18965',
    },
    {
      code: '84',
      key: '54',
      name: 'תל יוסף',
      post_code: '1913200',
      post_code_5: '19132',
    },
    {
      code: '85',
      key: '55',
      name: 'כפר יחזקאל',
      post_code: '1892500',
      post_code_5: '18925',
    },
    {
      code: '86',
      key: '56',
      name: 'גבע',
      post_code: '1891500',
      post_code_5: '18915',
    },
    {
      code: '88',
      key: '57',
      name: 'כרם בן שמן',
      post_code: '7311100',
      post_code_5: '73111',
    },
    {
      code: '89',
      key: '58',
      name: 'עין חרוד (איחוד)',
      post_code: '1896000',
      post_code_5: '18960',
    },
    {
      code: '90',
      key: '59',
      name: 'חפצי-בה',
      post_code: '1913500',
      post_code_5: '19135',
    },
    {
      code: '92',
      key: '60',
      name: 'גיניגר',
      post_code: '3658000',
      post_code_5: '36580',
    },
    {
      code: '94',
      key: '61',
      name: 'בלפוריה',
      post_code: '1930500',
      post_code_5: '19305',
    },
    {
      code: '95',
      key: '62',
      name: 'בית אלפא',
      post_code: '1080200',
      post_code_5: '10802',
    },
    {
      code: '96',
      key: '63',
      name: 'יגור',
      post_code: '3006500',
      post_code_5: '30065',
    },
    {
      code: '97',
      key: '64',
      name: 'מרחביה (מושב)',
      post_code: '1910500',
      post_code_5: '19105',
    },
    {
      code: '98',
      key: '65',
      name: 'כפר מל"ל',
      post_code: '4592000',
      post_code_5: '45920',
    },
    {
      code: '99',
      key: '66',
      name: 'מצפה רמון',
      post_code: '8060000',
      post_code_5: '80600',
    },
    {
      code: '102',
      key: '67',
      name: 'מאיר שפיה',
      post_code: '3080600',
      post_code_5: '30806',
    },
    {
      code: '103',
      key: '68',
      name: 'תל עדשים',
      post_code: '1931500',
      post_code_5: '19315',
    },
    {
      code: '104',
      key: '69',
      name: 'מזרע',
      post_code: '1931200',
      post_code_5: '19312',
    },
    {
      code: '106',
      key: '70',
      name: 'כפר גדעון',
      post_code: '1931000',
      post_code_5: '19310',
    },
    {
      code: '107',
      key: '71',
      name: 'כפר סילבר',
      post_code: '7929500',
      post_code_5: '79295',
    },
    {
      code: '112',
      key: '72',
      name: 'כפר חסידים א\'',
      post_code: '2040000',
      post_code_5: '20400',
    },
    {
      code: '113',
      key: '73',
      name: 'אדירים',
      post_code: '1920800',
      post_code_5: '19208',
    },
    {
      code: '115',
      key: '74',
      name: 'חופית',
      post_code: '4029500',
      post_code_5: '40295',
    },
    {
      code: '122',
      key: '75',
      name: 'רמת ישי',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '126',
      key: '76',
      name: 'שריד',
      post_code: '3658900',
      post_code_5: '36589',
    },
    {
      code: '127',
      key: '77',
      name: 'רמת רחל',
      post_code: '9090000',
      post_code_5: '90900',
    },
    {
      code: '128',
      key: '78',
      name: 'גת רימון',
      post_code: '4992000',
      post_code_5: '49920',
    },
    {
      code: '130',
      key: '79',
      name: 'משמר העמק',
      post_code: '1923600',
      post_code_5: '19236',
    },
    {
      code: '132',
      key: '80',
      name: 'כפר ברוך',
      post_code: '3658400',
      post_code_5: '36584',
    },
    {
      code: '133',
      key: '81',
      name: 'גבת',
      post_code: '3657900',
      post_code_5: '36579',
    },
    {
      code: '134',
      key: '82',
      name: 'יפעת',
      post_code: '3658300',
      post_code_5: '36583',
    },
    {
      code: '135',
      key: '83',
      name: 'רמת דוד',
      post_code: '3658700',
      post_code_5: '36587',
    },
    {
      code: '139',
      key: '84',
      name: 'עין שמר',
      post_code: '3881600',
      post_code_5: '38816',
    },
    {
      code: '140',
      key: '85',
      name: 'כפר יהושע',
      post_code: '3658200',
      post_code_5: '36582',
    },
    {
      code: '141',
      key: '86',
      name: 'ברק',
      post_code: '1920700',
      post_code_5: '19207',
    },
    {
      code: '142',
      key: '87',
      name: 'שדה יעקב',
      post_code: '3658600',
      post_code_5: '36586',
    },
    {
      code: '143',
      key: '88',
      name: 'בית זרע',
      post_code: '1513500',
      post_code_5: '15135',
    },
    {
      code: '144',
      key: '89',
      name: 'גן שלמה',
      post_code: '7680200',
      post_code_5: '76802',
    },
    {
      code: '145',
      key: '90',
      name: 'גדיש',
      post_code: '1921500',
      post_code_5: '19215',
    },
    {
      code: '146',
      key: '91',
      name: 'דבורה',
      post_code: '1920600',
      post_code_5: '19206',
    },
    {
      code: '147',
      key: '92',
      name: 'גבעת ברנר',
      post_code: '6094800',
      post_code_5: '60948',
    },
    {
      code: '154',
      key: '93',
      name: 'תל מונד',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '155',
      key: '94',
      name: 'באר טוביה',
      post_code: '8381500',
      post_code_5: '83815',
    },
    {
      code: '156',
      key: '95',
      name: 'עיינות',
      post_code: '7682000',
      post_code_5: '76820',
    },
    {
      code: '157',
      key: '96',
      name: 'עין ורד',
      post_code: '4069600',
      post_code_5: '40696',
    },
    {
      code: '158',
      key: '97',
      name: 'נען',
      post_code: '7682900',
      post_code_5: '76829',
    },
    {
      code: '159',
      key: '98',
      name: 'בית חנן',
      post_code: '7686800',
      post_code_5: '76868',
    },
    {
      code: '160',
      key: '99',
      name: 'חולדה',
      post_code: '7684200',
      post_code_5: '76842',
    },
    {
      code: '162',
      key: '100',
      name: 'חירות',
      post_code: '4069100',
      post_code_5: '40691',
    },
    {
      code: '163',
      key: '101',
      name: 'תימורים',
      post_code: '7986000',
      post_code_5: '79860',
    },
    {
      code: '164',
      key: '102',
      name: 'מלאה',
      post_code: '1922000',
      post_code_5: '19220',
    },
    {
      code: '165',
      key: '103',
      name: 'ניר יפה',
      post_code: '1921000',
      post_code_5: '19210',
    },
    {
      code: '166',
      key: '104',
      name: 'גן יבנה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '167',
      key: '105',
      name: 'עין החורש',
      post_code: '3898000',
      post_code_5: '38980',
    },
    {
      code: '168',
      key: '106',
      name: 'כפר יונה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '170',
      key: '107',
      name: 'כפר יעבץ',
      post_code: '4583000',
      post_code_5: '45830',
    },
    {
      code: '171',
      key: '108',
      name: 'פרדסיה',
      post_code: '4281500',
      post_code_5: '42815',
    },
    {
      code: '173',
      key: '109',
      name: 'גבעת חיים (מאוחד)',
      post_code: '3893000',
      post_code_5: '38930',
    },
    {
      code: '174',
      key: '110',
      name: 'נטעים',
      post_code: '7687000',
      post_code_5: '76870',
    },
    {
      code: '175',
      key: '111',
      name: 'אביחיל',
      post_code: '4291000',
      post_code_5: '42910',
    },
    {
      code: '176',
      key: '112',
      name: 'אפיקים',
      post_code: '1514800',
      post_code_5: '15148',
    },
    {
      code: '177',
      key: '113',
      name: 'כפר ביל"ו',
      post_code: '7696500',
      post_code_5: '76965',
    },
    {
      code: '178',
      key: '114',
      name: 'רמת יוחנן',
      post_code: '3003500',
      post_code_5: '30035',
    },
    {
      code: '182',
      key: '116',
      name: 'אבן יהודה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '183',
      key: '117',
      name: 'ירקונה',
      post_code: '4591500',
      post_code_5: '45915',
    },
    {
      code: '184',
      key: '118',
      name: 'רמת הכובש',
      post_code: '4493000',
      post_code_5: '44930',
    },
    {
      code: '186',
      key: '119',
      name: 'נעורים',
      post_code: '4029800',
      post_code_5: '40298',
    },
    {
      code: '187',
      key: '120',
      name: 'כפר הס',
      post_code: '4069200',
      post_code_5: '40692',
    },
    {
      code: '188',
      key: '121',
      name: 'אשדות יעקב  (מאוחד)',
      post_code: '1515000',
      post_code_5: '15150',
    },
    {
      code: '189',
      key: '122',
      name: 'כפר פינס',
      post_code: '3792000',
      post_code_5: '37920',
    },
    {
      code: '190',
      key: '123',
      name: 'כפר ויתקין',
      post_code: '4020000',
      post_code_5: '40200',
    },
    {
      code: '191',
      key: '124',
      name: 'הדר עם',
      post_code: '4293500',
      post_code_5: '42935',
    },
    {
      code: '192',
      key: '125',
      name: 'כפר החורש',
      post_code: '1696000',
      post_code_5: '16960',
    },
    {
      code: '193',
      key: '126',
      name: 'כפר חיים',
      post_code: '4294500',
      post_code_5: '42945',
    },
    {
      code: '194',
      key: '127',
      name: 'משמר השרון',
      post_code: '4027000',
      post_code_5: '40270',
    },
    {
      code: '196',
      key: '129',
      name: 'גיבתון',
      post_code: '7691000',
      post_code_5: '76910',
    },
    {
      code: '197',
      key: '130',
      name: 'מעברות',
      post_code: '4023000',
      post_code_5: '40230',
    },
    {
      code: '198',
      key: '131',
      name: 'צופית',
      post_code: '4492500',
      post_code_5: '44925',
    },
    {
      code: '199',
      key: '132',
      name: 'אשדות יעקב  (איחוד)',
      post_code: '1515500',
      post_code_5: '15155',
    },
    {
      code: '200',
      key: '133',
      name: 'בית ינאי',
      post_code: '4029300',
      post_code_5: '40293',
    },
    {
      code: '202',
      key: '134',
      name: 'בית עובד',
      post_code: '7680000',
      post_code_5: '76800',
    },
    {
      code: '204',
      key: '135',
      name: 'אלישיב',
      post_code: '4288500',
      post_code_5: '42885',
    },
    {
      code: '205',
      key: '136',
      name: 'חגלה',
      post_code: '3888000',
      post_code_5: '38880',
    },
    {
      code: '206',
      key: '137',
      name: 'רמות השבים',
      post_code: '4593000',
      post_code_5: '45930',
    },
    {
      code: '207',
      key: '138',
      name: 'גבעת ח"ן',
      post_code: '4390500',
      post_code_5: '43905',
    },
    {
      code: '208',
      key: '139',
      name: 'מוצא עילית',
      post_code: '9082000',
      post_code_5: '90820',
    },
    {
      code: '212',
      key: '140',
      name: 'בית צבי',
      post_code: '3088000',
      post_code_5: '30880',
    },
    {
      code: '213',
      key: '141',
      name: 'משמרות',
      post_code: '3784000',
      post_code_5: '37840',
    },
    {
      code: '217',
      key: '142',
      name: 'כפר הרא"ה',
      post_code: '3895500',
      post_code_5: '38955',
    },
    {
      code: '218',
      key: '143',
      name: 'גני עם',
      post_code: '4590500',
      post_code_5: '45905',
    },
    {
      code: '219',
      key: '144',
      name: 'חיבת ציון',
      post_code: '3888500',
      post_code_5: '38885',
    },
    {
      code: '220',
      key: '145',
      name: 'כפר ביאליק',
      post_code: '2790500',
      post_code_5: '27905',
    },
    {
      code: '223',
      key: '146',
      name: 'עין עירון',
      post_code: '3791000',
      post_code_5: '37910',
    },
    {
      code: '224',
      key: '147',
      name: 'שושנת העמקים (רסקו)',
      post_code: '4298400',
      post_code_5: '42984',
    },
    {
      code: '225',
      key: '148',
      name: 'גן השומרון',
      post_code: '3882500',
      post_code_5: '38825',
    },
    {
      code: '229',
      key: '149',
      name: 'גני תקווה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '230',
      key: '150',
      name: 'מעש',
      post_code: '4992500',
      post_code_5: '49925',
    },
    {
      code: '232',
      key: '151',
      name: 'שפיים',
      post_code: '6099000',
      post_code_5: '60990',
    },
    {
      code: '233',
      key: '152',
      name: 'כפר ידידיה',
      post_code: '4294000',
      post_code_5: '42940',
    },
    {
      code: '234',
      key: '153',
      name: 'ביצרון',
      post_code: '6094600',
      post_code_5: '60946',
    },
    {
      code: '235',
      key: '154',
      name: 'חבצלת השרון',
      post_code: '4293700',
      post_code_5: '42937',
    },
    {
      code: '237',
      key: '155',
      name: 'שער העמקים',
      post_code: '3658800',
      post_code_5: '36588',
    },
    {
      code: '239',
      key: '156',
      name: 'גן חיים',
      post_code: '4491000',
      post_code_5: '44910',
    },
    {
      code: '240',
      key: '157',
      name: 'יקנעם עילית',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '241',
      key: '158',
      name: 'יקנעם (מושבה)',
      post_code: '2060000',
      post_code_5: '20600',
    },
    {
      code: '242',
      key: '159',
      name: 'בית השיטה',
      post_code: '1080100',
      post_code_5: '10801',
    },
    {
      code: '246',
      key: '160',
      name: 'נתיבות',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '247',
      key: '161',
      name: 'רשפון',
      post_code: '4691500',
      post_code_5: '46915',
    },
    {
      code: '248',
      key: '162',
      name: 'בית שערים',
      post_code: '3657800',
      post_code_5: '36578',
    },
    {
      code: '249',
      key: '163',
      name: 'כפר סירקין',
      post_code: '4993500',
      post_code_5: '49935',
    },
    {
      code: '250',
      key: '164',
      name: 'הזורע',
      post_code: '3658100',
      post_code_5: '36581',
    },
    {
      code: '252',
      key: '165',
      name: 'ביתן אהרן',
      post_code: '4029400',
      post_code_5: '40294',
    },
    {
      code: '253',
      key: '166',
      name: 'חולתה',
      post_code: '1211000',
      post_code_5: '12110',
    },
    {
      code: '254',
      key: '167',
      name: 'כפר המכבי',
      post_code: '3003000',
      post_code_5: '30030',
    },
    {
      code: '255',
      key: '168',
      name: 'כפר חיטים',
      post_code: '1528000',
      post_code_5: '15280',
    },
    {
      code: '256',
      key: '169',
      name: 'ניר דוד (תל עמל)',
      post_code: '1080300',
      post_code_5: '10803',
    },
    {
      code: '257',
      key: '170',
      name: 'נופך',
      post_code: '7317000',
      post_code_5: '73170',
    },
    {
      code: '259',
      key: '171',
      name: 'שדה נחום',
      post_code: '1080600',
      post_code_5: '10806',
    },
    {
      code: '262',
      key: '172',
      name: 'גינוסר',
      post_code: '1498000',
      post_code_5: '14980',
    },
    {
      code: '263',
      key: '173',
      name: 'מסדה',
      post_code: '1514000',
      post_code_5: '15140',
    },
    {
      code: '264',
      key: '174',
      name: 'שער הגולן',
      post_code: '1514500',
      post_code_5: '15145',
    },
    {
      code: '265',
      key: '175',
      name: 'בית יוסף',
      post_code: '1086500',
      post_code_5: '10865',
    },
    {
      code: '267',
      key: '176',
      name: 'כפר שמריהו',
      post_code: '4691000',
      post_code_5: '46910',
    },
    {
      code: '268',
      key: '177',
      name: 'טירת צבי',
      post_code: '1081500',
      post_code_5: '10815',
    },
    {
      code: '269',
      key: '178',
      name: 'מולדת',
      post_code: '1913000',
      post_code_5: '19130',
    },
    {
      code: '270',
      key: '179',
      name: 'עין השופט',
      post_code: '1923700',
      post_code_5: '19237',
    },
    {
      code: '272',
      key: '180',
      name: 'מעוז חיים',
      post_code: '1084500',
      post_code_5: '10845',
    },
    {
      code: '273',
      key: '181',
      name: 'עין גב',
      post_code: '1494000',
      post_code_5: '14940',
    },
    {
      code: '274',
      key: '182',
      name: 'כפר מנחם',
      post_code: '7987500',
      post_code_5: '79875',
    },
    {
      code: '276',
      key: '183',
      name: 'צור משה',
      post_code: '4281000',
      post_code_5: '42810',
    },
    {
      code: '278',
      key: '184',
      name: 'אושה',
      post_code: '3003100',
      post_code_5: '30031',
    },
    {
      code: '280',
      key: '185',
      name: 'חניתה',
      post_code: '2288500',
      post_code_5: '22885',
    },
    {
      code: '281',
      key: '186',
      name: 'פקיעין חדשה',
      post_code: '2519000',
      post_code_5: '25190',
    },
    {
      code: '282',
      key: '187',
      name: 'שבי ציון',
      post_code: '2280600',
      post_code_5: '22806',
    },
    {
      code: '284',
      key: '188',
      name: 'שדה ורבורג',
      post_code: '4493500',
      post_code_5: '44935',
    },
    {
      code: '285',
      key: '189',
      name: 'אלונים',
      post_code: '3657700',
      post_code_5: '36577',
    },
    {
      code: '286',
      key: '190',
      name: 'מעלה החמישה',
      post_code: '9083500',
      post_code_5: '90835',
    },
    {
      code: '287',
      key: '191',
      name: 'תל יצחק',
      post_code: '4580500',
      post_code_5: '45805',
    },
    {
      code: '288',
      key: '192',
      name: 'בית יהושע',
      post_code: '4059100',
      post_code_5: '40591',
    },
    {
      code: '289',
      key: '193',
      name: 'עין המפרץ',
      post_code: '2521000',
      post_code_5: '25210',
    },
    {
      code: '290',
      key: '194',
      name: 'מעין צבי',
      post_code: '3080500',
      post_code_5: '30805',
    },
    {
      code: '292',
      key: '195',
      name: 'שרונה',
      post_code: '1523200',
      post_code_5: '15232',
    },
    {
      code: '293',
      key: '196',
      name: 'שדה יואב',
      post_code: '7935100',
      post_code_5: '79351',
    },
    {
      code: '294',
      key: '197',
      name: 'אילון',
      post_code: '2284500',
      post_code_5: '22845',
    },
    {
      code: '295',
      key: '198',
      name: 'כפר רופין',
      post_code: '1085000',
      post_code_5: '10850',
    },
    {
      code: '296',
      key: '199',
      name: 'נווה איתן',
      post_code: '1084000',
      post_code_5: '10840',
    },
    {
      code: '297',
      key: '200',
      name: 'כפר מסריק',
      post_code: '2520800',
      post_code_5: '25208',
    },
    {
      code: '298',
      key: '201',
      name: 'מסילות',
      post_code: '1080400',
      post_code_5: '10804',
    },
    {
      code: '300',
      key: '202',
      name: 'דליה',
      post_code: '1923900',
      post_code_5: '19239',
    },
    {
      code: '301',
      key: '203',
      name: 'בית עוזיאל',
      post_code: '9979400',
      post_code_5: '99794',
    },
    {
      code: '302',
      key: '204',
      name: 'דפנה',
      post_code: '1223500',
      post_code_5: '12235',
    },
    {
      code: '303',
      key: '205',
      name: 'דן',
      post_code: '1224500',
      post_code_5: '12245',
    },
    {
      code: '304',
      key: '206',
      name: 'שדה אליהו',
      post_code: '1081000',
      post_code_5: '10810',
    },
    {
      code: '305',
      key: '207',
      name: 'גשר',
      post_code: '1515700',
      post_code_5: '15157',
    },
    {
      code: '306',
      key: '208',
      name: 'שדמות דבורה',
      post_code: '1524000',
      post_code_5: '15240',
    },
    {
      code: '307',
      key: '209',
      name: 'הזורעים',
      post_code: '1520500',
      post_code_5: '15205',
    },
    {
      code: '308',
      key: '210',
      name: 'מחניים',
      post_code: '1231500',
      post_code_5: '12315',
    },
    {
      code: '309',
      key: '211',
      name: 'נהורה',
      post_code: '7934000',
      post_code_5: '79340',
    },
    {
      code: '310',
      key: '212',
      name: 'כפר גליקסון',
      post_code: '3781500',
      post_code_5: '37815',
    },
    {
      code: '311',
      key: '213',
      name: 'גן שורק',
      post_code: '7686500',
      post_code_5: '76865',
    },
    {
      code: '312',
      key: '214',
      name: 'נווה ים',
      post_code: '3088500',
      post_code_5: '30885',
    },
    {
      code: '313',
      key: '215',
      name: 'אפק',
      post_code: '3004200',
      post_code_5: '30042',
    },
    {
      code: '315',
      key: '216',
      name: 'נגבה',
      post_code: '7985600',
      post_code_5: '79856',
    },
    {
      code: '316',
      key: '217',
      name: 'כפר נטר',
      post_code: '4059300',
      post_code_5: '40593',
    },
    {
      code: '317',
      key: '218',
      name: 'בית אורן',
      post_code: '3004400',
      post_code_5: '30044',
    },
    {
      code: '318',
      key: '219',
      name: 'עמיעוז',
      post_code: '8543500',
      post_code_5: '85435',
    },
    {
      code: '319',
      key: '220',
      name: 'עמיר',
      post_code: '1214000',
      post_code_5: '12140',
    },
    {
      code: '320',
      key: '221',
      name: 'כפר ורבורג',
      post_code: '7099800',
      post_code_5: '70998',
    },
    {
      code: '322',
      key: '222',
      name: 'בית הלל',
      post_code: '1225500',
      post_code_5: '12255',
    },
    {
      code: '324',
      key: '223',
      name: 'שאר ישוב',
      post_code: '1224000',
      post_code_5: '12240',
    },
    {
      code: '325',
      key: '224',
      name: 'מצובה',
      post_code: '2283500',
      post_code_5: '22835',
    },
    {
      code: '326',
      key: '225',
      name: 'בית יצחק-שער חפר',
      post_code: '4292000',
      post_code_5: '42920',
    },
    {
      code: '327',
      key: '226',
      name: 'שדות ים',
      post_code: '3780400',
      post_code_5: '37804',
    },
    {
      code: '328',
      key: '227',
      name: 'עזוז',
      post_code: '8573500',
      post_code_5: '85735',
    },
    {
      code: '329',
      key: '228',
      name: 'שדה נחמיה',
      post_code: '1214500',
      post_code_5: '12145',
    },
    {
      code: '330',
      key: '229',
      name: 'אלומות',
      post_code: '1522300',
      post_code_5: '15223',
    },
    {
      code: '331',
      key: '230',
      name: 'ניר צבי',
      post_code: '7290500',
      post_code_5: '72905',
    },
    {
      code: '334',
      key: '231',
      name: 'קבוצת יבנה',
      post_code: '7923300',
      post_code_5: '79233',
    },
    {
      code: '335',
      key: '232',
      name: 'רמת השופט',
      post_code: '1923800',
      post_code_5: '19238',
    },
    {
      code: '336',
      key: '233',
      name: 'דורות',
      post_code: '7917500',
      post_code_5: '79175',
    },
    {
      code: '338',
      key: '234',
      name: 'איבים',
      post_code: '7916800',
      post_code_5: '79168',
    },
    {
      code: '339',
      key: '235',
      name: 'רמת צבי',
      post_code: '1912500',
      post_code_5: '19125',
    },
    {
      code: '340',
      key: '236',
      name: 'גת (קיבוץ)',
      post_code: '7956500',
      post_code_5: '79565',
    },
    {
      code: '342',
      key: '237',
      name: 'גברעם',
      post_code: '7913000',
      post_code_5: '79130',
    },
    {
      code: '343',
      key: '238',
      name: 'חמדיה',
      post_code: '1085500',
      post_code_5: '10855',
    },
    {
      code: '344',
      key: '239',
      name: 'מענית',
      post_code: '3785500',
      post_code_5: '37855',
    },
    {
      code: '345',
      key: '240',
      name: 'כפר סאלד',
      post_code: '1223000',
      post_code_5: '12230',
    },
    {
      code: '346',
      key: '241',
      name: 'גליל ים',
      post_code: '4690500',
      post_code_5: '46905',
    },
    {
      code: '347',
      key: '242',
      name: 'מנרה',
      post_code: '1216500',
      post_code_5: '12165',
    },
    {
      code: '348',
      key: '243',
      name: 'ניר עם',
      post_code: '7915500',
      post_code_5: '79155',
    },
    {
      code: '351',
      key: '244',
      name: 'ניצן',
      post_code: '7928700',
      post_code_5: '79287',
    },
    {
      code: '352',
      key: '245',
      name: 'גבולות',
      post_code: '8552500',
      post_code_5: '85525',
    },
    {
      code: '353',
      key: '246',
      name: 'בית זיד',
      post_code: '3600200',
      post_code_5: '36002',
    },
    {
      code: '354',
      key: '247',
      name: 'רביבים',
      post_code: '8551500',
      post_code_5: '85515',
    },
    {
      code: '355',
      key: '248',
      name: 'חורשים',
      post_code: '4586500',
      post_code_5: '45865',
    },
    {
      code: '356',
      key: '249',
      name: 'הגושרים',
      post_code: '1222500',
      post_code_5: '12225',
    },
    {
      code: '357',
      key: '250',
      name: 'כפר בלום',
      post_code: '1215000',
      post_code_5: '12150',
    },
    {
      code: '358',
      key: '251',
      name: 'יד מרדכי',
      post_code: '7914500',
      post_code_5: '79145',
    },
    {
      code: '359',
      key: '252',
      name: 'ניצנים',
      post_code: '7929000',
      post_code_5: '79290',
    },
    {
      code: '360',
      key: '253',
      name: 'גבעת ניל"י',
      post_code: '3782500',
      post_code_5: '37825',
    },
    {
      code: '362',
      key: '254',
      name: 'רוחמה',
      post_code: '7918000',
      post_code_5: '79180',
    },
    {
      code: '363',
      key: '255',
      name: 'חפץ חיים',
      post_code: '7681700',
      post_code_5: '76817',
    },
    {
      code: '364',
      key: '256',
      name: 'כפר אוריה',
      post_code: '9973500',
      post_code_5: '99735',
    },
    {
      code: '365',
      key: '257',
      name: 'בית קשת',
      post_code: '1524700',
      post_code_5: '15247',
    },
    {
      code: '366',
      key: '258',
      name: 'שמיר',
      post_code: '1213500',
      post_code_5: '12135',
    },
    {
      code: '367',
      key: '259',
      name: 'עין העמק',
      post_code: '1925000',
      post_code_5: '19250',
    },
    {
      code: '368',
      key: '260',
      name: 'ביריה',
      post_code: '1380500',
      post_code_5: '13805',
    },
    {
      code: '369',
      key: '261',
      name: 'גלעד (אבן יצחק)',
      post_code: '1924000',
      post_code_5: '19240',
    },
    {
      code: '370',
      key: '262',
      name: 'גזר',
      post_code: '9978600',
      post_code_5: '99786',
    },
    {
      code: '371',
      key: '263',
      name: 'כדורי',
      post_code: '1524200',
      post_code_5: '15242',
    },
    {
      code: '372',
      key: '264',
      name: 'רמות נפתלי',
      post_code: '1383000',
      post_code_5: '13830',
    },
    {
      code: '373',
      key: '265',
      name: 'בית הלוי',
      post_code: '4287000',
      post_code_5: '42870',
    },
    {
      code: '374',
      key: '266',
      name: 'חוקוק',
      post_code: '1292300',
      post_code_5: '12923',
    },
    {
      code: '375',
      key: '267',
      name: 'מגל',
      post_code: '3884500',
      post_code_5: '38845',
    },
    {
      code: '376',
      key: '268',
      name: 'עברון',
      post_code: '2280800',
      post_code_5: '22808',
    },
    {
      code: '377',
      key: '269',
      name: 'המעפיל',
      post_code: '3885700',
      post_code_5: '38857',
    },
    {
      code: '378',
      key: '270',
      name: 'משגב עם',
      post_code: '1215500',
      post_code_5: '12155',
    },
    {
      code: '379',
      key: '271',
      name: 'גאולים',
      post_code: '4282000',
      post_code_5: '42820',
    },
    {
      code: '380',
      key: '272',
      name: 'להבות הבשן',
      post_code: '1212500',
      post_code_5: '12125',
    },
    {
      code: '382',
      key: '273',
      name: 'מכמורת',
      post_code: '4029700',
      post_code_5: '40297',
    },
    {
      code: '383',
      key: '274',
      name: 'עין הנצי"ב',
      post_code: '1080500',
      post_code_5: '10805',
    },
    {
      code: '385',
      key: '275',
      name: 'עמיעד',
      post_code: '1233500',
      post_code_5: '12335',
    },
    {
      code: '386',
      key: '276',
      name: 'בני דרור',
      post_code: '4581500',
      post_code_5: '45815',
    },
    {
      code: '387',
      key: '277',
      name: 'כפר מונש',
      post_code: '4287500',
      post_code_5: '42875',
    },
    {
      code: '388',
      key: '278',
      name: 'כפר קיש',
      post_code: '1933000',
      post_code_5: '19330',
    },
    {
      code: '389',
      key: '279',
      name: 'בצרה',
      post_code: '6094400',
      post_code_5: '60944',
    },
    {
      code: '390',
      key: '280',
      name: 'רגבה',
      post_code: '2280400',
      post_code_5: '22804',
    },
    {
      code: '392',
      key: '281',
      name: 'קדמה',
      post_code: '7986200',
      post_code_5: '79862',
    },
    {
      code: '393',
      key: '282',
      name: 'גלאון',
      post_code: '7955500',
      post_code_5: '79555',
    },
    {
      code: '394',
      key: '283',
      name: 'שובל',
      post_code: '8532000',
      post_code_5: '85320',
    },
    {
      code: '395',
      key: '284',
      name: 'משמר הנגב',
      post_code: '8531500',
      post_code_5: '85315',
    },
    {
      code: '396',
      key: '285',
      name: 'נבטים',
      post_code: '8554000',
      post_code_5: '85540',
    },
    {
      code: '397',
      key: '286',
      name: 'חצרים',
      post_code: '8542000',
      post_code_5: '85420',
    },
    {
      code: '398',
      key: '287',
      name: 'שרשרת',
      post_code: '8539100',
      post_code_5: '85391',
    },
    {
      code: '399',
      key: '288',
      name: 'בארי',
      post_code: '8513500',
      post_code_5: '85135',
    },
    {
      code: '400',
      key: '289',
      name: 'אבן שמואל',
      post_code: '7958500',
      post_code_5: '79585',
    },
    {
      code: '402',
      key: '290',
      name: 'ניר יצחק',
      post_code: '8545500',
      post_code_5: '85455',
    },
    {
      code: '403',
      key: '291',
      name: 'אורים',
      post_code: '8553000',
      post_code_5: '85530',
    },
    {
      code: '405',
      key: '292',
      name: 'נווה אילן',
      post_code: '9085000',
      post_code_5: '90850',
    },
    {
      code: '406',
      key: '293',
      name: 'חצור-אשדוד',
      post_code: '6097000',
      post_code_5: '60970',
    },
    {
      code: '407',
      key: '294',
      name: 'דברת',
      post_code: '1932500',
      post_code_5: '19325',
    },
    {
      code: '408',
      key: '295',
      name: 'נאות מרדכי',
      post_code: '1212000',
      post_code_5: '12120',
    },
    {
      code: '409',
      key: '296',
      name: 'יחיעם',
      post_code: '2512500',
      post_code_5: '25125',
    },
    {
      code: '412',
      key: '297',
      name: 'קרית שלמה',
      post_code: '6091500',
      post_code_5: '60915',
    },
    {
      code: '413',
      key: '298',
      name: 'צאלים',
      post_code: '8552000',
      post_code_5: '85520',
    },
    {
      code: '414',
      key: '299',
      name: 'קלחים',
      post_code: '8535000',
      post_code_5: '85350',
    },
    {
      code: '415',
      key: '300',
      name: 'שוקדה',
      post_code: '8515500',
      post_code_5: '85155',
    },
    {
      code: '416',
      key: '301',
      name: 'מעין ברוך',
      post_code: '1222000',
      post_code_5: '12220',
    },
    {
      code: '417',
      key: '302',
      name: 'יקום',
      post_code: '6097200',
      post_code_5: '60972',
    },
    {
      code: '418',
      key: '303',
      name: 'בני ציון',
      post_code: '6091000',
      post_code_5: '60910',
    },
    {
      code: '419',
      key: '304',
      name: 'סעד',
      post_code: '8514000',
      post_code_5: '85140',
    },
    {
      code: '421',
      key: '305',
      name: 'משאבי שדה',
      post_code: '8551000',
      post_code_5: '85510',
    },
    {
      code: '422',
      key: '306',
      name: 'חרב לאת',
      post_code: '3886000',
      post_code_5: '38860',
    },
    {
      code: '423',
      key: '307',
      name: 'העוגן',
      post_code: '4288000',
      post_code_5: '42880',
    },
    {
      code: '424',
      key: '308',
      name: 'גבים',
      post_code: '7916500',
      post_code_5: '79165',
    },
    {
      code: '425',
      key: '309',
      name: 'משמרת',
      post_code: '4069500',
      post_code_5: '40695',
    },
    {
      code: '426',
      key: '310',
      name: 'עין כרמל',
      post_code: '3086000',
      post_code_5: '30860',
    },
    {
      code: '427',
      key: '311',
      name: 'כפר גלים',
      post_code: '3086500',
      post_code_5: '30865',
    },
    {
      code: '428',
      key: '312',
      name: 'ברור חיל',
      post_code: '7915200',
      post_code_5: '79152',
    },
    {
      code: '429',
      key: '313',
      name: 'אלוני אבא',
      post_code: '3600500',
      post_code_5: '36005',
    },
    {
      code: '430',
      key: '314',
      name: 'בית לחם הגלילית',
      post_code: '3600700',
      post_code_5: '36007',
    },
    {
      code: '431',
      key: '315',
      name: 'דלתון',
      post_code: '1381000',
      post_code_5: '13810',
    },
    {
      code: '432',
      key: '316',
      name: 'שמרת',
      post_code: '2521800',
      post_code_5: '25218',
    },
    {
      code: '433',
      key: '317',
      name: 'נחשולים',
      post_code: '3081500',
      post_code_5: '30815',
    },
    {
      code: '434',
      key: '318',
      name: 'החותרים',
      post_code: '3087000',
      post_code_5: '30870',
    },
    {
      code: '435',
      key: '319',
      name: 'נצר סרני',
      post_code: '7039500',
      post_code_5: '70395',
    },
    {
      code: '436',
      key: '320',
      name: 'עין דור',
      post_code: '1933500',
      post_code_5: '19335',
    },
    {
      code: '437',
      key: '321',
      name: 'רשפים',
      post_code: '1090500',
      post_code_5: '10905',
    },
    {
      code: '439',
      key: '322',
      name: 'שלוחות',
      post_code: '1091000',
      post_code_5: '10910',
    },
    {
      code: '440',
      key: '323',
      name: 'יסודות',
      post_code: '7681000',
      post_code_5: '76810',
    },
    {
      code: '442',
      key: '324',
      name: 'גדעונה',
      post_code: '1912000',
      post_code_5: '19120',
    },
    {
      code: '443',
      key: '325',
      name: 'כפר הנשיא',
      post_code: '1230500',
      post_code_5: '12305',
    },
    {
      code: '444',
      key: '326',
      name: 'רגבים',
      post_code: '3782000',
      post_code_5: '37820',
    },
    {
      code: '445',
      key: '327',
      name: 'רמות מנשה',
      post_code: '1924500',
      post_code_5: '19245',
    },
    {
      code: '446',
      key: '328',
      name: 'אודים',
      post_code: '4290500',
      post_code_5: '42905',
    },
    {
      code: '447',
      key: '329',
      name: 'נורדיה',
      post_code: '4295400',
      post_code_5: '42954',
    },
    {
      code: '448',
      key: '330',
      name: 'בני עטרות',
      post_code: '6099100',
      post_code_5: '60991',
    },
    {
      code: '449',
      key: '331',
      name: 'נחלים',
      post_code: '4995000',
      post_code_5: '49950',
    },
    {
      code: '450',
      key: '332',
      name: 'בארות יצחק',
      post_code: '6090500',
      post_code_5: '60905',
    },
    {
      code: '452',
      key: '333',
      name: 'יזרעאל',
      post_code: '1935000',
      post_code_5: '19350',
    },
    {
      code: '453',
      key: '334',
      name: 'יפתח',
      post_code: '1384000',
      post_code_5: '13840',
    },
    {
      code: '454',
      key: '335',
      name: 'סער',
      post_code: '2280500',
      post_code_5: '22805',
    },
    {
      code: '456',
      key: '336',
      name: 'שורש',
      post_code: '9086000',
      post_code_5: '90860',
    },
    {
      code: '457',
      key: '337',
      name: 'גזית',
      post_code: '1934000',
      post_code_5: '19340',
    },
    {
      code: '460',
      key: '338',
      name: 'רמת רזיאל',
      post_code: '9097400',
      post_code_5: '90974',
    },
    {
      code: '462',
      key: '339',
      name: 'טל שחר',
      post_code: '7680500',
      post_code_5: '76805',
    },
    {
      code: '463',
      key: '340',
      name: 'געתון',
      post_code: '2513000',
      post_code_5: '25130',
    },
    {
      code: '464',
      key: '341',
      name: 'הראל',
      post_code: '9974000',
      post_code_5: '99740',
    },
    {
      code: '465',
      key: '342',
      name: 'צובה',
      post_code: '9087000',
      post_code_5: '90870',
    },
    {
      code: '466',
      key: '343',
      name: 'בית דגן',
      post_code: '5020000',
      post_code_5: '50200',
    },
    {
      code: '469',
      key: '344',
      name: 'קריית עקרון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '472',
      key: '345',
      name: 'אבו גוש',
      post_code: '9084500',
      post_code_5: '90845',
    },
    {
      code: '473',
      key: '346',
      name: 'אבו סנאן',
      post_code: '2490500',
      post_code_5: '24905',
    },
    {
      code: '475',
      key: '347',
      name: 'דחי',
      post_code: '1880000',
      post_code_5: '18800',
    },
    {
      code: '478',
      key: '348',
      name: 'אכסאל',
      post_code: '1692000',
      post_code_5: '16920',
    },
    {
      code: '480',
      key: '349',
      name: 'בית ג\'ן',
      post_code: '2499000',
      post_code_5: '24990',
    },
    {
      code: '481',
      key: '350',
      name: 'מגאר',
      post_code: '2012800',
      post_code_5: '20128',
    },
    {
      code: '482',
      key: '351',
      name: 'בועיינה-נוג\'ידאת',
      post_code: '1692400',
      post_code_5: '16924',
    },
    {
      code: '485',
      key: '352',
      name: 'ג\'ולס',
      post_code: '2498000',
      post_code_5: '24980',
    },
    {
      code: '487',
      key: '353',
      name: 'ג\'ש (גוש חלב)',
      post_code: '1387200',
      post_code_5: '13872',
    },
    {
      code: '489',
      key: '354',
      name: 'דבוריה',
      post_code: '1691000',
      post_code_5: '16910',
    },
    {
      code: '492',
      key: '355',
      name: 'דייר חנא',
      post_code: '2497300',
      post_code_5: '24973',
    },
    {
      code: '493',
      key: '356',
      name: 'דייר ראפאת',
      post_code: '9988900',
      post_code_5: '99889',
    },
    {
      code: '496',
      key: '358',
      name: 'חורפיש',
      post_code: '2515500',
      post_code_5: '25155',
    },
    {
      code: '497',
      key: '359',
      name: 'טייבה (בעמק)',
      post_code: '1911800',
      post_code_5: '19118',
    },
    {
      code: '498',
      key: '360',
      name: 'טורעאן',
      post_code: '1695000',
      post_code_5: '16950',
    },
    {
      code: '499',
      key: '361',
      name: 'יפיע',
      post_code: '1695500',
      post_code_5: '16955',
    },
    {
      code: '502',
      key: '362',
      name: 'ירכא',
      post_code: '2496700',
      post_code_5: '24967',
    },
    {
      code: '504',
      key: '363',
      name: 'כאבול',
      post_code: '2496300',
      post_code_5: '24963',
    },
    {
      code: '505',
      key: '364',
      name: 'כאוכב אבו אל-היג\'א',
      post_code: '2018500',
      post_code_5: '20185',
    },
    {
      code: '507',
      key: '365',
      name: 'כפר יאסיף',
      post_code: '2490800',
      post_code_5: '24908',
    },
    {
      code: '508',
      key: '366',
      name: 'כפר כמא',
      post_code: '1523500',
      post_code_5: '15235',
    },
    {
      code: '509',
      key: '367',
      name: 'כפר כנא',
      post_code: '1693000',
      post_code_5: '16930',
    },
    {
      code: '510',
      key: '368',
      name: 'כפר מנדא',
      post_code: '1790700',
      post_code_5: '17907',
    },
    {
      code: '511',
      key: '369',
      name: 'עילוט',
      post_code: '1697000',
      post_code_5: '16970',
    },
    {
      code: '512',
      key: '370',
      name: 'כפר מצר',
      post_code: '1934500',
      post_code_5: '19345',
    },
    {
      code: '514',
      key: '371',
      name: 'עין ראפה',
      post_code: '9089000',
      post_code_5: '90890',
    },
    {
      code: '516',
      key: '372',
      name: 'מג\'ד אל כרום',
      post_code: '2019000',
      post_code_5: '20190',
    },
    {
      code: '517',
      key: '373',
      name: 'מזרעה',
      post_code: '2280700',
      post_code_5: '22807',
    },
    {
      code: '518',
      key: '374',
      name: 'מעיליא',
      post_code: '2514000',
      post_code_5: '25140',
    },
    {
      code: '520',
      key: '375',
      name: 'משהד',
      post_code: '1696700',
      post_code_5: '16967',
    },
    {
      code: '521',
      key: '376',
      name: 'עין נקובא',
      post_code: '9084700',
      post_code_5: '90847',
    },
    {
      code: '522',
      key: '377',
      name: 'נחף',
      post_code: '2013700',
      post_code_5: '20137',
    },
    {
      code: '523',
      key: '378',
      name: 'ניין',
      post_code: '1932000',
      post_code_5: '19320',
    },
    {
      code: '524',
      key: '379',
      name: 'נאעורה',
      post_code: '1895000',
      post_code_5: '18950',
    },
    {
      code: '525',
      key: '380',
      name: 'סאג\'ור',
      post_code: '2013000',
      post_code_5: '20130',
    },
    {
      code: '526',
      key: '381',
      name: 'סולם',
      post_code: '1911500',
      post_code_5: '19115',
    },
    {
      code: '527',
      key: '382',
      name: 'שזור',
      post_code: '2013500',
      post_code_5: '20135',
    },
    {
      code: '528',
      key: '383',
      name: 'עוזייר',
      post_code: '1790300',
      post_code_5: '17903',
    },
    {
      code: '529',
      key: '384',
      name: 'אעבלין',
      post_code: '3001200',
      post_code_5: '30012',
    },
    {
      code: '530',
      key: '385',
      name: 'עיילבון',
      post_code: '1697200',
      post_code_5: '16972',
    },
    {
      code: '531',
      key: '386',
      name: 'עראבה',
      post_code: '3081200',
      post_code_5: '30812',
    },
    {
      code: '532',
      key: '387',
      name: 'עין מאהל',
      post_code: '1790200',
      post_code_5: '17902',
    },
    {
      code: '535',
      key: '388',
      name: 'פסוטה',
      post_code: '2517000',
      post_code_5: '25170',
    },
    {
      code: '536',
      key: '389',
      name: 'פקיעין (בוקייעה)',
      post_code: '2491400',
      post_code_5: '24914',
    },
    {
      code: '537',
      key: '390',
      name: 'פוריידיס',
      post_code: '3089800',
      post_code_5: '30898',
    },
    {
      code: '538',
      key: '391',
      name: 'שעב',
      post_code: '2016500',
      post_code_5: '20165',
    },
    {
      code: '539',
      key: '392',
      name: 'רומאנה',
      post_code: '1790400',
      post_code_5: '17904',
    },
    {
      code: '540',
      key: '393',
      name: 'ריחאניה',
      post_code: '1381800',
      post_code_5: '13818',
    },
    {
      code: '541',
      key: '394',
      name: 'ג\'סר א-זרקא',
      post_code: '3785800',
      post_code_5: '37858',
    },
    {
      code: '542',
      key: '395',
      name: 'ריינה',
      post_code: '1694000',
      post_code_5: '16940',
    },
    {
      code: '543',
      key: '396',
      name: 'ראמה',
      post_code: '3005500',
      post_code_5: '30055',
    },
    {
      code: '546',
      key: '397',
      name: 'עין אל-אסד',
      post_code: '2012700',
      post_code_5: '20127',
    },
    {
      code: '547',
      key: '398',
      name: 'טמרה (יזרעאל)',
      post_code: '1932800',
      post_code_5: '19328',
    },
    {
      code: '549',
      key: '399',
      name: 'גנות הדר',
      post_code: '4293000',
      post_code_5: '42930',
    },
    {
      code: '553',
      key: '400',
      name: 'ניר בנים',
      post_code: '7952500',
      post_code_5: '79525',
    },
    {
      code: '555',
      key: '401',
      name: 'שדמה',
      post_code: '7685500',
      post_code_5: '76855',
    },
    {
      code: '559',
      key: '402',
      name: 'בוסתן הגליל',
      post_code: '2521300',
      post_code_5: '25213',
    },
    {
      code: '562',
      key: '403',
      name: 'בית אלעזרי',
      post_code: '7680300',
      post_code_5: '76803',
    },
    {
      code: '563',
      key: '404',
      name: 'משמר דוד',
      post_code: '7684100',
      post_code_5: '76841',
    },
    {
      code: '564',
      key: '405',
      name: 'רבדים',
      post_code: '7982000',
      post_code_5: '79820',
    },
    {
      code: '565',
      key: '406',
      name: 'אזור',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '566',
      key: '407',
      name: 'גבעת שמש',
      post_code: '9970800',
      post_code_5: '99708',
    },
    {
      code: '567',
      key: '408',
      name: 'צרעה',
      post_code: '9980300',
      post_code_5: '99803',
    },
    {
      code: '570',
      key: '409',
      name: 'מעונה',
      post_code: '2492000',
      post_code_5: '24920',
    },
    {
      code: '571',
      key: '410',
      name: 'בית גמליאל',
      post_code: '7688000',
      post_code_5: '76880',
    },
    {
      code: '572',
      key: '411',
      name: 'בית העמק',
      post_code: '2511500',
      post_code_5: '25115',
    },
    {
      code: '573',
      key: '412',
      name: 'מבקיעים',
      post_code: '7912500',
      post_code_5: '79125',
    },
    {
      code: '574',
      key: '413',
      name: 'גשר הזיו',
      post_code: '2281500',
      post_code_5: '22815',
    },
    {
      code: '575',
      key: '414',
      name: 'יסעור',
      post_code: '2015000',
      post_code_5: '20150',
    },
    {
      code: '576',
      key: '415',
      name: 'כברי',
      post_code: '2512000',
      post_code_5: '25120',
    },
    {
      code: '577',
      key: '416',
      name: 'יד בנימין',
      post_code: '7681200',
      post_code_5: '76812',
    },
    {
      code: '578',
      key: '417',
      name: 'סאסא',
      post_code: '1387000',
      post_code_5: '13870',
    },
    {
      code: '579',
      key: '418',
      name: 'כפר ראש הנקרה',
      post_code: '2282500',
      post_code_5: '22825',
    },
    {
      code: '580',
      key: '419',
      name: 'כרם מהר"ל',
      post_code: '3084000',
      post_code_5: '30840',
    },
    {
      code: '582',
      key: '420',
      name: 'כפר הנגיד',
      post_code: '7687500',
      post_code_5: '76875',
    },
    {
      code: '584',
      key: '421',
      name: 'זיקים',
      post_code: '7914000',
      post_code_5: '79140',
    },
    {
      code: '585',
      key: '422',
      name: 'לביא',
      post_code: '1526700',
      post_code_5: '15267',
    },
    {
      code: '586',
      key: '423',
      name: 'מגידו',
      post_code: '1923000',
      post_code_5: '19230',
    },
    {
      code: '587',
      key: '424',
      name: 'סביון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '588',
      key: '425',
      name: 'בני ראם',
      post_code: '7984000',
      post_code_5: '79840',
    },
    {
      code: '589',
      key: '426',
      name: 'בצת',
      post_code: '2283000',
      post_code_5: '22830',
    },
    {
      code: '590',
      key: '427',
      name: 'נווה אור',
      post_code: '1087500',
      post_code_5: '10875',
    },
    {
      code: '591',
      key: '428',
      name: 'עשרת',
      post_code: '7685800',
      post_code_5: '76858',
    },
    {
      code: '592',
      key: '429',
      name: 'בני דרום',
      post_code: '7925000',
      post_code_5: '79250',
    },
    {
      code: '593',
      key: '430',
      name: 'ערוגות',
      post_code: '7986400',
      post_code_5: '79864',
    },
    {
      code: '594',
      key: '431',
      name: 'צפריה',
      post_code: '6093200',
      post_code_5: '60932',
    },
    {
      code: '595',
      key: '432',
      name: 'לוחמי הגיטאות',
      post_code: '2280300',
      post_code_5: '22803',
    },
    {
      code: '596',
      key: '433',
      name: 'מלכיה',
      post_code: '1384500',
      post_code_5: '13845',
    },
    {
      code: '597',
      key: '434',
      name: 'פלמחים',
      post_code: '7689000',
      post_code_5: '76890',
    },
    {
      code: '598',
      key: '435',
      name: 'בית קמה',
      post_code: '8532500',
      post_code_5: '85325',
    },
    {
      code: '599',
      key: '436',
      name: 'פרוד',
      post_code: '2011000',
      post_code_5: '20110',
    },
    {
      code: '602',
      key: '437',
      name: 'נירים',
      post_code: '8512500',
      post_code_5: '85125',
    },
    {
      code: '603',
      key: '438',
      name: 'אלקוש',
      post_code: '1380400',
      post_code_5: '13804',
    },
    {
      code: '604',
      key: '439',
      name: 'בית עריף',
      post_code: '7314500',
      post_code_5: '73145',
    },
    {
      code: '605',
      key: '440',
      name: 'כפר שמאי',
      post_code: '2012500',
      post_code_5: '20125',
    },
    {
      code: '606',
      key: '441',
      name: 'מזור',
      post_code: '7316000',
      post_code_5: '73160',
    },
    {
      code: '607',
      key: '442',
      name: 'מירון',
      post_code: '1391000',
      post_code_5: '13910',
    },
    {
      code: '609',
      key: '443',
      name: 'כפר חושן',
      post_code: '1387500',
      post_code_5: '13875',
    },
    {
      code: '610',
      key: '444',
      name: 'סתריה',
      post_code: '7683400',
      post_code_5: '76834',
    },
    {
      code: '612',
      key: '445',
      name: 'צרופה',
      post_code: '3085000',
      post_code_5: '30850',
    },
    {
      code: '613',
      key: '446',
      name: 'ציפורי',
      post_code: '1791000',
      post_code_5: '17910',
    },
    {
      code: '614',
      key: '447',
      name: 'שומרה',
      post_code: '2285500',
      post_code_5: '22855',
    },
    {
      code: '615',
      key: '448',
      name: 'קדרון',
      post_code: '7079500',
      post_code_5: '70795',
    },
    {
      code: '616',
      key: '449',
      name: 'רינתיה',
      post_code: '7316500',
      post_code_5: '73165',
    },
    {
      code: '617',
      key: '450',
      name: 'ברקאי',
      post_code: '3786000',
      post_code_5: '37860',
    },
    {
      code: '618',
      key: '451',
      name: 'חדיד',
      post_code: '7313500',
      post_code_5: '73135',
    },
    {
      code: '619',
      key: '452',
      name: 'בית גוברין',
      post_code: '7937000',
      post_code_5: '79370',
    },
    {
      code: '620',
      key: '453',
      name: 'משואות יצחק',
      post_code: '7985800',
      post_code_5: '79858',
    },
    {
      code: '622',
      key: '454',
      name: 'עין צורים',
      post_code: '7951000',
      post_code_5: '79510',
    },
    {
      code: '623',
      key: '455',
      name: 'יראון',
      post_code: '1385500',
      post_code_5: '13855',
    },
    {
      code: '627',
      key: '456',
      name: 'ג\'לג\'וליה',
      post_code: '4585000',
      post_code_5: '45850',
    },
    {
      code: '633',
      key: '457',
      name: 'כפר ברא',
      post_code: '4586300',
      post_code_5: '45863',
    },
    {
      code: '634',
      key: '458',
      name: 'כפר קאסם',
      post_code: '4881000',
      post_code_5: '48810',
    },
    {
      code: '635',
      key: '459',
      name: 'מוקייבלה',
      post_code: '1935400',
      post_code_5: '19354',
    },
    {
      code: '636',
      key: '460',
      name: 'צנדלה',
      post_code: '1935300',
      post_code_5: '19353',
    },
    {
      code: '637',
      key: '461',
      name: 'ערערה',
      post_code: '3002600',
      post_code_5: '30026',
    },
    {
      code: '638',
      key: '462',
      name: 'קלנסווה',
      post_code: '4064000',
      post_code_5: '40640',
    },
    {
      code: '648',
      key: '463',
      name: 'מצר',
      post_code: '3882000',
      post_code_5: '38820',
    },
    {
      code: '649',
      key: '464',
      name: 'מייסר',
      post_code: '3881500',
      post_code_5: '38815',
    },
    {
      code: '652',
      key: '465',
      name: 'אבטין',
      post_code: '3659200',
      post_code_5: '36592',
    },
    {
      code: '654',
      key: '466',
      name: 'כפר קרע',
      post_code: '3007500',
      post_code_5: '30075',
    },
    {
      code: '658',
      key: '467',
      name: 'שייח\' דנון',
      post_code: '2524800',
      post_code_5: '25248',
    },
    {
      code: '661',
      key: '468',
      name: 'שער אפרים',
      post_code: '4283500',
      post_code_5: '42835',
    },
    {
      code: '662',
      key: '469',
      name: 'חוסן',
      post_code: '2518000',
      post_code_5: '25180',
    },
    {
      code: '663',
      key: '470',
      name: 'טירת יהודה',
      post_code: '7317500',
      post_code_5: '73175',
    },
    {
      code: '664',
      key: '471',
      name: 'כרם בן זמרה',
      post_code: '1381500',
      post_code_5: '13815',
    },
    {
      code: '665',
      key: '472',
      name: 'תקומה',
      post_code: '8515900',
      post_code_5: '85159',
    },
    {
      code: '666',
      key: '473',
      name: 'עומר',
      post_code: '8496500',
      post_code_5: '84965',
    },
    {
      code: '667',
      key: '474',
      name: 'ברעם',
      post_code: '1386000',
      post_code_5: '13860',
    },
    {
      code: '668',
      key: '475',
      name: 'מפלסים',
      post_code: '7916000',
      post_code_5: '79160',
    },
    {
      code: '670',
      key: '476',
      name: 'משמר איילון',
      post_code: '9978200',
      post_code_5: '99782',
    },
    {
      code: '672',
      key: '477',
      name: 'בית נקופה',
      post_code: '9083000',
      post_code_5: '90830',
    },
    {
      code: '673',
      key: '478',
      name: 'כפר טרומן',
      post_code: '7315000',
      post_code_5: '73150',
    },
    {
      code: '674',
      key: '479',
      name: 'לימן',
      post_code: '2282000',
      post_code_5: '22820',
    },
    {
      code: '675',
      key: '480',
      name: 'הבונים',
      post_code: '3084500',
      post_code_5: '30845',
    },
    {
      code: '676',
      key: '481',
      name: 'עין השלושה',
      post_code: '8512800',
      post_code_5: '85128',
    },
    {
      code: '677',
      key: '482',
      name: 'הסוללים',
      post_code: '1790500',
      post_code_5: '17905',
    },
    {
      code: '678',
      key: '483',
      name: 'מעגן',
      post_code: '1516000',
      post_code_5: '15160',
    },
    {
      code: '679',
      key: '484',
      name: 'אביאל',
      post_code: '3783500',
      post_code_5: '37835',
    },
    {
      code: '680',
      key: '485',
      name: 'אומץ',
      post_code: '3887000',
      post_code_5: '38870',
    },
    {
      code: '681',
      key: '486',
      name: 'גבעת שמואל',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '682',
      key: '487',
      name: 'אליקים',
      post_code: '1925500',
      post_code_5: '19255',
    },
    {
      code: '683',
      key: '488',
      name: 'גבע כרמל',
      post_code: '3085500',
      post_code_5: '30855',
    },
    {
      code: '684',
      key: '489',
      name: 'היוגב',
      post_code: '1923200',
      post_code_5: '19232',
    },
    {
      code: '685',
      key: '490',
      name: 'בניה',
      post_code: '7920500',
      post_code_5: '79205',
    },
    {
      code: '686',
      key: '491',
      name: 'נווה ימין',
      post_code: '4492000',
      post_code_5: '44920',
    },
    {
      code: '687',
      key: '492',
      name: 'עין איילה',
      post_code: '3082500',
      post_code_5: '30825',
    },
    {
      code: '688',
      key: '493',
      name: 'עלמה',
      post_code: '1382000',
      post_code_5: '13820',
    },
    {
      code: '689',
      key: '494',
      name: 'מגדים',
      post_code: '3087500',
      post_code_5: '30875',
    },
    {
      code: '690',
      key: '495',
      name: 'כפר אחים',
      post_code: '7980500',
      post_code_5: '79805',
    },
    {
      code: '692',
      key: '496',
      name: 'שפיר',
      post_code: '7951500',
      post_code_5: '79515',
    },
    {
      code: '693',
      key: '497',
      name: 'נתיב הל"ה',
      post_code: '9985500',
      post_code_5: '99855',
    },
    {
      code: '694',
      key: '498',
      name: 'מעגן מיכאל',
      post_code: '3780500',
      post_code_5: '37805',
    },
    {
      code: '695',
      key: '499',
      name: 'מגן',
      post_code: '8546500',
      post_code_5: '85465',
    },
    {
      code: '696',
      key: '500',
      name: 'כפר חב"ד',
      post_code: '6084000',
      post_code_5: '60840',
    },
    {
      code: '697',
      key: '501',
      name: 'בארותיים',
      post_code: '4285000',
      post_code_5: '42850',
    },
    {
      code: '698',
      key: '502',
      name: 'בורגתה',
      post_code: '4286000',
      post_code_5: '42860',
    },
    {
      code: '699',
      key: '503',
      name: 'ניר ישראל',
      post_code: '7950500',
      post_code_5: '79505',
    },
    {
      code: '700',
      key: '504',
      name: 'חצב',
      post_code: '7984200',
      post_code_5: '79842',
    },
    {
      code: '701',
      key: '505',
      name: 'ארבל',
      post_code: '1528200',
      post_code_5: '15282',
    },
    {
      code: '702',
      key: '506',
      name: 'האון',
      post_code: '1517000',
      post_code_5: '15170',
    },
    {
      code: '703',
      key: '507',
      name: 'גבעת עוז',
      post_code: '1922500',
      post_code_5: '19225',
    },
    {
      code: '705',
      key: '508',
      name: 'נחשונים',
      post_code: '7319000',
      post_code_5: '73190',
    },
    {
      code: '706',
      key: '509',
      name: 'גיאה',
      post_code: '7911000',
      post_code_5: '79110',
    },
    {
      code: '707',
      key: '510',
      name: 'כפר דניאל',
      post_code: '7312500',
      post_code_5: '73125',
    },
    {
      code: '708',
      key: '511',
      name: 'עמקה',
      post_code: '2525300',
      post_code_5: '25253',
    },
    {
      code: '709',
      key: '512',
      name: 'תפרח',
      post_code: '8510200',
      post_code_5: '85102',
    },
    {
      code: '710',
      key: '513',
      name: 'בית זית',
      post_code: '9081500',
      post_code_5: '90815',
    },
    {
      code: '711',
      key: '514',
      name: 'עזריה',
      post_code: '9979200',
      post_code_5: '99792',
    },
    {
      code: '712',
      key: '515',
      name: 'בן עמי',
      post_code: '2280900',
      post_code_5: '22809',
    },
    {
      code: '713',
      key: '516',
      name: 'רעים',
      post_code: '8513200',
      post_code_5: '85132',
    },
    {
      code: '714',
      key: '517',
      name: 'ארז',
      post_code: '7915000',
      post_code_5: '79150',
    },
    {
      code: '715',
      key: '518',
      name: 'להבות חביבה',
      post_code: '3883500',
      post_code_5: '38835',
    },
    {
      code: '716',
      key: '519',
      name: 'אייל',
      post_code: '4584000',
      post_code_5: '45840',
    },
    {
      code: '717',
      key: '520',
      name: 'חגור',
      post_code: '4587000',
      post_code_5: '45870',
    },
    {
      code: '718',
      key: '521',
      name: 'ירחיב',
      post_code: '4586000',
      post_code_5: '45860',
    },
    {
      code: '719',
      key: '522',
      name: 'תל קציר',
      post_code: '1516500',
      post_code_5: '15165',
    },
    {
      code: '720',
      key: '523',
      name: 'ניר גלים',
      post_code: '7924500',
      post_code_5: '79245',
    },
    {
      code: '721',
      key: '524',
      name: 'שדה אילן',
      post_code: '1526000',
      post_code_5: '15260',
    },
    {
      code: '722',
      key: '525',
      name: 'מגשימים',
      post_code: '5691000',
      post_code_5: '56910',
    },
    {
      code: '723',
      key: '526',
      name: 'בית הגדי',
      post_code: '8538500',
      post_code_5: '85385',
    },
    {
      code: '726',
      key: '527',
      name: 'הודיה',
      post_code: '7985400',
      post_code_5: '79854',
    },
    {
      code: '727',
      key: '528',
      name: 'תלמי יחיאל',
      post_code: '7981000',
      post_code_5: '79810',
    },
    {
      code: '729',
      key: '529',
      name: 'משמר השבעה',
      post_code: '5029700',
      post_code_5: '50297',
    },
    {
      code: '730',
      key: '530',
      name: 'אליפלט',
      post_code: '1233000',
      post_code_5: '12330',
    },
    {
      code: '731',
      key: '531',
      name: 'מישר',
      post_code: '7685000',
      post_code_5: '76850',
    },
    {
      code: '732',
      key: '532',
      name: 'משמר הירדן',
      post_code: '1232000',
      post_code_5: '12320',
    },
    {
      code: '734',
      key: '533',
      name: 'גן יאשיה',
      post_code: '3885000',
      post_code_5: '38850',
    },
    {
      code: '735',
      key: '534',
      name: 'רמות מאיר',
      post_code: '7683200',
      post_code_5: '76832',
    },
    {
      code: '736',
      key: '535',
      name: 'גילת',
      post_code: '8510500',
      post_code_5: '85105',
    },
    {
      code: '737',
      key: '536',
      name: 'עולש',
      post_code: '4285500',
      post_code_5: '42855',
    },
    {
      code: '738',
      key: '537',
      name: 'דור',
      post_code: '3082000',
      post_code_5: '30820',
    },
    {
      code: '739',
      key: '538',
      name: 'שדה עוזיהו',
      post_code: '7926000',
      post_code_5: '79260',
    },
    {
      code: '740',
      key: '539',
      name: 'אשתאול',
      post_code: '9977500',
      post_code_5: '99775',
    },
    {
      code: '741',
      key: '540',
      name: 'שואבה',
      post_code: '9085500',
      post_code_5: '90855',
    },
    {
      code: '742',
      key: '541',
      name: 'מסילת ציון',
      post_code: '9977000',
      post_code_5: '99770',
    },
    {
      code: '743',
      key: '542',
      name: 'כפר שמואל',
      post_code: '9978800',
      post_code_5: '99788',
    },
    {
      code: '744',
      key: '543',
      name: 'תלמי יפה',
      post_code: '7912000',
      post_code_5: '79120',
    },
    {
      code: '745',
      key: '544',
      name: 'גמזו',
      post_code: '7313000',
      post_code_5: '73130',
    },
    {
      code: '746',
      key: '545',
      name: 'ברכיה',
      post_code: '7985200',
      post_code_5: '79852',
    },
    {
      code: '747',
      key: '546',
      name: 'בית שקמה',
      post_code: '7910500',
      post_code_5: '79105',
    },
    {
      code: '748',
      key: '547',
      name: 'מסלול',
      post_code: '8511200',
      post_code_5: '85112',
    },
    {
      code: '749',
      key: '548',
      name: 'פטיש',
      post_code: '8511500',
      post_code_5: '85115',
    },
    {
      code: '750',
      key: '549',
      name: 'פדויים',
      post_code: '8510800',
      post_code_5: '85108',
    },
    {
      code: '751',
      key: '550',
      name: 'בית מאיר',
      post_code: '9086500',
      post_code_5: '90865',
    },
    {
      code: '752',
      key: '551',
      name: 'תעוז',
      post_code: '9972500',
      post_code_5: '99725',
    },
    {
      code: '753',
      key: '552',
      name: 'ינוב',
      post_code: '4282500',
      post_code_5: '42825',
    },
    {
      code: '755',
      key: '553',
      name: 'גורן',
      post_code: '2285000',
      post_code_5: '22850',
    },
    {
      code: '756',
      key: '554',
      name: 'בית עזרא',
      post_code: '7928500',
      post_code_5: '79285',
    },
    {
      code: '757',
      key: '555',
      name: 'מצליח',
      post_code: '7683600',
      post_code_5: '76836',
    },
    {
      code: '758',
      key: '556',
      name: 'יד חנה',
      post_code: '4284000',
      post_code_5: '42840',
    },
    {
      code: '759',
      key: '557',
      name: 'יציץ',
      post_code: '7684000',
      post_code_5: '76840',
    },
    {
      code: '760',
      key: '558',
      name: 'בן זכאי',
      post_code: '7921000',
      post_code_5: '79210',
    },
    {
      code: '761',
      key: '559',
      name: 'שובה',
      post_code: '8514700',
      post_code_5: '85147',
    },
    {
      code: '762',
      key: '560',
      name: 'בטחה',
      post_code: '8510600',
      post_code_5: '85106',
    },
    {
      code: '763',
      key: '561',
      name: 'שתולים',
      post_code: '7928000',
      post_code_5: '79280',
    },
    {
      code: '764',
      key: '562',
      name: 'כפר מרדכי',
      post_code: '7685400',
      post_code_5: '76854',
    },
    {
      code: '765',
      key: '563',
      name: 'משגב דב',
      post_code: '7686700',
      post_code_5: '76867',
    },
    {
      code: '766',
      key: '564',
      name: 'קוממיות',
      post_code: '7954800',
      post_code_5: '79548',
    },
    {
      code: '767',
      key: '565',
      name: 'פורת',
      post_code: '4582000',
      post_code_5: '45820',
    },
    {
      code: '768',
      key: '566',
      name: 'כרמיה',
      post_code: '7913500',
      post_code_5: '79135',
    },
    {
      code: '769',
      key: '567',
      name: 'ניר עציון',
      post_code: '3080800',
      post_code_5: '30808',
    },
    {
      code: '771',
      key: '568',
      name: 'מבוא ביתר',
      post_code: '9987800',
      post_code_5: '99878',
    },
    {
      code: '772',
      key: '569',
      name: 'אמונים',
      post_code: '7926500',
      post_code_5: '79265',
    },
    {
      code: '773',
      key: '570',
      name: 'עמיקם',
      post_code: '3783000',
      post_code_5: '37830',
    },
    {
      code: '774',
      key: '571',
      name: 'צוריאל',
      post_code: '2515000',
      post_code_5: '25150',
    },
    {
      code: '775',
      key: '572',
      name: 'יד נתן',
      post_code: '7935200',
      post_code_5: '79352',
    },
    {
      code: '776',
      key: '573',
      name: 'מחסיה',
      post_code: '9988700',
      post_code_5: '99887',
    },
    {
      code: '777',
      key: '574',
      name: 'נחשון',
      post_code: '9976000',
      post_code_5: '99760',
    },
    {
      code: '778',
      key: '575',
      name: 'תרום',
      post_code: '9973000',
      post_code_5: '99730',
    },
    {
      code: '779',
      key: '576',
      name: 'עמינדב',
      post_code: '9088500',
      post_code_5: '90885',
    },
    {
      code: '780',
      key: '577',
      name: 'אורה',
      post_code: '9088000',
      post_code_5: '90880',
    },
    {
      code: '783',
      key: '578',
      name: 'אבן ספיר',
      post_code: '9087500',
      post_code_5: '90875',
    },
    {
      code: '784',
      key: '579',
      name: 'בית נחמיה',
      post_code: '7314000',
      post_code_5: '73140',
    },
    {
      code: '785',
      key: '580',
      name: 'אחיהוד',
      post_code: '2014500',
      post_code_5: '20145',
    },
    {
      code: '786',
      key: '581',
      name: 'כפר זיתים',
      post_code: '1529000',
      post_code_5: '15290',
    },
    {
      code: '787',
      key: '582',
      name: 'גבעת יערים',
      post_code: '9097000',
      post_code_5: '90970',
    },
    {
      code: '788',
      key: '583',
      name: 'זיתן',
      post_code: '7169800',
      post_code_5: '71698',
    },
    {
      code: '789',
      key: '584',
      name: 'רנן',
      post_code: '8511000',
      post_code_5: '85110',
    },
    {
      code: '791',
      key: '585',
      name: 'משען',
      post_code: '7930200',
      post_code_5: '79302',
    },
    {
      code: '792',
      key: '586',
      name: 'נתיב השיירה',
      post_code: '2524500',
      post_code_5: '25245',
    },
    {
      code: '793',
      key: '587',
      name: 'גבעתי',
      post_code: '7927000',
      post_code_5: '79270',
    },
    {
      code: '794',
      key: '588',
      name: 'עגור',
      post_code: '9984000',
      post_code_5: '99840',
    },
    {
      code: '795',
      key: '589',
      name: 'יערה',
      post_code: '2284000',
      post_code_5: '22840',
    },
    {
      code: '796',
      key: '590',
      name: 'צלפון',
      post_code: '9975000',
      post_code_5: '99750',
    },
    {
      code: '797',
      key: '591',
      name: 'אחיעזר',
      post_code: '7169700',
      post_code_5: '71697',
    },
    {
      code: '798',
      key: '592',
      name: 'יגל',
      post_code: '7169600',
      post_code_5: '71696',
    },
    {
      code: '799',
      key: '593',
      name: 'זכריה',
      post_code: '9980800',
      post_code_5: '99808',
    },
    {
      code: '800',
      key: '594',
      name: 'בית חנניה',
      post_code: '3780700',
      post_code_5: '37807',
    },
    {
      code: '801',
      key: '595',
      name: 'חמד',
      post_code: '5029500',
      post_code_5: '50295',
    },
    {
      code: '802',
      key: '596',
      name: 'גבעת כ"ח',
      post_code: '7318000',
      post_code_5: '73180',
    },
    {
      code: '803',
      key: '597',
      name: 'יושיביה',
      post_code: '8516100',
      post_code_5: '85161',
    },
    {
      code: '804',
      key: '598',
      name: 'אחיסמך',
      post_code: '7310500',
      post_code_5: '73105',
    },
    {
      code: '805',
      key: '599',
      name: 'ישעי',
      post_code: '9980500',
      post_code_5: '99805',
    },
    {
      code: '806',
      key: '600',
      name: 'עין יהב',
      post_code: '8682000',
      post_code_5: '86820',
    },
    {
      code: '807',
      key: '601',
      name: 'חניאל',
      post_code: '4286500',
      post_code_5: '42865',
    },
    {
      code: '808',
      key: '602',
      name: 'ניר אליהו',
      post_code: '4584500',
      post_code_5: '45845',
    },
    {
      code: '809',
      key: '603',
      name: 'נחם',
      post_code: '9980100',
      post_code_5: '99801',
    },
    {
      code: '810',
      key: '604',
      name: 'עופר',
      post_code: '3083500',
      post_code_5: '30835',
    },
    {
      code: '811',
      key: '605',
      name: 'יכיני',
      post_code: '7917000',
      post_code_5: '79170',
    },
    {
      code: '812',
      key: '606',
      name: 'שלומי',
      post_code: '2283200',
      post_code_5: '22832',
    },
    {
      code: '813',
      key: '607',
      name: 'עין יעקב',
      post_code: '2513500',
      post_code_5: '25135',
    },
    {
      code: '814',
      key: '608',
      name: 'תלמים',
      post_code: '7931500',
      post_code_5: '79315',
    },
    {
      code: '815',
      key: '609',
      name: 'זבדיאל',
      post_code: '7955000',
      post_code_5: '79550',
    },
    {
      code: '816',
      key: '610',
      name: 'זנוח',
      post_code: '9988800',
      post_code_5: '99888',
    },
    {
      code: '817',
      key: '611',
      name: 'עזריקם',
      post_code: '7927500',
      post_code_5: '79275',
    },
    {
      code: '818',
      key: '612',
      name: 'זרחיה',
      post_code: '7952000',
      post_code_5: '79520',
    },
    {
      code: '819',
      key: '613',
      name: 'אביגדור',
      post_code: '8380000',
      post_code_5: '83800',
    },
    {
      code: '820',
      key: '614',
      name: 'חלץ',
      post_code: '7931000',
      post_code_5: '79310',
    },
    {
      code: '821',
      key: '615',
      name: 'אחוזם',
      post_code: '7959500',
      post_code_5: '79595',
    },
    {
      code: '822',
      key: '616',
      name: 'מטע',
      post_code: '9987000',
      post_code_5: '99870',
    },
    {
      code: '823',
      key: '617',
      name: 'בר גיורא',
      post_code: '9988000',
      post_code_5: '99880',
    },
    {
      code: '824',
      key: '618',
      name: 'כוכב מיכאל',
      post_code: '7930400',
      post_code_5: '79304',
    },
    {
      code: '825',
      key: '619',
      name: 'נס הרים',
      post_code: '9988500',
      post_code_5: '99885',
    },
    {
      code: '826',
      key: '620',
      name: 'עוזה',
      post_code: '7957500',
      post_code_5: '79575',
    },
    {
      code: '827',
      key: '621',
      name: 'נווה מבטח',
      post_code: '7985000',
      post_code_5: '79850',
    },
    {
      code: '828',
      key: '622',
      name: 'ישרש',
      post_code: '7683800',
      post_code_5: '76838',
    },
    {
      code: '829',
      key: '623',
      name: 'מבטחים',
      post_code: '8544000',
      post_code_5: '85440',
    },
    {
      code: '831',
      key: '624',
      name: 'ירוחם',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '833',
      key: '625',
      name: 'נורית',
      post_code: '1912200',
      post_code_5: '19122',
    },
    {
      code: '836',
      key: '626',
      name: 'גנות',
      post_code: '5029300',
      post_code_5: '50293',
    },
    {
      code: '837',
      key: '627',
      name: 'עזריאל',
      post_code: '4582500',
      post_code_5: '45825',
    },
    {
      code: '838',
      key: '628',
      name: 'פדיה',
      post_code: '9979600',
      post_code_5: '99796',
    },
    {
      code: '839',
      key: '629',
      name: 'פתחיה',
      post_code: '9979500',
      post_code_5: '99795',
    },
    {
      code: '840',
      key: '630',
      name: 'כיסופים',
      post_code: '8513000',
      post_code_5: '85130',
    },
    {
      code: '841',
      key: '631',
      name: 'אלישמע',
      post_code: '4588000',
      post_code_5: '45880',
    },
    {
      code: '842',
      key: '632',
      name: 'געש',
      post_code: '6095000',
      post_code_5: '60950',
    },
    {
      code: '843',
      key: '633',
      name: 'מרגליות',
      post_code: '1216000',
      post_code_5: '12160',
    },
    {
      code: '844',
      key: '634',
      name: 'נחל עוז',
      post_code: '8514500',
      post_code_5: '85145',
    },
    {
      code: '845',
      key: '635',
      name: 'כפר עזה',
      post_code: '8514200',
      post_code_5: '85142',
    },
    {
      code: '846',
      key: '636',
      name: 'שפר',
      post_code: '2012000',
      post_code_5: '20120',
    },
    {
      code: '848',
      key: '637',
      name: 'בית רבן',
      post_code: '7923900',
      post_code_5: '79239',
    },
    {
      code: '849',
      key: '638',
      name: 'דבירה',
      post_code: '8533000',
      post_code_5: '85330',
    },
    {
      code: '850',
      key: '639',
      name: 'אחיטוב',
      post_code: '3885500',
      post_code_5: '38855',
    },
    {
      code: '851',
      key: '640',
      name: 'ניצני עוז',
      post_code: '4283600',
      post_code_5: '42836',
    },
    {
      code: '852',
      key: '641',
      name: 'גונן',
      post_code: '1213000',
      post_code_5: '12130',
    },
    {
      code: '853',
      key: '642',
      name: 'גאליה',
      post_code: '7688500',
      post_code_5: '76885',
    },
    {
      code: '854',
      key: '643',
      name: 'רחוב',
      post_code: '1091500',
      post_code_5: '10915',
    },
    {
      code: '856',
      key: '644',
      name: 'שעלבים',
      post_code: '9978400',
      post_code_5: '99784',
    },
    {
      code: '857',
      key: '645',
      name: 'כפר אביב',
      post_code: '7924100',
      post_code_5: '79241',
    },
    {
      code: '858',
      key: '646',
      name: 'נווה ירק',
      post_code: '4994500',
      post_code_5: '49945',
    },
    {
      code: '859',
      key: '647',
      name: 'כסלון',
      post_code: '9097600',
      post_code_5: '90976',
    },
    {
      code: '861',
      key: '648',
      name: 'שדה אליעזר',
      post_code: '1220500',
      post_code_5: '12205',
    },
    {
      code: '862',
      key: '649',
      name: 'גני יוחנן',
      post_code: '7692200',
      post_code_5: '76922',
    },
    {
      code: '863',
      key: '650',
      name: 'גינתון',
      post_code: '7311000',
      post_code_5: '73110',
    },
    {
      code: '864',
      key: '651',
      name: 'בקוע',
      post_code: '9975500',
      post_code_5: '99755',
    },
    {
      code: '865',
      key: '652',
      name: 'שיבולים',
      post_code: '8539200',
      post_code_5: '85392',
    },
    {
      code: '866',
      key: '653',
      name: 'יטבתה',
      post_code: '8882000',
      post_code_5: '88820',
    },
    {
      code: '868',
      key: '654',
      name: 'אלוני יצחק',
      post_code: '3781000',
      post_code_5: '37810',
    },
    {
      code: '870',
      key: '655',
      name: 'גבעת השלושה',
      post_code: '4880000',
      post_code_5: '48800',
    },
    {
      code: '871',
      key: '656',
      name: 'עינת',
      post_code: '4880500',
      post_code_5: '48805',
    },
    {
      code: '872',
      key: '657',
      name: 'גאולי תימן',
      post_code: '3889000',
      post_code_5: '38890',
    },
    {
      code: '873',
      key: '658',
      name: 'שלווה',
      post_code: '7959300',
      post_code_5: '79593',
    },
    {
      code: '874',
      key: '659',
      name: 'מגדל העמק',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '875',
      key: '660',
      name: 'כפר עבודה',
      post_code: '4069400',
      post_code_5: '40694',
    },
    {
      code: '877',
      key: '661',
      name: 'בית חירות',
      post_code: '4029100',
      post_code_5: '40291',
    },
    {
      code: '880',
      key: '662',
      name: 'עין שריד',
      post_code: '4069700',
      post_code_5: '40697',
    },
    {
      code: '885',
      key: '665',
      name: 'שדה בוקר',
      post_code: '8499300',
      post_code_5: '84993',
    },
    {
      code: '886',
      key: '666',
      name: 'איתנים',
      post_code: '9097200',
      post_code_5: '90972',
    },
    {
      code: '888',
      key: '667',
      name: 'כפר הרי"ף',
      post_code: '7983000',
      post_code_5: '79830',
    },
    {
      code: '889',
      key: '668',
      name: 'כפר חסידים ב\'',
      post_code: '2049000',
      post_code_5: '20490',
    },
    {
      code: '890',
      key: '669',
      name: 'כפר הנוער הדתי',
      post_code: '2049400',
      post_code_5: '20494',
    },
    {
      code: '892',
      key: '670',
      name: 'עבדון',
      post_code: '2526000',
      post_code_5: '25260',
    },
    {
      code: '897',
      key: '671',
      name: 'מדרשת רופין',
      post_code: '4025000',
      post_code_5: '40250',
    },
    {
      code: '913',
      key: '672',
      name: 'שבלי - אום אל-גנם',
      post_code: '1680500',
      post_code_5: '16805',
    },
    {
      code: '916',
      key: '673',
      name: 'ישע',
      post_code: '8543000',
      post_code_5: '85430',
    },
    {
      code: '917',
      key: '674',
      name: 'עצמון שגב',
      post_code: '2017000',
      post_code_5: '20170',
    },
    {
      code: '919',
      key: '675',
      name: 'גבעת ישעיהו',
      post_code: '9982500',
      post_code_5: '99825',
    },
    {
      code: '921',
      key: '676',
      name: 'שער מנשה',
      post_code: '3780600',
      post_code_5: '37806',
    },
    {
      code: '922',
      key: '677',
      name: 'רכסים',
      post_code: '2049600',
      post_code_5: '20496',
    },
    {
      code: '926',
      key: '678',
      name: 'נווה אבות',
      post_code: '3791001',
      post_code_5: '37910',
    },
    {
      code: '932',
      key: '679',
      name: 'אבו עמרה (שבט)',
      post_code: '8571500',
      post_code_5: '85715',
    },
    {
      code: '935',
      key: '680',
      name: 'אבו סריחאן (שבט)',
      post_code: '8490700',
      post_code_5: '84907',
    },
    {
      code: '939',
      key: '681',
      name: 'מסעודין אל-עזאזמה',
      post_code: '8494200',
      post_code_5: '84942',
    },
    {
      code: '942',
      key: '682',
      name: 'סואעד (חמרייה)',
      post_code: '2020001',
      post_code_5: '20200',
    },
    {
      code: '944',
      key: '683',
      name: 'בסמת טבעון',
      post_code: '3600800',
      post_code_5: '36008',
    },
    {
      code: '948',
      key: '684',
      name: 'חוג\'ייראת (ד\'הרה)',
      post_code: '2524801',
      post_code_5: '25248',
    },
    {
      code: '956',
      key: '685',
      name: 'הוזייל (שבט)',
      post_code: '8493900',
      post_code_5: '84939',
    },
    {
      code: '957',
      key: '686',
      name: 'עוקבי (בנו עוקבה)',
      post_code: '8498200',
      post_code_5: '84982',
    },
    {
      code: '958',
      key: '687',
      name: 'אבו עבדון (שבט)',
      post_code: '8571000',
      post_code_5: '85710',
    },
    {
      code: '959',
      key: '688',
      name: 'אפיניש (שבט)',
      post_code: '8572500',
      post_code_5: '85725',
    },
    {
      code: '960',
      key: '689',
      name: 'אסד (שבט)',
      post_code: '8572000',
      post_code_5: '85720',
    },
    {
      code: '961',
      key: '690',
      name: 'אבו רוקייק (שבט)',
      post_code: '8492200',
      post_code_5: '84922',
    },
    {
      code: '962',
      key: '691',
      name: 'טובא-זנגריה',
      post_code: '1231000',
      post_code_5: '12310',
    },
    {
      code: '963',
      key: '692',
      name: 'אעצם (שבט)',
      post_code: '8493000',
      post_code_5: '84930',
    },
    {
      code: '964',
      key: '693',
      name: 'קודייראת א-צאנע(שבט)',
      post_code: '8498600',
      post_code_5: '84986',
    },
    {
      code: '965',
      key: '694',
      name: 'אטרש (שבט)',
      post_code: '8497500',
      post_code_5: '84975',
    },
    {
      code: '966',
      key: '695',
      name: 'אבו רובייעה (שבט)',
      post_code: '8492000',
      post_code_5: '84920',
    },
    {
      code: '967',
      key: '696',
      name: 'אבו ג\'ווייעד (שבט)',
      post_code: '8490500',
      post_code_5: '84905',
    },
    {
      code: '968',
      key: '697',
      name: 'אבו קורינאת (שבט)',
      post_code: '8491701',
      post_code_5: '84917',
    },
    {
      code: '969',
      key: '698',
      name: 'עטאוונה (שבט)',
      post_code: '8497000',
      post_code_5: '84970',
    },
    {
      code: '970',
      key: '699',
      name: 'תראבין א-צאנע (שבט)',
      post_code: '8480501',
      post_code_5: '84805',
    },
    {
      code: '972',
      key: '700',
      name: 'קוואעין (שבט)',
      post_code: '8498800',
      post_code_5: '84988',
    },
    {
      code: '975',
      key: '701',
      name: 'זרזיר',
      post_code: '3657100',
      post_code_5: '36571',
    },
    {
      code: '976',
      key: '702',
      name: 'ג\'נאביב (שבט)',
      post_code: '8500000',
      post_code_5: '85000',
    },
    {
      code: '978',
      key: '703',
      name: 'כעביה-טבאש-חג\'אג\'רה',
      post_code: '3659100',
      post_code_5: '36591',
    },
    {
      code: '989',
      key: '705',
      name: 'סואעד (כמאנה) (שבט)',
      post_code: '2010200',
      post_code_5: '20102',
    },
    {
      code: '990',
      key: '706',
      name: 'ראס עלי',
      post_code: '2029600',
      post_code_5: '20296',
    },
    {
      code: '993',
      key: '707',
      name: 'חמאם',
      post_code: '1497000',
      post_code_5: '14970',
    },
    {
      code: '994',
      key: '708',
      name: 'מנשית זבדה',
      post_code: '3659000',
      post_code_5: '36590',
    },
    {
      code: '997',
      key: '709',
      name: 'רומת הייב',
      post_code: '1790100',
      post_code_5: '17901',
    },
    {
      code: '998',
      key: '710',
      name: 'ביר אל-מכסור',
      post_code: '1792500',
      post_code_5: '17925',
    },
    {
      code: '1015',
      key: '711',
      name: 'מבשרת ציון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1020',
      key: '712',
      name: 'אור עקיבא',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1024',
      key: '713',
      name: 'חרוצים',
      post_code: '6091700',
      post_code_5: '60917',
    },
    {
      code: '1031',
      key: '714',
      name: 'שדרות',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1034',
      key: '715',
      name: 'קריית מלאכי',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1041',
      key: '716',
      name: 'נצאצרה (שבט)',
      post_code: '8495400',
      post_code_5: '84954',
    },
    {
      code: '1042',
      key: '717',
      name: 'אבו עמאר (שבט)',
      post_code: '8491200',
      post_code_5: '84912',
    },
    {
      code: '1043',
      key: '718',
      name: 'גיזו',
      post_code: '9974500',
      post_code_5: '99745',
    },
    {
      code: '1044',
      key: '719',
      name: 'יעף',
      post_code: '4588600',
      post_code_5: '45886',
    },
    {
      code: '1045',
      key: '720',
      name: 'שתולה',
      post_code: '2286500',
      post_code_5: '22865',
    },
    {
      code: '1046',
      key: '721',
      name: 'אוהד',
      post_code: '8545000',
      post_code_5: '85450',
    },
    {
      code: '1047',
      key: '722',
      name: 'חזון',
      post_code: '2010500',
      post_code_5: '20105',
    },
    {
      code: '1048',
      key: '723',
      name: 'אפעל-מרכז סמינריוני',
      post_code: '5296001',
      post_code_5: '52960',
    },
    {
      code: '1050',
      key: '725',
      name: 'בית חשמונאי',
      post_code: '9978900',
      post_code_5: '99789',
    },
    {
      code: '1051',
      key: '726',
      name: 'תלמי אליהו',
      post_code: '8545200',
      post_code_5: '85452',
    },
    {
      code: '1052',
      key: '727',
      name: 'קטורה',
      post_code: '8884000',
      post_code_5: '88840',
    },
    {
      code: '1053',
      key: '728',
      name: 'עין חצבה',
      post_code: '8681000',
      post_code_5: '86810',
    },
    {
      code: '1054',
      key: '729',
      name: 'תל שבע',
      post_code: '8495000',
      post_code_5: '84950',
    },
    {
      code: '1056',
      key: '730',
      name: 'עין כרם-בי"ס חקלאי',
      post_code: '9087200',
      post_code_5: '90872',
    },
    {
      code: '1057',
      key: '731',
      name: 'נווה זוהר',
      post_code: '8691000',
      post_code_5: '86910',
    },
    {
      code: '1058',
      key: '732',
      name: 'שדה ניצן',
      post_code: '8547000',
      post_code_5: '85470',
    },
    {
      code: '1059',
      key: '733',
      name: 'כסיפה',
      post_code: '8492300',
      post_code_5: '84923',
    },
    {
      code: '1060',
      key: '734',
      name: 'לקיה',
      post_code: '8499100',
      post_code_5: '84991',
    },
    {
      code: '1061',
      key: '735',
      name: 'נוף הגליל',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1063',
      key: '736',
      name: 'מעלות-תרשיחא',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1064',
      key: '737',
      name: 'אמירים',
      post_code: '2011500',
      post_code_5: '20115',
    },
    {
      code: '1065',
      key: '738',
      name: 'זמרת',
      post_code: '8515100',
      post_code_5: '85151',
    },
    {
      code: '1066',
      key: '739',
      name: 'בני עי"ש',
      post_code: '6086000',
      post_code_5: '60860',
    },
    {
      code: '1067',
      key: '740',
      name: 'דוב"ב',
      post_code: '1386500',
      post_code_5: '13865',
    },
    {
      code: '1068',
      key: '741',
      name: 'אדמית',
      post_code: '2287500',
      post_code_5: '22875',
    },
    {
      code: '1069',
      key: '742',
      name: 'רם-און',
      post_code: '1920500',
      post_code_5: '19205',
    },
    {
      code: '1070',
      key: '743',
      name: 'אביעזר',
      post_code: '9986000',
      post_code_5: '99860',
    },
    {
      code: '1071',
      key: '744',
      name: 'נווה מיכאל',
      post_code: '9986500',
      post_code_5: '99865',
    },
    {
      code: '1072',
      key: '745',
      name: 'גן הדרום',
      post_code: '7925500',
      post_code_5: '79255',
    },
    {
      code: '1076',
      key: '746',
      name: 'בית ברל',
      post_code: '4490500',
      post_code_5: '44905',
    },
    {
      code: '1077',
      key: '747',
      name: 'גבעת שפירא',
      post_code: '4291200',
      post_code_5: '42912',
    },
    {
      code: '1079',
      key: '748',
      name: 'צפרירים',
      post_code: '9983000',
      post_code_5: '99830',
    },
    {
      code: '1080',
      key: '749',
      name: 'מבועים',
      post_code: '8536000',
      post_code_5: '85360',
    },
    {
      code: '1081',
      key: '750',
      name: 'אבן מנחם',
      post_code: '2286000',
      post_code_5: '22860',
    },
    {
      code: '1082',
      key: '751',
      name: 'מעגלים',
      post_code: '8538600',
      post_code_5: '85386',
    },
    {
      code: '1083',
      key: '752',
      name: 'תושיה',
      post_code: '8523000',
      post_code_5: '85230',
    },
    {
      code: '1084',
      key: '753',
      name: 'בן שמן (כפר נוער)',
      post_code: '7311200',
      post_code_5: '73112',
    },
    {
      code: '1085',
      key: '754',
      name: 'כרם שלום',
      post_code: '8546000',
      post_code_5: '85460',
    },
    {
      code: '1094',
      key: '755',
      name: 'כרם יבנה (ישיבה)',
      post_code: '7985500',
      post_code_5: '79855',
    },
    {
      code: '1095',
      key: '756',
      name: 'כפר מימון',
      post_code: '8515300',
      post_code_5: '85153',
    },
    {
      code: '1098',
      key: '757',
      name: 'מרכז שפירא',
      post_code: '7941100',
      post_code_5: '79411',
    },
    {
      code: '1102',
      key: '758',
      name: 'צוקי ים',
      post_code: '4298401',
      post_code_5: '42984',
    },
    {
      code: '1103',
      key: '759',
      name: 'גני הדר',
      post_code: '7683000',
      post_code_5: '76830',
    },
    {
      code: '1104',
      key: '760',
      name: 'פוריה - כפר עבודה',
      post_code: '1522000',
      post_code_5: '15220',
    },
    {
      code: '1105',
      key: '761',
      name: 'פוריה - נווה עובד',
      post_code: '1521000',
      post_code_5: '15210',
    },
    {
      code: '1108',
      key: '762',
      name: 'אומן',
      post_code: '1926500',
      post_code_5: '19265',
    },
    {
      code: '1110',
      key: '763',
      name: 'חבר',
      post_code: '1916500',
      post_code_5: '19165',
    },
    {
      code: '1111',
      key: '764',
      name: 'צופיה',
      post_code: '7921500',
      post_code_5: '79215',
    },
    {
      code: '1112',
      key: '765',
      name: 'יודפת',
      post_code: '2018000',
      post_code_5: '20180',
    },
    {
      code: '1113',
      key: '766',
      name: 'צור הדסה',
      post_code: '9987500',
      post_code_5: '99875',
    },
    {
      code: '1114',
      key: '767',
      name: 'שריגים (לי-און)',
      post_code: '9983500',
      post_code_5: '99835',
    },
    {
      code: '1115',
      key: '768',
      name: 'אביבים',
      post_code: '1385000',
      post_code_5: '13850',
    },
    {
      code: '1117',
      key: '769',
      name: 'יעל',
      post_code: '1930000',
      post_code_5: '19300',
    },
    {
      code: '1123',
      key: '770',
      name: 'אדרת',
      post_code: '9985000',
      post_code_5: '99850',
    },
    {
      code: '1124',
      key: '771',
      name: 'נאות הכיכר',
      post_code: '8690500',
      post_code_5: '86905',
    },
    {
      code: '1125',
      key: '772',
      name: 'אלמגור',
      post_code: '1292200',
      post_code_5: '12922',
    },
    {
      code: '1126',
      key: '773',
      name: 'אילות',
      post_code: '8880500',
      post_code_5: '88805',
    },
    {
      code: '1127',
      key: '774',
      name: 'מעלה גלבוע',
      post_code: '1914500',
      post_code_5: '19145',
    },
    {
      code: '1128',
      key: '775',
      name: 'מי עמי',
      post_code: '3786500',
      post_code_5: '37865',
    },
    {
      code: '1129',
      key: '776',
      name: 'גרופית',
      post_code: '8882500',
      post_code_5: '88825',
    },
    {
      code: '1130',
      key: '777',
      name: 'כפר רוזנואלד (זרעית)',
      post_code: '2287000',
      post_code_5: '22870',
    },
    {
      code: '1132',
      key: '778',
      name: 'שניר',
      post_code: '1225000',
      post_code_5: '12250',
    },
    {
      code: '1133',
      key: '779',
      name: 'ורדון',
      post_code: '7943700',
      post_code_5: '79437',
    },
    {
      code: '1134',
      key: '780',
      name: 'יד השמונה',
      post_code: '9089500',
      post_code_5: '90895',
    },
    {
      code: '1136',
      key: '781',
      name: 'צוחר',
      post_code: '8547500',
      post_code_5: '85475',
    },
    {
      code: '1137',
      key: '782',
      name: 'קריית יערים',
      post_code: '9083800',
      post_code_5: '90838',
    },
    {
      code: '1138',
      key: '783',
      name: 'יעד',
      post_code: '2015500',
      post_code_5: '20155',
    },
    {
      code: '1139',
      key: '784',
      name: 'כרמיאל',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1140',
      key: '785',
      name: 'מדרשת בן גוריון',
      post_code: '8499000',
      post_code_5: '84990',
    },
    {
      code: '1141',
      key: '786',
      name: 'מבוא מודיעים',
      post_code: '7312200',
      post_code_5: '73122',
    },
    {
      code: '1143',
      key: '787',
      name: 'נס עמים',
      post_code: '2280100',
      post_code_5: '22801',
    },
    {
      code: '1144',
      key: '788',
      name: 'ידידה',
      post_code: '9011700',
      post_code_5: '90117',
    },
    {
      code: '1145',
      key: '789',
      name: 'אלומה',
      post_code: '7954500',
      post_code_5: '79545',
    },
    {
      code: '1146',
      key: '790',
      name: 'עלומים',
      post_code: '8513800',
      post_code_5: '85138',
    },
    {
      code: '1147',
      key: '791',
      name: 'נטועה',
      post_code: '1380700',
      post_code_5: '13807',
    },
    {
      code: '1148',
      key: '792',
      name: 'צור נתן',
      post_code: '4583600',
      post_code_5: '45836',
    },
    {
      code: '1149',
      key: '793',
      name: 'עזר',
      post_code: '7980000',
      post_code_5: '79800',
    },
    {
      code: '1150',
      key: '794',
      name: 'צופר',
      post_code: '8683000',
      post_code_5: '86830',
    },
    {
      code: '1151',
      key: '795',
      name: 'פארן',
      post_code: '8683500',
      post_code_5: '86835',
    },
    {
      code: '1152',
      key: '796',
      name: 'אשלים',
      post_code: '8551200',
      post_code_5: '85512',
    },
    {
      code: '1153',
      key: '797',
      name: 'כישור',
      post_code: '2012300',
      post_code_5: '20123',
    },
    {
      code: '1154',
      key: '798',
      name: 'מלכישוע',
      post_code: '1917000',
      post_code_5: '19170',
    },
    {
      code: '1155',
      key: '799',
      name: 'מגן שאול',
      post_code: '1935200',
      post_code_5: '19352',
    },
    {
      code: '1156',
      key: '800',
      name: 'סמר',
      post_code: '8881500',
      post_code_5: '88815',
    },
    {
      code: '1157',
      key: '801',
      name: 'אחווה',
      post_code: '7980300',
      post_code_5: '79803',
    },
    {
      code: '1158',
      key: '802',
      name: 'יהל',
      post_code: '8885000',
      post_code_5: '88850',
    },
    {
      code: '1160',
      key: '803',
      name: 'שכניה',
      post_code: '2018300',
      post_code_5: '20183',
    },
    {
      code: '1161',
      key: '804',
      name: 'רהט',
      post_code: '8535700',
      post_code_5: '85357',
    },
    {
      code: '1162',
      key: '805',
      name: 'בית רימון',
      post_code: '1795000',
      post_code_5: '17950',
    },
    {
      code: '1163',
      key: '806',
      name: 'מורן',
      post_code: '2010700',
      post_code_5: '20107',
    },
    {
      code: '1165',
      key: '807',
      name: 'שילת',
      post_code: '7318800',
      post_code_5: '73188',
    },
    {
      code: '1166',
      key: '808',
      name: 'כפר רות',
      post_code: '7319600',
      post_code_5: '73196',
    },
    {
      code: '1167',
      key: '809',
      name: 'קיסריה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1169',
      key: '810',
      name: 'הוואשלה (שבט)',
      post_code: '8494000',
      post_code_5: '84940',
    },
    {
      code: '1170',
      key: '811',
      name: 'סייד (שבט)',
      post_code: '8494100',
      post_code_5: '84941',
    },
    {
      code: '1171',
      key: '812',
      name: 'לוטם',
      post_code: '2012400',
      post_code_5: '20124',
    },
    {
      code: '1172',
      key: '813',
      name: 'תובל',
      post_code: '2013600',
      post_code_5: '20136',
    },
    {
      code: '1173',
      key: '814',
      name: 'לפידות',
      post_code: '2012200',
      post_code_5: '20122',
    },
    {
      code: '1174',
      key: '815',
      name: 'מנוף',
      post_code: '2018400',
      post_code_5: '20184',
    },
    {
      code: '1175',
      key: '816',
      name: 'עידן',
      post_code: '8684000',
      post_code_5: '86840',
    },
    {
      code: '1176',
      key: '817',
      name: 'ספיר',
      post_code: '8682500',
      post_code_5: '86825',
    },
    {
      code: '1177',
      key: '818',
      name: 'טללים',
      post_code: '8554500',
      post_code_5: '85545',
    },
    {
      code: '1178',
      key: '819',
      name: 'מורשת',
      post_code: '2018600',
      post_code_5: '20186',
    },
    {
      code: '1179',
      key: '820',
      name: 'קורנית',
      post_code: '2018100',
      post_code_5: '20181',
    },
    {
      code: '1180',
      key: '821',
      name: 'צביה',
      post_code: '2012900',
      post_code_5: '20129',
    },
    {
      code: '1181',
      key: '822',
      name: 'טל-אל',
      post_code: '2516700',
      post_code_5: '25167',
    },
    {
      code: '1182',
      key: '823',
      name: 'אלון הגליל',
      post_code: '1792000',
      post_code_5: '17920',
    },
    {
      code: '1183',
      key: '824',
      name: 'כליל',
      post_code: '2523300',
      post_code_5: '25233',
    },
    {
      code: '1184',
      key: '825',
      name: 'מתת',
      post_code: '1388000',
      post_code_5: '13880',
    },
    {
      code: '1185',
      key: '826',
      name: 'פלך',
      post_code: '2011900',
      post_code_5: '20119',
    },
    {
      code: '1186',
      key: '827',
      name: 'הושעיה',
      post_code: '1791500',
      post_code_5: '17915',
    },
    {
      code: '1187',
      key: '828',
      name: 'עיר אובות',
      post_code: '8680500',
      post_code_5: '86805',
    },
    {
      code: '1188',
      key: '829',
      name: 'אשחר',
      post_code: '2019600',
      post_code_5: '20196',
    },
    {
      code: '1190',
      key: '830',
      name: 'מצפה נטופה',
      post_code: '1529500',
      post_code_5: '15295',
    },
    {
      code: '1191',
      key: '831',
      name: 'בר יוחאי',
      post_code: '1391200',
      post_code_5: '13912',
    },
    {
      code: '1192',
      key: '832',
      name: 'ערערה-בנגב',
      post_code: '8491100',
      post_code_5: '84911',
    },
    {
      code: '1195',
      key: '833',
      name: 'ניצנה (קהילת חינוך)',
      post_code: '8490100',
      post_code_5: '84901',
    },
    {
      code: '1196',
      key: '834',
      name: 'מחנה יתיר',
      post_code: '8498900',
      post_code_5: '84989',
    },
    {
      code: '1197',
      key: '835',
      name: 'נאות סמדר',
      post_code: '8886000',
      post_code_5: '88860',
    },
    {
      code: '1198',
      key: '836',
      name: 'כרמים',
      post_code: '8496300',
      post_code_5: '84963',
    },
    {
      code: '1199',
      key: '837',
      name: 'עדי',
      post_code: '1794000',
      post_code_5: '17940',
    },
    {
      code: '1200',
      key: '838',
      name: 'מודיעין-מכבים-רעות',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1201',
      key: '839',
      name: 'כמון',
      post_code: '2011200',
      post_code_5: '20112',
    },
    {
      code: '1202',
      key: '840',
      name: 'מכמנים',
      post_code: '2010600',
      post_code_5: '20106',
    },
    {
      code: '1203',
      key: '841',
      name: 'הררית',
      post_code: '2018200',
      post_code_5: '20182',
    },
    {
      code: '1204',
      key: '842',
      name: 'גילון',
      post_code: '2010300',
      post_code_5: '20103',
    },
    {
      code: '1205',
      key: '843',
      name: 'מנות',
      post_code: '2523700',
      post_code_5: '25237',
    },
    {
      code: '1206',
      key: '844',
      name: 'גיתה',
      post_code: '2525700',
      post_code_5: '25257',
    },
    {
      code: '1207',
      key: '845',
      name: 'לבון',
      post_code: '2011800',
      post_code_5: '20118',
    },
    {
      code: '1208',
      key: '846',
      name: 'הילה',
      post_code: '2495300',
      post_code_5: '24953',
    },
    {
      code: '1209',
      key: '847',
      name: 'חרשים',
      post_code: '2495400',
      post_code_5: '24954',
    },
    {
      code: '1210',
      key: '848',
      name: 'כחל',
      post_code: '1238700',
      post_code_5: '12387',
    },
    {
      code: '1211',
      key: '849',
      name: 'קדרים',
      post_code: '2011700',
      post_code_5: '20117',
    },
    {
      code: '1212',
      key: '850',
      name: 'עמוקה',
      post_code: '1380200',
      post_code_5: '13802',
    },
    {
      code: '1213',
      key: '851',
      name: 'צבעון',
      post_code: '1383600',
      post_code_5: '13836',
    },
    {
      code: '1214',
      key: '852',
      name: 'טפחות',
      post_code: '1498500',
      post_code_5: '14985',
    },
    {
      code: '1217',
      key: '853',
      name: 'מחנה יבור',
      post_code: '2016000',
      post_code_5: '20160',
    },
    {
      code: '1219',
      key: '854',
      name: 'גורנות הגליל',
      post_code: '2286800',
      post_code_5: '22868',
    },
    {
      code: '1220',
      key: '855',
      name: 'אבירים',
      post_code: '1380600',
      post_code_5: '13806',
    },
    {
      code: '1221',
      key: '856',
      name: 'צורית',
      post_code: '2010400',
      post_code_5: '20104',
    },
    {
      code: '1222',
      key: '857',
      name: 'מצפה אבי"ב',
      post_code: '2018700',
      post_code_5: '20187',
    },
    {
      code: '1223',
      key: '858',
      name: 'שדי אברהם',
      post_code: '8548000',
      post_code_5: '85480',
    },
    {
      code: '1225',
      key: '860',
      name: 'רביד',
      post_code: '1496000',
      post_code_5: '14960',
    },
    {
      code: '1226',
      key: '861',
      name: 'יובלים',
      post_code: '2014200',
      post_code_5: '20142',
    },
    {
      code: '1227',
      key: '862',
      name: 'יתד',
      post_code: '8548200',
      post_code_5: '85482',
    },
    {
      code: '1228',
      key: '863',
      name: 'רקפת',
      post_code: '2017500',
      post_code_5: '20175',
    },
    {
      code: '1229',
      key: '864',
      name: 'כלנית',
      post_code: '1499500',
      post_code_5: '14995',
    },
    {
      code: '1230',
      key: '865',
      name: 'לבנים',
      post_code: '1293700',
      post_code_5: '12937',
    },
    {
      code: '1231',
      key: '866',
      name: 'פרי גן',
      post_code: '8549000',
      post_code_5: '85490',
    },
    {
      code: '1232',
      key: '867',
      name: 'יבול',
      post_code: '8548700',
      post_code_5: '85487',
    },
    {
      code: '1233',
      key: '868',
      name: 'שקף',
      post_code: '7938000',
      post_code_5: '79380',
    },
    {
      code: '1234',
      key: '869',
      name: 'קבועה (שבט)',
      post_code: '8491500',
      post_code_5: '84915',
    },
    {
      code: '1235',
      key: '870',
      name: 'שורשים',
      post_code: '2016400',
      post_code_5: '20164',
    },
    {
      code: '1236',
      key: '871',
      name: 'נירית',
      post_code: '4480500',
      post_code_5: '44805',
    },
    {
      code: '1237',
      key: '872',
      name: 'תלמי יוסף',
      post_code: '8548900',
      post_code_5: '85489',
    },
    {
      code: '1238',
      key: '873',
      name: 'סופה',
      post_code: '8545700',
      post_code_5: '85457',
    },
    {
      code: '1239',
      key: '874',
      name: 'חולית',
      post_code: '8545800',
      post_code_5: '85458',
    },
    {
      code: '1240',
      key: '875',
      name: 'עין הבשור',
      post_code: '8540500',
      post_code_5: '85405',
    },
    {
      code: '1241',
      key: '876',
      name: 'דקל',
      post_code: '8549200',
      post_code_5: '85492',
    },
    {
      code: '1242',
      key: '877',
      name: 'נתיב העשרה',
      post_code: '7975500',
      post_code_5: '79755',
    },
    {
      code: '1244',
      key: '878',
      name: 'תמרת',
      post_code: '3657600',
      post_code_5: '36576',
    },
    {
      code: '1245',
      key: '879',
      name: 'סלמה',
      post_code: '2010100',
      post_code_5: '20101',
    },
    {
      code: '1246',
      key: '880',
      name: 'עראמשה',
      post_code: '2287501',
      post_code_5: '22875',
    },
    {
      code: '1247',
      key: '881',
      name: 'חריש',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1248',
      key: '882',
      name: 'אליפז',
      post_code: '8881200',
      post_code_5: '88812',
    },
    {
      code: '1249',
      key: '883',
      name: 'הרדוף',
      post_code: '1793000',
      post_code_5: '17930',
    },
    {
      code: '1251',
      key: '884',
      name: 'עין תמר',
      post_code: '8690200',
      post_code_5: '86902',
    },
    {
      code: '1252',
      key: '885',
      name: 'כורזים',
      post_code: '1293300',
      post_code_5: '12933',
    },
    {
      code: '1253',
      key: '886',
      name: 'אמנון',
      post_code: '1293500',
      post_code_5: '12935',
    },
    {
      code: '1254',
      key: '887',
      name: 'נטף',
      post_code: '9080400',
      post_code_5: '90804',
    },
    {
      code: '1255',
      key: '888',
      name: 'לוטן',
      post_code: '8885500',
      post_code_5: '88855',
    },
    {
      code: '1256',
      key: '889',
      name: 'אשרת',
      post_code: '2524300',
      post_code_5: '25243',
    },
    {
      code: '1257',
      key: '890',
      name: 'חנתון',
      post_code: '1796000',
      post_code_5: '17960',
    },
    {
      code: '1258',
      key: '891',
      name: 'מסד',
      post_code: '1499000',
      post_code_5: '14990',
    },
    {
      code: '1259',
      key: '892',
      name: 'נווה שלום',
      post_code: '9976100',
      post_code_5: '99761',
    },
    {
      code: '1260',
      key: '893',
      name: 'רתמים',
      post_code: '8555000',
      post_code_5: '85550',
    },
    {
      code: '1261',
      key: '894',
      name: 'הר עמשא',
      post_code: '9040300',
      post_code_5: '90403',
    },
    {
      code: '1262',
      key: '895',
      name: 'צוקים',
      post_code: '8683300',
      post_code_5: '86833',
    },
    {
      code: '1263',
      key: '896',
      name: 'כפר ורדים',
      post_code: '2514700',
      post_code_5: '25147',
    },
    {
      code: '1264',
      key: '897',
      name: 'כרמי יוסף',
      post_code: '9979700',
      post_code_5: '99797',
    },
    {
      code: '1265',
      key: '898',
      name: 'שומריה',
      post_code: '8533600',
      post_code_5: '85336',
    },
    {
      code: '1266',
      key: '899',
      name: 'שחרות',
      post_code: '8886500',
      post_code_5: '88865',
    },
    {
      code: '1267',
      key: '900',
      name: 'שיטים',
      post_code: '8887400',
      post_code_5: '88874',
    },
    {
      code: '1268',
      key: '901',
      name: 'מיתר',
      post_code: '8502500',
      post_code_5: '85025',
    },
    {
      code: '1271',
      key: '902',
      name: 'להבים',
      post_code: '8533800',
      post_code_5: '85338',
    },
    {
      code: '1272',
      key: '903',
      name: 'חלוץ',
      post_code: '2012100',
      post_code_5: '20121',
    },
    {
      code: '1274',
      key: '904',
      name: 'גן נר',
      post_code: '1935100',
      post_code_5: '19351',
    },
    {
      code: '1275',
      key: '905',
      name: 'אבטליון',
      post_code: '2017600',
      post_code_5: '20176',
    },
    {
      code: '1276',
      key: '906',
      name: 'אשבל',
      post_code: '2019800',
      post_code_5: '20198',
    },
    {
      code: '1278',
      key: '907',
      name: 'באר מילכה',
      post_code: '8551700',
      post_code_5: '85517',
    },
    {
      code: '1279',
      key: '908',
      name: 'נווה חריף',
      post_code: '8887000',
      post_code_5: '88870',
    },
    {
      code: '1280',
      key: '909',
      name: 'ניצני סיני',
      post_code: '8551300',
      post_code_5: '85513',
    },
    {
      code: '1282',
      key: '910',
      name: 'מירב',
      post_code: '1914800',
      post_code_5: '19148',
    },
    {
      code: '1283',
      key: '911',
      name: 'תל תאומים',
      post_code: '1091800',
      post_code_5: '10918',
    },
    {
      code: '1284',
      key: '912',
      name: 'נופית',
      post_code: '3600100',
      post_code_5: '36001',
    },
    {
      code: '1285',
      key: '913',
      name: 'כרכום',
      post_code: '1292600',
      post_code_5: '12926',
    },
    {
      code: '1286',
      key: '914',
      name: 'שגב-שלום',
      post_code: '8574000',
      post_code_5: '85740',
    },
    {
      code: '1287',
      key: '915',
      name: 'שני',
      post_code: '9041100',
      post_code_5: '90411',
    },
    {
      code: '1288',
      key: '916',
      name: 'גבעת אלה',
      post_code: '3657000',
      post_code_5: '36570',
    },
    {
      code: '1290',
      key: '917',
      name: 'זמר',
      post_code: '3882800',
      post_code_5: '38828',
    },
    {
      code: '1291',
      key: '918',
      name: 'כמהין',
      post_code: '8551100',
      post_code_5: '85511',
    },
    {
      code: '1292',
      key: '919',
      name: 'ג\'דיידה-מכר',
      post_code: '2510500',
      post_code_5: '25105',
    },
    {
      code: '1293',
      key: '920',
      name: 'גבעת אבני',
      post_code: '1522700',
      post_code_5: '15227',
    },
    {
      code: '1294',
      key: '921',
      name: 'אור הגנוז',
      post_code: '1390900',
      post_code_5: '13909',
    },
    {
      code: '1295',
      key: '922',
      name: 'יאנוח-ג\'ת',
      post_code: '2514500',
      post_code_5: '25145',
    },
    {
      code: '1296',
      key: '923',
      name: 'כסרא-סמיע',
      post_code: '2013800',
      post_code_5: '20138',
    },
    {
      code: '1297',
      key: '924',
      name: 'כפר חנניה',
      post_code: '2010800',
      post_code_5: '20108',
    },
    {
      code: '1298',
      key: '925',
      name: 'אתגר',
      post_code: '2017700',
      post_code_5: '20177',
    },
    {
      code: '1299',
      key: '926',
      name: 'שעורים',
      post_code: '1524400',
      post_code_5: '15244',
    },
    {
      code: '1303',
      key: '927',
      name: 'חורה',
      post_code: '8573000',
      post_code_5: '85730',
    },
    {
      code: '1304',
      key: '928',
      name: 'שוהם',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1309',
      key: '929',
      name: 'אלעד',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1310',
      key: '930',
      name: 'לפיד',
      post_code: '7313300',
      post_code_5: '73133',
    },
    {
      code: '1311',
      key: '931',
      name: 'אבשלום',
      post_code: '8548800',
      post_code_5: '85488',
    },
    {
      code: '1313',
      key: '932',
      name: 'פוריה עילית',
      post_code: '1520800',
      post_code_5: '15208',
    },
    {
      code: '1314',
      key: '933',
      name: 'נווה זיו',
      post_code: '2512300',
      post_code_5: '25123',
    },
    {
      code: '1315',
      key: '934',
      name: 'מתן',
      post_code: '4585800',
      post_code_5: '45858',
    },
    {
      code: '1316',
      key: '935',
      name: 'אל-עריאן',
      post_code: '3002700',
      post_code_5: '30027',
    },
    {
      code: '1317',
      key: '936',
      name: 'דמיידה',
      post_code: '2018601',
      post_code_5: '20186',
    },
    {
      code: '1318',
      key: '937',
      name: 'מבואות ים',
      post_code: '4029600',
      post_code_5: '40296',
    },
    {
      code: '1319',
      key: '938',
      name: 'בת חפר',
      post_code: '4284200',
      post_code_5: '42842',
    },
    {
      code: '1320',
      key: '939',
      name: 'עין חוד',
      post_code: '3080900',
      post_code_5: '30809',
    },
    {
      code: '1321',
      key: '940',
      name: 'ח\'ואלד',
      post_code: '2029900',
      post_code_5: '20299',
    },
    {
      code: '1322',
      key: '941',
      name: 'הודיות',
      post_code: '1526300',
      post_code_5: '15263',
    },
    {
      code: '1323',
      key: '942',
      name: 'בת הדר',
      post_code: '7910300',
      post_code_5: '79103',
    },
    {
      code: '1324',
      key: '943',
      name: 'ארסוף',
      post_code: '4692000',
      post_code_5: '46920',
    },
    {
      code: '1325',
      key: '944',
      name: 'כפר זוהרים',
      post_code: '7937500',
      post_code_5: '79375',
    },
    {
      code: '1326',
      key: '945',
      name: 'בסמ"ה',
      post_code: '3002300',
      post_code_5: '30023',
    },
    {
      code: '1327',
      key: '946',
      name: 'מעלה עירון',
      post_code: '3092000',
      post_code_5: '30920',
    },
    {
      code: '3777',
      key: '947',
      name: 'סנסנה',
      post_code: '8533400',
      post_code_5: '85334',
    },
    {
      code: '1329',
      key: '948',
      name: 'יתיר',
      post_code: '9044001',
      post_code_5: '90440',
    },
    {
      code: '1330',
      key: '949',
      name: 'אחוזת ברק',
      post_code: '1932300',
      post_code_5: '19323',
    },
    {
      code: '1331',
      key: '950',
      name: 'כמאנה',
      post_code: '2170000',
      post_code_5: '21700',
    },
    {
      code: '1332',
      key: '951',
      name: 'חוסנייה',
      post_code: '2011100',
      post_code_5: '20111',
    },
    {
      code: '1333',
      key: '952',
      name: 'נוף איילון',
      post_code: '9978500',
      post_code_5: '99785',
    },
    {
      code: '1334',
      key: '953',
      name: 'ראס אל-עין',
      post_code: '2012600',
      post_code_5: '20126',
    },
    {
      code: '1337',
      key: '954',
      name: 'שמשית',
      post_code: '1790600',
      post_code_5: '17906',
    },
    {
      code: '1339',
      key: '955',
      name: 'אל-עזי',
      post_code: '7981600',
      post_code_5: '79816',
    },
    {
      code: '1340',
      key: '956',
      name: 'מרחב עם',
      post_code: '8553500',
      post_code_5: '85535',
    },
    {
      code: '1342',
      key: '957',
      name: 'אבו קרינאת (יישוב)',
      post_code: '8491700',
      post_code_5: '84917',
    },
    {
      code: '1343',
      key: '958',
      name: 'מכחול',
      post_code: '8490900',
      post_code_5: '84909',
    },
    {
      code: '1344',
      key: '959',
      name: 'גבעות בר',
      post_code: '8533700',
      post_code_5: '85337',
    },
    {
      code: '1346',
      key: '960',
      name: 'תראבין א-צאנע(ישוב)',
      post_code: '8480500',
      post_code_5: '84805',
    },
    {
      code: '1347',
      key: '961',
      name: 'קצר א-סר',
      post_code: '8490600',
      post_code_5: '84906',
    },
    {
      code: '1348',
      key: '962',
      name: 'ביר הדאג\'',
      post_code: '8491900',
      post_code_5: '84919',
    },
    {
      code: '1349',
      key: '963',
      name: 'דריג\'את',
      post_code: '8491000',
      post_code_5: '84910',
    },
    {
      code: '1358',
      key: '964',
      name: 'אום בטין',
      post_code: '8573300',
      post_code_5: '85733',
    },
    {
      code: '1359',
      key: '965',
      name: 'אל סייד',
      post_code: '8575000',
      post_code_5: '85750',
    },
    {
      code: '1360',
      key: '966',
      name: 'סעוה',
      post_code: '8492500',
      post_code_5: '84925',
    },
    {
      code: '1401',
      key: '967',
      name: 'בת חצור',
      post_code: '7079000',
      post_code_5: '70790',
    },
    {
      code: '1402',
      key: '968',
      name: 'חצרות חולדה',
      post_code: '7684300',
      post_code_5: '76843',
    },
    {
      code: '1409',
      key: '971',
      name: 'חצר בארותיים',
      post_code: '4284900',
      post_code_5: '42849',
    },
    {
      code: '1411',
      key: '972',
      name: 'מחנה הילה',
      post_code: '7687800',
      post_code_5: '76878',
    },
    {
      code: '1412',
      key: '973',
      name: 'מחנה תל נוף',
      post_code: '7071000',
      post_code_5: '70710',
    },
    {
      code: '1413',
      key: '974',
      name: 'מחנה יהודית',
      post_code: '3658500',
      post_code_5: '36585',
    },
    {
      code: '1414',
      key: '975',
      name: 'מחנה מרים',
      post_code: '7072000',
      post_code_5: '70720',
    },
    {
      code: '1415',
      key: '976',
      name: 'מחנה יפה',
      post_code: '8542500',
      post_code_5: '85425',
    },
    {
      code: '1416',
      key: '977',
      name: 'מחנה יוכבד',
      post_code: '8886900',
      post_code_5: '88869',
    },
    {
      code: '1417',
      key: '978',
      name: 'מחנה עדי',
      post_code: '8955000',
      post_code_5: '89550',
    },
    {
      code: '1418',
      key: '979',
      name: 'מחנה טלי',
      post_code: '8493500',
      post_code_5: '84935',
    },
    {
      code: '2002',
      key: '980',
      name: 'תנובות',
      post_code: '4283000',
      post_code_5: '42830',
    },
    {
      code: '2003',
      key: '981',
      name: 'תלמי אלעזר',
      post_code: '3881200',
      post_code_5: '38812',
    },
    {
      code: '2006',
      key: '982',
      name: 'כנות',
      post_code: '7982500',
      post_code_5: '79825',
    },
    {
      code: '2008',
      key: '983',
      name: 'שדה יצחק',
      post_code: '3884000',
      post_code_5: '38840',
    },
    {
      code: '2009',
      key: '984',
      name: 'יובל',
      post_code: '1221500',
      post_code_5: '12215',
    },
    {
      code: '2010',
      key: '985',
      name: 'כפר בן נון',
      post_code: '9978000',
      post_code_5: '99780',
    },
    {
      code: '2011',
      key: '986',
      name: 'ינון',
      post_code: '7981500',
      post_code_5: '79815',
    },
    {
      code: '2012',
      key: '987',
      name: 'אורות',
      post_code: '8381000',
      post_code_5: '83810',
    },
    {
      code: '2013',
      key: '988',
      name: 'בן שמן (מושב)',
      post_code: '7311500',
      post_code_5: '73115',
    },
    {
      code: '2014',
      key: '989',
      name: 'גבעולים',
      post_code: '8538800',
      post_code_5: '85388',
    },
    {
      code: '2015',
      key: '990',
      name: 'שדי חמד',
      post_code: '4585500',
      post_code_5: '45855',
    },
    {
      code: '2016',
      key: '991',
      name: 'רוויה',
      post_code: '1092000',
      post_code_5: '10920',
    },
    {
      code: '2018',
      key: '992',
      name: 'גבעת חיים (איחוד)',
      post_code: '3893500',
      post_code_5: '38935',
    },
    {
      code: '2021',
      key: '993',
      name: 'אשל הנשיא',
      post_code: '8531000',
      post_code_5: '85310',
    },
    {
      code: '2023',
      key: '994',
      name: 'להב',
      post_code: '8533500',
      post_code_5: '85335',
    },
    {
      code: '2024',
      key: '995',
      name: 'אום אל-קוטוף',
      post_code: '3785700',
      post_code_5: '37857',
    },
    {
      code: '2026',
      key: '996',
      name: 'ירדנה',
      post_code: '1087000',
      post_code_5: '10870',
    },
    {
      code: '2029',
      key: '997',
      name: 'מדרך עוז',
      post_code: '1923500',
      post_code_5: '19235',
    },
    {
      code: '2030',
      key: '998',
      name: 'מנוחה',
      post_code: '7953500',
      post_code_5: '79535',
    },
    {
      code: '2032',
      key: '999',
      name: 'נווה אפעל',
      post_code: '5296002',
      post_code_5: '52960',
    },
    {
      code: '2033',
      key: '1000',
      name: 'בית חלקיה',
      post_code: '7681500',
      post_code_5: '76815',
    },
    {
      code: '2034',
      key: '1001',
      name: 'חצור הגלילית',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2035',
      key: '1002',
      name: 'עדנים',
      post_code: '4592500',
      post_code_5: '45925',
    },
    {
      code: '2038',
      key: '1003',
      name: 'ברקת',
      post_code: '7318500',
      post_code_5: '73185',
    },
    {
      code: '2039',
      key: '1004',
      name: 'קרית יערים(מוסד)',
      post_code: '9084000',
      post_code_5: '90840',
    },
    {
      code: '2042',
      key: '1005',
      name: 'עין גדי',
      post_code: '8698000',
      post_code_5: '86980',
    },
    {
      code: '2043',
      key: '1006',
      name: 'בחן',
      post_code: '3882700',
      post_code_5: '38827',
    },
    {
      code: '2044',
      key: '1007',
      name: 'מלילות',
      post_code: '8539000',
      post_code_5: '85390',
    },
    {
      code: '2045',
      key: '1008',
      name: 'נחלה',
      post_code: '7954000',
      post_code_5: '79540',
    },
    {
      code: '2046',
      key: '1009',
      name: 'סגולה',
      post_code: '7953000',
      post_code_5: '79530',
    },
    {
      code: '2047',
      key: '1010',
      name: 'ניר משה',
      post_code: '8537000',
      post_code_5: '85370',
    },
    {
      code: '2048',
      key: '1011',
      name: 'ניר עקיבא',
      post_code: '8536500',
      post_code_5: '85365',
    },
    {
      code: '2049',
      key: '1012',
      name: 'שדה צבי',
      post_code: '8534000',
      post_code_5: '85340',
    },
    {
      code: '2050',
      key: '1013',
      name: 'תלמי ביל"ו',
      post_code: '8537500',
      post_code_5: '85375',
    },
    {
      code: '2051',
      key: '1014',
      name: 'רווחה',
      post_code: '7935300',
      post_code_5: '79353',
    },
    {
      code: '2052',
      key: '1015',
      name: 'אביטל',
      post_code: '1936500',
      post_code_5: '19365',
    },
    {
      code: '2053',
      key: '1016',
      name: 'פרזון',
      post_code: '1935500',
      post_code_5: '19355',
    },
    {
      code: '2054',
      key: '1017',
      name: 'מיטב',
      post_code: '1936000',
      post_code_5: '19360',
    },
    {
      code: '2055',
      key: '1018',
      name: 'מאור',
      post_code: '3883000',
      post_code_5: '38830',
    },
    {
      code: '2057',
      key: '1019',
      name: 'שדי תרומות',
      post_code: '1083500',
      post_code_5: '10835',
    },
    {
      code: '2059',
      key: '1020',
      name: 'פעמי תש"ז',
      post_code: '8534500',
      post_code_5: '85345',
    },
    {
      code: '2060',
      key: '1021',
      name: 'ברוש',
      post_code: '8539600',
      post_code_5: '85396',
    },
    {
      code: '2061',
      key: '1022',
      name: 'תדהר',
      post_code: '8539400',
      post_code_5: '85394',
    },
    {
      code: '2062',
      key: '1023',
      name: 'תאשור',
      post_code: '8539800',
      post_code_5: '85398',
    },
    {
      code: '2063',
      key: '1024',
      name: 'דישון',
      post_code: '1382500',
      post_code_5: '13825',
    },
    {
      code: '2064',
      key: '1025',
      name: 'זרועה',
      post_code: '8538000',
      post_code_5: '85380',
    },
    {
      code: '2100',
      key: '1026',
      name: 'טירת כרמל',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2200',
      key: '1027',
      name: 'דימונה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2300',
      key: '1028',
      name: 'קריית טבעון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2500',
      key: '1030',
      name: 'נשר',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2530',
      key: '1031',
      name: 'באר יעקב',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2550',
      key: '1032',
      name: 'גדרה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2560',
      key: '1033',
      name: 'ערד',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2600',
      key: '1034',
      name: 'אילת',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2610',
      key: '1035',
      name: 'בית שמש',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2620',
      key: '1036',
      name: 'קריית אונו',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2630',
      key: '1037',
      name: 'קריית גת',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2640',
      key: '1038',
      name: 'ראש העין',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2650',
      key: '1039',
      name: 'רמת השרון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2660',
      key: '1040',
      name: 'יבנה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '2710',
      key: '1041',
      name: 'אום אל-פחם',
      post_code: '3001000',
      post_code_5: '30010',
    },
    {
      code: '2720',
      key: '1042',
      name: 'טירה',
      post_code: '4491500',
      post_code_5: '44915',
    },
    {
      code: '2730',
      key: '1043',
      name: 'טייבה',
      post_code: '4040000',
      post_code_5: '40400',
    },
    {
      code: '2742',
      key: '1044',
      name: 'זבארגה (שבט)',
      post_code: '8480000',
      post_code_5: '84800',
    },
    {
      code: '2800',
      key: '1045',
      name: 'קריית שמונה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '3000',
      key: '1046',
      name: 'ירושלים',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '3488',
      key: '1048',
      name: 'כפר עציון',
      post_code: '9091200',
      post_code_5: '90912',
    },
    {
      code: '3555',
      key: '1049',
      name: 'נתיב הגדוד',
      post_code: '9067600',
      post_code_5: '90676',
    },
    {
      code: '3556',
      key: '1050',
      name: 'אלמוג',
      post_code: '9066500',
      post_code_5: '90665',
    },
    {
      code: '3557',
      key: '1051',
      name: 'קדומים',
      post_code: '4485600',
      post_code_5: '44856',
    },
    {
      code: '3558',
      key: '1052',
      name: 'תומר',
      post_code: '9068000',
      post_code_5: '90680',
    },
    {
      code: '3560',
      key: '1053',
      name: 'אלקנה',
      post_code: '4481400',
      post_code_5: '44814',
    },
    {
      code: '3561',
      key: '1054',
      name: 'מגדל עוז',
      post_code: '9091500',
      post_code_5: '90915',
    },
    {
      code: '3563',
      key: '1055',
      name: 'תקוע',
      post_code: '9090800',
      post_code_5: '90908',
    },
    {
      code: '3564',
      key: '1056',
      name: 'כוכב השחר',
      post_code: '9064100',
      post_code_5: '90641',
    },
    {
      code: '3565',
      key: '1057',
      name: 'רימונים',
      post_code: '9063600',
      post_code_5: '90636',
    },
    {
      code: '3566',
      key: '1058',
      name: 'יפית',
      post_code: '9068500',
      post_code_5: '90685',
    },
    {
      code: '3567',
      key: '1059',
      name: 'סלעית',
      post_code: '4588500',
      post_code_5: '45885',
    },
    {
      code: '3568',
      key: '1060',
      name: 'ריחן',
      post_code: '3787000',
      post_code_5: '37870',
    },
    {
      code: '3569',
      key: '1061',
      name: 'מבוא דותן',
      post_code: '4489000',
      post_code_5: '44890',
    },
    {
      code: '3570',
      key: '1062',
      name: 'אריאל',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '3571',
      key: '1063',
      name: 'שבי שומרון',
      post_code: '4485800',
      post_code_5: '44858',
    },
    {
      code: '3572',
      key: '1064',
      name: 'כפר תפוח',
      post_code: '4482900',
      post_code_5: '44829',
    },
    {
      code: '3573',
      key: '1065',
      name: 'נוה צוף',
      post_code: '7194500',
      post_code_5: '71945',
    },
    {
      code: '3574',
      key: '1066',
      name: 'בית אל',
      post_code: '9063100',
      post_code_5: '90631',
    },
    {
      code: '3575',
      key: '1067',
      name: 'בית חורון',
      post_code: '9093500',
      post_code_5: '90935',
    },
    {
      code: '3576',
      key: '1068',
      name: 'מצפה יריחו',
      post_code: '9065100',
      post_code_5: '90651',
    },
    {
      code: '3578',
      key: '1069',
      name: 'שדמות מחולה',
      post_code: '1093200',
      post_code_5: '10932',
    },
    {
      code: '3579',
      key: '1070',
      name: 'אלון מורה',
      post_code: '4483300',
      post_code_5: '44833',
    },
    {
      code: '3598',
      key: '1071',
      name: 'ארגמן',
      post_code: '9069200',
      post_code_5: '90692',
    },
    {
      code: '3599',
      key: '1072',
      name: 'מחולה',
      post_code: '1093000',
      post_code_5: '10930',
    },
    {
      code: '3601',
      key: '1073',
      name: 'קליה',
      post_code: '9066600',
      post_code_5: '90666',
    },
    {
      code: '3602',
      key: '1074',
      name: 'ראש צורים',
      post_code: '9093800',
      post_code_5: '90938',
    },
    {
      code: '3603',
      key: '1075',
      name: 'הר גילה',
      post_code: '9090700',
      post_code_5: '90907',
    },
    {
      code: '3604',
      key: '1076',
      name: 'אלון שבות',
      post_code: '9043300',
      post_code_5: '90433',
    },
    {
      code: '3605',
      key: '1077',
      name: 'משואה',
      post_code: '9069000',
      post_code_5: '90690',
    },
    {
      code: '3606',
      key: '1078',
      name: 'גלגל',
      post_code: '9067400',
      post_code_5: '90674',
    },
    {
      code: '3607',
      key: '1079',
      name: 'ייט"ב',
      post_code: '9067000',
      post_code_5: '90670',
    },
    {
      code: '3608',
      key: '1080',
      name: 'מעלה אפרים',
      post_code: '9063800',
      post_code_5: '90638',
    },
    {
      code: '3609',
      key: '1081',
      name: 'חמרה',
      post_code: '9069700',
      post_code_5: '90697',
    },
    {
      code: '3610',
      key: '1082',
      name: 'מצפה שלם',
      post_code: '8698300',
      post_code_5: '86983',
    },
    {
      code: '3611',
      key: '1083',
      name: 'קריית ארבע',
      post_code: '9010000',
      post_code_5: '90100',
    },
    {
      code: '3612',
      key: '1084',
      name: 'בקעות',
      post_code: '9069400',
      post_code_5: '90694',
    },
    {
      code: '3613',
      key: '1085',
      name: 'גיתית',
      post_code: '9064500',
      post_code_5: '90645',
    },
    {
      code: '3614',
      key: '1086',
      name: 'מכורה',
      post_code: '9069800',
      post_code_5: '90698',
    },
    {
      code: '3615',
      key: '1087',
      name: 'פצאל',
      post_code: '9068200',
      post_code_5: '90682',
    },
    {
      code: '3616',
      key: '1088',
      name: 'מעלה אדומים',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '3617',
      key: '1089',
      name: 'עופרה',
      post_code: '9062700',
      post_code_5: '90627',
    },
    {
      code: '3618',
      key: '1090',
      name: 'אלעזר',
      post_code: '9094200',
      post_code_5: '90942',
    },
    {
      code: '3619',
      key: '1091',
      name: 'רועי',
      post_code: '9069600',
      post_code_5: '90696',
    },
    {
      code: '3620',
      key: '1092',
      name: 'נערן',
      post_code: '9067200',
      post_code_5: '90672',
    },
    {
      code: '3637',
      key: '1093',
      name: 'מעלה שומרון',
      post_code: '4485200',
      post_code_5: '44852',
    },
    {
      code: '3638',
      key: '1094',
      name: 'כפר אדומים',
      post_code: '9061800',
      post_code_5: '90618',
    },
    {
      code: '3639',
      key: '1095',
      name: 'ורד יריחו',
      post_code: '9066800',
      post_code_5: '90668',
    },
    {
      code: '3640',
      key: '1096',
      name: 'קרני שומרון',
      post_code: '4485500',
      post_code_5: '44855',
    },
    {
      code: '3641',
      key: '1097',
      name: 'שילה',
      post_code: '4483000',
      post_code_5: '44830',
    },
    {
      code: '3643',
      key: '1099',
      name: 'חיננית',
      post_code: '3786700',
      post_code_5: '37867',
    },
    {
      code: '3644',
      key: '1100',
      name: 'גבעון החדשה',
      post_code: '9090100',
      post_code_5: '90901',
    },
    {
      code: '3645',
      key: '1101',
      name: 'בית הערבה',
      post_code: '9068700',
      post_code_5: '90687',
    },
    {
      code: '3646',
      key: '1102',
      name: 'חמדת',
      post_code: '9069500',
      post_code_5: '90695',
    },
    {
      code: '3647',
      key: '1103',
      name: 'יקיר',
      post_code: '4484300',
      post_code_5: '44843',
    },
    {
      code: '3648',
      key: '1104',
      name: 'מתתיהו',
      post_code: '7191700',
      post_code_5: '71917',
    },
    {
      code: '3649',
      key: '1105',
      name: 'שקד',
      post_code: '3786200',
      post_code_5: '37862',
    },
    {
      code: '3650',
      key: '1106',
      name: 'אפרת',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '3651',
      key: '1107',
      name: 'מעלה מכמש',
      post_code: '9063400',
      post_code_5: '90634',
    },
    {
      code: '3652',
      key: '1108',
      name: 'בית אריה',
      post_code: '7194700',
      post_code_5: '71947',
    },
    {
      code: '3653',
      key: '1109',
      name: 'מעלה עמוס',
      post_code: '9096600',
      post_code_5: '90966',
    },
    {
      code: '3654',
      key: '1110',
      name: 'ברקן',
      post_code: '4482000',
      post_code_5: '44820',
    },
    {
      code: '3655',
      key: '1111',
      name: 'ניל"י',
      post_code: '7193000',
      post_code_5: '71930',
    },
    {
      code: '3656',
      key: '1112',
      name: 'כרמל',
      post_code: '9040400',
      post_code_5: '90404',
    },
    {
      code: '3657',
      key: '1113',
      name: 'מעון',
      post_code: '9041000',
      post_code_5: '90410',
    },
    {
      code: '3658',
      key: '1114',
      name: 'עטרת',
      post_code: '7193900',
      post_code_5: '71939',
    },
    {
      code: '3659',
      key: '1115',
      name: 'פסגות',
      post_code: '9062400',
      post_code_5: '90624',
    },
    {
      code: '3660',
      key: '1116',
      name: 'עמנואל',
      post_code: '4484500',
      post_code_5: '44845',
    },
    {
      code: '3709',
      key: '1117',
      name: 'מבוא חורון',
      post_code: '9976500',
      post_code_5: '99765',
    },
    {
      code: '3710',
      key: '1118',
      name: 'ברכה',
      post_code: '4483500',
      post_code_5: '44835',
    },
    {
      code: '3712',
      key: '1120',
      name: 'ענב',
      post_code: '4485700',
      post_code_5: '44857',
    },
    {
      code: '3713',
      key: '1121',
      name: 'נעמ"ה',
      post_code: '9066900',
      post_code_5: '90669',
    },
    {
      code: '3715',
      key: '1122',
      name: 'עלמון',
      post_code: '9062000',
      post_code_5: '90620',
    },
    {
      code: '3717',
      key: '1123',
      name: 'חרמש',
      post_code: '4489500',
      post_code_5: '44895',
    },
    {
      code: '3719',
      key: '1124',
      name: 'תלם',
      post_code: '9040900',
      post_code_5: '90409',
    },
    {
      code: '3720',
      key: '1125',
      name: 'שערי תקווה',
      post_code: '4481000',
      post_code_5: '44810',
    },
    {
      code: '3722',
      key: '1126',
      name: 'אשכולות',
      post_code: '8537700',
      post_code_5: '85377',
    },
    {
      code: '3723',
      key: '1127',
      name: 'פני חבר',
      post_code: '9042000',
      post_code_5: '90420',
    },
    {
      code: '3724',
      key: '1128',
      name: 'נגוהות',
      post_code: '7939000',
      post_code_5: '79390',
    },
    {
      code: '3725',
      key: '1129',
      name: 'נווה דניאל',
      post_code: '9090900',
      post_code_5: '90909',
    },
    {
      code: '3726',
      key: '1130',
      name: 'נוקדים',
      post_code: '9091600',
      post_code_5: '90916',
    },
    {
      code: '3727',
      key: '1131',
      name: 'עלי זהב',
      post_code: '7194900',
      post_code_5: '71949',
    },
    {
      code: '3730',
      key: '1133',
      name: 'גבעת זאב',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '3743',
      key: '1134',
      name: 'טנא',
      post_code: '9040800',
      post_code_5: '90408',
    },
    {
      code: '3745',
      key: '1135',
      name: 'מצדות יהודה',
      post_code: '9044000',
      post_code_5: '90440',
    },
    {
      code: '3746',
      key: '1136',
      name: 'קריית נטפים',
      post_code: '4481500',
      post_code_5: '44815',
    },
    {
      code: '3747',
      key: '1137',
      name: 'דולב',
      post_code: '7193500',
      post_code_5: '71935',
    },
    {
      code: '3748',
      key: '1138',
      name: 'עתניאל',
      post_code: '9040700',
      post_code_5: '90407',
    },
    {
      code: '3749',
      key: '1139',
      name: 'יצהר',
      post_code: '4483100',
      post_code_5: '44831',
    },
    {
      code: '3750',
      key: '1140',
      name: 'אלפי מנשה',
      post_code: '4485100',
      post_code_5: '44851',
    },
    {
      code: '3751',
      key: '1141',
      name: 'מגדלים',
      post_code: '9064200',
      post_code_5: '90642',
    },
    {
      code: '3752',
      key: '1142',
      name: 'מעלה לבונה',
      post_code: '4482500',
      post_code_5: '44825',
    },
    {
      code: '3754',
      key: '1143',
      name: 'אספר',
      post_code: '9096500',
      post_code_5: '90965',
    },
    {
      code: '3756',
      key: '1144',
      name: 'סוסיה',
      post_code: '9040100',
      post_code_5: '90401',
    },
    {
      code: '3759',
      key: '1146',
      name: 'אדורה',
      post_code: '9041500',
      post_code_5: '90415',
    },
    {
      code: '3760',
      key: '1147',
      name: 'אורנית',
      post_code: '4481300',
      post_code_5: '44813',
    },
    {
      code: '3762',
      key: '1148',
      name: 'איתמר',
      post_code: '4483400',
      post_code_5: '44834',
    },
    {
      code: '3763',
      key: '1149',
      name: 'גבע בנימין',
      post_code: '9063200',
      post_code_5: '90632',
    },
    {
      code: '3764',
      key: '1150',
      name: 'חגי',
      post_code: '9043000',
      post_code_5: '90430',
    },
    {
      code: '3765',
      key: '1151',
      name: 'עלי',
      post_code: '4482800',
      post_code_5: '44828',
    },
    {
      code: '3766',
      key: '1152',
      name: 'כרמי צור',
      post_code: '9040000',
      post_code_5: '90400',
    },
    {
      code: '3767',
      key: '1153',
      name: 'נחליאל',
      post_code: '7193800',
      post_code_5: '71938',
    },
    {
      code: '3768',
      key: '1154',
      name: 'פדואל',
      post_code: '7194000',
      post_code_5: '71940',
    },
    {
      code: '3769',
      key: '1155',
      name: 'הר אדר',
      post_code: '9083600',
      post_code_5: '90836',
    },
    {
      code: '3770',
      key: '1156',
      name: 'חשמונאים',
      post_code: '7312700',
      post_code_5: '73127',
    },
    {
      code: '3778',
      key: '1157',
      name: 'עץ אפרים',
      post_code: '4481600',
      post_code_5: '44816',
    },
    {
      code: '3779',
      key: '1158',
      name: 'כוכב יעקב',
      post_code: '9062200',
      post_code_5: '90622',
    },
    {
      code: '3780',
      key: '1159',
      name: 'ביתר עילית',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '3781',
      key: '1160',
      name: 'קדר',
      post_code: '9061200',
      post_code_5: '90612',
    },
    {
      code: '3782',
      key: '1161',
      name: 'רותם',
      post_code: '1093100',
      post_code_5: '10931',
    },
    {
      code: '3784',
      key: '1162',
      name: 'שמעה',
      post_code: '9040600',
      post_code_5: '90406',
    },
    {
      code: '3785',
      key: '1163',
      name: 'משכיות',
      post_code: '1093500',
      post_code_5: '10935',
    },
    {
      code: '3786',
      key: '1164',
      name: 'אבנת',
      post_code: '9065600',
      post_code_5: '90656',
    },
    {
      code: '3787',
      key: '1165',
      name: 'נעלה',
      post_code: '7193200',
      post_code_5: '71932',
    },
    {
      code: '3788',
      key: '1166',
      name: 'טלמון',
      post_code: '7193700',
      post_code_5: '71937',
    },
    {
      code: '3790',
      key: '1167',
      name: 'נופים',
      post_code: '4484100',
      post_code_5: '44841',
    },
    {
      code: '3791',
      key: '1168',
      name: 'צופים',
      post_code: '4486500',
      post_code_5: '44865',
    },
    {
      code: '3793',
      key: '1169',
      name: 'אבני חפץ',
      post_code: '4486100',
      post_code_5: '44861',
    },
    {
      code: '3794',
      key: '1170',
      name: 'בת עין',
      post_code: '9091300',
      post_code_5: '90913',
    },
    {
      code: '3795',
      key: '1171',
      name: 'רבבה',
      post_code: '4483900',
      post_code_5: '44839',
    },
    {
      code: '3796',
      key: '1172',
      name: 'כפר האורנים',
      post_code: '7313400',
      post_code_5: '73134',
    },
    {
      code: '3797',
      key: '1173',
      name: 'מודיעין עילית',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '4001',
      key: '1175',
      name: 'בוקעאתא',
      post_code: '1243700',
      post_code_5: '12437',
    },
    {
      code: '4002',
      key: '1176',
      name: 'אלי-עד',
      post_code: '1292700',
      post_code_5: '12927',
    },
    {
      code: '4003',
      key: '1177',
      name: 'אל-רום',
      post_code: '1246600',
      post_code_5: '12466',
    },
    {
      code: '4004',
      key: '1178',
      name: 'כפר חרוב',
      post_code: '1293200',
      post_code_5: '12932',
    },
    {
      code: '4005',
      key: '1179',
      name: 'חספין',
      post_code: '1292000',
      post_code_5: '12920',
    },
    {
      code: '4006',
      key: '1180',
      name: 'קשת',
      post_code: '1241000',
      post_code_5: '12410',
    },
    {
      code: '4007',
      key: '1181',
      name: 'יונתן',
      post_code: '1241500',
      post_code_5: '12415',
    },
    {
      code: '4008',
      key: '1182',
      name: 'מעלה גמלא',
      post_code: '1294900',
      post_code_5: '12949',
    },
    {
      code: '4009',
      key: '1183',
      name: 'שעל',
      post_code: '1242000',
      post_code_5: '12420',
    },
    {
      code: '4010',
      key: '1184',
      name: 'אודם',
      post_code: '1247300',
      post_code_5: '12473',
    },
    {
      code: '4011',
      key: '1185',
      name: 'אבני איתן',
      post_code: '1292500',
      post_code_5: '12925',
    },
    {
      code: '4012',
      key: '1186',
      name: 'אניעם',
      post_code: '1249500',
      post_code_5: '12495',
    },
    {
      code: '4013',
      key: '1187',
      name: 'אורטל',
      post_code: '1243000',
      post_code_5: '12430',
    },
    {
      code: '4014',
      key: '1188',
      name: 'נטור',
      post_code: '1291500',
      post_code_5: '12915',
    },
    {
      code: '4015',
      key: '1189',
      name: 'בני יהודה',
      post_code: '1294400',
      post_code_5: '12944',
    },
    {
      code: '4017',
      key: '1190',
      name: 'אלוני הבשן',
      post_code: '1241200',
      post_code_5: '12412',
    },
    {
      code: '4019',
      key: '1191',
      name: 'מיצר',
      post_code: '1293600',
      post_code_5: '12936',
    },
    {
      code: '4021',
      key: '1192',
      name: 'גבעת יואב',
      post_code: '1294600',
      post_code_5: '12946',
    },
    {
      code: '4022',
      key: '1193',
      name: 'גשור',
      post_code: '1294200',
      post_code_5: '12942',
    },
    {
      code: '4024',
      key: '1194',
      name: 'קלע',
      post_code: '1242200',
      post_code_5: '12422',
    },
    {
      code: '4025',
      key: '1195',
      name: 'קדמת צבי',
      post_code: '1242100',
      post_code_5: '12421',
    },
    {
      code: '4026',
      key: '1196',
      name: 'חד-נס',
      post_code: '1295000',
      post_code_5: '12950',
    },
    {
      code: '4028',
      key: '1197',
      name: 'כנף',
      post_code: '1293000',
      post_code_5: '12930',
    },
    {
      code: '4100',
      key: '1198',
      name: 'קצרין',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '4101',
      key: '1199',
      name: 'מרום גולן',
      post_code: '1243600',
      post_code_5: '12436',
    },
    {
      code: '4201',
      key: '1200',
      name: 'מג\'דל שמס',
      post_code: '1243800',
      post_code_5: '12438',
    },
    {
      code: '4203',
      key: '1201',
      name: 'מסעדה',
      post_code: '1243500',
      post_code_5: '12435',
    },
    {
      code: '4204',
      key: '1202',
      name: 'מבוא חמה',
      post_code: '1293400',
      post_code_5: '12934',
    },
    {
      code: '4301',
      key: '1203',
      name: 'אפיק',
      post_code: '1293800',
      post_code_5: '12938',
    },
    {
      code: '4303',
      key: '1204',
      name: 'נווה אטי"ב',
      post_code: '1249300',
      post_code_5: '12493',
    },
    {
      code: '4304',
      key: '1205',
      name: 'נוב',
      post_code: '1292100',
      post_code_5: '12921',
    },
    {
      code: '4501',
      key: '1206',
      name: 'ע\'ג\'ר',
      post_code: '1244000',
      post_code_5: '12440',
    },
    {
      code: '4502',
      key: '1207',
      name: 'עין קנייא',
      post_code: '1243200',
      post_code_5: '12432',
    },
    {
      code: '4503',
      key: '1208',
      name: 'עין זיוון',
      post_code: '1242600',
      post_code_5: '12426',
    },
    {
      code: '4551',
      key: '1209',
      name: 'נאות גולן',
      post_code: '1294000',
      post_code_5: '12940',
    },
    {
      code: '4701',
      key: '1210',
      name: 'רמת מגשימים',
      post_code: '1291700',
      post_code_5: '12917',
    },
    {
      code: '4702',
      key: '1211',
      name: 'רמות',
      post_code: '1294800',
      post_code_5: '12948',
    },
    {
      code: '5000',
      key: '1212',
      name: 'תל אביב - יפו',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6100',
      key: '1231',
      name: 'בני ברק',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6200',
      key: '1232',
      name: 'בת ים',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6300',
      key: '1233',
      name: 'גבעתיים',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6400',
      key: '1234',
      name: 'הרצליה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6500',
      key: '1235',
      name: 'חדרה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6600',
      key: '1236',
      name: 'חולון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6700',
      key: '1237',
      name: 'טבריה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6800',
      key: '1238',
      name: 'קריית אתא',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '6900',
      key: '1239',
      name: 'כפר סבא',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7000',
      key: '1240',
      name: 'לוד',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7100',
      key: '1241',
      name: 'אשקלון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7200',
      key: '1242',
      name: 'נס ציונה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7300',
      key: '1243',
      name: 'נצרת',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7400',
      key: '1244',
      name: 'נתניה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7500',
      key: '1245',
      name: 'סח\'נין',
      post_code: '3081000',
      post_code_5: '30810',
    },
    {
      code: '7600',
      key: '1246',
      name: 'עכו',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7700',
      key: '1247',
      name: 'עפולה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7800',
      key: '1248',
      name: 'פרדס חנה-כרכור',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '7900',
      key: '1249',
      name: 'פתח תקווה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '8000',
      key: '1250',
      name: 'צפת',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '8200',
      key: '1251',
      name: 'קריית מוצקין',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '8300',
      key: '1252',
      name: 'ראשון לציון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '8400',
      key: '1253',
      name: 'רחובות',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '8500',
      key: '1254',
      name: 'רמלה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '8700',
      key: '1256',
      name: 'רעננה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '8800',
      key: '1257',
      name: 'שפרעם',
      post_code: '2020000',
      post_code_5: '20200',
    },
    {
      code: '8900',
      key: '1258',
      name: 'טמרה',
      post_code: '3081100',
      post_code_5: '30811',
    },
    {
      code: '9000',
      key: '1259',
      name: 'באר שבע',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '9100',
      key: '1260',
      name: 'נהרייה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '9200',
      key: '1261',
      name: 'בית שאן',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '9300',
      key: '1262',
      name: 'זכרון יעקב',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '9400',
      key: '1263',
      name: 'יהוד-מונוסון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '9500',
      key: '1264',
      name: 'קריית ביאליק',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '9600',
      key: '1265',
      name: 'קריית ים',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '9700',
      key: '1266',
      name: 'הוד השרון',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1335',
      key: '1268',
      name: 'ערב אל נעים',
      post_code: '2019400',
      post_code_5: '20194',
    },
    {
      code: '1361',
      key: '1269',
      name: 'בת חן',
      post_code: '4029000',
      post_code_5: '40290',
    },
    {
      code: '0',
      key: '1271',
      name: 'אוהלו',
      post_code: '1511000',
      post_code_5: '15110',
    },
    {
      code: '0',
      key: '1274',
      name: 'אילנות',
      post_code: '4281200',
      post_code_5: '42812',
    },
    {
      code: '1725',
      key: '1275',
      name: 'ביה\'ס אזורי מטה אשר',
      post_code: '2512100',
      post_code_5: '25121',
    },
    {
      code: '0',
      key: '1276',
      name: 'בית אל ב\'',
      post_code: '9062800',
      post_code_5: '90628',
    },
    {
      code: '0',
      key: '1278',
      name: 'בית ירח',
      post_code: '1511500',
      post_code_5: '15115',
    },
    {
      code: '3744',
      key: '1279',
      name: 'ברוכין',
      post_code: '7194600',
      post_code_5: '71946',
    },
    {
      code: '0',
      key: '1280',
      name: 'ברורים',
      post_code: '7983700',
      post_code_5: '79837',
    },
    {
      code: '1740',
      key: '1283',
      name: 'גבעת חביבה',
      post_code: '3785000',
      post_code_5: '37850',
    },
    {
      code: '0',
      key: '1284',
      name: 'הדסים',
      post_code: '4284500',
      post_code_5: '42845',
    },
    {
      code: '0',
      key: '1285',
      name: 'ורד הגליל',
      post_code: '1292800',
      post_code_5: '12928',
    },
    {
      code: '0',
      key: '1286',
      name: 'חוות השומר',
      post_code: '1525000',
      post_code_5: '15250',
    },
    {
      code: '0',
      key: '1287',
      name: 'חוות שלם',
      post_code: '6019500',
      post_code_5: '60195',
    },
    {
      code: '0',
      key: '1288',
      name: 'חצרות געש',
      post_code: '6095100',
      post_code_5: '60951',
    },
    {
      code: '0',
      key: '1289',
      name: 'חצרות חפר',
      post_code: '3889500',
      post_code_5: '38895',
    },
    {
      code: '0',
      key: '1292',
      name: 'יבולה',
      post_code: '8528000',
      post_code_5: '85280',
    },
    {
      code: '0',
      key: '1293',
      name: 'יחד',
      post_code: '2019300',
      post_code_5: '20193',
    },
    {
      code: '0',
      key: '1294',
      name: 'ימין אורד',
      post_code: '3089500',
      post_code_5: '30895',
    },
    {
      code: '0',
      key: '1295',
      name: 'כפר אל עוברה',
      post_code: '8532200',
      post_code_5: '85322',
    },
    {
      code: '0',
      key: '1296',
      name: 'כפר אליהו',
      post_code: '7070500',
      post_code_5: '70705',
    },
    {
      code: '0',
      key: '1297',
      name: 'כפר הבפטיסטים',
      post_code: '4587500',
      post_code_5: '45875',
    },
    {
      code: '0',
      key: '1298',
      name: 'כפר הירוק',
      post_code: '4780000',
      post_code_5: '47800',
    },
    {
      code: '0',
      key: '1299',
      name: 'כפר זמיר',
      post_code: '3086200',
      post_code_5: '30862',
    },
    {
      code: '0',
      key: '1300',
      name: 'כפר מרמורק',
      post_code: '7610400',
      post_code_5: '76104',
    },
    {
      code: '0',
      key: '1301',
      name: 'כפר נחום',
      post_code: '1293100',
      post_code_5: '12931',
    },
    {
      code: '0',
      key: '1302',
      name: 'מבואות החרמון',
      post_code: '1227000',
      post_code_5: '12270',
    },
    {
      code: '0',
      key: '1303',
      name: 'מבחור',
      post_code: '7935400',
      post_code_5: '79354',
    },
    {
      code: '1722',
      key: '1304',
      name: 'מגדל תפן',
      post_code: '2495900',
      post_code_5: '24959',
    },
    {
      code: '0',
      key: '1305',
      name: 'מכון וינגייט',
      post_code: '4290200',
      post_code_5: '42902',
    },
    {
      code: '0',
      key: '1306',
      name: 'מנזר לטרון',
      post_code: '9976200',
      post_code_5: '99762',
    },
    {
      code: '0',
      key: '1307',
      name: 'מסמיה',
      post_code: '7981300',
      post_code_5: '79813',
    },
    {
      code: '0',
      key: '1308',
      name: 'מעני אבו חאמד',
      post_code: '8492800',
      post_code_5: '84928',
    },
    {
      code: '1791',
      key: '1309',
      name: 'א.ת. ברקן',
      post_code: '4482100',
      post_code_5: '44821',
    },
    {
      code: '1745',
      key: '1311',
      name: 'פארק תעשיות עמק חפר',
      post_code: '3877701',
      post_code_5: '38777',
    },
    {
      code: '1753',
      key: '1312',
      name: 'מפעלי כנות',
      post_code: '7982501',
      post_code_5: '79825',
    },
    {
      code: '1701',
      key: '1313',
      name: 'מפעלי נחם הר טוב',
      post_code: '9980101',
      post_code_5: '99801',
    },
    {
      code: '0',
      key: '1314',
      name: 'א. תעשיה באר טוביה',
      post_code: '8381600',
      post_code_5: '83816',
    },
    {
      code: '0',
      key: '1315',
      name: 'מצדה',
      post_code: '8693500',
      post_code_5: '86935',
    },
    {
      code: '1723',
      key: '1317',
      name: 'מרכז אזורי משגב',
      post_code: '2017402',
      post_code_5: '20174',
    },
    {
      code: '0',
      key: '1318',
      name: 'מרכז למחקר גרעיני',
      post_code: '8180000',
      post_code_5: '81800',
    },
    {
      code: '0',
      key: '1320',
      name: 'מרעית',
      post_code: '7918500',
      post_code_5: '79185',
    },
    {
      code: '0',
      key: '1321',
      name: 'משגב',
      post_code: '2017900',
      post_code_5: '20179',
    },
    {
      code: '0',
      key: '1323',
      name: 'נווה הדסה',
      post_code: '4581000',
      post_code_5: '45810',
    },
    {
      code: '0',
      key: '1324',
      name: 'נווה הרצוג',
      post_code: '7923000',
      post_code_5: '79230',
    },
    {
      code: '0',
      key: '1325',
      name: 'נווה יער',
      post_code: '3657500',
      post_code_5: '36575',
    },
    {
      code: '1748',
      key: '1329',
      name: 'נתב"ג',
      post_code: '7010000',
      post_code_5: '70100',
    },
    {
      code: '1775',
      key: '1330',
      name: 'סדום',
      post_code: '8695000',
      post_code_5: '86950',
    },
    {
      code: '1762',
      key: '1331',
      name: 'עד הלום',
      post_code: '7898800',
      post_code_5: '78988',
    },
    {
      code: '3792',
      key: '1332',
      name: 'עופרים',
      post_code: '7194800',
      post_code_5: '71948',
    },
    {
      code: '0',
      key: '1334',
      name: 'עין בוקק',
      post_code: '8693000',
      post_code_5: '86930',
    },
    {
      code: '0',
      key: '1335',
      name: 'עין כמונים',
      post_code: '2010900',
      post_code_5: '20109',
    },
    {
      code: '0',
      key: '1337',
      name: 'עכברה',
      post_code: '1391500',
      post_code_5: '13915',
    },
    {
      code: '0',
      key: '1338',
      name: 'עמור',
      post_code: '8491300',
      post_code_5: '84913',
    },
    {
      code: '0',
      key: '1339',
      name: 'ערב אזונר',
      post_code: '2480500',
      post_code_5: '24805',
    },
    {
      code: '0',
      key: '1340',
      name: 'ערב אל גנאדי',
      post_code: '2020002',
      post_code_5: '20200',
    },
    {
      code: '0',
      key: '1341',
      name: 'ערב אל היב',
      post_code: '1794500',
      post_code_5: '17945',
    },
    {
      code: '0',
      key: '1342',
      name: 'ערב אל ווהב',
      post_code: '2013100',
      post_code_5: '20131',
    },
    {
      code: '0',
      key: '1343',
      name: 'ערב אל חרנאבה',
      post_code: '2013200',
      post_code_5: '20132',
    },
    {
      code: '0',
      key: '1344',
      name: 'ערב אל כעביה',
      post_code: '3600000',
      post_code_5: '36000',
    },
    {
      code: '0',
      key: '1345',
      name: 'ערב אל מוריסאת',
      post_code: '2481000',
      post_code_5: '24810',
    },
    {
      code: '0',
      key: '1346',
      name: 'ערב אל נגדאת',
      post_code: '1600000',
      post_code_5: '16000',
    },
    {
      code: '0',
      key: '1347',
      name: 'ערב אל סגיראת',
      post_code: '2020003',
      post_code_5: '20200',
    },
    {
      code: '0',
      key: '1348',
      name: 'ערב אל סמניה',
      post_code: '2020004',
      post_code_5: '20200',
    },
    {
      code: '0',
      key: '1349',
      name: 'ערב אל עיסאת',
      post_code: '2013300',
      post_code_5: '20133',
    },
    {
      code: '0',
      key: '1350',
      name: 'ערב אל עיצאת',
      post_code: '3657300',
      post_code_5: '36573',
    },
    {
      code: '0',
      key: '1351',
      name: 'ערב אל פמאז',
      post_code: '1600001',
      post_code_5: '16000',
    },
    {
      code: '0',
      key: '1354',
      name: 'ערב טוואפרה',
      post_code: '2013400',
      post_code_5: '20134',
    },
    {
      code: '0',
      key: '1355',
      name: 'ערב סעדיה',
      post_code: '3600300',
      post_code_5: '36003',
    },
    {
      code: '1714',
      key: '1356',
      name: 'פארק תעשיות הגליל הת',
      post_code: '1522800',
      post_code_5: '15228',
    },
    {
      code: '0',
      key: '1357',
      name: 'פארק תעשיות ומסחר שי',
      post_code: '7318900',
      post_code_5: '73189',
    },
    {
      code: '1729',
      key: '1358',
      name: 'פארק תעשיות חבל מודי',
      post_code: '7319900',
      post_code_5: '73199',
    },
    {
      code: '1709',
      key: '1359',
      name: 'פארק תעשיות רמת דלתו',
      post_code: '1381100',
      post_code_5: '13811',
    },
    {
      code: '0',
      key: '1360',
      name: 'ציקלון',
      post_code: '2014000',
      post_code_5: '20140',
    },
    {
      code: '0',
      key: '1361',
      name: 'צריפין',
      post_code: '6093000',
      post_code_5: '60930',
    },
    {
      code: '0',
      key: '1363',
      name: 'קריית חוף',
      post_code: '2281000',
      post_code_5: '22810',
    },
    {
      code: '1746',
      key: '1365',
      name: 'קריית שדה התעופה',
      post_code: '7019900',
      post_code_5: '70199',
    },
    {
      code: '1770',
      key: '1367',
      name: 'רמת חובב',
      post_code: '8551600',
      post_code_5: '85516',
    },
    {
      code: '0',
      key: '1368',
      name: 'שבטה',
      post_code: '8551400',
      post_code_5: '85514',
    },
    {
      code: '0',
      key: '1370',
      name: 'תל השומר',
      post_code: '5262000',
      post_code_5: '52620',
    },
    {
      code: '1707',
      key: '1371',
      name: 'מכללת תל חי',
      post_code: '1220800',
      post_code_5: '12208',
    },
    {
      code: '0',
      key: '1372',
      name: 'פארק תעשיות ראם',
      post_code: '7984100',
      post_code_5: '79841',
    },
    {
      code: '1749',
      key: '1373',
      name: 'תעשיון צריפין',
      post_code: '7316700',
      post_code_5: '73167',
    },
    {
      code: '1728',
      key: '1374',
      name: 'בר-לב',
      post_code: '2015600',
      post_code_5: '20156',
    },
    {
      code: '0',
      key: '1375',
      name: 'תרדיון',
      post_code: '2017400',
      post_code_5: '20174',
    },
    {
      code: '1106',
      key: '1376',
      name: 'שערי אברהם',
      post_code: '7981700',
      post_code_5: '79817',
    },
    {
      code: '3824',
      key: '1377',
      name: 'עמיחי',
      post_code: '4483800',
      post_code_5: '44838',
    },
    {
      code: '0',
      key: '1379',
      name: 'אבן העזר',
      post_code: '9980200',
      post_code_5: '99802',
    },
    {
      code: '0',
      key: '1380',
      name: 'מנועי בית שמש',
      post_code: '9980600',
      post_code_5: '99806',
    },
    {
      code: '0',
      key: '1381',
      name: 'חוות גילת',
      post_code: '8531100',
      post_code_5: '85311',
    },
    {
      code: '0',
      key: '1383',
      name: 'צומת בית קמה',
      post_code: '8532800',
      post_code_5: '85328',
    },
    {
      code: '0',
      key: '1384',
      name: 'מ.א. בני שמעון',
      post_code: '8532400',
      post_code_5: '85324',
    },
    {
      code: '0',
      key: '1385',
      name: 'חבל מעון',
      post_code: '8546700',
      post_code_5: '85467',
    },
    {
      code: '0',
      key: '1386',
      name: 'משקי חבל עזה',
      post_code: '8540400',
      post_code_5: '85404',
    },
    {
      code: '1773',
      key: '1388',
      name: 'מפעלי מישור רותם',
      post_code: '8680600',
      post_code_5: '86806',
    },
    {
      code: '0',
      key: '1389',
      name: 'מפעל כימיקלים',
      post_code: '8680900',
      post_code_5: '86809',
    },
    {
      code: '0',
      key: '1391',
      name: 'ביה"ס שדה - חצבה',
      post_code: '8681400',
      post_code_5: '86814',
    },
    {
      code: '0',
      key: '1392',
      name: 'עין יהב - מסעדה',
      post_code: '8682100',
      post_code_5: '86821',
    },
    {
      code: '0',
      key: '1393',
      name: 'מפעלי ערבה',
      post_code: '8682700',
      post_code_5: '86827',
    },
    {
      code: '0',
      key: '1394',
      name: 'מ.א. רמת הנגב',
      post_code: '8551900',
      post_code_5: '85519',
    },
    {
      code: '0',
      key: '1395',
      name: 'מסוף ניצנה',
      post_code: '8551800',
      post_code_5: '85518',
    },
    {
      code: '0',
      key: '1396',
      name: 'מ.א. תמר',
      post_code: '8691100',
      post_code_5: '86911',
    },
    {
      code: '0',
      key: '1402',
      name: 'אכסנית נוער מצדה',
      post_code: '8693600',
      post_code_5: '86936',
    },
    {
      code: '0',
      key: '1403',
      name: 'שמורת עין גדי',
      post_code: '8698400',
      post_code_5: '86984',
    },
    {
      code: '0',
      key: '1404',
      name: 'אכסנית עין גדי',
      post_code: '8698100',
      post_code_5: '86981',
    },
    {
      code: '0',
      key: '1405',
      name: 'ביה"ס שדה - עין גדי',
      post_code: '8698200',
      post_code_5: '86982',
    },
    {
      code: '0',
      key: '1406',
      name: 'מעבדות מצפה שלם',
      post_code: '8698401',
      post_code_5: '86984',
    },
    {
      code: '0',
      key: '1408',
      name: 'חניון יוטבתה',
      post_code: '8882001',
      post_code_5: '88820',
    },
    {
      code: '0',
      key: '1409',
      name: 'מסוף ערבה',
      post_code: '8886300',
      post_code_5: '88863',
    },
    {
      code: '0',
      key: '1410',
      name: 'מ.א. נחל שורק',
      post_code: '7681100',
      post_code_5: '76811',
    },
    {
      code: '0',
      key: '1412',
      name: 'מכללת אחווה',
      post_code: '7980400',
      post_code_5: '79804',
    },
    {
      code: '0',
      key: '1415',
      name: 'מ.א. חוף אשקלון',
      post_code: '7912100',
      post_code_5: '79121',
    },
    {
      code: '0',
      key: '1416',
      name: 'בית חטיבת גבעתי',
      post_code: '7934100',
      post_code_5: '79341',
    },
    {
      code: '0',
      key: '1418',
      name: 'גן לאומי - בית גוברי',
      post_code: '7937100',
      post_code_5: '79371',
    },
    {
      code: '0',
      key: '1425',
      name: 'ביה"ח פרדסיה',
      post_code: '4281600',
      post_code_5: '42816',
    },
    {
      code: '0',
      key: '1431',
      name: 'א. תעשיה צחר',
      post_code: '1231400',
      post_code_5: '12314',
    },
    {
      code: '0',
      key: '1434',
      name: 'שמורת תל-דן',
      post_code: '1220003',
      post_code_5: '12200',
    },
    {
      code: '0',
      key: '1438',
      name: 'צומת יוקנעם',
      post_code: '2069209',
      post_code_5: '20692',
    },
    {
      code: '0',
      key: '1439',
      name: 'חמי טבריה',
      post_code: '1510600',
      post_code_5: '15106',
    },
    {
      code: '0',
      key: '1442',
      name: 'מ.א. מטה אשר',
      post_code: '2280000',
      post_code_5: '22800',
    },
    {
      code: '0',
      key: '1443',
      name: 'פארק תעשיה גורן',
      post_code: '2285100',
      post_code_5: '22851',
    },
    {
      code: '0',
      key: '1444',
      name: 'מ.א. מעלה יוסף',
      post_code: '2285101',
      post_code_5: '22851',
    },
    {
      code: '0',
      key: '1447',
      name: 'מ.א. גליל תחתון',
      post_code: '1524800',
      post_code_5: '15248',
    },
    {
      code: '0',
      key: '1449',
      name: 'משקי עמק חפר',
      post_code: '3877702',
      post_code_5: '38777',
    },
    {
      code: '0',
      key: '1450',
      name: 'מבואות עירון',
      post_code: '3881700',
      post_code_5: '38817',
    },
    {
      code: '0',
      key: '1451',
      name: 'מ.א. מנשה',
      post_code: '3780900',
      post_code_5: '37809',
    },
    {
      code: '0',
      key: '1452',
      name: 'ביה"ס חוף הכרמל',
      post_code: '3082400',
      post_code_5: '30824',
    },
    {
      code: '0',
      key: '1453',
      name: 'נחל מערות',
      post_code: '3085600',
      post_code_5: '30856',
    },
    {
      code: '0',
      key: '1457',
      name: 'מכללת עמק יזרעאל',
      post_code: '1930600',
      post_code_5: '19306',
    },
    {
      code: '0',
      key: '1458',
      name: 'מ.א. יזרעאל',
      post_code: '1892200',
      post_code_5: '18922',
    },
    {
      code: '0',
      key: '1459',
      name: 'עתיקות מגידו',
      post_code: '1920501',
      post_code_5: '19205',
    },
    {
      code: '0',
      key: '1460',
      name: 'גבעת ניח (ג\'וערה)',
      post_code: '1923400',
      post_code_5: '19234',
    },
    {
      code: '0',
      key: '1461',
      name: 'הרי אפריים',
      post_code: '1923300',
      post_code_5: '19233',
    },
    {
      code: '0',
      key: '1462',
      name: 'מוסד נעמן',
      post_code: '2521900',
      post_code_5: '25219',
    },
    {
      code: '0',
      key: '1463',
      name: 'ביה"ח מיזרע',
      post_code: '2520200',
      post_code_5: '25202',
    },
    {
      code: '0',
      key: '1464',
      name: 'משטרת עירון',
      post_code: '3786400',
      post_code_5: '37864',
    },
    {
      code: '0',
      key: '1467',
      name: 'מפרץ אמנון',
      post_code: '1293501',
      post_code_5: '12935',
    },
    {
      code: '0',
      key: '1470',
      name: 'גן תעשיה-לבון',
      post_code: '2011801',
      post_code_5: '20118',
    },
    {
      code: '0',
      key: '1474',
      name: 'משטרת משגב',
      post_code: '2017401',
      post_code_5: '20174',
    },
    {
      code: '0',
      key: '1476',
      name: 'ארזה-מעון הרופא',
      post_code: '9082100',
      post_code_5: '90821',
    },
    {
      code: '0',
      key: '1481',
      name: 'מ.א. מגילות',
      post_code: '9066700',
      post_code_5: '90667',
    },
    {
      code: '0',
      key: '1487',
      name: 'בית האלה',
      post_code: '9985100',
      post_code_5: '99851',
    },
    {
      code: '0',
      key: '1488',
      name: 'תחנת תקשורת לווינים',
      post_code: '9985400',
      post_code_5: '99854',
    },
    {
      code: '0',
      key: '1492',
      name: 'מ.א. מרחבים',
      post_code: '8510300',
      post_code_5: '85103',
    },
    {
      code: '0',
      key: '1494',
      name: 'מסוף קרני',
      post_code: '8514600',
      post_code_5: '85146',
    },
    {
      code: '0',
      key: '1495',
      name: 'יזרעם',
      post_code: '8515800',
      post_code_5: '85158',
    },
    {
      code: '0',
      key: '1496',
      name: 'אסם הנגב',
      post_code: '8533900',
      post_code_5: '85339',
    },
    {
      code: '0',
      key: '1497',
      name: 'ביה"ס מרחב',
      post_code: '8553400',
      post_code_5: '85534',
    },
    {
      code: '0',
      key: '1500',
      name: 'מ.א. אשכול',
      post_code: '8553100',
      post_code_5: '85531',
    },
    {
      code: '0',
      key: '1501',
      name: 'ביה"ס מעלה הבשור',
      post_code: '8546600',
      post_code_5: '85466',
    },
    {
      code: '0',
      key: '1502',
      name: 'חוות הבשור',
      post_code: '8544100',
      post_code_5: '85441',
    },
    {
      code: '0',
      key: '1506',
      name: 'אייטש איי ישראל בע"מ',
      post_code: '8680400',
      post_code_5: '86804',
    },
    {
      code: '0',
      key: '1507',
      name: 'פמ"א',
      post_code: '8680800',
      post_code_5: '86808',
    },
    {
      code: '0',
      key: '1508',
      name: 'פריקלאס',
      post_code: '8680700',
      post_code_5: '86807',
    },
    {
      code: '0',
      key: '1510',
      name: 'מוסך ברין',
      post_code: '8681700',
      post_code_5: '86817',
    },
    {
      code: '0',
      key: '1511',
      name: 'מפעל אגרקסקו',
      post_code: '8682800',
      post_code_5: '86828',
    },
    {
      code: '0',
      key: '1512',
      name: 'מפעל סולו',
      post_code: '8682600',
      post_code_5: '86826',
    },
    {
      code: '0',
      key: '1516',
      name: 'מ.א. חבל אילות',
      post_code: '8882011',
      post_code_5: '88820',
    },
    {
      code: '0',
      key: '1544',
      name: 'מ.א. יואב',
      post_code: '7981601',
      post_code_5: '79816',
    },
    {
      code: '0',
      key: '1547',
      name: 'ביה"ס החקלאי להזרעה',
      post_code: '7985300',
      post_code_5: '79853',
    },
    {
      code: '0',
      key: '1548',
      name: 'מפעל על בד',
      post_code: '7985900',
      post_code_5: '79859',
    },
    {
      code: '0',
      key: '1557',
      name: 'חוות השקמים',
      post_code: '7917600',
      post_code_5: '79176',
    },
    {
      code: '0',
      key: '1558',
      name: 'מ.א. שער הנגב',
      post_code: '7916700',
      post_code_5: '79167',
    },
    {
      code: '0',
      key: '1559',
      name: 'ביה"ס שער',
      post_code: '7918200',
      post_code_5: '79182',
    },
    {
      code: '0',
      key: '1560',
      name: 'מכללת הנגב',
      post_code: '7915600',
      post_code_5: '79156',
    },
    {
      code: '0',
      key: '1563',
      name: 'ביה"ס שקמים',
      post_code: '7985202',
      post_code_5: '79852',
    },
    {
      code: '0',
      key: '1564',
      name: 'מ.א. חבל יבנה',
      post_code: '7924000',
      post_code_5: '79240',
    },
    {
      code: '0',
      key: '1568',
      name: 'שבות רחל',
      post_code: '4483200',
      post_code_5: '44832',
    },
    {
      code: '0',
      key: '1569',
      name: 'ישיבת עוד יוסף חי',
      post_code: '4483700',
      post_code_5: '44837',
    },
    {
      code: '0',
      key: '1571',
      name: 'ביה"ס חקלאי - נווה ה',
      post_code: '4581400',
      post_code_5: '45814',
    },
    {
      code: '0',
      key: '1573',
      name: 'רשות החולה',
      post_code: '1210501',
      post_code_5: '12105',
    },
    {
      code: '0',
      key: '1575',
      name: 'גן הצפון',
      post_code: '1222100',
      post_code_5: '12221',
    },
    {
      code: '0',
      key: '1576',
      name: 'ביה"ס שדה חרמון',
      post_code: '1220001',
      post_code_5: '12200',
    },
    {
      code: '0',
      key: '1577',
      name: 'חורשת טל',
      post_code: '1220002',
      post_code_5: '12200',
    },
    {
      code: '0',
      key: '1578',
      name: 'גן השלושה',
      post_code: '1080000',
      post_code_5: '10800',
    },
    {
      code: '0',
      key: '1580',
      name: 'מאזריב',
      post_code: '3657201',
      post_code_5: '36572',
    },
    {
      code: '0',
      key: '1581',
      name: 'עיידאת',
      post_code: '3657200',
      post_code_5: '36572',
    },
    {
      code: '0',
      key: '1582',
      name: 'גריפאת',
      post_code: '3659300',
      post_code_5: '36593',
    },
    {
      code: '0',
      key: '1585',
      name: 'משטרת החופים',
      post_code: '1512100',
      post_code_5: '15121',
    },
    {
      code: '0',
      key: '1587',
      name: 'משטרת צמח',
      post_code: '1512101',
      post_code_5: '15121',
    },
    {
      code: '0',
      key: '1589',
      name: 'ביח"ר ספן',
      post_code: '1515300',
      post_code_5: '15153',
    },
    {
      code: '0',
      key: '1591',
      name: 'מילואות',
      post_code: '1032900',
      post_code_5: '10329',
    },
    {
      code: '1712',
      key: '1592',
      name: 'ביה"ח פוריה',
      post_code: '1528001',
      post_code_5: '15280',
    },
    {
      code: '0',
      key: '1593',
      name: 'ביה"ס שדה אלון תבור',
      post_code: '1524300',
      post_code_5: '15243',
    },
    {
      code: '0',
      key: '1599',
      name: 'מפעלי נון',
      post_code: '3089100',
      post_code_5: '30891',
    },
    {
      code: '0',
      key: '1600',
      name: 'כלא שטה',
      post_code: '1080900',
      post_code_5: '10809',
    },
    {
      code: '0',
      key: '1601',
      name: 'כלא גלבוע',
      post_code: '1080800',
      post_code_5: '10808',
    },
    {
      code: '0',
      key: '1604',
      name: 'מסוף גבול - ג\'למה',
      post_code: '1935900',
      post_code_5: '19359',
    },
    {
      code: '0',
      key: '1606',
      name: 'מ.א. מרום הגליל',
      post_code: '1387600',
      post_code_5: '13876',
    },
    {
      code: '0',
      key: '1608',
      name: 'איגוד ערים',
      post_code: '2521101',
      post_code_5: '25211',
    },
    {
      code: '0',
      key: '1610',
      name: 'חופים',
      post_code: '2521100',
      post_code_5: '25211',
    },
    {
      code: '0',
      key: '1614',
      name: 'גל עם',
      post_code: '3785400',
      post_code_5: '37854',
    },
    {
      code: '0',
      key: '1616',
      name: 'תעשיות גולן',
      post_code: '1291503',
      post_code_5: '12915',
    },
    {
      code: '0',
      key: '1617',
      name: 'חוף גולן',
      post_code: '1291501',
      post_code_5: '12915',
    },
    {
      code: '0',
      key: '1618',
      name: 'כינר',
      post_code: '1291502',
      post_code_5: '12915',
    },
    {
      code: '0',
      key: '1619',
      name: 'הר אושר',
      post_code: '1202000',
      post_code_5: '12020',
    },
    {
      code: '0',
      key: '1622',
      name: 'פירות הבשן',
      post_code: '1243300',
      post_code_5: '12433',
    },
    {
      code: '0',
      key: '1624',
      name: 'אולפני ג.ג',
      post_code: '9085100',
      post_code_5: '90851',
    },
    {
      code: '0',
      key: '1629',
      name: 'מסוף אלנבי',
      post_code: '9069300',
      post_code_5: '90693',
    },
    {
      code: '0',
      key: '1637',
      name: 'עארה',
      post_code: '3002500',
      post_code_5: '30025',
    },
    {
      code: '0',
      key: '1639',
      name: 'מ.א. מגידו',
      post_code: '1893300',
      post_code_5: '18933',
    },
    {
      code: '0',
      key: '1641',
      name: 'מ.א. גלבוע',
      post_code: '1894400',
      post_code_5: '18944',
    },
    {
      code: '0',
      key: '1647',
      name: 'בית ג\'\'מאל',
      post_code: '9988600',
      post_code_5: '99886',
    },
    {
      code: '0',
      key: '1665',
      name: 'צמח',
      post_code: '1510501',
      post_code_5: '15105',
    },
    {
      code: '0',
      key: '1667',
      name: 'בניאס',
      post_code: '1225100',
      post_code_5: '12251',
    },
    {
      code: '0',
      key: '1668',
      name: 'מ.א. גדרות',
      post_code: '7600100',
      post_code_5: '76001',
    },
    {
      code: '1739',
      key: '1670',
      name: 'מפעלי גרנות',
      post_code: '3881100',
      post_code_5: '38811',
    },
    {
      code: '1864',
      key: '1674',
      name: 'מ.א. חוף הכרמל',
      post_code: '3086100',
      post_code_5: '30861',
    },
    {
      code: '0',
      key: '1675',
      name: 'יד לבנים',
      post_code: '1923100',
      post_code_5: '19231',
    },
    {
      code: '1751',
      key: '1680',
      name: 'נחל שורק',
      post_code: '7680202',
      post_code_5: '76802',
    },
    {
      code: '0',
      key: '1683',
      name: 'מ.א. עמק חפר',
      post_code: '4279900',
      post_code_5: '42799',
    },
    {
      code: '0',
      key: '1689',
      name: 'תמנע',
      post_code: '8880200',
      post_code_5: '88802',
    },
    {
      code: '195',
      key: '1690',
      name: 'קדימה',
      post_code: '6092000',
      post_code_5: '60920',
    },
    {
      code: '1308',
      key: '1691',
      name: 'צורן',
      post_code: '4282300',
      post_code_5: '42823',
    },
    {
      code: '1224',
      key: '1692',
      name: 'כוכב יאיר',
      post_code: '4486400',
      post_code_5: '44864',
    },
    {
      code: '1306',
      key: '1693',
      name: 'צור יגאל',
      post_code: '4486200',
      post_code_5: '44862',
    },
    {
      code: '9800',
      key: '1696',
      name: 'בנימינה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '50',
      key: '1697',
      name: 'גבעת עדה',
      post_code: '3780800',
      post_code_5: '37808',
    },
    {
      code: '494',
      key: '1698',
      name: 'דאלית אל-כרמל',
      post_code: '3005600',
      post_code_5: '30056',
    },
    {
      code: '534',
      key: '1699',
      name: 'עספיא',
      post_code: '3009000',
      post_code_5: '30090',
    },
    {
      code: '6000',
      key: '1700',
      name: 'באקה אל-גרביה',
      post_code: '3010000',
      post_code_5: '30100',
    },
    {
      code: '628',
      key: '1701',
      name: 'ג\'ת',
      post_code: '3009100',
      post_code_5: '30091',
    },
    {
      code: '490',
      key: '1707',
      name: 'דייר אל-אסד',
      post_code: '2018800',
      post_code_5: '20188',
    },
    {
      code: '483',
      key: '1708',
      name: 'בענה',
      post_code: '2018900',
      post_code_5: '20189',
    },
    {
      code: '986',
      key: '2741',
      name: 'ח\'ואלד (שבט)',
      post_code: '2029901',
      post_code_5: '20299',
    },
    {
      code: '3400',
      key: '2742',
      name: 'חברון',
      post_code: '9020000',
      post_code_5: '90200',
    },
    {
      code: '1345',
      key: '2761',
      name: 'צור יצחק',
      post_code: '4580800',
      post_code_5: '45808',
    },
    {
      code: '1419',
      key: '2762',
      name: 'ניצן ב\'',
      post_code: '7928800',
      post_code_5: '79288',
    },
    {
      code: '1367',
      key: '2763',
      name: 'כחלה',
      post_code: '8492100',
      post_code_5: '84921',
    },
    {
      code: '2400',
      key: '2769',
      name: 'אור יהודה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '8600',
      key: '2770',
      name: 'רמת גן',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '4000',
      key: '2779',
      name: 'חיפה',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '4991',
      key: '2780',
      name: 'קריית חיים תאי דואר',
      post_code: '0',
      post_code_5: '0',
    },
    {
      code: '1338',
      key: '2781',
      name: 'כדיתה',
      post_code: '1390000',
      post_code_5: '13900',
    },
    {
      code: '0',
      key: '2782',
      name: 'פארק אפק',
      post_code: '4809900',
      post_code_5: '48099',
    },
    {
      code: '1363',
      key: '2783',
      name: 'בני נצרים',
      post_code: '8548400',
      post_code_5: '85484',
    },
    {
      code: '1366',
      key: '2784',
      name: 'נוה',
      post_code: '8548500',
      post_code_5: '85485',
    },
    {
      code: '1370',
      key: '2785',
      name: 'מצפה אילן',
      post_code: '3786300',
      post_code_5: '37863',
    },
    {
      code: '1373',
      key: '2787',
      name: 'שלווה במדבר',
      post_code: '8553200',
      post_code_5: '85532',
    },
    {
      code: '1371',
      key: '2788',
      name: 'גני טל',
      post_code: '7681801',
      post_code_5: '76818',
    },
    {
      code: '1372',
      key: '2789',
      name: 'נצר חזני',
      post_code: '7681401',
      post_code_5: '76814',
    },
    {
      code: '1364',
      key: '2790',
      name: 'שלומית',
      post_code: '8548301',
      post_code_5: '85483',
    },
    {
      code: '1375',
      key: '2791',
      name: 'אבו תלול',
      post_code: '8491401',
      post_code_5: '84914',
    },
    {
      code: '0',
      key: '2792',
      name: 'פריגת',
      post_code: '3898300',
      post_code_5: '38983',
    },
    {
      code: '0',
      key: '2794',
      name: 'פארק תעשיות נועם',
      post_code: '8538700',
      post_code_5: '85387',
    },
    {
      code: '1765',
      key: '2795',
      name: 'פארק תעשיות עידן הנג',
      post_code: '8532700',
      post_code_5: '85327',
    },
    {
      code: '1341',
      key: '2802',
      name: 'רוח מדבר',
      post_code: '8552100',
      post_code_5: '85521',
    },
    {
      code: '1368',
      key: '2803',
      name: 'בני דקלים',
      post_code: '7936700',
      post_code_5: '79367',
    },
    {
      code: '1369',
      key: '2804',
      name: 'נטע',
      post_code: '7938500',
      post_code_5: '79385',
    },
    {
      code: '1376',
      key: '2805',
      name: 'באר גנים',
      post_code: '7928900',
      post_code_5: '79289',
    },
    {
      code: '3822',
      key: '2806',
      name: 'רחלים',
      post_code: '4482700',
      post_code_5: '44827',
    },
    {
      code: '0',
      key: '2807',
      name: 'גן תעשיה תל חי',
      post_code: '1220900',
      post_code_5: '12209',
    },
    {
      code: '1365',
      key: '2808',
      name: 'אליאב',
      post_code: '7938300',
      post_code_5: '79383',
    },
    {
      code: '1336',
      key: '2809',
      name: 'אירוס',
      post_code: '7680900',
      post_code_5: '76809',
    },
    {
      code: '1243',
      key: '2811',
      name: 'קציר',
      post_code: '3786800',
      post_code_5: '37868',
    },
    {
      code: '0',
      key: '2812',
      name: 'מפעלים אזוריים בית ש',
      post_code: '1082000',
      post_code_5: '10820',
    },
    {
      code: '0',
      key: '2813',
      name: 'א.ת. ניצני שלום',
      post_code: '4584800',
      post_code_5: '45848',
    },
    {
      code: '0',
      key: '2814',
      name: 'א.ת. שחורת',
      post_code: '8886700',
      post_code_5: '88867',
    },
    {
      code: '3823',
      key: '2815',
      name: 'גני מודיעין',
      post_code: '7192900',
      post_code_5: '71929',
    },
    {
      code: '4035',
      key: '2816',
      name: 'נמרוד',
      post_code: '1242400',
      post_code_5: '12424',
    },
    {
      code: '1374',
      key: '2817',
      name: 'כרמי קטיף',
      post_code: '7959800',
      post_code_5: '79598',
    },
    {
      code: '1377',
      key: '2818',
      name: 'שבי דרום',
      post_code: '8536900',
      post_code_5: '85369',
    },
    {
      code: '3825',
      key: '2846',
      name: 'מבואות יריחו',
      post_code: '9067100',
      post_code_5: '90671',
    },
    {
      code: '1763',
      key: '2847',
      name: 'פארק תעשיות ספירים',
      post_code: '7916900',
      post_code_5: '79169',
    },
  ];

export default cities;
