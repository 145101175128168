export default {
  details: [
    {index: 0,
      key: 'auto-info',
      label: 'פרטי רכב'},
    {index: 1,
      key: 'drivers',
      label: 'נוהגים ברכב'},
    {index: 2,
      key: 'insurance-history',
      label: 'עבר ביטוחי'},
  ],
};
