import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core';
import {agentDeveloperUtils} from 'wobi-web-common';

const TwoFaceButton = ({onText, offText, onClick}) => {
  const [isOn, setIsOn] = useState(false);

  const handleClick = () => {
    setIsOn(!isOn);
    onClick(!isOn);
  };

  return (
    <Button
      size='small'
      onClick={handleClick}
      style={{
        backgroundColor: isOn ? agentDeveloperUtils.getSecondaryColor(300) :
          agentDeveloperUtils.getPrimaryColor(450),
      }} // Change the background color based on the state
    >
      {isOn ? onText : offText}
    </Button>
  );
};

TwoFaceButton.propTypes = {
  offText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onText: PropTypes.string.isRequired,
};

export default TwoFaceButton;
