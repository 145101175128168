import React from 'react';
import {FormattedMessage} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {getImageByAgent} from '../../config/agent';

const BannerImage = getImageByAgent('banners/banner2b.svg');
const useStyles = makeStyles(theme => ({
  banner: {
    '& b': {color: theme.palette.secondary.main},
    '& img': {
      // [theme.breakpoints.down(1040)]: {width: 67},
      [theme.breakpoints.down(1430)]: {
        top: -38,
        width: 170,
      },
      left: '50%',
      maxWidth: 200,
      position: 'absolute',
      top: '-50%',
      transform: 'translateX(-50%)',
      width: '66%',
    },
    '& span, b': {
      display: 'block',
    },
    borderRadius: 47,
    boxShadow: '0 0 12px 0 #0000001F',
    color: theme.palette.secondary.mainLight,
    display: 'block',
    margin: '0 20px',
    maxWidth: 416,
    minWidth: 213,
    order: 0,
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      fontSize: '1em',
      margin: '130px -20px 0 20px',
      padding: '40px 26px 15px',
    },
    [theme.breakpoints.up(1430)]: {
      fontSize: '1.125em',
      margin: '80px auto 0',
      padding: '35px 26px 15px',
    },
  },
}));

const BannerStep2b = () => {
  const classes = useStyles();
  return (
    <div className={classes.banner}>
      <img src={BannerImage} alt='' role='presentation'/>
      <FormattedMessage id='drivers.banner2_1' tagName='span'/>
      <FormattedMessage id='drivers.banner2_2' tagName='b'/>
    </div>
  );
};

export default BannerStep2b;
