import {isValidEmail} from './common';

describe('isValidEmail', () => {
  it('should remove hidden symbols from a dirty email', () => {
    const dirtyEmail = 'e\u200Bxample@examp\u200Dle.com';
    const res = isValidEmail(dirtyEmail);
    expect(res).toBe(false);
  });

  it('should handle an email without hidden symbols', () => {
    const email = 'valid@example.com';
    const res = isValidEmail(email);
    expect(res).toBe(true);
  });

  it('should handle an empty email', () => {
    const email = '';
    const res = isValidEmail(email);
    expect(res).toBe('');
  });

  it('should handle an email with only hidden symbols', () => {
    const dirtyEmail = '\u200B\u200B\u200B\u200B';
    const res = isValidEmail(dirtyEmail);
    expect(res).toBe(false);
  });
});
