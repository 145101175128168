import {Breakpoints as breakpoints} from 'wobi-web-common';

export const useStyles = (theme) => ({
  datepickerError: {
    marginBottom: 5,
    marginTop: 0,
  },
  paper: {
    boxShadow: 'unset',
  },
  preDefineDates: {
    marginTop: 20,
  },
  root: {
    '& .container': {
      '&.with-banner': {
        '& .img-container': {display: 'none'},
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.up(1280)]: {'& .img-container': {display: 'block'}},
        [theme.breakpoints.down(768)]: {'& .img-container': {display: 'block'}},
      },
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 auto',
      flexFlow: 'column nowrap',
      height: '100%',
      justifyContent: 'flex-end',
      order: 1,
    },
    '& .form-container': {
      minHeight: 354,
      minWidth: 272,
    },
    '& .img-container': {
      '& > img': {
        maxWidth: 500,
        width: '100%',
      },
      '@media (min-width: 385px) and (max-width: 500px)': {
        margin: '10px auto 0',
        width: '80%',
      },
      '@media screen and (max-width: 340px)': {marginTop: 20},
      padding: '20px 45px 20px 0',
      textAlign: 'center',
      width: '100%',
      [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {padding: '10px'},
    },
    [theme.breakpoints.down('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
    display: 'flex',
    height: 'inherit',
    justifyContent: 'space-between',
  },
  submitBtn: {
    fontSize: 18,
    height: 52,
    marginTop: 30,
    width: '100%',
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    marginBottom: 10,
    marginTop: 12,
    paddingLeft: 30,
    paddingRight: 10,
  },
  title: {
    '& > span': {
      color: theme.palette.text.secondary,
      fontSize: 21,
    },
    '& h2': {
      color: theme.palette.secondary.main,
      fontSize: 56,
      fontWeight: 300,
      lineHeight: 1,
      margin: 0,
    },
  },
  tooltip: {
    marginTop: 7,
    position: 'absolute',
  },
  tooltipOverride: {
    [theme.breakpoints.down('md')]: {
      left: 0,
      position: 'relative',
      top: 8,
    },
    [theme.breakpoints.up('md')]: {
      left: 280,
      position: 'absolute',
      top: 21,
    },
    alignItems: 'center',
    height: 35,
  },
  wrapper: {
    '&>.MuiFormControl-root': {
      '& .Mui-error': {
        // position: 'absolute',
        top: 54,
      },
      marginTop: 12,
    },
    position: 'relative',
    width: 272,
  },
});
