import React from 'react';
import {injectIntl} from 'react-intl';
import propTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Breakpoints as breakpoints, Offer as GenericOffer} from 'wobi-web-common';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import companiesLogos from '../../assets/js/companiesLogos';
import ClosedDesktop from './ClosedDesktop';
import OpenMobile from './OpenMobile';
import OpenDesktop from './OpenDesktop';
import ClosedMobile from './ClosedMobile';

const Offer = (props) => {
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);
  const {isOpen} = props;
  const [creditCardCommission, setCreditCardCommission] = React.useState(0);
  const [creditCardCommissionDiscount, setCreditCardCommissionDiscount] = React.useState(0);

  const logo = companiesLogos.find(elm => elm.key === props.data.logo);

  let Component;
  if (isOpen && isMobile) {
    Component = OpenMobile;
  } else if (isOpen && !isMobile) {
    Component = OpenDesktop;
  } else if (!isOpen && isMobile) {
    Component = ClosedMobile;
  } else {
    Component = ClosedDesktop;
  }
  const hanelingFeePrice = agentDeveloperUtils.getCarHandlingFee();
  const isHendelingFee = agentDeveloperUtils.isUsingCarHandlingFee();
  const totalprice = isHendelingFee ? props.data.price + hanelingFeePrice : props.data.price;
  const priceAfterDiscountWithHandlingFee = isHendelingFee ?
    props.data.priceAfterDiscount + hanelingFeePrice :
    props.data.priceAfterDiscount;
  return (
    <Component
      {...props}
      data = {{...props.data, price: totalprice, priceAfterDiscount: priceAfterDiscountWithHandlingFee}}
      creditCardCommission={creditCardCommission}
      setCreditCardCommission={setCreditCardCommission}
      creditCardCommissionDiscount={creditCardCommissionDiscount}
      setCreditCardCommissionDiscount={setCreditCardCommissionDiscount}
      logo={logo}
    />
  );
};

Offer.propTypes = {
  ...GenericOffer.propTypes,
  closeContactUsHandler: propTypes.func.isRequired,
  openContactUs: propTypes.func.isRequired,
};

export default injectIntl(Offer);

