/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {FormattedMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import Moment from 'moment';
import {
  FormControlLabel,
  makeStyles,
  RadioGroup,
  MenuItem,
  Select,
  Button,
  Radio,
  Icon,
  TextField,
} from '@material-ui/core';
import {Green} from 'wobi-web-common/dist/Colors';
import {AutocompleteSingleSelect} from 'wobi-web-common/dist/components/Autocomplete';
import {EzFormControl, DatePicker, Breakpoints as breakpoints} from 'wobi-web-common';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import AlwaysOnTooltip from '../../../components/AlwaysOnTooltip';
import cities from '../../../config/cities';
import {getOffersFields, setOffersFields} from '../../../utils/persistOfferDetails';
import {conditions} from '../../../utils/formConditions';
import {calculateSeniority, getYoungDriverMaxDate, handleDriverSeniorityDateChange, isValidEmail} from '../../../utils/common';
import {fireGtmEvent} from '../../../utils/marketing.utils';
import {DRIVERS_COUNT} from '../../../constants/enums';
import {DRIVERS_KEYS} from '../../../constants/defaultValues';
import BannerStep2a from '../../../components/BannersDesktop-experiment/Banner2a';
import BannerStep2b from '../../../components/BannersDesktop-experiment/Banner2b';
import useKeyboardShortcut from '../../../utils/useKeyboardShortcut';

const maleFemaleSelectOptions = [
  {messageId: 'male',
    value: '1'},
  {messageId: 'female',
    value: '2'},
];

const youngDriverMaxDate = getYoungDriverMaxDate();
const youngDriverMinDate = Moment().subtract(85, 'year');
const initialFocusedDate = Moment().subtract(40, 'year');

const handleCitySelectChange = (value) => {
  if (value.length < 1) {
    return [];
  }
  return cities.filter(item => item.name.slice(0, value.length) === value).map(item => Object.assign(item, {
    label: item.name,
    value: item.code,
  }));
};

const DriversTab = ({
  history,
  className,
  intl,
  formikErrors,
  formikHandleChange,
  formikSetField,
  formikValues,
  formikValidateFrom,
  formikSetTouched,
}) => {
  const messages = (id) => intl.formatMessage({id}) || '';
  const classes = useStyles();
  const defaultSeniorityOptions = calculateSeniority(Moment('01/01/1950'), messages);
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);

  const [seniorityOptions, setSeniorityOptions] = useState(defaultSeniorityOptions);

  const [callFillkeys, setCallFillkeys] = useState(false);
  const [keysDrivers, setKeysDrivers] = useState({});
  const keys = ['Control', ' '];
  const handleKeyboardShortcut = useCallback(() => {
    setCallFillkeys(current => !current);
    setKeysDrivers(DRIVERS_KEYS);
  }, [setCallFillkeys]);
  useKeyboardShortcut(keys, handleKeyboardShortcut);
  if (Object.keys(keysDrivers).length) {
    if (formikValues.driversCount !== keysDrivers.driversCount) {
      formikValues.driversCount = keysDrivers.driversCount;
    }
    if (formikValues.youngDriverGender !== keysDrivers.youngDriverGender) {
      formikValues.youngDriverGender = keysDrivers.youngDriverGender;
    }
    if (formikValues.youngDriverBirthDate !== keysDrivers.youngDriverBirthDate) {
      formikValues.youngDriverBirthDate = keysDrivers.youngDriverBirthDate;
    }
    if (formikValues.youngDriverSeniority !== keysDrivers.youngDriverSeniority) {
      formikValues.youngDriverSeniority = keysDrivers.youngDriverSeniority;
    }
    if (formikValues.email !== keysDrivers.email) {
      formikValues.email = keysDrivers.email;
    }
    if (formikValues.city !== keysDrivers.city) {
      formikValues.city = keysDrivers.city;
    }
    if (formikValues.cityCode !== keysDrivers.cityCode) {
      formikValues.cityCode = keysDrivers.cityCode;
    }
  }

  const handleDateChange = (date) => handleDriverSeniorityDateChange(
    date,
    formikSetField,
    setSeniorityOptions,
    messages,
    'young',
  );

  const handleCityChange = (cityObject) => {
    if (!cityObject || !cityObject.name) {
      setCityFields('', '', '', '');
    } else {
      setCityFields(cityObject.name, cityObject.code, cityObject.post_code, cityObject.post_code_5);
    }
  };

  const setCityFields = (name, code, post_code, post_code_5) => {
    formikSetField('city', name);
    formikSetField('cityCode', code);
    formikSetField('cityPostCode', Number(post_code));
    formikSetField('cityPostCode5', Number(post_code_5));
  };

  const getCityFields = () => {
    const {city, cityCode, cityPostCode, cityPostCode5} = JSON.parse(sessionStorage.getItem('offers_fields'));

    if (!city || !Number.isFinite(cityCode) || !Number.isFinite(cityPostCode)) {
      return null;
    }

    return {
      cityCode,
      cityPostCode,
      cityPostCode5,
      label: city,
    };
  };

  const handleSubmit = async () => {
    const errors = await formikValidateFrom();
    const hasErrors = Object.keys(errors).some(field => Object.keys(driversTabValidation).includes(field));

    if (hasErrors) {
      Object.keys(driversTabValidation).forEach(field => formikSetTouched(field, true));
      return;
    }

    setOffersFields(formikValues);
    fireGtmEvent('ConfirmDriversDetails', {
      DriversGender: formikValues.youngDriverGender,
      DriversNumber: formikValues.driversCount,
      Email: formikValues.email || '',
    });

    history.push('#insurance-history');
  };

  const conditionalTooltip = formikKey => {
    if (formikValues[formikKey] === '') {
      return '';
    }
    return 'with-tooltip';
  };

  const isWithBanners = Number.parseInt(getOffersFields('experimentVariant'), 10) > 0;

  return (
    <div className={`drivers ${className} ${classes.root} ${isWithBanners && 'with-banner'}`}
      id='vertical-tabpanel-1' role='tabpanel'>
      <div className={classes.driversForm}>
        <div className={`${classes.marginBottomBig} ${classes.driversCount} ${conditionalTooltip('driversCount')}`}>
          <EzFormControl name='driversCount' label={messages('fields.drivers')} isInlineLabel={false}>
            <RadioGroup
              value={formikValues.driversCount}
              onChange={(event, value) => {
                formikSetField('driversCount', value);
                fireGtmEvent('DriversNumber', {value});
              }}
              aria-labelledby='driversCount-label'
              row>
              <FormControlLabel value={DRIVERS_COUNT.ONE}
                control={<Radio inputProps={{'aria-setsize': '3'}}/>} label={messages('fields.one_driver')}/>
              <FormControlLabel value={DRIVERS_COUNT.TWO}
                control={<Radio inputProps={{'aria-setsize': '3'}}/>} label={messages('fields.two_drivers')}/>
              <FormControlLabel value={DRIVERS_COUNT.EVERY}
                control={<Radio inputProps={{'aria-setsize': '3'}}/>} label={messages('fields.every_driver')}/>
              {tooltipIcon(formikValues, messages, classes)}
            </RadioGroup>
          </EzFormControl>
        </div>
        <div className={classes.fillTheDetailsTitle}>{messages('drivers.fill_the_details')}</div>
        <div className={`${classes.marginBottomBig} ${classes.gender} ${conditionalTooltip('youngDriverGender')}`}>
          <EzFormControl name='youngDriverGender' isInlineLabel={false}
            label={messages('fields.youngDriverGender')}>
            <RadioGroup
              value={formikValues.youngDriverGender}
              onChange={(event, value) => {
                formikSetField('youngDriverGender', value);
                fireGtmEvent('DriversGender', {value});
              }}
              aria-labelledby='youngDriverGender-label'
              row>
              {maleFemaleSelectOptions.map(item => (<FormControlLabel
                key={`gender-${item.value}`} value={item.value} control={<Radio/>}
                label={messages(`fields.${item.messageId}`)}/>))}
              <AlwaysOnTooltip show={formikValues.youngDriverGender === '2'}
                kind='Hat' showText
                bubbleClassName={classes.mainCarTooltipBubble}
                className={`${classes.tooltip} driversCountTooltip`}>
                {messages('drivers.females_pay_less')}
              </AlwaysOnTooltip>
            </RadioGroup>
          </EzFormControl>
        </div>
        <div className={classes.marginBottom}>
          <EzFormControl
            name='youngDriverBirthDate'
            label={messages('fields.youngDriverBirthDate')}
            labelFor='youngDriverBirthDate-picker'
            isLabelInChild
          >
            <DatePicker
              id='youngDriverBirthDate-picker'
              style={{width: isMobile ? '100%' : 281}}
              value={Moment(formikValues.youngDriverBirthDate, 'DD/MM/YYYY').toISOString()}
              invalidDateMessage='תאריך שגוי'
              maxDateMessage='לא ניתן לרכוש עבור מבוטח מתחת לגיל 17'
              minDateMessage='לא ניתן לרכוש עבור מבוטח מעבר לגיל 85'
              onChange={handleDateChange}
              autoOk
              maxDate={youngDriverMaxDate}
              minDate={youngDriverMinDate}
              initialFocusedDate={initialFocusedDate}
              variant='inline'/>
          </EzFormControl>
        </div>
        <div className={`${classes.marginBottom} ${isMobile ? '' : classes.customWidth}`}>
          <EzFormControl
            name='youngDriverSeniority'
            label={messages('fields.youngDriverSeniority')}
            labelFor='youngDriverSeniority-picker'
            isInputLabel
            disabled={!seniorityOptions.length || !formikValues.youngDriverBirthDate}
          >
            <Select
              id='youngDriverSeniority-picker'
              labelId='youngDriverSeniority-label'
              className={classes.fullWidth}
              disabled={!seniorityOptions.length || !formikValues.youngDriverBirthDate}
              value={formikValues.youngDriverSeniority}
              onChange={(event) => {
                formikHandleChange(event);
              }}
            >
              {seniorityOptions.map(option => (
                <MenuItem key={`youngDriverSeniority-${option.value}`} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </EzFormControl>
        </div>
        <div className={`${classes.marginBottom} ${isMobile ? '' : classes.customWidth} ${classes.zIndexHigh}`}>
          <EzFormControl
            name='email'
            label={<FormattedMessage id='fields.email.optional'/>}
            isLabelInChild
          >
            <TextField
              id='email-textfield'
              onChange={(event) => {
                formikHandleChange(event);
              }}
              onFocus={() => fireGtmEvent('CarEmail')}
              className={classes.fullWidth}
              value={formikValues.email}
            />
          </EzFormControl>
        </div>
        <div className={`${isMobile ? '' : classes.customWidth} ${classes.marginBottomBig} ${classes.zIndexHigh}`}>
          <EzFormControl name='city'>
            <AutocompleteSingleSelect
              inputLabel={messages('fields.city')}
              parentValue={formikValues.city}
              placeholder={messages('fields.city')}
              onInput={handleCitySelectChange}
              onSelect={(cityObject) => handleCityChange(cityObject)}
              defaultValue={getCityFields()}
            />
          </EzFormControl>
        </div>
        <Button className='submit' onClick={handleSubmit}
          aria-label={formikErrors && Object.keys(formikErrors).length ?
            `${messages('drivers.continue_to_insurance_history2')} ${messages('validation.needToFillAllFields')}` :
            null}>
          {messages('drivers.continue_to_insurance_history')}
          <Icon>arrow_back</Icon>
        </Button>
      </div>
      <div className='container'>
        {getOffersFields('experimentVariant') === '1' && <BannerStep2a />}
        {getOffersFields('experimentVariant') === '2' && <BannerStep2b />}
      </div>
    </div>
  );
};

const tooltipIcon = (formikValues, messages, classes) => (
  <div>
    {
      // eslint-disable-next-line no-negated-condition
      formikValues.driversCount !== DRIVERS_COUNT.EVERY ?
        <AlwaysOnTooltip
          show={formikValues.driversCount === DRIVERS_COUNT.ONE || formikValues.driversCount === DRIVERS_COUNT.TWO}
          kind='Hat' showText
          bubbleClassName={classes.mainCarTooltipBubble}
          className={`${classes.tooltip} driversCountTooltip`}>
          <FormattedMessage
            id='drivers.good_choice_drivers'
            values={{
              value: <span className={classes.driversCountTooltipExcellentText}>
                {messages('drivers.good_choice.excellent.text')}
              </span>,
            }}
          />
        </AlwaysOnTooltip> :
        <AlwaysOnTooltip show={formikValues.driversCount === DRIVERS_COUNT.EVERY}
          kind='Bulb' showText
          bubbleClassName={classes.mainCarTooltipBubble}
          className={`${classes.tooltip} driversCountTooltip`}>
          {messages('drivers.better_specific_drivers')}
        </AlwaysOnTooltip>
    }
  </div>
);

const useStyles = makeStyles(theme => ({
  customWidth: {
    maxWidth: 281,
    width: '100%',
  },
  driversCount: {
    '& .MuiFormControlLabel-root': {
      [theme.breakpoints.down(1343)]: {
        marginRight: 8,
      },
    },
    '& .MuiRadio-root + .MuiFormControlLabel-label': {
      [theme.breakpoints.down(1343)]: {
        fontSize: theme.typography.pxToRem(13.125),
        minWidth: 52,
        padding: '7px 8px',
        textAlign: 'center',
      },
    },
    maxWidth: 400,
  },
  driversCountTooltipExcellentText: {
    color: agentDeveloperUtils.getConvertedColor(Green[2]),
  },
  driversForm: {
    '& > div': {
      '&.with-tooltip .MuiFormHelperText-root.Mui-error': {
        height: 0,
        margin: 0,
        minHeight: 0,
        [theme.breakpoints.up(1181)]: {maxWidth: 'unset'},
      },
      display: 'flex',
      [theme.breakpoints.up(1181)]: {
        '&.with-tooltip': {maxWidth: 'unset'},
        '&.with-tooltip > .MuiFormControl-root': {marginRight: 20,
          width: 'auto'},
        alignItems: 'flex-end',
        flexFlow: 'row wrap',
      },
      [theme.breakpoints.down(1343)]: {
        alignItems: 'flex-start',
        flexFlow: 'column nowrap',
        justifyContent: 'flex-start',
      },
    },
    flex: '1 1 auto',
  },
  fillTheDetailsTitle: {
    color: theme.palette.secondary.darkLight,
    fontSize: theme.typography.fontSize,
    fontWeight: 700,
    marginBottom: 20,
  },
  fullWidth: {
    width: '100%',
  },
  gender: {
    maxWidth: 240,
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.fontSize,
    fontWeight: 500,
    marginBottom: 20,
  },
  mainCarTooltipBubble: {
    [theme.breakpoints.down(1343)]: {
      '&:before': {
        left: '72%',
        top: '100%',
        transform: 'rotate(180deg) translateX(50%)',
      },
      position: 'absolute',
      right: '-50%',
      textAlign: 'center',
      top: '-80%',
    },
  },
  marginBottom: {
    marginBottom: 10,
  },
  marginBottomBig: {
    '& .MuiFormLabel-root': {marginBottom: 12},
    marginBottom: 20,
  },
  root: {
    '& .container': {
      flex: '1 1 auto',
      marginTop: 25,
      width: '50%',
    },
    '& button[type=submit] ': {
      '& .MuiIcon-root': {
        marginLeft: 10,
      },
    },
    [theme.breakpoints.down(1343)]: {
      minWidth: 225,
    },
    '&.with-banner': {
      '& .container': {
        [theme.breakpoints.down(960)]: {
          display: 'none',
        },
      },
      display: 'flex',
    },
  },
  tooltip: {
    '&.driversCountTooltip': {padding: '15px 0 8px', position: 'relative', top: -4},
    '&.youngDriverGenderTooltip': {padding: '15px 0 8px', position: 'relative'},
    [theme.breakpoints.up(1181)]: {padding: '0 0 8px'},
    height: 55,
    [theme.breakpoints.up(1181)]: {padding: '0 0 8px'},
    padding: '12px 0 0',
    textAlign: 'right',
  },
  zIndexHigh: {
    position: 'relative',
    zIndex: 100,
  },
}));

DriversTab.propTypes = {
  className: PropTypes.string,
  formikErrors: PropTypes.object,
  formikHandleBlur: PropTypes.func,
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikValidateFrom: PropTypes.func,
  formikValues: PropTypes.object,
  history: PropTypes.object,
  intl: PropTypes.object,
};
export const driversTabValidation = {
  city: yup.string().required('שדה חובה'),
  driversCount: yup.number().required('שדה חובה'),
  email: yup.string().email('כתובת מייל לא תקינה').test('email', 'כתובת מייל לא תקינה', (email) => {
    if (!email) {
      return true;
    }
    return isValidEmail(email);
  }),
  youngDriverBirthDate: yup.mixed().required('שדה חובה')
    .test('validDate', 'תאריך שגוי', (date) => conditions.isValidDate(date))
    .test('underMax', 'לא ניתן לרכוש עבור מבוטח מתחת לגיל 17',
      (date) => conditions.isUnderMax(date, youngDriverMaxDate))
    .test('aboveMin', 'לא ניתן לרכוש עבור מבוטח מעבר לגיל 85',
      (date) => conditions.isAboveMin(date, youngDriverMinDate)),
  youngDriverGender: yup.number().required('שדה חובה'),
  youngDriverSeniority: yup.number().required('שדה חובה'),
};

export default injectIntl(withRouter(DriversTab));
