import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {getImageByAgent} from '../../config/agent';

const BannerImage = getImageByAgent('banners/banner3a.png');
const useStyles = makeStyles(theme => ({
  banner: {
    '& img': {
      [theme.breakpoints.up(1280)]: {width: 231},
      [theme.breakpoints.down(1280)]: {width: 213},
      [theme.breakpoints.up(1700)]: {maxWidth: 360, width: '44%'},
    },
    order: 0,
    overflow: 'hidden',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {display: 'none'},
    [theme.breakpoints.up('md')]: {
      display: 'block',
      margin: '74px -30px 0 0',
    },
    [theme.breakpoints.up(1280)]: {margin: 0},
  },
}));

const BannerStep3a = ({intl}) => {
  const classes = useStyles();
  const messages = id => intl.formatMessage({id});
  return (
    <div className={classes.banner}>
      <img src={BannerImage}
        alt={`${messages('insurance_history.banner3_1')} ${messages('insurance_history.banner3_2')}`} />
    </div>
  );
};

BannerStep3a.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(BannerStep3a);
