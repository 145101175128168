import React from 'react';
import PropTypes from 'prop-types';
import {Backdrop, Button, DialogTitle, Fade, makeStyles, Modal, Paper} from '@material-ui/core';
import {useIntl} from 'react-intl';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';

const useStyles = makeStyles(() => ({
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  modalBtn: {
    fontSize: 18,
    height: 52,
    margin: '0 20px 40px',
    width: '150px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px',
    maxHeight: 'calc(100% - 64px)',
    maxWidth: '600px',
    overflowY: 'auto',
    position: 'relative',
  },
}));

const ModalContainer = ({openModal, closeModalCallback, showCloseButton = true,
  showDefaultHeader = true, modalHeader = ''}) => {
  const classes = useStyles();
  const intl = useIntl();
  const messages = (id) => intl.formatMessage({id});

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={openModal}
      className={`MuiModal ${classes.modal}`}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Paper className={classes.paper}>
          <DialogTitle style={{color: agentDeveloperUtils.getSecondaryColor(300), textAlign: 'center'}}>
            {showDefaultHeader ? messages('simulator.offer.modal_header') :
              messages(modalHeader)}
          </DialogTitle>
          <div className={classes.modal}>
            <Button className={classes.modalBtn} onClick={() => closeModalCallback(true)}>
              {messages('simulator.offer.to_CRM')}
            </Button>
            {showCloseButton ? (
              <Button className={classes.modalBtn} onClick={() => closeModalCallback(false)}>
                {messages('offers.cancel')}
              </Button>
            ) : null}
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

ModalContainer.propTypes = {
  closeModalCallback: PropTypes.func,
  modalHeader: PropTypes.string,
  openModal: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showDefaultHeader: PropTypes.bool,
};

export default ModalContainer;
