const fullcoverPopularBrands = [
  // 'הונדה',
  312,

  // 'טויוטה',
  413,

  // 'יונדאי',
  481,

  // 'מאזדה',
  588,

  // 'מיצובישי',
  590,

  // 'סקודה',
  676,

  // 'סוזוקי',
  683,

  // 'ניסאן',
  624,

  // 'סיאט',
  778,

  // 'קיה מוטורס',
  885,
];

const thirdPartyPopularBrands = [
  // 'הונדה',
  312,

  // 'טויוטה',
  413,

  // 'יונדאי',
  481,

  // 'מאזדה',
  588,

  // 'מיצובישי',
  590,

  // 'סוזוקי',
  683,

  // 'פולקסווגן',
  724,

  // 'פורד',
  729,

  // 'שברולט',
  961,

  // 'סובארו',
  650,
];

export {fullcoverPopularBrands, thirdPartyPopularBrands};
