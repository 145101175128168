import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {useIntl} from 'react-intl';
import {Backdrop, Button, DialogTitle, Fade, Grid,
  makeStyles, Modal, Paper, TextField, MenuItem, FormHelperText} from '@material-ui/core';
import EzFormControl from 'wobi-web-common/dist/components/EzFormControl';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import {validIsraeliId} from 'wobi-web-common/dist/components/utils/validation';
import Loader from 'wobi-web-common/dist/components/Loader';
import {YesNoRadioGroup} from 'wobi-web-common/dist';
import useStyles from '../../components/Offer-inner-comps/useStyles';
import {updatePaymentPotential, updateSalesAuthenticationStep} from '../../utils/apiHandlers';
import {utils} from '../../utils/offerFieldsActions';
import {CardsLabels} from '../../enums';
import {setSimulatorOffersFields} from '../../utils/persistOfferDetails';
import {CREDITCARD_OWNER_RELATION_SELECT_OPTIONS} from '../../constants';
import {CREDITCARD_SIMULATOR_STATUSES} from '../../constants/enums';

const cardTypeEnum = {
  credit: 'CREDIT',
  debit: 'DEBIT',
  diners: 'DINERS',
};

const cardTypes = [{label: CardsLabels.Debit, value: cardTypeEnum.debit},
  {label: CardsLabels.Credit, value: cardTypeEnum.credit},
  {label: CardsLabels.Diners, value: cardTypeEnum.diners}];

const modalStyles = makeStyles((theme) => ({
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  modalBtn: {
    fontSize: 18,
    height: 52,
    margin: '0 20px 40px',
    width: '150px',
  },
  modalDialog: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px',
    maxHeight: 'calc(100% - 64px)',
    maxWidth: '600px',
    overflowY: 'auto',
    position: 'relative',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const yesNoOverriddenStyles = {
  inlineLabel: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};

const isNumericWithoutSpaces = (val) => /^\d+$/.test(val);
const NUMERIC_TEST_CASE = 'NUMERIC_TEST_CASE';

const ModalContainer = ({showModal, backToCrm, closeModal
  , isDebit, handleOnSubmit, message}) => {
  const classes = modalStyles();
  const intl = useIntl();
  const messages = (id) => intl.formatMessage({id});

  useEffect(() => {
    setSimulatorOffersFields({
      isSimulator: true,
    });
  }, []);

  return (
    <Modal
      open={showModal}
      className={`MuiModal ${classes.modal}`}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <Paper className={classes.paper}>
          <DialogTitle className={classes.modalDialog}>
            <div>
              {isDebit ?
                (<React.Fragment>
                  <div>
                    {messages('simulator.creditGuard.debitMessage1')}
                  </div>
                  {messages('simulator.creditGuard.debitMessage2')}
                </React.Fragment>) : message}
            </div>
          </DialogTitle>
          <div className={classes.row}>
            <div className={classes.modal}>
              <Button className={classes.modalBtn} onClick={() => isDebit ?
                handleOnSubmit(true) : backToCrm()}>
                {isDebit ? messages('simulator.creditGuard.yes') :
                  messages('simulator.creditGuard.backToCrm')}
              </Button>
            </div>
            <div className={classes.modal}>
              <Button className={classes.modalBtn} onClick={() => isDebit ?
                handleOnSubmit(false) : closeModal()}>
                {isDebit ? messages('simulator.creditGuard.no') :
                  messages('simulator.creditGuard.cancel')}
              </Button>
            </div>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );

  // eslint-disable-next-line no-unreachable
  ModalContainer.propTypes = {
    backToCrm: PropTypes.func,
    closeModal: PropTypes.func,
    handleOnSubmit: PropTypes.func,
    isDebit: PropTypes.bool,
    message: PropTypes.bool,
    showModal: PropTypes.bool,

  };
};

const handleIsCCOwnerIsThePolicyOwner = (event, setFieldValue, handleChange) => {
  const value = event.target.value;
  if (value === 'yes') {
    setFieldValue('creditcardOwnerRelationToPolicyOwner', '');
  }
  handleChange(event);
};

const CreditGuard = (props) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isDebit, setIsDebit] = useState(false);
  const [valuesFormik, setValuesFormik] = useState(null);
  const [message, setMessage] = useState(null);
  const classes = useStyles();
  const parameters = queryString.parse(props.location.search);
  const yearsOptions = utils.getYearsOptions();
  const intl = useIntl();
  const closeModal = () => {
    setOpenModal(false);
  };

  const redirectToCRM = () => {
    window.location.href = `${process.env.REACT_APP_CRM_URL}/${parameters.id}`;
  };

  const updateOpportunityAndRedirectToCRM = async () => {
    if (parameters.isUpdateReturnedFromCreditCardSimulatorField !== 'true') {
      redirectToCRM();

      return;
    }
    try {
      await updateSalesAuthenticationStep({
        creditguardStatus: CREDITCARD_SIMULATOR_STATUSES.RETURNED_FROM_SIMULATOR,
        potentialId: parameters.id,
        token: parameters.token,
      });
    } catch (error) {
      let errorMessage = error.message;
      if (error.message.includes('Salesforce authorization token is missing or expired')) {
        errorMessage = messages('simulator.creditGuard.error.token_missed');
      }
      setMessage(errorMessage);
    } finally {
      redirectToCRM();
    }
  };

  const handleOnSubmit = async (confirm, values) => {
    if (confirm) {
      try {
        setLoading({loading: true});
        const response = await updatePaymentPotential(values);

        if (!response.success) {
          let errorMessage = response?.status ? `(${response?.status}) ${response.message}` : response.message;
          if (response.message.includes('Salesforce authorization token is missing or expired')) {
            errorMessage = messages('simulator.creditGuard.error.token_missed');
          }
          setMessage(errorMessage);
          setLoading(false);
          setOpenModal(true);
        } else {
          await updateOpportunityAndRedirectToCRM();
        }
      } catch (error) {
        let errorMessage = error?.status ? `(${error?.status}) ${error.message}` : error.message;
        if (error.message.includes('Salesforce authorization token is missing or expired')) {
          errorMessage = messages('simulator.creditGuard.error.token_missed');
        }
        setMessage(errorMessage);
        setLoading(false);
        setOpenModal(true);
      }
    } else {
      await updateOpportunityAndRedirectToCRM();
    }
  };
  const messages = (id) => intl.formatMessage({id});
  const cascoStanding = [{label: messages('simulator.creditGuard.yes'), value: messages('simulator.creditGuard.yes')},
    {label: messages('simulator.creditGuard.no'), value: messages('simulator.creditGuard.no')}];
  const validationSchema = {
    cardHolder: yup.string().required(messages('validation.required')),
    cardType: yup.string().required(messages('validation.required')),
    creditCardNumber: yup.string()
      .required(messages('validation.required'))
      .test(NUMERIC_TEST_CASE, messages('validation.number_not_valid'), isNumericWithoutSpaces),
    creditcardOwnerRelationToPolicyOwner: yup.string().when('isCCOwnerIsThePolicyOwner',
      {
        is: (value) => value === 'no',
        then: yup.string().required('שדה חובה'),
      }),
    cvv: yup.string()
      .required(messages('validation.required'))
      .test(NUMERIC_TEST_CASE, messages('validation.number_not_valid'), isNumericWithoutSpaces),
    identification: validIsraeliId
      .test(NUMERIC_TEST_CASE, messages('validation.number_not_valid'), isNumericWithoutSpaces),
    month: yup.string().required(messages('validation.required')),
    paymentCasco: yup.string().required(messages('validation.required')),
    paymentMandatory: yup.string().required(messages('validation.required')),
    year: yup.string().required(messages('validation.required')),
  };
  const creditCardNumber_class = (errors_creditCardNumber, touched_creditCardNumber) => {
    if (!errors_creditCardNumber) {
      return 'helperText';
    }
    if (errors_creditCardNumber && touched_creditCardNumber) {
      return 'helperText err';
    }
    return '';
  };
  const monthsOptions = utils.getMonthsOptions();

  const paymentDetails = (values) => [...new Array(values.cardType === cardTypeEnum.debit ? 2 : 13).keys()]
    .map(item => <MenuItem key={`payment-${item}`} value={item}>{item}</MenuItem>);

  if (loading) {
    return <Loader show={loading}/>;
  }

  return (<React.Fragment>
    <ModalContainer
      showModal={openModal}
      values={valuesFormik}
      isDebit={isDebit}
      message={message}
      closeModal={closeModal}
      handleOnSubmit={() => {
        setOpenModal(false);
        setIsDebit(false);
        Object.assign(valuesFormik, {
          ownerId: parameters.ownerId,
          potentialId: parameters.id,
          token: parameters.token,
        });
        handleOnSubmit(true, valuesFormik);
      }}
      backToCrm={() => handleOnSubmit(false)}/>
    <div className={classes.purchaseContainer}>
      <Formik
        validationSchema={yup.object().shape(validationSchema)}
        initialValues={{
          cardHolder: valuesFormik ? valuesFormik.cardHolder : '',
          cardType: valuesFormik ? valuesFormik.cardType : '',
          casco_standing_order: valuesFormik ? valuesFormik.casco_standing_order :
            messages('simulator.creditGuard.yes'),
          comments: valuesFormik ? valuesFormik.comments : '',
          creditCardNumber: valuesFormik ? valuesFormik.creditCardNumber : '',
          creditcardOwnerRelationToPolicyOwner: valuesFormik ? valuesFormik.creditcardOwnerRelationToPolicyOwner : '',
          cvv: valuesFormik ? valuesFormik.cvv : '',
          identification: valuesFormik ? valuesFormik.identification : '',
          isCCOwnerIsThePolicyOwner: valuesFormik ? valuesFormik.isCCOwnerIsThePolicyOwner : 'no',
          month: valuesFormik ? valuesFormik.month : '',
          paymentCasco: valuesFormik ? valuesFormik.paymentCasco : '',
          paymentMandatory: valuesFormik ? valuesFormik.paymentMandatory : '',
          year: valuesFormik ? valuesFormik.year : '',
        }}
        onSubmit={(values) => {
          Object.assign(values, {
            potentialId: parameters.id,
            token: parameters.token,
          });
          handleOnSubmit(true, values);
        }}
      >

        {({values, errors, touched, handleChange, validateForm, handleSubmit,
          setFieldTouched, setFieldValue}) => (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              className={`${classes.form}`}
              alignItems='center'
              justify='space-between'
            >
              <Grid item md={12}>
                <h3 >
                  {messages('simulator.creditGuard.ownerTitle')}
                </h3>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='cardHolder'
                  label={messages('fields.cardHolder')}
                  labelFor='cardHolder'
                  isLabelInChild
                >
                  <TextField
                    id='cardHolder'
                    onChange={handleChange}
                    value={values.cardHolder}
                    size='small'
                    fullWidth
                    InputLabelProps={{className: classes.inputLabel}}
                  />
                </EzFormControl>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='identification'
                  label={messages('fields.identification')}
                  labelFor='identification'
                  isLabelInChild
                >
                  <TextField
                    id='identification'
                    value={values.identification}
                    onChange={handleChange}
                    size='small'
                    fullWidth
                    InputLabelProps={{className: classes.inputLabel}}
                  />
                </EzFormControl>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='isCCOwnerIsThePolicyOwner'
                  label={messages('fields.isCreditcardOwnerIsThePolicyOwner')}
                  isInlineLabel
                  overriddenStyles={yesNoOverriddenStyles}
                >
                  <YesNoRadioGroup
                    value={values.isCCOwnerIsThePolicyOwner}
                    onChange={(event) => handleIsCCOwnerIsThePolicyOwner(event, setFieldValue, handleChange)} />
                </EzFormControl>
              </Grid>
              {values.isCCOwnerIsThePolicyOwner === 'no' ?
                (<Grid item md={6}>
                  <EzFormControl
                    name='creditcardOwnerRelationToPolicyOwner'
                    label={messages('fields.creditcardOwnerRelationToPolicyOwner')}
                    labelFor='creditcard-creditcardOwnerRelationToPolicyOwner'
                    isLabelInChild
                  >
                    <TextField
                      select
                      id='creditcard-creditcardOwnerRelationToPolicyOwner'
                      labelId='creditcardOwnerRelationToPolicyOwner-label'
                      value={values.creditcardOwnerRelationToPolicyOwner}
                      onChange={handleChange}
                      size='small'
                      fullWidth
                      InputLabelProps={{className: classes.inputLabel}}
                    >
                      {CREDITCARD_OWNER_RELATION_SELECT_OPTIONS.map(relation => (
                        <MenuItem key={`relation-${relation.value}`} value={`${relation.value}`}>
                          {relation.name}
                        </MenuItem>))
                      }
                    </TextField>
                  </EzFormControl>
                </Grid>) : null
              }
              <Grid md={12}>
                <h3 >
                  {messages('simulator.creditGuard.creditCardTitle')}
                </h3>
              </Grid>
              <Grid
                item
                md={6}
                className={creditCardNumber_class(errors.creditCardNumber, touched.creditCardNumber)}
              >
                <EzFormControl
                  name='creditCardNumber'
                  label={messages('fields.credit_card_number')}
                  labelFor='creditCardNumber'
                  isLabelInChild
                >
                  <TextField
                    id='creditCardNumber'
                    value={values.creditCardNumber}
                    className={classes.creditCardInput}
                    onChange={handleChange}
                    size='small'
                    fullWidth
                    InputLabelProps={{className: classes.inputLabel}}
                  />
                </EzFormControl>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='year'
                  label={messages('fields.card_year')}
                  labelFor='creditcard-year'
                  isLabelInChild
                >
                  <TextField
                    select
                    id='creditcard-year'
                    labelId='year-label'
                    value={values.year}
                    onChange={handleChange}
                    size='small'
                    fullWidth
                    InputLabelProps={{className: classes.inputLabel}}
                  >
                    {yearsOptions.map(year => <MenuItem key={`year-${year}`} value={`${year}`}>{year}</MenuItem>)}
                  </TextField>
                </EzFormControl>
              </Grid>
              <Grid md={6}>
                <EzFormControl
                  name='month'
                  label={messages('fields.card_month')}
                  labelFor='creditcard-month'
                  isLabelInChild
                >
                  <TextField
                    select
                    id='creditcard-month'
                    labelId='month-label'
                    value={values.month}
                    onChange={handleChange}
                    fullWidth
                    size='small'
                    InputLabelProps={{className: classes.inputLabel}}
                  >
                    {monthsOptions.map(month => (<MenuItem key={`month-${month}`}
                      value={month < 10 ? `0${month}` : `${month}`}>
                      {month}
                    </MenuItem>))}
                  </TextField>
                </EzFormControl>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='cvv'
                  label={messages('fields.cvv')}
                  labelFor='cvv'
                  isLabelInChild
                >
                  <TextField
                    id='cvv'
                    value={values.cvv}
                    onChange={handleChange}
                    inputProps={{maxLength: '4'}}
                    size='small'
                    fullWidth
                    InputLabelProps={{className: classes.inputLabel}}
                  />
                </EzFormControl>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='cardType'
                  label={messages('fields.cardType')}
                  labelFor='cardType'
                  isLabelInChild
                >
                  <TextField
                    select
                    id='cardType'
                    labelId='cardType-label'
                    value={values.cardType}
                    onChange={(event) => {
                      if (event.target.value === cardTypeEnum.debit) {
                        setFieldValue('paymentCasco', 1);
                        setFieldValue('paymentMandatory', 1);
                      }
                      handleChange(event);
                    }}
                    fullWidth
                    size='small'
                    InputLabelProps={{className: classes.inputLabel}}
                  >
                    {cardTypes.map(type => (<MenuItem key={`${type.label}`}
                      value={type.value}>
                      {type.label}
                    </MenuItem>))}
                  </TextField>
                </EzFormControl>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='casco_standing_order'
                  label={messages('fields.casco_standing_order')}
                  labelFor='casco_standing_order'
                  isLabelInChild
                >
                  <TextField
                    select
                    id='casco_standing_order'
                    labelId='casco_standing_order-label'
                    value={values.casco_standing_order}
                    onChange={handleChange}
                    size='small'
                    fullWidth
                    InputLabelProps={{className: classes.inputLabel}}
                  >
                    {cascoStanding.map(type => (<MenuItem key={`${type.label}`}
                      value={type.value}>
                      {type.label}
                    </MenuItem>))}
                  </TextField>
                </EzFormControl>
              </Grid>
              <Grid item md={6} />
              <Grid md={12}>
                <h3 >
                  {messages('simulator.creditGuard.paymentTitle')}
                </h3>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='paymentCasco'
                  isLabelInChild
                  label={`${messages('fields.payments.casco')}`}
                  labelFor='paymentCasco'
                >
                  <TextField
                    select
                    id='paymentCasco'
                    value={values.paymentCasco}
                    onChange={handleChange}
                    className={classes.payment}
                    size='small'
                    InputLabelProps={{className: classes.inputLabel}}
                  >
                    {paymentDetails(values)}
                  </TextField>
                </EzFormControl>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='paymentMandatory'
                  isLabelInChild
                  label={`${messages('fields.payments.mandatory')}`}
                  labelFor='paymentMandatory'
                >
                  <TextField
                    select
                    id='paymentMandatory'
                    value={values.paymentMandatory}
                    onChange={handleChange}
                    className={classes.payment}
                    size='small'
                    InputLabelProps={{className: classes.inputLabel}}
                  >
                    {paymentDetails(values)}
                  </TextField>
                </EzFormControl>
              </Grid>
              <Grid item md={6}>
                <EzFormControl
                  name='comments'
                  label={messages('fields.comments')}
                  labelFor='comments'
                  isLabelInChild
                >
                  <TextField
                    id='comments'
                    value={values.comments}
                    onChange={handleChange}
                    size='small'
                    fullWidth
                    InputLabelProps={{className: classes.inputLabel}}
                  />
                </EzFormControl>
              </Grid>
              <div className={classes.securedPayment}>
                <div className={classes.securedPaymentInner}>{messages('offers.secured')}</div>
                <FormHelperText className={classes.creditCardHelp}>
                  <span className={classes.debitNote}>
                    {' '}
                    {messages('simulator.creditGuard.helpText1')}
                  </span>
                  <div>
                    {messages('simulator.creditGuard.helpText2')}
                  </div>

                </FormHelperText>
              </div>
              <Grid md={12} container mt={3} alignContent='center' justify='center' style={
                {
                  columnGap: 8,
                }
              }>
                <Button
                  className={`${classes.closeBtn}`}
                  onClick={updateOpportunityAndRedirectToCRM}
                >
                  {messages('simulator.creditGuard.cancel')}
                </Button>
                <Button
                  disabled={values.paymentCasco === 0 && values.paymentMandatory === 0}
                  className={`${classes.paymentBtn} init`}
                  onClick={ async () => {
                    const errorsValidation = await validateForm();

                    if (Object.keys(errorsValidation).length !== 0) {
                      Object.keys(validationSchema)
                        .forEach(field => setFieldTouched(field, true));
                    } else if (values.cardType === 'DEBIT') {
                      setValuesFormik(values);
                      setIsDebit(true);
                      setOpenModal(true);
                    } else {
                      setValuesFormik(values);
                      handleSubmit();
                    }
                  }}
                >
                  {messages('simulator.creditGuard.paymentBtn')}
                </Button>
              </Grid>
              {values.paymentCasco === 0 && values.paymentMandatory === 0 &&
                <Grid md={12} container alignContent='center' justify='center'>
                  <span className={classes.errorText}>{messages('simulator.creditGuard.paymentsRequire')}</span>
                </Grid>}
            </Grid>
          </Form>)
        }
      </Formik>
      {loading ? <Loader show={loading}/> : null}
    </div>
  </React.Fragment>);
};

CreditGuard.propTypes = {
  location: PropTypes.object,
};

export default CreditGuard;
