import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {FormControlLabel, Grid, MenuItem, Radio, TextField, Typography} from '@material-ui/core';
import {EzFormControl, DatePicker} from 'wobi-web-common';
import Moment from 'moment';
import PropTypes from 'prop-types';
import useStyles from '../useStyles';
import SimulatorExpansionPanel from '../../../components/SimulatorComponents/ExpansionPanel';
import SmallSelect from '../../../components/SimulatorComponents/SmallSelect';

const END_DATE = 20;
const GeneralDetails = React.memo(({
  formikHandleChange,
  formikSetField,
  formikValues,
  isOpportunity,
  updateFollowUpCallRandomDate,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const messages = (id) => intl.formatMessage({id});
  const nextMonth = Moment().add('1', 'month').startOf('month');
  const nextMonthTitle = `מ - 1 ${nextMonth.format('MMMM')}`;
  const formattedNextMonth = nextMonth.format('YYYY-MM-DD');
  const [endDate, setEndDate] = useState([]);
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    const day = Moment(formikValues.startDate).date();
    if (day >= END_DATE) {
      const previousMonthNextYear = Moment(formikValues.startDate).add(1, 'year').subtract(1, 'month').endOf('month');
      const currentMonthNextYear = Moment(formikValues.startDate).add(1, 'year').endOf('month');
      const format = (momentDate) => momentDate.format('DD/MM/YYYY');
      setSelectedDate(previousMonthNextYear);
      setEndDate([
        {
          text: format(previousMonthNextYear),
          value: previousMonthNextYear,
        },
        {
          text: format(currentMonthNextYear),
          value: currentMonthNextYear,
        }]);
    } else {
      setEndDate([]);
    }
  }, [formikValues.startDate]);

  return (
    <SimulatorExpansionPanel title={<Typography>{messages('simulator.title.general')}</Typography>}>
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <EzFormControl name='firstName' label={intl.formatMessage({id: 'simulator.firstName'})} isLabelInChild>
            <TextField
              id='outlined-required'
              className={`${classes.fullWidth}`}
              onChange={formikHandleChange}
              value={formikValues.firstName}
              size='small'
            />
          </EzFormControl>
        </Grid>
        <Grid item xs={2}>
          <EzFormControl name='lastName' label={intl.formatMessage({id: 'simulator.lastName'})} isLabelInChild>
            <TextField
              id='outlined-required'
              className={`${classes.fullWidth}`}
              onChange={formikHandleChange}
              value={formikValues.lastName}
              size='small'
            />
          </EzFormControl>
        </Grid>
        <Grid item xs={3}>
          <EzFormControl name='israeliId' label={intl.formatMessage({id: 'simulator.israeliId'})} isLabelInChild>
            <TextField
              id='outlined-required'
              className={`${classes.fullWidth}`}
              onChange={formikHandleChange}
              value={formikValues.israeliId}
              disabled={isOpportunity}
              size='small'
            />
          </EzFormControl>
        </Grid>
        <Grid item xs={3}>
          <EzFormControl label={intl.formatMessage({id: 'simulator.startDate'})} name='startDate' isLabelInChild>
            <DatePicker
              style={{
                height: 40,
                width: '100%',
              }}
              value={formikValues.startDate}
              onChange={(date) => {
                formikSetField('startDate', date);
                formikSetField('endDate', Moment(date).add(1, 'year').subtract(1, 'month').endOf('month').toDate());
              }}
              onAccept={() => {}}
              autoOk
              minDate={Moment()}
              minDateMessage=''
              maxDateMessage=''
              openTo='date'
              id='startDate'
              variant='inline'
              size='small'
              keyboardIcon={null}
              onBlur={updateFollowUpCallRandomDate}
            />
          </EzFormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            checked={formattedNextMonth === formikValues.startDate}
            value={formattedNextMonth}
            control={<Radio/>}
            onChange={() => {
              formikSetField('startDate', formattedNextMonth);
              formikSetField('endDate', Moment(nextMonth).add(1, 'year').subtract(1, 'month').endOf('month').toDate());
              updateFollowUpCallRandomDate(true);
            }}
            label={nextMonthTitle}
          />
        </Grid>
      </Grid>
      {endDate.length > 0 &&
      <Grid item xs={4}>
        <SmallSelect
          value={selectedDate}
          onChange={({target: {value}}) => {
            formikSetField('endDate', value);
            setSelectedDate(value);
          }
          }
          name='endDate'
          label={messages('simulator.endDate')}
        >
          {endDate.map(date => (
            <MenuItem key={`endDate-${date.value}`} value={date.value}>
              {date.text}
            </MenuItem>
          ))}
        </SmallSelect>
      </Grid>}
    </SimulatorExpansionPanel>
  );
});

GeneralDetails.propTypes = {
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikValues: PropTypes.object,
  isOpportunity: PropTypes.bool,
  updateFollowUpCallRandomDate: PropTypes.func,
};

export default GeneralDetails;
