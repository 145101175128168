import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  Modal,
  Grid,
  Button,
  Popper,
  Fade,
} from '@material-ui/core';
import * as yup from 'yup';
import {Formik, Form} from 'formik';
import {EzFormControl, DatePicker, Breakpoints as breakpoints} from 'wobi-web-common';
import {injectIntl} from 'react-intl';
import Moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import productTypes from '../../enums/product-type';
import {conditions} from '../../utils/formConditions';
import {calculateSeniority} from '../../utils/common';
import {ExpandIcon, CollapseIcon, SortIcon} from '../../components/Icons';
import {fireGtmEvent} from '../../utils/marketing.utils';
import {getOffersFields, setOffersFields} from '../../utils/persistOfferDetails';
import {DRIVERS_COUNT} from '../../constants/enums';
import {sortingOptions} from './offersSort';

const youngDriverMaxDate = Moment().subtract(17, 'year');
const youngDriverMinDate = Moment().subtract(85, 'year');
const initialFocusedDate = Moment().subtract(40, 'year');

const ToggledState = (initialState, preToggleMethod) => {
  const [state, setState] = React.useState(initialState);

  // TODO: need to aquire state from filled in driver details and picked insurance type
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    if (preToggleMethod) {
      preToggleMethod();
    }
    setState(previousOpen => !previousOpen);
  };

  return [
    state,
    setState,
    anchorRef,
    handleToggle,
  ];
};

const ToggledIcon = open => open ? <CollapseIcon/> : <ExpandIcon/>;

const ActionButton = (isOpen, isMobile, refAnchor, clickHandler, title, className, hasIcon) => (<Button
  className={`${isOpen ? 'open' : ''} ${className}`}
  variant='text'
  color='secondary'
  ref={refAnchor}
  aria-controls={isOpen ? 'menu-list-grow' : undefined}
  aria-haspopup='true'
  onClick={clickHandler}
>
  {isMobile && hasIcon ? <SortIcon/> : null}
  {title}
  {isMobile ? null : ToggledIcon(isOpen)}
</Button>);

const OffersQuickActions = ({criteriaChanged, offersFields, intl}) => {
  const messages = (id) => intl.formatMessage({id});
  const [selected, setSelected] = React.useState({
    drivers: getDriverTitle(getOffersFields().driversCount, getOffersFields().youngDriverAge, messages),
    sort: sortingOptions[0].id,
    type: getOffersFields().policyType,
  });

  const [typeOpen, setTypeOpen, typeAnchorRef, handleTypeToggle] = ToggledState(false, () => {
    fireGtmEvent('ChangeInsuranceType');
    setUpdateOpen(false);
    setSortOpen(false);
  });
  const [updateOpen, setUpdateOpen, updateAnchorRef, handleUpdateToggle] = ToggledState(false, () => {
    fireGtmEvent('ChangeDrivers');
    setTypeOpen(false);
    setSortOpen(false);
  });
  const [sortOpen, setSortOpen, sortAnchorRef, handleSortToggle] = ToggledState(false, () => {
    fireGtmEvent('SortBy');
    setUpdateOpen(false);
    setTypeOpen(false);
  });

  const handleChange = (actionType, values) => {
    switch (actionType) {
    case 'sort': {
      fireGtmEvent('SortBy', {value: values});
      setSelected(Object.assign(selected, {sort: values}));
      break;
    }
    case 'drivers': {
      setOffersFields(values);
      setSelected(Object.assign(selected, {
        drivers: getDriverTitle(values.driversCount, values.youngDriverAge, messages),
      }));
      break;
    }
    case 'type': {
      fireGtmEvent('policyTypeChanged', {selectedPolicyType: values});
      break;
    }
    default:
      break;
    }

    setSortOpen(false);
    setTypeOpen(false);
    setUpdateOpen(false);

    criteriaChanged(actionType, values);
  };

  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);

  const autoString = () => {
    if (!offersFields.autoInfo) {
      return '';
    }
    const {manufacturer, main_car_desc, car_year} = offersFields.autoInfo;
    const {licenseNumber} = offersFields;

    return `ל${manufacturer} ${main_car_desc} שנת ${car_year} ${licenseNumber ? 'מספר ' + licenseNumber : ''}`;
  };

  const getTypeTitle = (mobile) => {
    if (!selected.type) {
      return '';
    }
    return `${mobile ? '' : 'ביטוח'} ${messages(selected.type.toLowerCase())} + ${messages('mandatory')}`;
  };

  const getSortTitle = () => sortingOptions.find(option => option.id === selected.sort).title;

  return (
    <div className={classes.offersQuickContainer}>
      {isMobile ? (
        <div className={classes.buttonsRowMobile}>
          {ActionButton(sortOpen, isMobile, sortAnchorRef, handleSortToggle, getSortTitle(), 'sort', true)}
          {ActionButton(typeOpen, isMobile, typeAnchorRef, handleTypeToggle, getTypeTitle(isMobile), 'type')}
          {ActionButton(updateOpen, isMobile, updateAnchorRef, handleUpdateToggle, selected.drivers, 'drivers')}
        </div>
      ) : (
        <Grid container className={`${classes.buttonsRow} util-noHeight`}>
          <Grid container item xs={12} sm={8} alignItems='center'>
            <Grid item xs={12} sm={4} lg='auto'>
              {ActionButton(typeOpen, isMobile, typeAnchorRef, handleTypeToggle, getTypeTitle(isMobile), 'type')}
            </Grid>
            <Grid item xs={12} sm={4} lg='auto'>
              <span className='auto-info'>{ autoString() }</span>
            </Grid>
            <Grid item xs={12} sm={4} lg='auto'>
              {ActionButton(updateOpen, isMobile, updateAnchorRef, handleUpdateToggle, selected.drivers, 'drivers')}
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={4} alignItems='center' className={classes.sortBox}>
            <Grid item sm={3} md='auto'>
              <span>מיין לפי</span>
            </Grid>
            <Grid item sm={9} md='auto'>
              {ActionButton(sortOpen, isMobile, sortAnchorRef, handleSortToggle, getSortTitle(), 'sort')}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Popper className={`${classes.popperType} ${classes.popper}`} open={typeOpen} anchorEl={typeAnchorRef.current}
        transition disablePortal placement={isMobile ? 'bottom' : 'bottom-start'}>
        {({TransitionProps}) => (
          <Fade {...TransitionProps}>
            <div className={`${classes.paperContent}`}>
              {isMobile && <div className='title'>{messages('offers.update_insurance_type')}</div>}
              {
                Object.values(productTypes)
                  .filter(type => isMobile ? true : type !== selected.type)
                  .map(type => (
                    <MenuItem key={`type-${type}`} onClick={() => handleChange('type', type)}
                      selected={type === selected.type}>
                      {`ביטוח ${messages(type.toLowerCase())} + ${messages('mandatory')}`}
                    </MenuItem>
                  ))
              }
            </div>
          </Fade>)}
      </Popper>
      {isMobile ? (
        <Modal className={`${classes.popper} ${classes.popperDrivers}`} open={updateOpen}
          onClose={() => setUpdateOpen(false)}
          BackdropProps={{style: {backgroundColor: 'rgba(255, 255, 255, 0.75)'}}}>
          <div className={`${classes.paperContent} ${classes.driversDetails}`}>
            <ChangeDriverDetails handleSubmit={(values) => {
              handleChange('drivers', values);
            }} closePopper={() => setUpdateOpen(false)}/>
          </div>
        </Modal>
      ) : (
        <Popper className={`${classes.fullWidth} ${classes.popper} ${classes.popperDrivers}`} open={updateOpen}
          anchorEl={typeAnchorRef.current} transition disablePortal placement='bottom-start'
          modifiers={{flip: {enabled: false}}}>
          {({TransitionProps}) => (
            <Fade {...TransitionProps}>
              <div className={`${classes.paperContent} ${classes.driversDetails}`}>
                <ChangeDriverDetails handleSubmit={(values) => {
                  handleChange('drivers', values);
                }} closePopper={() => setUpdateOpen(false)}/>
              </div>
            </Fade>)}
        </Popper>)}
      <Popper className={`${classes.popper} ${classes.popperSort}`} open={sortOpen} anchorEl={sortAnchorRef.current}
        transition disablePortal placement='bottom-end'>
        {({TransitionProps}) => (
          <Fade {...TransitionProps}>
            <div className={`${classes.paperContent}`}>
              {isMobile && <div className='title'>{messages('offers.update_sort')}</div>}
              {
                sortingOptions
                  .filter(option => isMobile ? true : option.id !== selected.sort)
                  .map(sortOption => (
                    <MenuItem key={sortOption.id}
                      onClick={() => handleChange('sort', sortOption.id)}
                      selected={sortOption.id === selected.sort}>
                      {sortOption.title}
                    </MenuItem>
                  ))
              }
            </div>
          </Fade>)}
      </Popper>
    </div>);
};

OffersQuickActions.propTypes = {
  criteria: PropTypes.object,
  criteriaChanged: PropTypes.func,
  intl: PropTypes.object,
  offers: PropTypes.array,
  offersFields: PropTypes.object,
};

const getDriverTitle = (driversCount, age, messages) => `ל${messages('fields.drivers.' + driversCount)} מעל גיל ${age}`;

const useStyles = makeStyles(theme => ({
  buttonsRow: {
    '& .auto-info': {
      marginRight: 10,
    },
    '& Button': {
      border: '1px solid transparent',
      fontWeight: 'bold',
      height: 51,
      lineHeight: 1,
      position: 'relative',
    },
    '& button.open': {
      border: `1px solid ${agentDeveloperUtils.getSecondaryColor(100)}`,
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderRadius: 7,
    },
    '& button.open:after': {
      backgroundColor: theme.palette.background.paper,
      bottom: -1,
      content: '""',
      height: 1,
      position: 'absolute',
      width: '100%',
      zIndex: 9,
    },
    '&.util-noHeight': {
      height: 'unset',
      paddingLeft: 10,
    },
    height: 51,
  },
  buttonsRowMobile: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: 10,
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      '& .MuiButton-label > *': {
        flex: '0 0 auto',
        position: 'relative',
        right: 3,
      },
      '& button': {
        '&.open': {
          backgroundColor: theme.palette.type === 'light' ? agentDeveloperUtils.getSecondaryColor(100) :
            theme.palette.background.default,
        },
        fontSize: theme.typography.pxToRem(11),
        lineHeight: 1,
        margin: '0 3px',
      },
      '& Button': {
        '& > span': {
          justifyContent: 'space-evenly',
        },
        background: 'white',
        border: `1px solid ${agentDeveloperUtils.getSecondaryColor(190)}`,
        fontWeight: 500,
        width: '33%',
      },
      '& svg': {
        fill: agentDeveloperUtils.getSecondaryColor(300),
        height: 19,
        width: 18,
      },
    },
  },
  fullWidth: {
    width: '100%',
  },
  offersQuickContainer: {
    position: 'relative',
    zIndex: 2,
  },
  paperContent: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${agentDeveloperUtils.getSecondaryColor(100)}`,
    padding: 30,
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      border: `1px solid ${agentDeveloperUtils.getSecondaryColor(190)}`,
      marginTop: 4,
      padding: 10,
    },
  },
  popper: {
    '& .title': {
      [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {display: 'block'},
      color: agentDeveloperUtils.getSecondaryColor(300),
      display: 'none',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      lineHeight: 2,
      textAlign: 'center',
    },
    '& > div': {
      borderRadius: 12,
      padding: 10,
      [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
        borderRadius: 20,
        padding: 4,
      },
    },
    '& li': {
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' ? agentDeveloperUtils.getSecondaryColor(100) :
          theme.palette.background.default,
      },
      borderRadius: 20,
      [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
        justifyContent: 'center',
        minHeight: 'unset',
      },
    },
    borderRadius: 12,
    boxShadow: '0px 5px 6px #0000001F',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {borderRadius: 20},
  },
  popperDrivers: {
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      '& > div': {padding: 0},
      margin: '50px 0 0',
      padding: 10,
    },
    '& .title1': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(18.4),
      fontWeight: 600,
      [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
        color: agentDeveloperUtils.getSecondaryColor(300),
        fontSize: theme.typography.pxToRem(16),
      },
    },
    '& .title2': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.fontSize,
      marginBottom: 30,
      marginTop: 5,
    },
    '& .title3': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.fontSize,
    },
    '& > div': {
      boxSizing: 'border-box',
    },
    '& form': {
      '& .buttons button': {
        '&:last-child': {
          '& span': {
            width: 'auto',
          },
          backgroundColor: 'transparent',
          boxShadow: 'unset',
          color: theme.palette.text.secondary,
          justifyContent: 'flex-end',
          width: 120,
        },
        fontSize: theme.typography.pxToRem(15.75),
        fontWeight: 700,
        height: 40,
        marginTop: 30,
        padding: '5px 22px',
        width: 170,
      },
      '& .left': {marginTop: 10},
      '& .MuiFormControlLabel-root.narrow > .MuiFormControlLabel-label': {
        padding: '9px 13px',
      },
      '& .MuiFormLabel-root:not(.MuiInputLabel-shrink)': {
        fontSize: theme.typography.fontSize,
        top: -6,
      },
      '& .MuiInputAdornment-root img': {
        width: 19,
      },
      '& .MuiInputBase-root': {
        fontSize: theme.typography.pxToRem(12.25),
        fontWeight: 700,
        height: 40,
        paddingRight: 8,
      },
      '& .right': {minWidth: 276},
      boxSizing: 'border-box',
      padding: '30px',
      [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
        '& .buttons': {
          justifyContent: 'center',
          lexFlow: 'row nowrap',
        },
        '& .buttons button': {
          margin: '20px 0 10px',
          width: 'unset',
        },
        padding: 10,
      },
    },
  },
  popperSort: {
    [`@media screen and (min-width: ${breakpoints.mobile + 1}px)`]: {
      '& > div': {
        borderTopRightRadius: 0,
      },
    },
  },
  popperType: {
    [`@media screen and (min-width: ${breakpoints.mobile + 1}px)`]: {
      '& > div': {
        borderTopLeftRadius: 0,
      },
    },
  },
  sortBox: {
    '& span': {
      marginRight: 4,
    },
    justifyContent: 'flex-end',
    textAlign: 'end',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      justifyContent: 'flex-start',
    },
  },
}));

const maleFemaleSelectOptions = [
  {messageId: 'male',
    value: '1'},
  {messageId: 'female',
    value: '2'},
];

const ChangeDriverDetails = injectIntl(({intl, closePopper, handleSubmit}) => {
  const messages = id => intl.formatMessage({id});
  const defaultSeniorityOptions = calculateSeniority(Moment('01/01/1950'), messages);
  const [seniorityOptions, setSeniorityOptions] = useState(defaultSeniorityOptions);

  const handleDateChange = (date, setFieldValue) => {
    setFieldValue('youngDriverBirthDate', Moment(date).format('DD/MM/YYYY'));
    setSeniorityOptions(calculateSeniority(date, messages));
  };

  return (
    <Formik
      onSubmit={(values) => {
        const finalValues = Object.assign(values, {
          youngDriverAge: Moment().diff(Moment(values.youngDriverBirthDate, 'DD/MM/YYYY'), 'years'),
          youngDriverSeniority: String(values.youngDriverSeniority),
        });
        setOffersFields(finalValues);
        handleSubmit(finalValues, 'drivers');
        fireGtmEvent('UpdateDriversDetails', {
          DriversGender: finalValues.youngDriverGender,
          DriversNumber: finalValues.driversCount,
        });
      }}
      validationSchema={yup.object().shape({
        driversCount: yup.number().required(messages('validation.required')),
        youngDriverBirthDate: yup.mixed().required('שדה חובה')
          .test('validDate', 'תאריך שגוי', (date) => conditions.isValidDate(date))
          .test('underMax', 'לא ניתן לרכוש עבור מבוטח מתחת לגיל 17',
            (date) => conditions.isUnderMax(date, youngDriverMaxDate))
          .test('aboveMin', 'לא ניתן לרכוש עבור מבוטח מעבר לגיל 85',
            (date) => conditions.isAboveMin(date, youngDriverMinDate)),
        youngDriverGender: yup.number().required(messages('validation.required')),
        youngDriverSeniority: yup.number().required(messages('validation.required')),
      })}
      initialValues={{
        driversCount: '',
        youngDriverBirthDate: null,
        youngDriverGender: '',
        youngDriverSeniority: '',
      }}>
      {({values, handleChange, setFieldValue}) => (
        <Form>
          <div className='title1'>{ messages('offers.update_drivers_details') }</div>
          <div className='title2'>{ messages('offers.will_take_10')}</div>
          <Grid container justify='space-between'>
            <Grid item sm={12} md={4} className='right'>
              <EzFormControl name='driversCount' label={messages('fields.drivers')} isInlineLabel={false}>
                <RadioGroup
                  value={values.driversCount}
                  onChange={(event, value) => {
                    fireGtmEvent('UpdateDriversNumber', {value});
                    setFieldValue('driversCount', value);
                  }}
                  row>
                  <FormControlLabel
                    value={DRIVERS_COUNT.ONE}
                    className='narrow'
                    control={<Radio/>}
                    label={messages('fields.one_driver')}/>
                  <FormControlLabel
                    value={DRIVERS_COUNT.TWO}
                    className='narrow'
                    control={<Radio/>}
                    label={messages('fields.two_drivers')}
                  />
                  <FormControlLabel
                    value={DRIVERS_COUNT.EVERY}
                    className='narrow'
                    control={<Radio/>}
                    label={messages('fields.every_driver')}
                  />
                </RadioGroup>
              </EzFormControl>
            </Grid>
            <Grid item container className='left' spacing={1} sm={12} md={8} alignItems='flex-end'>
              {values.driversCount === DRIVERS_COUNT.TWO || values.driversCount === DRIVERS_COUNT.EVERY ?
                <Grid item xs={12}>
                  <div className='title3'>{messages('offers.fill_driver_info')}</div>
                </Grid> :
                null
              }
              <Grid item xs={12} sm={4}>
                <EzFormControl name='youngDriverGender' isInlineLabel={false}>
                  <RadioGroup
                    value={values.youngDriverGender}
                    onChange={(event, value) => {
                      fireGtmEvent('UpdateDriversGender', {value});
                      setFieldValue('youngDriverGender', value);
                    }}
                    row>
                    {maleFemaleSelectOptions.map(item => (<FormControlLabel
                      key={`gender-${item.value}`} value={item.value} control={<Radio/>}
                      label={messages(`fields.${item.messageId}`)}/>))}
                  </RadioGroup>
                </EzFormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <EzFormControl
                  name='youngDriverBirthDate'
                  label={messages('fields.youngDriverBirthDate')}
                  isLabelInChild
                >
                  <DatePicker
                    value={Moment(values.youngDriverBirthDate, 'DD/MM/YYYY').toISOString()}
                    onChange={(date) => handleDateChange(date, setFieldValue)}
                    onAccept={() => {}}
                    autoOk
                    invalidDateMessage=''
                    maxDateMessage=''
                    minDateMessage=''
                    maxDate={youngDriverMaxDate}
                    minDate={youngDriverMinDate}
                    initialFocusedDate={initialFocusedDate}
                    variant='inline'/>
                </EzFormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <EzFormControl name='youngDriverSeniority' label={messages('fields.youngDriverSeniority')} isInputLabel>
                  <Select value={values.youngDriverSeniority} fullWidth
                    onChange={handleChange}>
                    {seniorityOptions.map(option => (<MenuItem key={`youngDriverSeniority-${option.value}`}
                      value={option.value}>
                      {option.text}
                    </MenuItem>))}
                  </Select>
                </EzFormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify='flex-end'>
            <Grid item sm={12} justify='flex-end' container className='buttons'>
              <Button type='submit'>{messages('offers.update_changes')}</Button>
              <Button onClick={closePopper}>{messages('offers.cancel')}</Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});

export default injectIntl(OffersQuickActions);
