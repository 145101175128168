import {makeStyles} from '@material-ui/core';
import {Black} from 'wobi-web-common/dist/Colors';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';

const useStyles = makeStyles(theme => ({
  addressSection: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: [[10, 0]],
  },
  autoFillButton: {
    fontSize: '0.75rem',
    left: 140,
    lineHeight: 1,
    position: 'absolute',
    top: 12,
    width: 'auto',
  },
  autoInfoDetails: {
    '& .borderBottom': {
      '&:first-child': {
        paddingTop: 0,
      },
      '&:last-child': {
        borderBottom: 'none',
      },
      borderBottom: `2px dotted ${agentDeveloperUtils.getSecondaryColor(100)}`,
    },
    '& .borderBottom2': {
      borderBottom: `2px dotted ${agentDeveloperUtils.getSecondaryColor(100)}`,
    },
    '& .details': {
      '& .brand-logo': {
        borderRight: '1px solid #ECF5F8',
        marginRight: 10,
        paddingRight: 10,
        width: 50,
      },
      '& > div': {
        '& > span': {
          fontWeight: 'normal',
          width: 90,
        },
        alignItems: 'center',
        color: agentDeveloperUtils.getSecondaryColor(300),
        display: 'flex',
        fontSize: 16,
        fontWeight: 600,

        height: 60,
      },
      marginBottom: 30,
    },

    '& .title-row': {
      color: agentDeveloperUtils.getConvertedColor(Black[300]),
      fontSize: 21,
      fontWeight: 500,
    },
  },
  buildingNumber: {
    '& input': {
      '&::placeholder': {
        color: 'black',
        fontSize: 13,
      },
    },
  },
  color: agentDeveloperUtils.getConvertedColor(Black[300]),
  defaultButton: {
    marginLeft: 20,
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexRow: {
    '& > *': {
      marginRight: 12,
    },
    display: 'flex',
  },
  fontSize: 13,
  fullWidth: {
    '&.no-margin': {
      marginTop: 0,
    },
    width: '100%',
  },
  hide: {
    display: 'none',
  },
  iContinuity: {
    alignItems: 'center',
    height: 60,
    marginBottom: 10,
  },
  iTypeSelect: {
    '&.mrgr0': {marginLeft: 'unset'},
    '@media screen and (max-width: 1400px)': {marginLeft: -12},
    marginLeft: '-10%',
    marginTop: 6,
  },
  lineHeight: 1.5,
  loaderContainer: {
    '&.hide': {
      margin: 0,
      maxHeight: 0,
      overflow: 'hidden',
      transition: 'max-height 0.75s ease-out',
    },
    margin: '88px 0 38px',
    maxHeight: 300,

    transition: 'max-height 0.75s ease-in',
  },
  localHeader: {
    '& img': {
      display: 'block',
    },
    backgroundColor: 'rgb(255, 255, 255)',
    padding: '10px 0',
    position: 'fixed',
    width: '100%',

    zIndex: 2,
  },
  mainContainer: {
    '& label.MuiFormLabel-root': {
      fontSize: 13,
      lineHeight: 1,
      maxWidth: '75%',
      top: -4,
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  show: {
    opacity: '1 !important',
  },
  subtitle: {
    color: agentDeveloperUtils.getConvertedColor(Black[220]),
    flexGrow: 1,
    fontSize: 14,
  },
  title: {
    color: agentDeveloperUtils.getConvertedColor(Black[300]),
    fontSize: 21,
    fontWeight: 600,
    margin: 0,
    marginBottom: 10,
  },
  tooltip: {
    '& div': {
      maxWidth: 'unset',
    },
  },
  wrapperDisabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  yesNoSelectWrapper: {
    justifyContent: 'flex-start',
  },
}));

export default useStyles;
