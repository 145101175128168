export const DRIVERS_COUNT = {
  EVERY: '-1',
  ONE: '1',
  THREE: '3',
  TWO: '2',
};

export const PRODUCT_TYPES = {
  FULL_PACKAGE: 'FULL_PACKAGE',
  FULLCOVER: 'FULLCOVER',
  INCREASING_3RDPARTY_LIMIT: 'INCREASING_3RDPARTY_LIMIT',
  MANDATORY: 'MANDATORY',
  MIRRORS_HEADLIGHTS: 'MIRRORS_HEADLIGHTS',
  ORIGINAL_WINDSHIELD: 'ORIGINAL_WINDSHIELD',
  SELF_PARTICIPATION_CANCELLATION: 'SELF_PARTICIPATION_CANCELLATION',
  THIRDPARTY: '3RDPARTY',
  THIRDPARTY_CAR_REPLACEMENT: '3RDPARTY_CAR_REPLACEMENT',
  THIRDPARTY_MOTORCYCLE: '3RDPARTY_MOTORCYCLE',
};

export const abandonmentStep = {
  ABANDONMENT_STEP_1: {
    key: 'ABANDONMENT_STEP_1', score: 2, value: 'Abandonment Step 1',
  },
  ABANDONMENT_STEP_2: {
    key: 'ABANDONMENT_STEP_2', score: 1, value: 'Abandonment Step 2',
  },
  ABANDONMENT_STEP_2_NO_SUGGESTION: {
    key: 'ABANDONMENT_STEP_2_NO_SUGGESTION', score: 1, value: 'Abandonment Step 2 - No Suggestions',
  },
  ABANDONMENT_STEP_3: {
    key: 'ABANDONMENT_STEP_3', score: 3, value: 'Abandonment Step 3',
  },
  ABANDONMENT_STEP_4: {
    key: 'ABANDONMENT_STEP_4', score: 4, value: 'Abandonment Step 4',
  },
};

export const TRIGGERED_BY = {
  CHAT: 'chat',
  EMAIL: 'Email',
  IN_WEB_ONE_CLAIM_OR_MORE: 'in_web_one_claim_or_more',
  PHONE: 'phone',
  RENEWAL: 'renewal',
  SITE: 'Site',
  WEBSITE_2CLAIMS_OR_MORE: 'website_2claims_or_more',
  WEBSITE_BLOCKED_OVERUSE_USER: 'website_blocked_overuse_user',
  WEBSITE_COMPANY_CAR: 'Website Company Car',
  WEBSITE_INSIDE_OFFER: 'website_inside_offer',
  WEBSITE_LEFT_FUNNEL: 'website_left_funnel',
  WEBSITE_NO_ACTIVE_CAR: 'website_no_active_car',
  WEBSITE_NO_OFFERS: 'website_no_offers',
  WEBSITE_OFFERS_UP_LEFT: 'website_offers_up_left',
  WEBSITE_UPPER_MOBILE_FUNNEL: 'website_upper_mobile_funnel',
};

export const CREDITCARD_SIMULATOR_STATUSES = {
  RETURNED_FROM_SIMULATOR: 'RETURNED_FROM_SIMULATOR',
};
