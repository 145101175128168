import React from 'react';
import {injectIntl} from 'react-intl';
import propTypes from 'prop-types';
import {Grid, Button} from '@material-ui/core';
import {Offer as GenericOffer} from 'wobi-web-common';
import useStyles from './useStyles';

const Offer = (props) => {
  const {data, setIsOpen, creditCardCommission, creditCardCommissionDiscount, logo} = props;
  const classes = useStyles({isMobile: false,
    isOpen: false});
  const {
    providerName,
    productLineName,
    price,
    protectionTitle,
    priceAfterDiscount,
  } = data;

  const messages = (id) => props.intl.formatMessage({id});

  return (
    <GenericOffer {...props}>
      <div
        role='button'
        tabIndex={0}
        className={[classes.offerContent, classes.offerContentClosed].join(' ')}
        onClick={() => setIsOpen(true)}
        onKeyPress={() => setIsOpen(true)}
      >
        <div className={[classes.logo, classes.logoClosed].join(' ')}>
          <img
            src={logo ? logo.imgSrc_big : ''}
            alt={`חברת ${providerName}`}
          />
          <b>{productLineName}</b>
        </div>
        <div className={[classes.left, classes.leftClosed].join(' ')}>
          <Grid
            container
            style={{position: 'relative'}}
            spacing={0}
            justify='space-between'
          >
            <Grid item xs={6}>
              {priceAfterDiscount ? (
                <div className={classes.pricesWrapDiscount}>
                  <div className={[classes.prices, classes.totalPriceDiscount].join(' ')}>
                    <div className={[classes.totalPrice, classes.totalPriceClosed].join(' ')}>
                      <span>₪</span>
                      { Math.round(creditCardCommissionDiscount + priceAfterDiscount).toLocaleString() }
                    </div>
                  </div>
                  <div className={[classes.prices, classes.totalPriceDiscount].join(' ')}>
                    <div className={[classes.totalPrice,
                      classes.totalPriceClosed,
                      classes.totalPriceCrossedClosed].join(' ')}>
                      <span>₪</span>
                      { Math.round(creditCardCommission + price).toLocaleString() }
                    </div>
                  </div>
                </div>) : (
                <div className={classes.pricesWrap}>
                  <div className={[classes.prices, classes.pricesClosed].join(' ')}>
                    <div className={[classes.totalPrice, classes.totalPriceClosed].join(' ')}>
                      <span>₪</span>
                      { Math.round(creditCardCommission + price).toLocaleString() }
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            <Grid className={classes.purchaseBtnWrapper} item xs={6}>
              <Button
                onClick={() => setIsOpen(true)}
                color='secondary'
                variant='outlined'
                className={classes.purchaseBtn}
              >
                {messages('offers.purchase')}
              </Button>
              {protectionTitle ? (
                <div className={classes.protectionWrapper}>
                  <span className={classes.protection}>{protectionTitle}</span>
                </div>
              ) : false}
            </Grid>
          </Grid>
        </div>
      </div>
    </GenericOffer>
  );
};

Offer.propTypes = {
  ...GenericOffer.propTypes,
  closeContactUsHandler: propTypes.func.isRequired,
  openContactUs: propTypes.func.isRequired,
};

export default injectIntl(Offer);
