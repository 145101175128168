import React, {Component} from 'react';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import CheckIcon from '@material-ui/icons/Check';
import {sendChurnPotentialId} from '../utils/apiHandlers';
import {getImageByAgent} from '../config/agent';

const MobileBackground = getImageByAgent('mobileBackground.png');
const companyLogo = getImageByAgent('companyLogo.svg');

class ChurnThankYouPage extends Component {
  componentDidMount() {
    const parameters = this.props.match.params;
    const {potentialId, retryMessage, thirdMessage, lastYearOtp} = parameters;
    sendChurnPotentialId({lastYearOtp, potentialId, retryMessage, thirdMessage});
  }

  render() {
    return (
      <Container background={MobileBackground}>
        <div className='inner'>
          <div className='logo-wrapper'>
            <img width='200px' src={companyLogo} alt='wobi logo'/>
          </div>
          <CheckIcon />
          <FormattedMessage tagName='div' id='thankYouSms.thankYou'/>
          <FormattedMessage tagName='div' id='thankYouSms.weWillContactYou'/>
        </div>
      </Container>
    );
  }
}

ChurnThankYouPage.propTypes = {
  match: PropTypes.object,
  sendChurnPotentialId: PropTypes.func,
};

const Container = styled.div`
  padding: 20px;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-image: url(${props => props.background});
  .inner {
    color: #046994;
    text-align: center;
  }
  .logo-wrapper {
    margin-bottom: 30px;
  }
  .check-icon {
    color: #f6921e;
    font-size: 40px;
    margin-bottom: 30px;
    border: 1px solid #f6921e;
    padding: 10px;
    border-radius: 50px;
    background-color: #fff;
  }
`;

export default withRouter(ChurnThankYouPage);
