import Moment from 'moment';
import {agentDeveloperUtils} from 'wobi-web-common';
import {ProductType} from '../enums';
import {abandonmentStep} from '../constants/enums';
import {getOffersFields, setOffersFields} from './persistOfferDetails';

const setAutoDescEscaped = (desc) => {
  if (desc.startsWith('"') && desc.endsWith('"')) {
    return desc.slice(1, -1).replace('"', '');
  }
  return desc;
};

const isObject = (object) => object !== null && typeof object === 'object';

export const handleDriverSeniorityDateChange = (
  date,
  setFieldValue,
  setSeniorityOptions,
  messages,
  fieldNamePrefix,
) => {
  if (date === null) {
    setFieldValue(`${fieldNamePrefix}DriverBirthDate`, null);
    setFieldValue(`${fieldNamePrefix}DriverAge`, '');
    setFieldValue(`${fieldNamePrefix}DriverSeniority`, '');
    return;
  }
  const isValidDate = date instanceof Date && !Number.isNaN(date);
  const driverBirthDate = Moment(date);

  if (isValidDate) {
    setFieldValue(`${fieldNamePrefix}DriverBirthDate`, driverBirthDate.format('DD/MM/YYYY'));
    setFieldValue(`${fieldNamePrefix}DriverSeniority`, '');
    setFieldValue(`${fieldNamePrefix}DriverAge`, Moment().diff(driverBirthDate, 'years'));
    setSeniorityOptions(calculateSeniority(date, messages));
  } else {
    /* case typed impossible date- show 'wrong date' validation */
    setFieldValue(`${fieldNamePrefix}DriverBirthDate`, driverBirthDate.format('DD/MM/YYYY'));
    setFieldValue(`${fieldNamePrefix}DriverAge`, '');
    setFieldValue(`${fieldNamePrefix}DriverSeniority`, '');
    setSeniorityOptions(calculateSeniority(null, messages));
  }
};

export const setAutoDesc = (subModel) => {
  const desc1 = setAutoDescEscaped(subModel.model_desc_1);
  const desc2 = setAutoDescEscaped(subModel.model_desc_2);
  const desc3 = setAutoDescEscaped(subModel.model_desc_3);
  return `${desc1} ${desc2} ${desc3}`;
};

// eslint-disable-next-line no-extra-parens
export const getCompanyLogo = (companyLogo, isXSmall) => (!companyLogo ?
  '' : isXSmall ? companyLogo.imgSrc_small : companyLogo.imgSrc_big);

export const calculateSeniority = (value, messages) => {
  const maxSeniorityPossible = 70;
  const now = Moment();
  const birthDate = Moment(value);
  const age = now.diff(birthDate, 'years');
  const months = now.diff(birthDate, 'month') - age * 12;
  const maxSeniority = age === 16 ? months >= 9 ? 1 : 0 : age - 16;
  if (Number.isNaN(maxSeniority) || maxSeniority < 0) {
    // maybe show a validation to the user instead of return
    return [];
  }

  const seniorityOptions = new Array(maxSeniority).fill('1')
    .map((value_, index) => {
      const label = index < 3 ?
        `${messages(`seniorityOptions.years-${index}`)}` :
        `${index} ${messages('seniorityOptions.years')}`;
      return {
        text: label,
        value: index.toString(),
      };
    });

  if (seniorityOptions.length < maxSeniorityPossible) {
    return seniorityOptions;
  }
  return [];
};

// eslint-disable-next-line no-extra-parens
export const getGender = gender => (gender === '1' || gender === 'Male' ? 'זכר' : 'נקבה');

export const constructedInsuranceType = (year, coverType) => {
  let timePeriod;
  let cover;

  if (coverType === '1') {
    cover = 'ביטוח מקיף';
  } else if (coverType === '2') {
    cover = 'ביטוח צד ג׳';
  } else if (coverType === '0') {
    cover = 'ללא ביטוח';
  }

  if (year === '1') {
    timePeriod = 'בשנה האחרונה';
  } else if (year === '2') {
    timePeriod = 'לפני שנתיים';
  } else if (year === '3') {
    timePeriod = 'לפני שלוש שנים';
  } else if (year === '4') {
    timePeriod = 'לפני ארבע שנים';
  }

  return `${cover} ${timePeriod}`;
};

export const constructedInsuranceClaims = (year, claimNumber, claimType) => {
  let timePeriod;

  if (year === '1') {
    timePeriod = 'בשנה האחרונה';
  } else if (year === '2') {
    timePeriod = 'בשנתיים האחרונות';
  } else if (year === '3') {
    timePeriod = 'בשלוש שנים האחרונות';
  } else if (year === '4') {
    timePeriod = 'בארבע שנים האחרונות';
  }

  return `${claimNumber === '0' ?
    'ללא' : claimNumber} תביעות ${claimType === 'injuryClaim' ? 'גוף ' : ''}${timePeriod}`;
};

export const checkFullCoverIsProtected = (offer) => offer?.protectionTitle ||
  offer.productType !== ProductType.FullCover;

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let i = 0; i < keys1.length; i++) {
    const key = keys1[i];
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
};

export const updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields = (newStepKey) => {
  try {
    const prevStepKey = getOffersFields('forceAbandonmentStepForNewLeads');
    const newScore = abandonmentStep[newStepKey].score;
    let prevScore;
    if (prevStepKey) {
      prevScore =
        abandonmentStep[prevStepKey].score;
    }
    if (!prevScore || newScore > prevScore || newStepKey === abandonmentStep.ABANDONMENT_STEP_2_NO_SUGGESTION.key) {
      setOffersFields({forceAbandonmentStepForNewLeads: newStepKey});
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateForceTriggeredByInSessionStorageOffersFields = (forceTriggeredBy) => {
  try {
    if (forceTriggeredBy) {
      setOffersFields({forceTriggeredBy});
    }
  } catch (error) {
    console.log(error);
  }
};

export const getYoungDriverMaxDate = () => Moment().subtract(17, 'year').add(1, 'month');
export const getAgentDeveloperName = () => agentDeveloperUtils.getAgentDeveloperName;

export const isValidEmail = (email) => {
  if (email) {
    const nonASCIIRegex = /[^\u0020-\u007E]+/g;
    return !nonASCIIRegex.test(email);
  }
  return '';
};
