import React, {useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as yup from 'yup';
import {Backdrop, Button, Paper, TextField} from '@material-ui/core';
import {EzFormControl, Loader, CallMePopover} from 'wobi-web-common';
import {FormattedMessage} from 'react-intl';
import {getOffersFields, setOffersFields} from '../utils/persistOfferDetails';
import {getLicenseInfo, handleContactUsSubmit} from '../utils/apiHandlers';
import {fireGtmEvent, fireGtmEventWithUserId} from '../utils/marketing.utils';
import {MAX_YEAR_FOR_ESP_QUESTION} from '../steps/tabs-contents/constants';
import {LICENSE_KEY} from '../constants/defaultValues';
import useKeyboardShortcut from '../utils/useKeyboardShortcut';
import {TRIGGERED_BY, abandonmentStep} from '../constants/enums';
import {
  updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields,
  updateForceTriggeredByInSessionStorageOffersFields,
  getAgentDeveloperName,
} from '../utils/common';
import {getImageByAgent} from '../config/agent';

const BulbOnce = getImageByAgent('bulb_once.gif');
const FoundCarOnce = getImageByAgent('we_found_car_once.gif');
const CheckIcon = getImageByAgent('task-complete.svg');

const GetAutoDetailsFrom = ({className, intl, handleNoLicenseNumberClick
  , formikSetField, setDetectEsp, setAutoInfo, setRenewal, setShowAutoInfo
  , setShowRenewalsLanding, classes, resetFormWithoutLicence, shouldAskEspQuestion,
  haveAutoAnswer, setHaveAutoAnswer, isValidAutoInfo, setIsValidAutoInfo, isMobile}) => {
  const messages = (id) => intl.formatMessage({id});
  const timeoutShowModalDetect = isMobile ? 3000 : 0;
  const [loading, setLoading] = useState(false);
  const [callMeOpen, setCallMeOpen] = useState(false);
  const [callMeSubmitted, setCallMeSubmitted] = useState(false);
  const [disabledContinue, setDisabledContinue] = useState(false);
  const [inActiveCarInfo, setInActiveCarInfo] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [callFillkeys, setCallFillkeys] = useState(false);
  const [keyLicenseNumber, setKeyLicenseNumber] = useState('');
  const keys = ['Control', ' '];
  const handleKeyboardShortcut = useCallback(() => {
    setCallFillkeys(current => !current);
    setKeyLicenseNumber(LICENSE_KEY);
  }, [setCallFillkeys]);
  useKeyboardShortcut(keys, handleKeyboardShortcut);

  const handelGetAutoInfoSubmit = async (values, validateForm, setFieldTouched, setFieldValue) => {
    const errors = await validateForm();

    const hasErrors = errors.licenseNumber;

    if (hasErrors) {
      setFieldTouched('licenseNumber', true);
      return;
    }
    if (errors.licenseNumber) {
      return;
    }

    const {licenseNumber} = values;
    formikSetField('licenseNumber', licenseNumber);
    setLoading(true);
    getAutoInfo(licenseNumber, setFieldValue);
    fireGtmEvent('LocateAuto');
  };

  // eslint-disable-next-line no-extra-parens
  const handleBrandName = brandName => (brandName.slice(0, 2) === 'מ ' ? brandName.slice(2) : brandName);

  const getAutoInfo = (licenseNumber, setFieldValue) => {
    const agentDeveloperName = getAgentDeveloperName();
    getLicenseInfo(licenseNumber, agentDeveloperName).then(resp => {
      setLoading(false);
      if (resp.success) {
        setOffersFields({licenseNumber});
        if (resp.data.policyId) {
          setRenewal(resp.data);
          setLoading(false);
          setShowRenewalsLanding(true);
          sessionStorage.setItem('isRenewal', JSON.stringify(resp.data));
          showAutoInfoTimeout();
        } else if (resp.data.active) {
          const autoInfo = resp.data;
          setHaveAutoAnswer(true);
          setIsValidAutoInfo(true);
          formikSetField('brand', autoInfo.manufacturer_id);
          formikSetField('year', autoInfo.car_year);
          formikSetField('model', autoInfo.car_model);
          formikSetField('subModel', `${autoInfo.car_model}${autoInfo.car_year}`);
          setTimeout(() => {
            setAutoInfo(autoInfo);
            resetFormWithoutLicence();
          }, timeoutShowModalDetect);
          fireGtmEvent('FindCar', {value: 'yes'});
          shouldAskEspQuestion(autoInfo.car_year <= MAX_YEAR_FOR_ESP_QUESTION);
          setDetectEsp(autoInfo.car_year <= MAX_YEAR_FOR_ESP_QUESTION &&
          autoInfo.esp === 1);

          const isEqLevel = autoInfo.eq_level >= 7;
          const eqLevelTextOption = isEqLevel ? 'yes' : autoInfo.car_year <= 2018 ? 'no' : '';

          /*
          * needed to set those values to local storage because
          * formik values is cleared from [showAutoInfo] useEffect
          * they will be restored in resetFormWithoutLicenseNumber
          * */
          setOffersFields({fcw: eqLevelTextOption, ldw: eqLevelTextOption});
          if (isEqLevel) {
            fireGtmEvent('FindCarProtection', {value: 'yes'});
          } else {
            fireGtmEvent('FindCarProtection', {value: 'no'});
          }
        } else if (!resp.data.active) {
          setInActiveCarInfo(resp.data);
          fireGtmEvent('InactiveAutoInfo');
          setFieldValue('brand', handleBrandName(resp.data.manufacturer));
          setDisabledContinue(true);
          setCallMeOpen(true);
        }
      } else {
        setHaveAutoAnswer(true);
        formikSetField('hasEspClientAnswer', '');

        showAutoInfoTimeout();
        fireGtmEvent('FindCar', {value: 'no'});
      }
    }).catch(() => {
      setHaveAutoAnswer(true);
      formikSetField('hasEspClientAnswer', '');
      fireGtmEvent('FindCar', {value: 'no'});
      showAutoInfoTimeout();
    });
  };

  const showAutoInfoTimeout = () => {
    setTimeout(() => {
      setShowAutoInfo(true);
    }, timeoutShowModalDetect);
  };

  const licenseNumberValidation = value => {
    if (!value) {
      return true;
    }

    const reg = new RegExp(/^\d+$/);

    return (value.toString().length === 7 || value.toString().length === 8) && reg.test(value);
  };

  const callMeId = callMeOpen ? 'call-me-popover' : undefined;

  const handleCallMeSubmit = async (values) => {
    fireGtmEventWithUserId(values.phone, 'InactiveAutoCallMe');
    setCallMeSubmitted(true);
    updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_1.key);
    updateForceTriggeredByInSessionStorageOffersFields(TRIGGERED_BY.WEBSITE_NO_ACTIVE_CAR);
    const autoInfo = inActiveCarInfo ? {...inActiveCarInfo, autoInfo: inActiveCarInfo} : {};
    const initialDetails = {...getOffersFields(), ...autoInfo};

    initialDetails.model_desc_1 = initialDetails.model_desc_1.replace(/סמ""ק/g, 'סמק');
    initialDetails.autoInfo.model_desc_1 = initialDetails.autoInfo.model_desc_1.replace(/סמ""ק/g, 'סמק');

    initialDetails.model_desc_2 = initialDetails.model_desc_2.replace(/סמ""ק/g, 'סמק');
    initialDetails.autoInfo.model_desc_2 = initialDetails.autoInfo.model_desc_2.replace(/סמ""ק/g, 'סמק');

    initialDetails.model_desc_3 = initialDetails.model_desc_3.replace(/סמ""ק/g, 'סמק');
    initialDetails.autoInfo.model_desc_3 = initialDetails.autoInfo.model_desc_3.replace(/סמ""ק/g, 'סמק');

    const result = await handleContactUsSubmit(
      {...values, activeCarModel: false},
      initialDetails,
      TRIGGERED_BY.WEBSITE_NO_ACTIVE_CAR,
    );
    if (result.success) {
      setTimeout(() => {
        setCallMeOpen(false);
        setTimeout(() => {
          setCallMeSubmitted(false);
        }, 500);
      }, 2000);
    }
  };

  const handleLicenseNumberChange = (changeEvent, handleChange) => {
    if (changeEvent.currentTarget.value.length > 8) {
      return;
    }
    changeEvent.currentTarget.value = changeEvent.currentTarget.value.replace(/\D/g, '');
    setKeyLicenseNumber(changeEvent.currentTarget.value);
    setDisabledContinue(false);
    handleChange(changeEvent);
  };

  const ref = useRef(null);

  return (<Formik
    validationSchema={yup.object().shape({
      licenseNumber: yup.string().required('שדה חובה')
        .test('len', 'מספר הרישוי חייב להכיל 7-8 ספרות', licenseNumberValidation),
    })}
    initialValues={{
      brand: '',
      licenseNumber: '',
    }}>
    {({values, errors, handleChange, handleBlur, validateForm, setFieldTouched, setFieldValue}) => {
      if (keyLicenseNumber) {
        values.licenseNumber = keyLicenseNumber;
      }

      return (
        <div className={`${className} ${classes.autoInfoTab}`} id='vertical-tabpanel-0' role='tabpanel'>
          <h2 className={classes.title}>{messages('auto_info.license_number_to_insure')}</h2>
          <div className={classes.subtitle}>מספר הרכב יאפשר לנו לאתר את הפרטים בעצמנו ולחסוך לך זמן בהמשך.</div>
          <div
            // eslint-disable-next-line max-len
            className={`license-number-wrapper ${classes.licenseNumberWrapper} ${classes.fullWidth} ${loading ? classes.wrapperDisabled : ''}`}>
            <div className={`${classes.textFieldWrapper}`}>
              <EzFormControl name='licenseNumber' isLabelInChild
                label={messages('auto_info.insert_license_number')}>
                <TextField
                  id='licenseNumber-selector'
                  className={classes.fullWidth}
                  onBlur={handleBlur}
                  onChange={(changeEvent) => handleLicenseNumberChange(changeEvent, handleChange)}
                  value={values.licenseNumber}
                />
              </EzFormControl>
              <img className={!errors.licenseNumber && values.licenseNumber ? classes.show : ''} alt='check'
                src={CheckIcon}/>
            </div>
            <AnchorElement ref={ref}/>
            <div className={classes.buttons}>
              <Button
                onClick={() => handelGetAutoInfoSubmit(values, validateForm, setFieldTouched, setFieldValue)}
                variant='contained'
                color='primary'
                type='submit'
                disabled={disabledContinue}
              >
                {messages('auto_info.locate_auto_info')}
              </Button>
              <Button
                style={{backgroundColor: disabledContinue && 'transparent'}}
                className={`${classes.noFill} ${classes.inline}`}
                variant='contained'
                color='primary'
                onClick={handleNoLicenseNumberClick}
                data-testid='no-license-button'
                disabled={disabledContinue}
              >
                {messages('auto_info.no_licenseNumber')}
              </Button>
              <CallMePopover
                id={callMeId} anchorEl={ref.current}
                open={callMeOpen}
                onClose={ () => setCallMeOpen(false) }
                onSubmit={handleCallMeSubmit}
                message={callMeSubmitted ? <FormattedMessage id='auto_info.call_me_message_success'/> : (
                  <div>
                    <div>
                      <FormattedMessage
                        id='auto_info.call_me_message.car_information'
                        values={{carBrand: values.brand, licenceNumber: values.licenseNumber}}
                      />
                    </div>
                    <div>
                      {messages('auto_info.call_me_message.get_back')}
                    </div>
                  </div>)}
                secondaryMessage = {messages('auto_info.call_me_message_representative')}
                callMeSubmitted={callMeSubmitted}
              />
            </div>
          </div>

          <Backdrop className={classes.loader} open={loading}>
            <Paper className={classes.loaderPaper}>
              <Loader>
                {messages('auto_info.locating')}
              </Loader>
            </Paper>
          </Backdrop>

          { isMobile && <Backdrop className={classes.loader} open={haveAutoAnswer && !loading}>
            <Paper className={classes.loaderPaper}>
              <Loader imageContainerClassName={classes.loaderInfoImageContainer}
                image={isValidAutoInfo ? FoundCarOnce : BulbOnce}
                textClassName={isValidAutoInfo ? false : classes.loaderNoInfoText}
                imageClassName={isValidAutoInfo ? false : classes.loaderNoInfoImage }>
                { !isValidAutoInfo && messages('auto_info.please_fill_manual_auto_details')}
              </Loader>
            </Paper>
          </Backdrop>}

        </div>);
    }
    }
  </Formik>
  );
};

const AnchorElement = React.forwardRef((props, ref) => (<span style={{position: 'relative'}} ref={ref}/>));

GetAutoDetailsFrom.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  formikSetField: PropTypes.func,
  handleNoLicenseNumberClick: PropTypes.func,
  haveAutoAnswer: PropTypes.bool,
  intl: PropTypes.object,
  isMobile: PropTypes.bool,
  isValidAutoInfo: PropTypes.bool,
  resetFormWithoutLicence: PropTypes.func,
  setAutoInfo: PropTypes.func,
  setDetectEsp: PropTypes.func,
  setHaveAutoAnswer: PropTypes.func,
  setIsValidAutoInfo: PropTypes.func,
  setRenewal: PropTypes.func,
  setShowAutoInfo: PropTypes.func,
  setShowRenewalsLanding: PropTypes.func,
  shouldAskEspQuestion: PropTypes.func,

};

export default GetAutoDetailsFrom;
