// insurance companies logos
import AgricultLogo_big from '../images/company-logos/150x84/AgricultLogo.png';
import ClalLogo_big from '../images/company-logos/150x84/ClalLogo.png';
import FenixLogo_big from '../images/company-logos/150x84/FenixLogo.png';
import HachsharaLogo_big from '../images/company-logos/150x84/HachsharaLogo.png';
import HarelLogo_big from '../images/company-logos/150x84/HarelLogo.png';
import MenoraLogo_big from '../images/company-logos/150x84/MenoraLogo.png';
import ShirbitLogo_big from '../images/company-logos/150x84/ShirbitLogo.png';
import ShlomoLogo_big from '../images/company-logos/150x84/ShlomoLogo.png';
import ShomeraLogo_big from '../images/company-logos/150x84/ShomeraLogo.png';
import MigdalLogo_big from '../images/company-logos/150x84/MigdalLogo.png';
import AyalonLogo_big from '../images/company-logos/150x84/AyalonLogo.png';
import AgricultLogo_small from '../images/company-logos/88x47/AgricultLogo.png';
import ClalLogo_small from '../images/company-logos/88x47/ClalLogo.png';
import FenixLogo_small from '../images/company-logos/88x47/FenixLogo.png';
import HachsharaLogo_small from '../images/company-logos/88x47/HachsharaLogo.png';
import HarelLogo_small from '../images/company-logos/88x47/HarelLogo.png';
import MenoraLogo_small from '../images/company-logos/88x47/MenoraLogo.png';
import ShirbitLogo_small from '../images/company-logos/88x47/ShirbitLogo.png';
import ShlomoLogo_small from '../images/company-logos/88x47/ShlomoLogo.png';
import ShomeraLogo_small from '../images/company-logos/88x47/ShomeraLogo.png';
import MigdalLogo_small from '../images/company-logos/88x47/MigdalLogo.png';
import AyalonLogo_small from '../images/company-logos/88x47/AyalonLogo.png';

const companiesLogos = [
  {imgSrc_big: AgricultLogo_big, imgSrc_small: AgricultLogo_small, key: 'AgricultLogo'},
  {imgSrc_big: ClalLogo_big, imgSrc_small: ClalLogo_small, key: 'ClalLogo'},
  {imgSrc_big: FenixLogo_big, imgSrc_small: FenixLogo_small, key: 'FenixLogo'},
  {imgSrc_big: HachsharaLogo_big, imgSrc_small: HachsharaLogo_small, key: 'HachsharaLogo'},
  {imgSrc_big: HarelLogo_big, imgSrc_small: HarelLogo_small, key: 'HarelLogo'},
  {imgSrc_big: MenoraLogo_big, imgSrc_small: MenoraLogo_small, key: 'MenoraLogo'},
  {imgSrc_big: ShirbitLogo_big, imgSrc_small: ShirbitLogo_small, key: 'ShirbitLogo'},
  {imgSrc_big: ShlomoLogo_big, imgSrc_small: ShlomoLogo_small, key: 'ShlomoLogo'},
  {imgSrc_big: ShomeraLogo_big, imgSrc_small: ShomeraLogo_small, key: 'ShomeraLogo'},
  {imgSrc_big: MigdalLogo_big, imgSrc_small: MigdalLogo_small, key: 'MigdalLogo'},
  {imgSrc_big: AyalonLogo_big, imgSrc_small: AyalonLogo_small, key: 'AyalonLogo'},

];

export default companiesLogos;
