import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {makeStyles} from '@material-ui/core/styles';
import {getImageByAgent} from '../../config/agent';

const BannerImage = getImageByAgent('banners/banner1a.png');

const useStyles = makeStyles(theme => ({
  banner: {
    '& img': {
      height: 141,
      width: 375,
      [theme.breakpoints.up(1280)]: {
        height: 188,
        width: 500,
      },
    },
    margin: 0,
    order: 0,
    overflow: 'hidden',
    textAlign: 'right',
    [theme.breakpoints.down(1280)]: {margin: '30px 0 0'},
    [theme.breakpoints.down(768)]: {display: 'none'},
    [theme.breakpoints.up(769)]: {display: 'block'},
  },
}));

const BannerStep1a = ({intl}) => {
  const classes = useStyles();
  const messages = id => intl.formatMessage({id});
  return (
    <div className={classes.banner}>
      <img src={BannerImage}
        alt={`${messages('getting_started.banner1_1')} ${messages('getting_started.banner1_2')}`} />
    </div>
  );
};

BannerStep1a.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(BannerStep1a);
