// File may seem redundant as most of requests are called from a single location,
// regardless it helps see all endpoint in one location and mend them as a unit,
// will also be easier to mock later in unit tests

import {apiGetRequest, apiPostRequest} from './apiHelpers';

const GET_AUTO_INFO_MSTIMEOUT = process.env.REACT_APP_GET_AUTO_INFO_MSTIMEOUT || 14000;

export const handleContactUsSubmit = (values, initialDetails, triggeredBy) => apiPostRequest('api/v2/contact_us', {
  activeCarModel: values?.activeCarModel,
  fullName: values.name || values.fullname,
  initialDetails,
  isFromNoOffers: values.isFromNoOffers,
  offersCallId: values.offersCallId,
  phoneNumber: values.phone || values.phoneNumber,
  policyEndDate: initialDetails.endDate,
  policyStartDate: initialDetails.startDate,
  triggeredBy,
});

export const getLicenseInfo = (licenseNumber, agentDeveloperName) => apiPostRequest('api/v2/get_auto_info', {
  agentDeveloperName,
  licenseNumber: licenseNumber.toString(),
}, {}, GET_AUTO_INFO_MSTIMEOUT);

export const getLicenseInfoSimulator =
  (licenseNumber, phone, agentDeveloperName) => apiPostRequest('api/get_auto_info', {
    agentDeveloperName,
    licenseNumber: licenseNumber.toString(),
    phone,
  }, {}, GET_AUTO_INFO_MSTIMEOUT);

export const handleAdditionalDetailsSubmit = (values) => apiPostRequest('api/v2/updateCustomerAdditionalDetails',
  values);

export const checkOfferStatus = (values) => apiPostRequest('api/check_status', values);

export const getCoversTitles = (policyType) => apiGetRequest(`data/get_covers_titles?policyType=${policyType}`);

export const getAllOffers = (requestData) => apiPostRequest('api/get_offers', requestData);

export const getPotential = (requestData) => apiPostRequest('api/get_potential', requestData);

export const getFollowUpCallRandomDate = (requestData) => apiPostRequest(
  'api/simulator/get_follow_up_call_random_date',
  requestData,
);

export const getCallSummaryData = (requestData) => apiPostRequest('api/get_call_summary_data', requestData);

export const getPreferredOffers = (requestData) => apiPostRequest('api/get_preferred_offers', requestData);

export const updatePotentialDetails = (requestData) => apiPostRequest('api/update_potential_details', requestData);

export const updatePotential = (requestData) => apiPostRequest('api/v2/update_potential', requestData);

export const updatePotentialById = (requestData) => apiPostRequest('api/update_potential_by_id', requestData);

export const updateSalesAuthenticationStep =
  (requestData) => apiPostRequest('api/update_car_sales_authentication_step', requestData);

export const updatePreferredOffers = (requestData) => apiPostRequest('api/update_preferred_offers', requestData);

export const updatePreferredPotential = (requestData) => apiPostRequest('api/update_preferred_potential', requestData);

export const deletePreferredOffer = (requestData) => apiPostRequest('api/delete_preferred', requestData);

export const getToken = (details) => apiPostRequest('sms_api/v2/get_token', details);

export const validateSecret = (request) => apiPostRequest('sms_api/v2/validate_secret', request);

export const getStartingDate = () => apiGetRequest('api/get_start_date');

export const getAutoModelsAndSubModels = (data) => apiPostRequest('data/auto_models_and_sub_models', data);

export const getAutoBrandsAndYears = () => apiGetRequest('data/auto_brands_and_years');

export const verifyPayment = (data) => apiPostRequest('api/v2/verifyPayment', data);

export const updatePaymentPotential = (data) => apiPostRequest('api/create_potential_payment', data);

export const updateCustomerSelectedOffer = (data) => apiPostRequest('api/v2/updateCustomerSelectedOffer', data);

export const sendChurnPotentialId = (data) => apiPostRequest('api/churn_contact_me', data);

export const sendCallSummaryMail = (data) => apiPostRequest('api/send_call_summary_mail', data);

export const logGtmEvent = (data) => apiPostRequest('data/gtm/event', data);

export const getStreetsByCityCode = (cityCode) => apiGetRequest(`data/streets/${cityCode}`);

export const getPhoneNumber = (words) => apiGetRequest(`data/campaign_keywords?words=${words}`);

export const askToContact = (data) => apiPostRequest('api/v2/ask_to_contact', data);
