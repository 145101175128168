import React from 'react';
import {injectIntl} from 'react-intl';
import propTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import {Offer as GenericOffer} from 'wobi-web-common';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import PurchaseContainer from '../Offer-inner-comps/PurchaseContainer';
import useStyles from './useStyles';

// import { agentDeveloperUtils } from 'wobi-web-common/agentDeveloperUtils';
const Offer = (props) => {
  const {data, creditCardCommission, setCreditCardCommission, creditCardCommissionDiscount,
    setCreditCardCommissionDiscount, logo} = props;
  const classes = useStyles({isMobile: false,
    isOpen: true});
  const {
    productType,
    providerName,
    productLineName,
    price,
    mandatoryPrice,
    cascoPrice,
    cascoAfterDiscountPrice,
    priceAfterDiscount,
  } = data;

  const messages = (id) => props.intl.formatMessage({id});
  const isUsingHandelingFee = agentDeveloperUtils.isUsingCarHandlingFee();
  const carHendlingFee = agentDeveloperUtils.getCarHandlingFee();

  return (
    <GenericOffer {...props}>
      <div
        role='button'
        tabIndex={0}
        className={classes.offerContent}
      >

        <div className={classes.left}>
          <Grid
            container
            style={{flexWrap: 'nowrap', position: 'relative'}}
            spacing={0}
            justify='space-between'
          >
            <Grid item xs={3}>
              <div className={classes.logo}>
                <img
                  src={logo ? logo.imgSrc_big : ''}
                  alt={`חברת ${providerName}`}
                  data-testid='open-offer-logo-img'
                />
                <b data-testid='open-offer-product-line-name'>{productLineName}</b>
              </div>
            </Grid>
            <Grid item xs={6} >
              {priceAfterDiscount ? (
                <Grid item xs={6}
                  container
                  className={classes.allPricesWrapDiscount}
                  spacing={0}
                  justify='space-between'>
                  <Grid items xs={3} className={classes.pricesWrapDiscount}>
                    <div className={classes.pricesWrap}>
                      <div className={[classes.prices].join(' ')}>
                        <div className={classes.totalPrice}>
                          <span>₪</span>
                          { Math.round(creditCardCommissionDiscount + priceAfterDiscount).toLocaleString() }
                        </div>
                        <div className={classes.partialPrices} style={{display: 'grid'}}>
                          <span className={classes.partialPrice}>
                            {`${messages(productType.toLowerCase())}
                        ${cascoAfterDiscountPrice.toLocaleString() }`}
                          </span>
                          <span className={classes.partialPrice}>
                            {`${messages('mandatory')}
                        ${mandatoryPrice.toLocaleString()}`}
                          </span>
                          {isUsingHandelingFee && <span className={classes.partialPrice}>
                            {`${messages('דמי טיפול')}
                        ${carHendlingFee.toLocaleString()}`}
                          </span>
                          }
                          {
                            creditCardCommissionDiscount ? (
                              <span className={[classes.partialPrice, classes.creditCardCommission].join(' ')}>
                                {`${messages('offers.credit_card_commission')}
                          ${creditCardCommissionDiscount.toLocaleString()} ₪`}
                              </span>
                            ) : false
                          }
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid items xs={3} className={classes.pricesWrapDiscount}>
                    <div className={[classes.prices, classes.totalPriceCrossedOpen].join(' ')}>
                      <div className={[classes.totalPrice, classes.totalPriceDiscount].join(' ')}>
                        <span>₪</span>
                        { Math.round(creditCardCommission + price).toLocaleString() }
                      </div>
                      <div className={[classes.partialPrices].join(' ')} style={{display: 'grid'}}>
                        <span className={[classes.partialPrice, classes.partialPriceDiscount].join(' ')}>
                          {`${messages(productType.toLowerCase())}
                          ${cascoPrice.toLocaleString() }`}
                        </span>
                        <span className={[classes.partialPrice, classes.partialPriceDiscount].join(' ')}>
                          {`${messages('mandatory')}
                          ${mandatoryPrice.toLocaleString()}`}
                        </span>
                        {isUsingHandelingFee &&
                        <span className={[classes.partialPrice, classes.partialPriceDiscount].join(' ')}>
                          {`${messages('דמי טיפול')}
                        ${carHendlingFee.toLocaleString()}`}
                        </span>
                        }
                        {
                          creditCardCommission ? (
                            <span className={[classes.partialPrice, classes.creditCardCommission].join(' ')}>
                              {`${messages('offers.credit_card_commission')}
                            ${creditCardCommission.toLocaleString()} ₪`}
                            </span>
                          ) : false
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) :
                <div className={classes.pricesWrap}>
                  <div className={classes.prices}>
                    <div className={classes.totalPrice}>
                      <span>₪</span>
                      { Math.round(creditCardCommission + price).toLocaleString() }
                    </div>
                    <div className={classes.partialPrices}>
                      <span className={classes.partialPrice}>
                        {`${messages(productType.toLowerCase())}
                        ${cascoPrice.toLocaleString() }`}
                      </span>
                      <span className={classes.partialPrice}>
                        {`${messages('mandatory')}
                        ${mandatoryPrice.toLocaleString()}`}
                      </span>
                      {isUsingHandelingFee && <span className={classes.partialPrice}>
                        {`${messages('דמי טיפול')}
                        ${carHendlingFee.toLocaleString()}`}
                      </span>
                      }
                      {
                        creditCardCommission ? (
                          <span className={[classes.partialPrice, classes.creditCardCommission].join(' ')}>
                            {`${messages('offers.credit_card_commission')}
                          ${creditCardCommission.toLocaleString()} ₪`}
                          </span>
                        ) : false
                      }
                    </div>
                  </div>
                </div>}
            </Grid>
            <Grid className={classes.includedWrapper} item xs={12} lg={6}>
              <div className={classes.includedText}>{messages('offers.included1')}</div>
              <div className={classes.includedText}>{messages('offers.included2')}</div>
            </Grid>
          </Grid>
          <PurchaseContainer
            classes={classes}
            creditCardCommission={creditCardCommission}
            setCreditCardCommission={setCreditCardCommission}
            creditCardCommissionDiscount={creditCardCommissionDiscount}
            setCreditCardCommissionDiscount={setCreditCardCommissionDiscount}
            openContactUs={props.openContactUs}
            data={data}
          />
        </div>
      </div>
    </GenericOffer>
  );
};

Offer.propTypes = {
  ...GenericOffer.propTypes,
  closeContactUsHandler: propTypes.func.isRequired,
  openContactUs: propTypes.func.isRequired,
};

export default injectIntl(Offer);
