import React, {useCallback, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {ErrorMessage} from 'formik';
import {
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  SnackbarContent,
  Button,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import {Green} from 'wobi-web-common/dist/Colors';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Breakpoints as breakpoints, YesNoRadioGroup, CallMePopover, Tooltip} from 'wobi-web-common';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import AlwaysOnTooltip from '../../../components/AlwaysOnTooltip';
import {getOffersFields, setOffersFields} from '../../../utils/persistOfferDetails';
import {isFullCover, isThirdParty} from '../../../enums/product-type';
import {HISTORY_KEYS} from '../../../constants/defaultValues';
import {fireGtmEvent, gtmEventWrapper, fireGtmEventWithUserId} from '../../../utils/marketing.utils';
import {handleContactUsSubmit} from '../../../utils/apiHandlers';
import BannerStep3a from '../../../components/BannersDesktop-experiment/Banner3a';
import BannerStep3b from '../../../components/BannersDesktop-experiment/Banner3b';
import useKeyboardShortcut from '../../../utils/useKeyboardShortcut';
import {
  updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields,
  updateForceTriggeredByInSessionStorageOffersFields,
} from '../../../utils/common';
import {TRIGGERED_BY, abandonmentStep} from '../../../constants/enums';

const InsuranceHistoryTab = ({
  className,
  intl,
  handleOtpOpen,
  formikHandleChange,
  formikErrors,
  formikValues,
  formikValidateFrom,
  formikTouched,
  formikSetTouched,
}) => {
  const classes = useStyles();
  const messages = (id) => intl.formatMessage({id});

  const [callMeOpen, setCallMeOpen] = useState(false);
  const [callMeSubmitted, setCallMeSubmitted] = useState(false);

  // const [callMeSubmitSuccess, setCallMeSubmitSuccess] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [callFillkeys, setCallFillkeys] = useState(false);
  const [keysHistory, setKeysHistory] = useState({});
  const keys = ['Control', ' '];
  const handleKeyboardShortcut = useCallback(() => {
    setCallFillkeys(current => !current);
    setKeysHistory(HISTORY_KEYS);
  }, [setCallFillkeys]);
  useKeyboardShortcut(keys, handleKeyboardShortcut);
  if (Object.keys(keysHistory).length) {
    if (formikValues.hasFullInsPast !== keysHistory.hasFullInsPast) {
      formikValues.hasFullInsPast = keysHistory.hasFullInsPast;
    }
    if (formikValues.propClaimInLast3Years !== keysHistory.propClaimInLast3Years) {
      formikValues.propClaimInLast3Years = keysHistory.propClaimInLast3Years;
    }
    if (formikValues.bodyClaimInLast3Years !== keysHistory.bodyClaimInLast3Years) {
      formikValues.bodyClaimInLast3Years = keysHistory.bodyClaimInLast3Years;
    }
    if (formikValues.licenseRevocation !== keysHistory.licenseRevocation) {
      formikValues.licenseRevocation = keysHistory.licenseRevocation;
    }
  }

  const isFullCoverSelected = () => isFullCover(getOffersFields('policyType'));

  const isWithBanners = Number.parseInt(getOffersFields('experimentVariant'), 10) > 0;

  const validateAndSetFilled = async () => {
    const errors = await formikValidateFrom();
    setIsFilled(
      !Object.keys(errors).some(field => Object.keys(insuranceHistoryValidations).includes(field)),
    );
  };

  useEffect(() => {
    setCallMeSubmitted(false);

    // setCallMeSubmitSuccess(false);
    if (formikValues.propClaimInLast3Years === '2') {
      setCallMeOpen(true);
      return;
    }
    validateAndSetFilled();
  }, [formikValues]);

  const handleSubmitStep = async () => {
    const errors = await formikValidateFrom();

    const hasErrors = Object.keys(errors).some(field => Object.keys(insuranceHistoryValidations).includes(field));

    if (hasErrors) {
      Object.keys(insuranceHistoryValidations).forEach(field => formikSetTouched(field, true));
      return;
    }

    const formattedFields = {
      bodyClaimInLast3Years: Number(formikValues.bodyClaimInLast3Years),
      bodyClaimInYear: formikValues.bodyClaimInYear ? Number(formikValues.bodyClaimInYear) : '',
      insCovers: formikValues.hasFullInsPast === 'yes' ? {} : formikValues.insCovers,
      licenseRevocation: Number(formikValues.licenseRevocation),
      propClaimInLast3Years: Number(formikValues.propClaimInLast3Years),
      propClaimInYear: formikValues.propClaimInYear ? Number(formikValues.propClaimInYear) : '',
    };

    setOffersFields(Object.assign(formikValues, formattedFields));
    fireGtmEvent('ConfirmInsuranceHistory', {
      BodyClaims: formikValues.bodyClaimInYear,
      LicenseSuspend: formikValues.licenseRevocation,
      PastInsurance: formikValues.hasFullInsPast,
      PropertyClaims: formikValues.propClaimInLast3Years,
    });

    handleOtpOpen();
  };

  const handleCallMeClose = () => {
    setCallMeOpen(false);
  };

  const handleCallMeSubmit = async (values) => {
    updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_1.key);
    updateForceTriggeredByInSessionStorageOffersFields(TRIGGERED_BY.WEBSITE_2CLAIMS_OR_MORE);
    fireGtmEventWithUserId(values.phone, 'Above2PropClaimsContactUs');
    setCallMeSubmitted(true);
    const result = await handleContactUsSubmit(
      values,
      {
        ...getOffersFields(),
        bodyClaimInLast3Years: Number(formikValues.bodyClaimInLast3Years),
        bodyClaimInYear: formikValues.bodyClaimInYear ? Number(formikValues.bodyClaimInYear) : '',
        firstPropClaimInYear: 0,
        hasFullInsPast: formikValues.hasFullInsPast === 'yes',
        insCovers: formikValues.hasFullInsPast === 'yes' ? {} : formikValues.insCovers,
        licenseRevocation: Number(formikValues.licenseRevocation),
        propClaimInLast3Years: 2,
        secondPropClaimInYear: 0,
      },
      TRIGGERED_BY.WEBSITE_2CLAIMS_OR_MORE,
    );
    if (result.success) {
      // setCallMeSubmitSuccess(true);
      setTimeout(() => setCallMeOpen(false), 2000);
    }
  };

  const callMeid = callMeOpen ? 'call-me-popover' : undefined;

  const renderError = (message) => (<FormHelperText error>{message}</FormHelperText>);

  const claimsYearsOptions = () => {
    const labels = [
      'label_last_year',
      'label_2_years_ago',
      'label_3_years_ago',
    ];

    if (isFullCoverSelected()) {
      labels.push('label_4_years_ago');
    }

    return labels;
  };

  const submitButtonAriaLabel = () => {
    const nextBtnText = `${messages('insurance_history.submit_button')}`;
    if (formikValues.licenseRevocation === '-1') {
      return `${nextBtnText} ${messages('insurance_history.currently_negative_tooltip')}`;
    } else if (formikValues.licenseRevocation === '2') {
      return `${nextBtnText} ${messages('insurance_history.2_and_more_negatives_tooltip')}`;
    } else if (formikValues.bodyClaimInLast3Years === '2' || formikValues.propClaimInLast3Years === '2') {
      return `${nextBtnText} ${messages('insurance_history.2_and_more_claims')}`;
    } else if (formikErrors && Object.keys(formikErrors).length) {
      return `${messages('insurance_history.submit_button')} ${messages('validation.needToFillAllFields')}`;
    } else {
      return null;
    }
  };

  return (
    <div className={`${classes.root} ${className} ${isWithBanners ? 'with-banner' : ''}`}
      id='vertical-tabpanel-2' role='tabpanel'>
      <div className={`${classes.insuranceHistoryContainer}`}>
        <Box mb={4}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item lg={12}>
              <FormattedMessage id={isFullCoverSelected() ?
                'insurance_history.has_past_insurance_fullcover' :
                'insurance_history.has_past_insurance_3rdparty'
              }/>
            </Grid>
            <Grid item lg={12}>
              <Box component='div' display='flex' alignItems='center'>
                <YesNoRadioGroup name='hasFullInsPast' value={formikValues.hasFullInsPast}
                  onChange={(event) => {
                    gtmEventWrapper(formikHandleChange, 'PastInsurance').invoke(event);
                  }}/>
                <Tooltip name='hasFullInsPast' disableFocusListener placement='left'
                  classes={{tooltip: classes.maxWidth250}} className={classes.tooltip}
                  title={
                    <FormattedMessage
                      id={isFullCoverSelected() ?
                        'insurance_history.full_insuranse_tooltip_fullcover' :
                        'insurance_history.full_insuranse_tooltip_3rdparty'}
                    />
                  }
                >
                  <span><FormattedMessage id='text.not_sure'/></span>
                </Tooltip>
              </Box>
              <ErrorMessage name='hasFullInsPast' render={renderError}/>
            </Grid>
          </Grid>
        </Box>
        {formikValues.hasFullInsPast === 'no' ? <div>
          <Box mb={{md: 4,
            xs: 3}}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item lg={12}><FormattedMessage id='insurance_history.year_ago'/></Grid>
              <Grid item xs={12}>
                <Box component='div' display='flex' alignItems='center'>
                  <RadioGroup name='insCovers.1' value={formikValues.insCovers[1]}
                    onChange={formikHandleChange('insCovers.1')}
                    className={classes.radioGroup} row>
                    <FormControlLabel value='1' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_fullcover'/>}/>
                    <FormControlLabel value='2' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_3rdparty'/>}/>
                    <FormControlLabel value='0' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_no_insurance'/>}/>
                  </RadioGroup>
                </Box>
                {formikErrors.insCovers !== undefined && formikErrors.insCovers[1] && formikTouched.insCovers ?
                  renderError(formikErrors.insCovers[1]) : ''}
              </Grid>
            </Grid>
          </Box>
          <Box mb={{md: 4,
            xs: 3}}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item lg={12}><FormattedMessage id='insurance_history.2years_ago'/></Grid>
              <Grid item xs={12}>
                <Box component='div' display='flex' alignItems='center'>
                  <RadioGroup name='insCovers.2' value={formikValues.insCovers[2]}
                    onChange={formikHandleChange('insCovers.2')}
                    className={classes.radioGroup} row>
                    <FormControlLabel value='1' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_fullcover'/>}/>
                    <FormControlLabel value='2' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_3rdparty'/>}/>
                    <FormControlLabel value='0' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_no_insurance'/>}/>
                  </RadioGroup>
                </Box>
                {formikErrors.insCovers !== undefined && formikErrors.insCovers[2] && formikTouched.insCovers ?
                  renderError(formikErrors.insCovers[2]) : ''}
              </Grid>
            </Grid>
          </Box>
          <Box mb={{md: 4,
            xs: 3}}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item lg={12}><FormattedMessage id='insurance_history.3years_ago'/></Grid>
              <Grid item xs={12}>
                <Box component='div' display='flex' alignItems='center'>
                  <RadioGroup name='insCovers.3' value={formikValues.insCovers[3]}
                    onChange={formikHandleChange('insCovers.3')}
                    className={classes.radioGroup} row>
                    <FormControlLabel value='1' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_fullcover'/>}/>
                    <FormControlLabel value='2' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_3rdparty'/>}/>
                    <FormControlLabel value='0' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                      label={<FormattedMessage id='insurance_history.label_no_insurance'/>}/>
                  </RadioGroup>
                </Box>
                {formikErrors.insCovers !== undefined && formikErrors.insCovers[3] && formikTouched.insCovers ?
                  renderError(formikErrors.insCovers[3]) : ''}
              </Grid>
            </Grid>
          </Box>
          {
            isFullCoverSelected() ?
              <Box mb={{md: 4,
                xs: 3}}>
                <Grid container alignItems='center' spacing={1}>
                  <Grid item lg={12}><FormattedMessage id='insurance_history.4years_ago'/></Grid>
                  <Grid item xs={12}>
                    <Box component='div' display='flex' alignItems='center'>
                      <RadioGroup name='insCovers.4' value={formikValues.insCovers[4]}
                        onChange={formikHandleChange('insCovers.4')}
                        className={classes.radioGroup} row>
                        <FormControlLabel value='1' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                          label={<FormattedMessage id='insurance_history.label_fullcover'/>}/>
                        <FormControlLabel value='2' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                          label={<FormattedMessage id='insurance_history.label_3rdparty'/>}/>
                        <FormControlLabel value='0' control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                          label={<FormattedMessage id='insurance_history.label_no_insurance'/>}/>
                      </RadioGroup>
                    </Box>
                    {formikErrors.insCovers !== undefined && formikErrors.insCovers[4] && formikTouched.insCovers ?
                      renderError(formikErrors.insCovers[4]) : ''}
                  </Grid>
                </Grid>
              </Box> : false
          }
        </div> : false}
        <Box mb={{md: 4,
          xs: 3}}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item lg={12}>
              {getOffersFields('driversCount') ? (
                <FormattedMessage
                  values={{numberOfYears: isFullCoverSelected() ? 4 : 3}}
                  id={`insurance_history.property_claims.${getOffersFields('driversCount')}`}/>
              ) : null}
            </Grid>
            <Grid item lg={12}>
              <Box component='div' display='flex' alignItems='center'>
                <RadioGroup name='propClaimInLast3Years'
                  value={Number(formikValues.propClaimInLast3Years)}
                  onChange={(event) => {
                    gtmEventWrapper(formikHandleChange, 'PropertyClaims').invoke(event);
                  }}
                  className={classes.radioGroup} row>
                  <FormControlLabel value={-1} control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                    label={<FormattedMessage id='insurance_history.label_no_claims'/>}/>
                  <FormControlLabel value={1} control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                    label={<FormattedMessage id='insurance_history.label_one'/>}/>
                  <FormControlLabel value={2} control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                    label={<FormattedMessage id='insurance_history.label_two_plus'/>}/>
                </RadioGroup>
              </Box>
              <ErrorMessage name='propClaimInLast3Years' render={renderError}/>
            </Grid>
          </Grid>
        </Box>
        {Number(formikValues.propClaimInLast3Years) === 1 ?
          <Box mb={{md: 4,
            xs: 3}}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item lg={12}><FormattedMessage id='insurance_history.which_year'/></Grid>
              <Grid item lg={12}>
                <Box component='div' display='flex' alignItems='center'>
                  <RadioGroup name='propClaimInYear' value={Number(formikValues.propClaimInYear)}
                    onChange={formikHandleChange('propClaimInYear')}
                    className={classes.radioGroup} row>
                    {
                      claimsYearsOptions().map((option, index, arr) => (
                        <FormControlLabel key={`propClaimInYear-${index + 1}`} value={index + 1}
                          control={<Radio inputProps={{'aria-setsize': arr.length}}/>}
                          label={<FormattedMessage id={`insurance_history.${option}`}/>}/>))
                    }
                  </RadioGroup>
                </Box>
                <ErrorMessage name='propClaimInYear' render={renderError}/>
              </Grid>
            </Grid>
          </Box> : false}
        <Box mb={{md: 4,
          xs: 3}}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item lg={12}>
              {getOffersFields('driversCount') ? (
                <FormattedMessage
                  values={{numberOfYears: 3}}
                  id={`insurance_history.body_claims.${getOffersFields('driversCount')}`}/>
              ) : null}
            </Grid>
            <Grid item lg={12}>
              <Box component='div' display={{md: 'flex',
                xs: 'block'}} alignItems='center'
              className={classes.withBodyClaimTooltip}>
                <RadioGroup name='bodyClaimInLast3Years' value={Number(formikValues.bodyClaimInLast3Years)}
                  onChange={(event) => {
                    gtmEventWrapper(formikHandleChange, 'BodyClaims').invoke(event);
                  }}
                  className={classes.radioGroup} row>
                  <FormControlLabel value={-1} control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                    label={<FormattedMessage id='insurance_history.label_no_claims'/>}/>
                  <FormControlLabel value={1} control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                    label={<FormattedMessage id='insurance_history.label_one'/>}/>
                  <FormControlLabel value={2} control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                    label={<FormattedMessage id='insurance_history.label_two_plus'/>}/>
                </RadioGroup>
                {formikValues.bodyClaimInLast3Years === '2' ?
                  <AlwaysOnTooltip show={formikValues.bodyClaimInLast3Years === '2'}
                    className={classes.bodyClaimTooltip}
                    kind='Alert' showText>
                    <FormattedMessage id='insurance_history.2_and_more_body_claims_tooltip'/>
                  </AlwaysOnTooltip> : false
                }
              </Box>
              <ErrorMessage name='bodyClaimInLast3Years' render={renderError}/>
            </Grid>
          </Grid>
        </Box>
        {Number(formikValues.bodyClaimInLast3Years) === 1 ?
          <Box mb={{md: 4,
            xs: 3}}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item lg={12}><FormattedMessage id='insurance_history.which_year'/></Grid>
              <Grid item lg={12}>
                <Box component='div' display='flex' alignItems='center'>
                  <RadioGroup name='bodyClaimInYear'
                    value={Number(formikValues.bodyClaimInYear)}
                    onChange={formikHandleChange('bodyClaimInYear')}
                    className={classes.radioGroup} row>
                    {
                      claimsYearsOptions().filter((option, index) => index !== 3)
                        .map((option, index) => (
                          <FormControlLabel key={`bodyClaimInYear-${index + 1}`}
                            value={index + 1} control={<Radio inputProps={{'aria-setsize': '3'}}/>}
                            label={<FormattedMessage id={`insurance_history.${option}`}/>}
                          />))
                    }
                  </RadioGroup>
                </Box>
                <ErrorMessage name='bodyClaimInYear' render={renderError}/>
              </Grid>
            </Grid>
          </Box> : false}
        <Box mb={{lg: 4, xs: 2}}>
          <Grid container alignItems='center' spacing={1}>
            <Grid item lg={12}>
              <Box>
                {getOffersFields('driversCount') ? (
                  <FormattedMessage id={`insurance_history.license_revocation.${getOffersFields('driversCount')}`}/>
                ) : null}
              </Box>
            </Grid>
            <Grid item lg={12}>
              <Box component='div'
                display={{lg: 'flex', xs: 'block'}}
                alignItems='center'
                className={classes.withLicenseRevocationTooltip}
              >
                <RadioGroup name='licenseRevocation'
                  value={formikValues.licenseRevocation?.toString()}
                  onChange={(event) => {
                    gtmEventWrapper(formikHandleChange, 'LicenseSuspend').invoke(event);
                  }}
                  className={classes.radioGroup} row
                >
                  <div className={classes.wrapperOption}>
                    <FormControlLabel value='0' control={<Radio inputProps={{'aria-setsize': '4'}}/>}
                      label={<FormattedMessage id='insurance_history.label_no_negatives'/>}
                    />
                    <FormControlLabel value='-1' control={<Radio inputProps={{'aria-setsize': '4'}}/>}
                      label={<FormattedMessage id='insurance_history.label_currently_negative'/>}
                    />
                  </div>
                  <div>
                    <FormControlLabel value='1' control={<Radio inputProps={{'aria-setsize': '4'}}/>}
                      label={<FormattedMessage id='insurance_history.label_one'/>}
                    />
                    <FormControlLabel value='2' control={<Radio inputProps={{'aria-setsize': '4'}}/>}
                      label={<FormattedMessage id='insurance_history.label_two_plus'/>}
                    />
                  </div>
                </RadioGroup>
                {formikValues.licenseRevocation === '0' ?
                  <AlwaysOnTooltip
                    show={formikValues.licenseRevocation === '0'}
                    className={classes.licenseRevocationTooltip}
                    kind='Hat' showText
                  >
                    <span>
                      <span style={{color: agentDeveloperUtils.getConvertedColor(Green[2])}}>
                        <FormattedMessage
                          id='insurance_history.successful_tootip_excelent'/>
                      </span>
                      <span style={{color: agentDeveloperUtils.getConvertedColor(Green[2])}}>!</span>
                      <br/>
                      <FormattedMessage id='insurance_history.successful_tootip_text'/>
                    </span>
                  </AlwaysOnTooltip> : formikValues.licenseRevocation === '-1' ||
                    formikValues.licenseRevocation === '2' ?
                    <AlwaysOnTooltip
                      show={formikValues.licenseRevocation === '-1' || formikValues.licenseRevocation === '2'}
                      className={classes.licenseRevocationTooltip}
                      kind='Alert' showText
                    >
                      <FormattedMessage id={formikValues.licenseRevocation === '-1' ?
                        'insurance_history.currently_negative_tooltip' :
                        'insurance_history.2_and_more_negatives_tooltip'}/>
                    </AlwaysOnTooltip> :
                    <div className={classes.emptyBlock} />
                }
              </Box>
              <ErrorMessage name='licenseRevocation' render={renderError}/>
            </Grid>
          </Grid>
        </Box>
        {
          isFilled &&
            !(formikValues.licenseRevocation === '-1' ||
              formikValues.licenseRevocation === '2' ||
              formikValues.bodyClaimInLast3Years === '2') &&
            (
              <Box mb={2}>
                <SnackbarContent message={<FormattedMessage id='insurance_history.notification'/>}/>
              </Box>
            )
        }
        <Box>
          <AnchorElement/>
          <Button className={classes.submitButton}
            disabled={
              formikValues.licenseRevocation === '-1' ||
              formikValues.licenseRevocation === '2' ||
              formikValues.bodyClaimInLast3Years === '2' ||
              formikValues.propClaimInLast3Years === '2'
            }
            variant='contained'
            color='primary'
            onClick={handleSubmitStep}
            aria-label={submitButtonAriaLabel()}
            aria-describedby={callMeid}>
            <FormattedMessage id='insurance_history.submit_button'/>
          </Button>
          <CallMePopover
            id={callMeid} anchorEl={document.querySelector('#popoverAnchor')}
            open={callMeOpen}
            onClose={handleCallMeClose}
            onSubmit={handleCallMeSubmit}
            message={callMeSubmitted ? <FormattedMessage id='insurance_history.call_me_message_success'/> :
              <FormattedMessage id='insurance_history.call_me_message'/>}
            secondaryMessage = {<FormattedMessage id='insurance_history.call_me_message_representative'/>}
            callMeSubmitted={callMeSubmitted}
          />
        </Box>
      </div>
      { isWithBanners && (
        <div className='container'>
          {getOffersFields('experimentVariant') === '1' && <BannerStep3a />}
          {getOffersFields('experimentVariant') === '2' && <BannerStep3b />}
        </div>
      )}
    </div>
  );
};

const AnchorElement = () => (<span style={{position: 'relative',
  top: 20}} id='popoverAnchor'/>);

const useStyles = makeStyles(theme => ({
  bodyClaimTooltip: {
    [theme.breakpoints.up(1330)]: {marginLeft: 20},
    '&>.icon.Alert': {backgroundPositionY: 'center',
      height: 30},
  },
  emptyBlock: {
    [theme.breakpoints.down(breakpoints.mobile)]: {
      display: 'block',
      height: '42px',
      width: '100%',
    },
    display: 'none',
  },
  insuranceHistoryContainer: {
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {width: 'unset'},
    [theme.breakpoints.down(1100)]: {width: 281},
    [theme.breakpoints.up(1100)]: {width: 410},
  },
  licenseRevocationTooltip: {
    [theme.breakpoints.up(1422)]: {marginLeft: 20, maxWidth: 200},
  },
  maxWidth155: {
    maxWidth: 155,
  },
  maxWidth250: {
    maxWidth: 250,
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 15,
      },
    },
  },
  root: {
    '& .container': {
      flex: '1 1 auto',
      marginTop: 25,
      width: '65%',
    },
    '&.with-banner': {
      display: 'flex',
    },
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  tooltip: {
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
    borderBottom: '2px solid transparent',
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize,
    marginLeft: 30,
    padding: '5px 0',
    textAlign: 'right',
    transition: '0.3s all',
  },
  vAlignMiddle: {
    verticalAlign: 'middle',
  },
  withBodyClaimTooltip: {
    [theme.breakpoints.down(1330)]: {
      '& .MuiFormControlLabel-root': {marginBottom: 15},
      '& .MuiFormGroup-root': {width: 266},
      alignItems: 'flex-start',
      flexFlow: 'column nowrap',
    },
  },
  withLicenseRevocationTooltip: {
    [theme.breakpoints.down(1422)]: {
      '& .MuiFormControlLabel-root': {marginBottom: 15},
      alignItems: 'flex-start',
      flexFlow: 'column nowrap',
    },
    [theme.breakpoints.up(1422)]: {
      '& .MuiFormControlLabel-root': {marginBottom: 15},
    },
    [theme.breakpoints.between(1100, 1422)]: {
      '& .MuiFormGroup-root': {width: 410},
    },
    [theme.breakpoints.between(768, 1100)]: {
      '& .MuiFormGroup-root': {width: 281},
    },
  },
  wrapperOption: {
    [theme.breakpoints.down(breakpoints.mobile)]: {
      width: '100%',
    },
    [theme.breakpoints.up(breakpoints.mobile)]: {
      marginRight: 15,
      width: 'auto',
    },
  },
}));

InsuranceHistoryTab.propTypes = {
  className: PropTypes.string,
  formikErrors: PropTypes.object,
  formikHandleChange: PropTypes.func,
  formikSetField: PropTypes.func,
  formikSetTouched: PropTypes.func,
  formikTouched: PropTypes.object,
  formikValidateFrom: PropTypes.func,
  formikValues: PropTypes.object,
  handleOtpOpen: PropTypes.func,
  intl: PropTypes.object,
};

export const insuranceHistoryValidations = {
  bodyClaimInLast3Years: yup.string().required(<FormattedMessage id='validation.required'/>),
  bodyClaimInYear: yup.number().when('bodyClaimInLast3Years', {
    is: '1',
    then: yup.number().required(<FormattedMessage id='validation.required'/>),
  }),
  hasFullInsPast: yup.string().required(<FormattedMessage id='validation.required'/>),
  insCovers: yup.object().when('hasFullInsPast', {
    is: 'no',
    then: yup.object().shape({
      1: yup.string().required(<FormattedMessage id='validation.required'/>),
      2: yup.string().required(<FormattedMessage id='validation.required'/>),
      3: yup.string().required(<FormattedMessage id='validation.required'/>),
      4: yup.string().test('required-if-fullcover', <FormattedMessage id='validation.required'/>,
        value => isThirdParty(getOffersFields('policyType')) || value !== undefined),
    }),
  }),
  licenseRevocation: yup.string().required(<FormattedMessage id='validation.required'/>),
  propClaimInLast3Years: yup.string().required(<FormattedMessage id='validation.required'/>),
  propClaimInYear: yup.number().when('propClaimInLast3Years', {
    is: '1',
    then: yup.number().required(<FormattedMessage id='validation.required'/>),
  }),
};

export default injectIntl(InsuranceHistoryTab);
