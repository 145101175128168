import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import {Button, TextField, Grid, Box} from '@material-ui/core';
import {FormattedMessage} from 'react-intl';
import {Loader} from 'wobi-web-common';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Black} from 'wobi-web-common/dist/Colors';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';
import {fireGtmEvent} from '../utils/marketing.utils';
import {getImageByAgent} from '../config/agent';

const Coffee = getImageByAgent('coffee.svg');

const RenewalsLanding = ({className, renewal, setShowAutoInfo, setShowRenewalsLanding}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isSent, setisSent] = useState(false);

  const handleRenewalsSubmit = async () => {
    fireGtmEvent('RenewContactUs');
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setisSent(true);
    }, 1000);
  };

  const handleChooseCar = () => {
    fireGtmEvent('RenewAnotherAuto');
    setShowRenewalsLanding(false);
    setShowAutoInfo(false);
  };

  return (<Formik
    onSubmit={handleRenewalsSubmit}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={yup.object().shape({
      fullName: yup.string().required(<FormattedMessage id='validation.required'/>),
      phoneNumber: yup.string().required(<FormattedMessage id='validation.required'/>),
    })}
    initialValues={{
      fullName: renewal.name || 'empty',
      phoneNumber: renewal.phone || '0',
    }}>
    {({values, errors, handleChange, handleBlur, validateForm}) => (
      <div className={`${className}`} id='vertical-tabpanel-0' role='tabpanel'>
        <Box pr={{lg: 0,
          xs: 3}}>
          <Grid container>
            <Grid item xs={12} lg={8}>
              <h2 className={`${classes.messageTop} ${classes.textSecondary}`}>
                <FormattedMessage id='renewals.hello_message' values={{name: renewal.name}}/>
                <br/>
                <FormattedMessage
                  id={renewal.isActive ? 'renewals.renewal_message_active' : 'renewals.renewal_message_expired'}
                  values={{
                    endDate: moment(renewal.endDate).format('DD.M.YY'),
                    manufacturer: renewal.manufacturer,
                    year: renewal.year,
                  }}
                />
              </h2>
              <Box className={classes.messageBottom}><FormattedMessage id='renewals.message'/></Box>
              <Box pt={1} pb={1}>
                <Button className={classes.withArrow} variant='text' onClick={handleChooseCar}>
                  <FormattedMessage id='renewals.car_select'/>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.formContainer} pt={2} pb={3}>
          {isSent ?
            <Grid container spacing={2} alignItems='center' justify='space-around'>
              <Grid item xs={12} md={2}>
                <Box mt={1} style={{textAlign: 'center'}}>
                  <img src={Coffee} alt='coffee'/>
                </Box>
              </Grid>
              <Grid item xs={12} md={10}>
                <strong className={`${classes.formTitle} ${classes.textSecondary}`}>
                  <FormattedMessage id='renewals.success_message'/>
                </strong>
              </Grid>
            </Grid> :
            <Grid className={loading ? classes.displayNone : ''} container spacing={2} alignItems='center'>
              <Grid className={classes.displayNone} item xs={12} lg={4}>
                <TextField className={classes.fullWidth}
                  label={<FormattedMessage id='renewals.field_name'/>}
                  name='fullName'
                  value={values.fullName}
                  error={Boolean(errors.fullName)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Grid>
              <Grid className={classes.displayNone} item xs={12} lg={4}>
                <TextField className={classes.fullWidth}
                  label={<FormattedMessage id='renewals.field_phone'/>}
                  name='phoneNumber'
                  value={values.phoneNumber}
                  error={Boolean(errors.phoneNumber)}
                  onBlur={handleBlur}
                  onChange={handleChange} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button disabled={errors.fullName || errors.phoneNumber} onClick={() => {
                  handleRenewalsSubmit(values, validateForm);
                }} className={classes.fullWidth}>
                  <FormattedMessage id='renewals.send_button'/>
                </Button>
              </Grid>
            </Grid>}
          <Box className={!loading ? classes.displayNone : ''}><Loader show={loading} /></Box>
        </Box>
      </div>
    )}
  </Formik>
  );
};

RenewalsLanding.propTypes = {
  className: PropTypes.string,
  formikSetField: PropTypes.func,
  intl: PropTypes.object,
  renewal: PropTypes.object,
  setAutoInfo: PropTypes.func,
  setShowAutoInfo: PropTypes.func,
  setShowRenewalsLanding: PropTypes.func,
};

export default RenewalsLanding;

const useStyles = makeStyles(theme => ({
  displayNone: {
    display: 'none',
  },
  formContainer: {
    borderBottom: `2px dotted ${agentDeveloperUtils.getSecondaryColor(100)}`,
    borderTop: `2px dotted ${agentDeveloperUtils.getSecondaryColor(100)}`,
  },
  formTitle: {
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: 21,
    },
  },
  fullWidth: {
    width: '100%',
  },
  messageBottom: {
    color: agentDeveloperUtils.getConvertedColor(Black[300]),
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '24px',
  },
  messageTop: {
    fontSize: 23,
    fontWeight: 300,
  },
  textSecondary: {
    color: agentDeveloperUtils.getSecondaryColor(300),
  },
  withArrow: {
    '&::after': {
      borderBottom: '2px solid #fff',
      borderLeft: '2px solid #fff',
      height: 7,
      right: 17,
      transform: 'rotate(-135deg)',
      width: 7,
    },
    '&::after, &::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
    },
    '&::before': {
      backgroundColor: agentDeveloperUtils.getPrimaryColor(450),
      borderRadius: '50%',
      height: 19,
      right: 10,
      width: 19,
    },
    paddingRight: 45,
    position: 'relative',
  },
}));
