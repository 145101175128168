import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'wobi-web-common';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getImageByAgent} from '../config/agent';

const partyHat = getImageByAgent('party-hat.gif');
const useStyles = makeStyles({
  greatValueTooltip: {
    marginLeft: 20,
    opacity: 0,
    transition: '0.3s all',
  },
  show: {
    opacity: 1,
  },
});

const GreatPriceTag = (props) => {
  const classes = useStyles();

  return (
    <Tooltip interactive className={`${classes.greatValueTooltip} ${props.show ? classes.show : ''} ${props.className}`}
      placement='left'
      title={props.children}>
      <img src={partyHat} alt='party-hat'/>
    </Tooltip>
  );
};

GreatPriceTag.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  show: PropTypes.bool,
};

export default GreatPriceTag;
